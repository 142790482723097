import axios from "axios";
import { AuthHeader } from "./header";

const url = process.env.REACT_APP_SERVER_URL as string;

const createurl: any = url + "/company/create";
export const createNewCompany = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(createurl, searchObj, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const createIndustryurl: any = url + "/industry/create";
export const createNewIndustry = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(createIndustryurl, searchObj, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};
