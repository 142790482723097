import axios from "axios";
import { AuthHeader, Download_Auth } from "./header";
const url = process.env.REACT_APP_SERVER_URL as string;

export const getCountryFileIndex = (
  filterControl: any = undefined,
  sortControl: any = undefined
) => {
  return new Promise((resolve, reject) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(`${url}/fileindex/getCountryFileIndex`, {}, header)
      .then((data: any) => {
        resolve(data.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getFilterCountryFileIndex = (
  filterControl: any = undefined,
  searchResult: any = undefined,
  sortControl: any = undefined
) => {
  let sr: any = undefined;
  if (searchResult) {
    sr = searchResult;
  }
  return new Promise((resolve, reject) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(`${url}/fileindex/getFilterCountryFileIndex`, sr, header)
      .then((data: any) => {
        resolve(data.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getIndustryFileIndex = (
  filterControl: any = undefined,
  sortControl: any = undefined
) => {
  return new Promise((resolve, reject) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(`${url}/fileindex/getIndustryFileIndex`, {}, header)
      .then((data: any) => {
        resolve(data.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getCompanyFileIndex = (searchObj: any) => {
  return new Promise((resolve, reject) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(`${url}/fileindex/getCompanyFileIndex`, { q: searchObj }, header)
      .then((data: any) => {
        resolve(data.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getCompanyPubFileIndex = (searchObj: any) => {
  return new Promise((resolve, reject) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(`${url}/fileindex/getCompanyWithPub`, { q: searchObj }, header)
      .then((data: any) => {
        resolve(data.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getNumCompanies = (filter: any = {}) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(`${url}/company/getNumCompaniesCount`, filter, header)
      .then((data: any) => {
        resolve(data.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getDownloadFile = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header: any = {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(`${url}/downloadFile`, searchObj, header)
      .then((data: any) => {
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data.data]));
          const link = document.createElement("a");
          link.href = url;
          if (searchObj.doccode === "HCS") {
            link.setAttribute(
              "download",
              searchObj.filename.replace(".docx", ".pdf")
            ); //or any other extension
          } else {
            link.setAttribute("download", searchObj.filename); //or any other extension
          } //or any other extension
          document.body.appendChild(link);
          link.click();
        } else {
          console.log("File does not exist.");
        }
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const MergeCompanyFileIndex = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .put(`${url}/fileindex/mergeCompany`, searchObj, header)
      .then((data: any) => {
        resolve(data.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
