import React, { useEffect, useState } from "react";
import { checkverifyProfile, generatemailProfile } from "../controller/profile";
import { useNavigate } from "react-router-dom";
import AlertModal from "../component/AlertMessage";
import ForgetPassword from "../component/ForgetPassword";
import { Form, Button, Input, notification } from "antd";

const MyComponent = (props: any) => {
  const url = process.env.REACT_APP_URL as string;
  const [showAlert, setShowAlert] = useState(false);
  const [alertmessage, setAlertMessage] = useState("");
  const [alerttype, setAlertType] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("privilage");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("template");
    localStorage.removeItem("redirect");
    localStorage.removeItem("industry_mapping");
    localStorage.removeItem("configuration_gui");
    localStorage.removeItem("changes");
    localStorage.removeItem("original_configuration");
    localStorage.removeItem("original_company_mergedata");
    localStorage.removeItem("deletedCompany");
  });

  const handleEmail = (el: any) => {
    checkverifyProfile(el).then((res: any) => {
      if (res.data?.length > 0) {
        handleAlertType("Reset password link send");
        handleAlertMessage(
          `A reset link has been sent to you. Please check your email.`
        );
        generatemailProfile(el).then((resp: any) => {});
      } else {
        handleAlertType("Alert");
        handleAlertMessage(
          `This email doesn't exists. Please try a different email.`
        );
      }
    });
  };

  const handleAlertMessage = (el: any) => {
    setAlertMessage(el);
    setShowAlert(true);
    off();
  };

  const handleAlertType = (el: any) => {
    setAlertType(el);
    setShowAlert(true);
    off();
  };

  const off = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  const closeAlert = () => {
    setAlertType("");
    setAlertMessage("");
    setShowAlert(false);
  };

  const formData = [
    {
      key: "email",
      name: "email",
      rules: [
        {
          required: true,
          message: "Please enter a registered email address!",
        },
        {
          type: "email",
          message: "Please enter a valid email!",
        },
        {
          validator: async (rule: any, value: any) => {
            if (value) {
              let checkavailable: any = await checkverifyProfile({
                email: value,
              });
              if (checkavailable.data.length > 0) {
                if (checkavailable.data["0"].verify === false) {
                  return Promise.reject(
                    <>
                      Email address not verified! Please click for{" "}
                      <a
                        style={{
                          color: "#2B65EC",
                          textDecoration: "underline",
                        }}
                        href={
                          url +
                          "verify?id=" +
                          checkavailable.data["0"].randomcode
                        }
                      >
                        Verification
                      </a>
                    </>
                  );
                } else if (
                  checkavailable.data["0"].adminApproval === "Declined"
                ) {
                  return Promise.reject(
                    "The email you entered is rejected! Rejected account cannot reset password."
                  );
                } else {
                  return Promise.resolve();
                }
              }
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      validateTrigger: "onBlur",
      component: (
        <Input
          placeholder="Please Enter Email"
          style={{ width: "100%", textAlign: "center" }}
        />
      ),
    },
  ];

  const onFinish = (values: any) => {
    checkverifyProfile(values).then((res: any) => {
      if (res.data.length > 0) {
        generatemailProfile(res.data[0]).then((resp: any) => {
          if (resp.data) {
            notification.success({
              message: "Password Reset Link Sent!",
              description: "Please check your email.",
              placement: "top",
              duration: 3,
              top: 85,
            });
          } else {
            notification.error({
              message: "Password Reset Link Failed!",
              description: "Sending Email Failed.",
              placement: "top",
              duration: 3,
              top: 85,
            });
          }
        });
      } else {
        notification.error({
          message: "Password Reset Link Failed!",
          description: "This email doesn't exists. Please register first.",
          placement: "top",
          duration: 3,
          top: 85,
        });
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      {/* <AlertModal
        show={showAlert}
        onClose={closeAlert}
        alertmessage={alertmessage}
        alerttype={alerttype}
        alertmsg
      />
      <ForgetPassword handleEmail={handleEmail} /> */}

      <div className="forget-password-page">
        <div className="forget-password-page-form-box">
          <h1>Forget Password</h1>
          <hr style={{ margin: "10px 0px 30px 0px" }} />
          <div>
            <p>
              Please enter your email to receive the reset link for your
              password.
            </p>
          </div>
          <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            {formData.map((item: any) => (
              <Form.Item {...item}>{item.component}</Form.Item>
            ))}
            {/* <Form.Item>
              <Button className="button-green" type="primary" htmlType="submit">
                Send Email
              </Button>
            </Form.Item>
            <Button
              type="link"
              className="button-link"
              onClick={() => navigate("/login")}
            >
              Back to Login Page
            </Button> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                type="link"
                style={{
                  marginRight: "auto",
                  paddingLeft: "0px",

                  color: "rgba(0, 124, 117, 0.74)",
                }}
                onClick={() => {
                  navigate("/login", { replace: true });
                }}
              >
                <span style={{ textDecoration: "underline" }}>
                  Back to Login
                </span>
              </Button>

              <div>
                {/* <Button
                  type="link"
                  style={{ margin: "5px" }}
                  // onClick={onReset}
                >
                  Reset
                </Button> */}
                <Button
                  className="button-green"
                  htmlType="submit"
                  style={{ margin: "auto" }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default MyComponent;
