import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Modal,
  Input,
  Upload,
  message,
  notification,
  Breadcrumb,
  Select,
} from "antd";
import RT, { PageControl } from "../component/RajangTable";
import {
  UploadOutlined,
  SettingOutlined,
  EditOutlined,
} from "@ant-design/icons";
import ModalComponent from "../component/Modal";
import {
  uploadFile,
  previewFile,
  getFile,
  deleteMultiCode,
  updateIndustryCodeDictionary,
  getAllRICIndustry,
} from "../controller/industry_mapping";
import { useNavigate } from "react-router-dom";
import { addLog } from "../controller/profile";

const MyComponent = (props: any) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [fileList, setFileList] = useState<any>([]);
  const [showIndustryCode, setshowIndustryCode] = useState([]);
  const [mode, setMode] = useState(false);
  const [industrycodevalue, setIndustyCodeValue]: any = useState(null);
  const [editmode, setEditMode] = useState(false);
  const [checkedData, setCheckedData]: any = useState([]);
  const [selectedData, setSelectedData]: any = useState([]);
  const initialPageControl: PageControl = {
    numRec: 0,
    recPerPage: 10,
    pageNum: 1,
  };
  const [data, setData]: any = useState([]);
  const [subdata, setSubData]: any = useState([]);
  const [dropdownIndustryDescription, setDropdownIndustryDescription]: any =
    useState([]);
  const [dictionarylist, setDictionaryList]: any = useState([]);
  const [pageC, setPageC]: any = useState(initialPageControl);
  const [loading, setLoading] = useState(false);
  const [icdloading, setICDLoading] = useState(false);
  const [oridata, setOriData]: any = useState(null);
  const [breadcrumb, setBreadCrumb]: any = useState(props.breadcrumb);
  const navigate = useNavigate();
  // const deleteConfirmation = {
  //   title: "Delete Industry Code Dictionary",
  //   content: "Are you sure you want to delete these Industry Code Dictionary?",
  // };

  useEffect(() => {}, [oridata, industrycodevalue]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
    getFile()
      .then((res: any) => {
        if (res.data) {
          const newData = res.data.map((rec: any) => ({
            _id: rec._id,
            child: rec.child,
          }));
          newData.sort((a: any, b: any) => {
            if (a._id < b._id) {
              return -1;
            }
            if (a._id > b._id) {
              return 1;
            }
            return 0;
          });
          setData(newData);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });

    getAllRICIndustry()
      .then((res: any) => {
        if (res.data) {
          setDictionaryList(res.data);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
    setLoading(false);
  }, []);

  const refreshData = () => {
    setLoading(true);

    getFile()
      .then((res: any) => {
        if (res.data) {
          const newData = res.data.map((rec: any) => ({
            _id: rec._id,
            child: rec.child,
          }));
          newData.sort((a: any, b: any) => {
            if (a._id < b._id) {
              return -1;
            }
            if (a._id > b._id) {
              return 1;
            }
            return 0;
          });
          setData(newData);
        }
        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const handleFileChange = (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    setFileList(fileList);
    form.setFieldsValue({ attachment: fileList[0] });
    let formData: any = new FormData();
    formData.append("files", fileList[0].originFileObj);
    previewFile(formData)
      .then((res: any) => {
        // console.log("res", res);
        if (res.status === "done") {
          form.setFieldsValue({ attachment: res.filename[0].filename });
          info.file.status = "done";
        } else {
          info.file.status = "error";
        }
        if (info.file.status !== "uploading") {
        }
        if (info.file.status === "done") {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      })
      .catch((error) => {
        message.error("File upload failed");
      });
  };

  const handleRemove = (file: any) => {
    setFileList([]);
    form.setFieldsValue({ attachment: [] });
  };

  const handleSelected = (data: any) => {
    selectedData[pageC.pageNum] = data;
    data.forEach((element: any) => {
      selectedData[pageC.pageNum].push(element);
    });
    setCheckedData(data);
  };

  const formData = [
    {
      key: "attachment",
      name: "attachment",
      valuePropName: "fileList",
      getValueFromEvent: (event: any) => {
        return event?.fileList;
      },
      rules: [
        // { required: true, message: "Please input your file!" },
        {
          validator(_: any, fileList: any) {
            return new Promise((resolve: any, reject: any) => {
              if (fileList && fileList.length > 0) {
                // If the file has been uploaded, return Promise.resolve()
                resolve();
              } else {
                // If the file has not been uploaded, return Promise.reject() with an error message
                reject(new Error("Please upload an Excel file."));
              }
            });
          },
        },
      ],
      component: (
        <div>
          <div>
            <Upload
              listType="text"
              style={{ width: "100%", border: "1px solid red" }}
              accept=".xls, .xlsx"
              customRequest={(info: any) => {
                setFileList([info.file]);
              }}
              onChange={handleFileChange}
              onRemove={handleRemove}
              maxCount={1}
              showUploadList={{
                showPreviewIcon: false,
                showRemoveIcon: true,
                showDownloadIcon: false,
              }}
              beforeUpload={(file: any) => {
                const excel =
                  file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                  file.type === "application/vnd.ms-excel";
                if (!excel) {
                  message.error(`${file.name} is not a excel file`);
                }
                return excel || Upload.LIST_IGNORE;
              }}
            >
              <Button>Upload File</Button>
            </Upload>
          </div>
        </div>
      ),
    },
    {
      key: "dcode",
      name: "dcode",
      rules: [
        { required: true, message: "Please select an Industry Code." },
        // {
        //   validator(_: any, value: any) {
        //     return new Promise((resolve: any, reject: any) => {
        //       if (form.getFieldsValue().dcode) {
        //         // If the file has been uploaded, return Promise.resolve()
        //         resolve();
        //       } else {
        //         // If the file has not been uploaded, return Promise.reject() with an error message
        //         reject(new Error("Please select an Industry Code."));
        //       }
        //     });
        //   },
        // },
      ],
      validateTrigger: "onBlur",
      component: (
        <div>
          <label>Type of Industry Code&nbsp;</label>
          <Select
            listHeight={160}
            getPopupContainer={(trigger) => trigger.parentElement}
            style={{ width: "100%" }}
            placeholder="Industry Code Type"
            onChange={(e: any) => {
              form.setFieldsValue({
                dcode: e,
              });
              setSelectedRow({
                ...selectedRow,
                dcode: e,
              });
            }}
          >
            <Option value="aic">AIC</Option>
            <Option value="naic">NAIC</Option>
            <Option value="trbc">TRBC</Option>
          </Select>
        </div>
      ),
    },
    {
      key: "saveas",
      name: "saveas",
      rules: [
        { required: true, message: "Please enter a file name." },
        {
          validator: (rule: any, value: any) => {
            const existsInArray = data.some((obj: any) => obj._id === value);
            if (existsInArray) {
              return Promise.reject(`${value} already exists`);
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      validateTrigger: "onBlur",
      component: (
        <div>
          <label>Save as&nbsp;</label>

          <Input
            placeholder="Enter Name here"
            value={selectedRow?.saveas}
            onChange={(e: any) => {
              // assign value to form
              form.setFieldsValue({
                saveas: e.target.value,
              });
              setSelectedRow({
                ...selectedRow,
                saveas: e.target.value,
              });
            }}
          />
        </div>
      ),
    },
  ];

  const checkingIndustryCodeValue = () => {
    form.submit();
  };

  const CodeformData = [
    {
      key: "type",
      name: "type",
      initialValue: industrycodevalue,
      label: "Industry Code Dictionary",
      labelCol: { style: { paddingLeft: "5%", fontWeight: "600" } },
      rules: [
        { required: true, message: "Industry Code Dictionary cannot be empty" },
        {
          validator: (rule: any, value: any) => {
            if (value === oridata) {
              return Promise.resolve();
            } else {
              const existsInArray = data.some((obj: any) => obj._id === value);
              if (existsInArray) {
                return Promise.reject(`${value} already exists`);
              } else {
                return Promise.resolve();
              }
            }
          },
        },
      ],
      component: (
        <Input
          placeholder="Enter Name here"
          value={industrycodevalue}
          style={{ marginRight: "50px", width: "93.7%" }}
          onPressEnter={checkingIndustryCodeValue}
          onChange={(e: any) => {
            setIndustyCodeValue(e.target.value);
          }}
        />
      ),
    },
  ];

  // function to open modal when username clicked
  const openModal = (record: any) => {
    setShowModal(true);
    setSelectedRow(record);
  };

  // modal functions
  const handleCancel = () => {
    if (
      form.getFieldsValue().attachment ||
      form.getFieldsValue().saveas ||
      form.getFieldsValue().dcode
    ) {
      Modal.confirm({
        title: "Leave without uploading?",
        content: "Are you sure you want to cancel uploading the file?",
        okText: "Leave",
        cancelText: "Stay on this page",
        onOk() {
          setShowModal(false);
          setFileList([]);
          form.resetFields();
        },
      });
    } else {
      setShowModal(false);
      setFileList([]);
      form.resetFields();
    }
  };

  const handleOk = async () => {
    setShowModal(true);
    form.submit();
    setFileList([]);
  };

  const onFinish = (values: any) => {
    if (values.attachment && values.saveas && values.dcode) {
      uploadFile(values)
        .then((res: any) => {
          setLoading(true);
          setTimeout(() => {
            refreshData();
          }, 3000);
          let user: any = localStorage.getItem("user");
          let log: any = {
            page: window.location.pathname,
            action: "Create",
            datetime: new Date(),
            details: `Create Industry Code Dictionary ${values.saveas}`,
            email: user,
          };
          addLog(log);
          notification.success({
            message: `Industry Code Dictionary created!`,
            description: (
              <>
                Industry Code Dictionary <strong>{values.saveas}</strong> has
                been created successfully.
              </>
            ),
            placement: "bottomRight",
            bottom: 50,
          });
        })
        .catch((error) => {
          notification.error({
            message: `Industry Code Dictionary created!`,
            description: (
              <>
                Industry Code Dictionary <strong>{values.saveas}</strong> failed
                to been created.
              </>
            ),
            placement: "bottomRight",
            bottom: 50,
          });
        });
      form.resetFields();
      setShowModal(false);
    } else {
      message.error("Please fill in all required fields");
      setShowModal(false);
    }
  };

  const selectedIndustryCode = (data: any) => {
    setMode(true);
    setIndustyCodeValue(data._id);
    setBreadCrumb([
      ...breadcrumb,
      { _id: "Industry Code Dictionary" },
      { _id: data._id },
    ]);
    setOriData(data._id);
    const child = data.child.map((d: any) => ({
      _id: d.code,
      description: d.description,
    }));
    setSubData(child);
    const dropDownDescriptionOption = data.child.map((x: any) => ({
      label: x.description,
      value: x.description,
    }));
    setDropdownIndustryDescription(dropDownDescriptionOption);
    form.setFieldsValue({
      type: data._id,
    });
  };

  const handleDelete = (data: any) => {
    const matchingItems = dictionarylist.filter((item: any) =>
      data.includes(item.code)
    );
    const matchingCodes = matchingItems.map((item: any) => item.code);
    const uniqueCodes = Array.from(new Set(matchingCodes));
    if (uniqueCodes.length > 0) {
      uniqueCodes.forEach((code: any) => {
        notification.error({
          message: "Industry Code Dictionary deleted Failed!",
          description: (
            <>
              Unable to delete Industry Code Dictionary due to{" "}
              <strong>{code}</strong> has been used in Industry Template.
            </>
          ),
          placement: "bottomRight",
          bottom: 50,
        });
      });
    } else {
      Modal.confirm({
        title: "Delete Industry Code Dictionary",
        content: (
          <>
            Are you sure you want to delete all <strong>{data.length}</strong>{" "}
            Industry Code Dictionary? <br></br>
            <br></br>
            Important note: Deleted Industry Code Dictionary cannot be
            recovered.
          </>
        ),
        okText: "Delete",
        onOk() {
          deleteMultipleDictionary(data);
        },
      });
    }
  };

  const deleteMultipleDictionary = (data: any) => {
    deleteMultiCode(data).then((res: any) => {
      if (res.data) {
        if (data.length > 0) {
          data.forEach((code: any) => {
            let user: any = localStorage.getItem("user");
            let log: any = {
              page: window.location.pathname,
              action: "Delete",
              datetime: new Date(),
              details: `Delete Industry Code Dictionary ${code}`,
              email: user,
            };
            addLog(log);
            notification.success({
              message: "Industry Code Dictionary deleted!",
              description: (
                <>
                  Industry Code Dictionary <strong>{code}</strong> has been
                  deleted successfully.
                </>
              ),
              placement: "bottomRight",
              bottom: 50,
            });
          });
        }
        setSelectedData([]);
        setCheckedData([]);
        refreshData();
      } else {
        notification.error({
          message: "Failed to delete Industry Code Dictionary!",
          description: (
            <>
              Industry Code Dictionary <strong>{industrycodevalue}</strong> has
              not been deleted.
            </>
          ),
          placement: "bottomRight",
          bottom: 50,
        });
        setSelectedData([]);
        setCheckedData([]);
        refreshData();
      }
    });
  };

  const handleUpdate = () => {
    // setEditMode(false);
    if (oridata !== industrycodevalue) {
      form.submit();
      setBreadCrumb([]);
      let p = { oritype: oridata, chgtype: industrycodevalue };
      updateIndustryCodeDictionary(p).then((res: any) => {
        if (res.data) {
          let user: any = localStorage.getItem("user");
          let log: any = {
            page: window.location.pathname,
            action: "Update",
            datetime: new Date(),
            details: `Update Industry Code Dictionary ${industrycodevalue}`,
            email: user,
          };
          addLog(log);
          notification.success({
            message: `Industry Code Dictionary updated!`,
            description: (
              <>
                Industry Code Dictionary <strong>{industrycodevalue}</strong>{" "}
                has been updated successfully.
              </>
            ),
            placement: "bottomRight",
            bottom: 50,
          });
          setMode(false);
          refreshData();
          navigate("/industry_mapping", {
            replace: true,
            state: {
              mode: "code",
              refresh: true,
            },
          });
        } else {
          notification.error({
            message: `Failed to update Industry Code Dictionary!`,
            description: (
              <>
                Industry Code Dictionary <strong>{industrycodevalue}</strong>{" "}
                has not been updated.
              </>
            ),
            placement: "bottomRight",
            bottom: 50,
          });
        }
      });
    }
  };

  const breadcrumbCOM = (el: any) => {
    if (el) {
      return el.map((d: any, index: any) => {
        return (
          <React.Fragment key={d._id}>
            {ComRenderRow(d._id, index)}
          </React.Fragment>
        );
      });
    }
  };

  const ComRenderRow = (d: any, index: any) => {
    if (d) {
      if (index === 0) {
        return (
          <>
            <Breadcrumb.Item>
              <a
                onClick={() => (
                  setMode(false), setEditMode(false), setBreadCrumb([])
                )}
              >
                {d}
              </a>
            </Breadcrumb.Item>
          </>
        );
      }
      return (
        <>
          <Breadcrumb.Item>
            <a>{d}</a>
          </Breadcrumb.Item>
        </>
      );
    }
  };

  return (
    <>
      {showModal && (
        <ModalComponent
          title={
            <>
              <UploadOutlined />
              &nbsp;Upload File
            </>
          }
          open={showModal}
          visible={showModal}
          closable={false}
          maskClosable={false}
          mask={true}
          keyboard={false}
          handleOk={handleOk}
          handleCancel={handleCancel}
          okText="Upload this file"
          cancelText="Cancel"
        >
          <Form
            form={form}
            onFinish={onFinish}
            // labelCol={{ span: 4 }}
            // wrapperCol={{ span: 16 }}
            // layout="vertical"
          >
            {formData.map((item) => (
              <div key={item.key}>
                {item.component}
                <Form.Item {...item} />
              </div>
            ))}
          </Form>
        </ModalComponent>
      )}
      {mode === false && (
        <div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              width: "50%",
              textAlign: "center",
            }}
          >
            <div style={{ marginBottom: "15px", marginTop: "15px" }}>
              Industry Code Dictionary
            </div>
            <Button
              type="text"
              icon={<UploadOutlined />}
              style={{
                width: "100%",
                marginBottom: "15px",
                marginTop: "15px",
                border: "1px solid #2C86DA",
                color: "#2C86DA",
              }}
              onClick={openModal}
            >
              Upload file
            </Button>
          </div>
          <div style={{ width: "60%" }}>
            <RT
              tablewidth="90%"
              hasSelectAll={true}
              data={data}
              cols={[
                {
                  fieldname: "_id",
                  width: "90%",
                  label: "Industry Code Dictionary",
                  align: "left",
                  clickable: "true",
                },
              ]}
              pageControl={pageC}
              action={["delete", "reset"]}
              rowSelection={"true"}
              loading={loading}
              type={"small"}
              selectedIndustryCode={selectedIndustryCode}
              removeData={selectedData}
              checkedData={checkedData}
              handleSelected={handleSelected}
              handleLoadData={refreshData}
              handleDelete={handleDelete}
              // deleteConfirmation={deleteConfirmation}
              deleteOutside
            ></RT>
          </div>
        </div>
      )}
      {mode === true && (
        <>
          <div
            style={{
              height: "10%",
              margin: "auto",
              paddingLeft: "4%",
              paddingTop: "20px",
            }}
          >
            <Breadcrumb separator=">">{breadcrumbCOM(breadcrumb)}</Breadcrumb>
          </div>
          <div>
            <Form
              form={form}
              // onFinish={onFinish}
              style={{
                width: "80%",
                paddingBottom: "100px",
                paddingTop: "-30px",
              }}
            >
              {CodeformData.map((item: any) => (
                <Form.Item {...item}>{item.component}</Form.Item>
              ))}
              <div style={{ width: "100%", marginTop: "-50px" }}>
                <RT
                  tablewidth="90%"
                  data={subdata}
                  cols={[
                    {
                      fieldname: "_id",
                      width: "20%",
                      label: "Code",
                      align: "left",
                      sortcolumn: "String",
                      filter: "true",
                    },
                    {
                      fieldname: "description",
                      width: "80%",
                      label: "Industry",
                      align: "left",
                      sortcolumn: "String",
                      filter: "true",
                      lookup: dropdownIndustryDescription,
                    },
                  ]}
                  action={["reset"]}
                  pageControl={pageC}
                  loading={loading}
                  type={"small"}
                ></RT>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "4.5%",
                  marginTop: "-30px",
                }}
              >
                <div>
                  <Button
                    htmlType="submit"
                    style={{
                      margin: "5px",
                      color: "#AAAAAA",
                      border: "1px solid #AAAAAA",
                    }}
                    onClick={() => {
                      setIndustyCodeValue(oridata);
                      form.setFieldsValue({
                        type: oridata,
                      });
                      setEditMode(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    style={{
                      margin: "5px",
                      color: "#FFFFFF",
                      backgroundColor: "#898989",
                    }}
                    onClick={() => {
                      // console.log("changed", industrycodevalue);
                      // console.log("original", oridata);

                      handleUpdate();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </>
      )}
    </>
  );
};

export default MyComponent;
