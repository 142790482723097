import axios from "axios";
import { AuthHeader } from "./header";

const url = process.env.REACT_APP_SERVER_URL as string;

const createurl: any = url + "/access/create";
export const createNewProfile = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    axios
      .post(createurl, searchObj)
      .then((res: any) => {
        // console.log("res",res)
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        // console.log("error",err)
        console.error(err);
        reject(err);
      });
  });
};

const loginurl: any = url + "/access/login";
export const LoginProfile = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    axios
      .post(loginurl, searchObj)
      .then((res: any) => {
        console.log("res", res);
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        // console.log("error",err)
        console.error(err);
        reject(err);
      });
  });
};

const verifyurl: any = url + "/access/verify";
export const VerifyProfile = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    // console.log("s", searchObj);
    axios
      .put(verifyurl, searchObj)
      .then((res: any) => {
        // console.log("res",res)
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        // console.log("error",err)
        console.error(err);
        reject(err);
      });
  });
};

const loadurl: any = url + "/profile";
export const readAllProfile = (searchObj: any = undefined) => {
  return new Promise((resolve: any, reject: any) => {
    // console.log('s',searchObj)
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .get(loadurl, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        // console.log("error",err)
        console.error(err);
        reject(err);
      });
  });
};

const checkmailurl: any = url + "/profile/checkmail";
export const checkmailProfile = (searchObj: any = undefined) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(checkmailurl, searchObj, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const checkverifyurl: any = url + "/access/checkverify";
export const checkverifyProfile = (searchObj: any = undefined) => {
  return new Promise((resolve: any, reject: any) => {
    axios
      .post(checkverifyurl, searchObj)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const generateurl: any = url + "/access/sendmail";
export const generatemailProfile = (searchObj: any = undefined) => {
  return new Promise((resolve: any, reject: any) => {
    axios
      .put(generateurl, searchObj)
      .then((res: any) => {
        console.log("res", res);
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.log("error", err);
        console.error(err);
        reject(err);
      });
  });
};

const updatepasswordurl: any = url + "/access/resetpassword";
export const resetPasswordProfile = (searchObj: any = undefined) => {
  return new Promise((resolve: any, reject: any) => {
    axios
      .put(updatepasswordurl, searchObj)
      .then((res: any) => {
        console.log("res", res);
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.log("error", err);
        console.error(err);
        reject(err);
      });
  });
};

const updateroleurl: any = url + "/profile/updaterole";
export const updateRoleProfile = (searchObj: any = undefined) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .put(updateroleurl, searchObj, header)
      .then((res: any) => {
        console.log("res", res);
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.log("error", err);
        console.error(err);
        reject(err);
      });
  });
};

const regeneratecodeurl: any = url + "/access/regeneratecode";
export const regenerateCodeProfile = (searchObj: any = undefined) => {
  return new Promise((resolve: any, reject: any) => {
    axios
      .put(regeneratecodeurl, searchObj)
      .then((res: any) => {
        console.log("res", res);
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.log("error", err);
        console.error(err);
        reject(err);
      });
  });
};

const updateurl: string = url + "/profile";
export const updateProfile = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .put(updateurl, searchObj, header)
      .then((res: any) => {
        console.log("res", res);
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.log("error", err);
        console.error(err);
        reject(err);
      });
  });
};

const deleteurl: string = url + "/profile/deleteMany";
export const deleteProfile = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(deleteurl, searchObj, header)
      .then((res: any) => {
        console.log("res", res);
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.log("error", err);
        console.error(err);
        reject(err);
      });
  });
};

const addlog: string = url + "/log";
export const addLog = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    // let header = {
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem(`token`)}`,
    //   },
    // };
    axios
      .post(addlog, searchObj)
      .then((res: any) => {
        console.log("res", res);
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.log("error", err);
        console.error(err);
        reject(err);
      });
  });
};
