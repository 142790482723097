import React, { useEffect, useState } from "react";
import RT, { PageControl } from "./../component/RajangTable";
import {
  getAllCompanies,
  getNumCompanies,
  getSearchCompanies,
  getSearchLimitResult,
  getExportCSV,
} from "../controller/company";
import {
  getAllIndustry,
  getAllCountry,
  getAllCompany,
} from "../controller/search";
import {
  getIndustryFileIndex,
  getCountryFileIndex,
  getCompanyPubFileIndex,
} from "../controller/fileindex";
import { deleteMultiCompany } from "../controller/delete";
import { addLog, checkmailProfile } from "../controller/profile";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import IO from "../lib/socketio";

const Container = (props: any) => {
  const navigate = useNavigate();
  const initialPageControl: PageControl = {
    numRec: 0,
    recPerPage: 10,
    pageNum: 1,
  };
  const [pageC, setPageC]: any = useState(initialPageControl);
  const [data, setData]: any = useState([]);
  const [cpdata, setCPData]: any = useState([]);
  const [ipdata, setIPData]: any = useState([]);
  const [selectedPageData, setSelectedPageData]: any = useState([]);
  const [dropDownIndustryOptions, setdropDownIndustryOptions]: any = useState(
    []
  );
  const [checkedData, setCheckedData]: any = useState([]);
  const [dropDownCountryOptions, setdropDownCountryOptions]: any = useState([]);
  const [dropDownCompanyOptions, setdropDownCompanyOptions]: any = useState([]);
  const [is_data, setIs_data] = React.useState(false);
  const [loading, setLoading] = useState(false);
  // const [selectedData, setSelectData]:any =  useState<React.Key[]>
  const [selectedData, setSelectedData]: any = useState([]);
  // const [selectedDataPage]:any[] = useState([]);
  // var selectedDataPage:any = useState([]);
  // const [showData, setShowData]:any = useState("companywithpublications")
  const deleteConfirmation = {
    title: "Delete Company",
    content: "Are you sure you want to delete these Company?",
  };

  // useEffect(() => {
  //   let privilage: any = localStorage.getItem("privilage");
  //   let user: any = localStorage.getItem("user");
  //   let login: any = localStorage.getItem("isLogin");
  //   if (login === "true") {
  //     checkmailProfile({ email: user }).then((res: any) => {
  //       if (res === "You are unauthorized to use this resource") {
  //         setTimeout(() => {
  //           handleTokenExpired();
  //         }, 3000); // delay navigation for 3 seconds
  //       } else if (res.data[0].role !== privilage) {
  //         localStorage.setItem("privilage", `${res.data[0].role}`);
  //       }
  //     });
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log("dataCC", dataControl);
  //   localStorage.setItem("dataControl", dataControl);
  // }, [dataControl]);

  // useEffect(() => {
  //   IO.on("merging_done", (msg: any) => {
  //     handleLoadData(
  //       {
  //         numRec: 0,
  //         recPerPage: 10,
  //         pageNum: 1,
  //       },
  //       {},
  //       {}
  //     );
  //     notification.success({
  //       message: `Company Merging`,
  //       description: `${msg.name}Updated and Merged completed.
  //           Company Data Table refresh.`,
  //       placement: "bottomRight",
  //       bottom: 50,
  //     });
  //     return IO.off("merging_done", () => {});
  //   });
  // });

  useEffect(() => {
    IO.on("merging_done", (msg: any) => {
      // handleLoadData(
      //   {
      //     numRec: 0,
      //     recPerPage: 10,
      //     pageNum: 1,
      //   },
      //   {},
      //   {}
      // );
      notification.success({
        message: `Company Merging`,
        description: `${msg.name} Updated and Merged completed. Company Data Table refresh.`,
        placement: "bottomRight",
        bottom: 50,
      });

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
    return () => {
      IO.off("merging_done");
    };
  });

  useEffect(() => {
    let user: any = localStorage.getItem("user");
    let log: any = {
      page: window.location.pathname,
      action: "Read",
      datetime: new Date(),
      details: "-",
      email: user,
    };
    addLog(log);
  }, []);

  useEffect(() => {
    setIs_data(is_data);
  }, [is_data]);

  useEffect(() => {
    const fetchCompanyCount = async () => {
      getNumCompanies({}).then((data: any) => {
        setPageC({ ...pageC, numRec: data.data[0]["Count"] });
      });
    };
    fetchCompanyCount();
  }, []);

  useEffect(() => {
    const fetchIndustryData = async () => {
      getAllIndustry().then((res: any) => {
        if (res.data) {
          const dropDownIndustryOption = res.data.map((x: any, i: any) => ({
            label: x.description,
            value: x.industrycode,
          }));
          setdropDownIndustryOptions(dropDownIndustryOption);
        }
      });
    };
    fetchIndustryData();
  }, []);

  useEffect(() => {
    const fetchCountryData = async () => {
      getAllCountry().then((res: any) => {
        if (res.data) {
          const dropDownCountryOption = res.data.map((x: any, i: any) => ({
            label: x.name,
            value: x.countrycode,
          }));
          setdropDownCountryOptions(dropDownCountryOption);
        }
      });
    };
    fetchCountryData();
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchCoumpanyData = async () => {
      getAllCompany().then((res: any) => {
        if (res.data) {
          const dropDownCompanyOption = res.data.map((x: any, i: any) => ({
            label: x.name,
            value: x.compnumber,
          }));
          setdropDownCompanyOptions(dropDownCompanyOption);
          //   setData(res.data)
          setLoading(false);
        }
      });
    };
    fetchCoumpanyData();
  }, []);

  const handleFilter = (
    newFilter: any,
    pc: PageControl,
    sortControl: any = undefined
  ) => {
    // setLoading(true)
    getNumCompanies(newFilter).then((data: any) => {
      if (data.data.length > 0) {
        setPageC({ ...pageC, numRec: data.data[0]["Count"] });
      } else {
        setPageC({ ...pageC, numRec: 0 });
      }
      getSearchCompanies(
        { pageControl: pc },
        { newFilter },
        { sortControl }
      ).then((d: any) => {
        setData(d.data);
        // setLoading(false)
      });
    });
  };

  const convertCompanyName = (el: any) => {
    let answer = data.find((o: any) => {
      return o["_id"] === el;
    });
    if (!answer) {
      return el;
    }
    if (!answer["name"]) {
      return "";
    }
    return answer["name"];
  };

  const handleDelete = (selected: any, load: any) => {
    deleteMultiCompany(selected).then((res: any) => {
      if (res.data) {
        notification.success({
          message: "Company deleted!",
          description: "Company has been deleted successfully.",
          placement: "bottomRight",
          bottom: 50,
        });
        let user: any = localStorage.getItem("user");
        let log: any = {
          page: window.location.pathname,
          action: "Delete",
          datetime: new Date(),
          details: `Delete Company ${selected
            .map((name: any) => convertCompanyName(name))
            .join(", ")}`,
          email: user,
        };
        addLog(log);
        const { tempPageControl, dataFilter, sortControl } = load;
        handleLoadData(tempPageControl, dataFilter, sortControl);
        setSelectedData([]);
        setCheckedData([]);
      } else {
        notification.error({
          message: "Failed to delete Company!",
          description: "Company has not been deleted.",
          placement: "bottomRight",
          bottom: 50,
        });
      }
    });
  };

  let checkGotanyData = () => {
    let got_data: boolean = false;
    selectedData.forEach((element: any) => {
      if (!element) {
        return;
      }
      if (element.length > 0) {
        // setIs_data(true);
        got_data = true;
        return;
      }
    });
    return got_data;
  };
  const handleLoadData = (
    pc: PageControl,
    newFilter: any,
    sortControl: any = undefined
  ) => {
    getAllIndustry().then((res: any) => {
      if (res.data) {
        const dropDownIndustryOption = res.data.map((x: any, i: any) => ({
          label: x.description,
          value: x.industrycode,
        }));
        setdropDownIndustryOptions(dropDownIndustryOption);
      }
    });
    // if(showData==='allcompany'){
    setSelectedPageData(selectedData[pageC.pageNum]);
    setIs_data(false);
    selectedData.forEach((element: any) => {
      if (!element) {
        return;
      }
      if (element.length > 0) {
        // console.log("element.length",element.length);
        // console.log("element.length>0",element.length>0);
        setIs_data(true);
        return;
      }
    });

    if (newFilter && Object.keys(newFilter).length != 0) {
      // setLoading(true)
      getSearchCompanies(
        { pageControl: pc },
        { newFilter },
        { sortControl }
      ).then((d: any) => {
        // setDataControl({ pageControl: pc }, { newFilter }, { sortControl });
        setData(d.data);
        // setLoading(false)
      });
    } else {
      setLoading(true);
      getAllCompanies({ pageControl: pc }, { sortControl }).then((d: any) => {
        // setDataControl({ pageControl: pc }, { sortControl });
        if (d.data) {
          setData(d.data);
          getNumCompanies({}).then((data: any) => {
            if (data.data[0]["Count"]) {
              setPageC({ ...pageC, numRec: data.data[0]["Count"] });
            } else {
              setPageC({ ...pageC, numRec: 0 });
            }
          });
          setLoading(false);
        }
      });
    }
    // }else{

    //       setLoading(true)
    //         getCompanyPubFileIndex({}).then((d:any)=>{
    //             setData(d.data)
    //             console.log("dada",d.data)
    //             // getNumCompanies({}).then((data:any)=>{
    //             //   // console.log("COUNT",data)
    //             //   if(data.data[0]['Count']){
    //             //     setPageC({...pageC,numRec:data.data[0]['Count']})
    //             //   }else{
    //             //     setPageC({...pageC,numRec:0})
    //             //   }

    //             // })
    //             setLoading(false)
    //         })

    // }
    // console.log(pageC,"PAGECONTROLDATA")
    // console.log('loading',pc,newFilter,sortControl)
  };

  // const handleTokenExpired = () => {
  //   notification.warning({
  //     message: "Warning",
  //     description: "Your authorization has expired.Please login again.",
  //     placement: "top",
  //     duration: 3,
  //   });

  //   setTimeout(() => {
  //     navigate("/login", { replace: true });
  //   }, 3000); // delay navigation for 3 seconds
  // };

  const handleSelected = (data: any) => {
    selectedData[pageC.pageNum] = data;
    // setSelectData(data)
    data.forEach((element: any) => {
      selectedData[pageC.pageNum].push(element);
    });
    setCheckedData(data);

    //         let newlist:any = Array.from(new Set(props.data))
    // setData(newlist)
    // console.log("selectedData", selectedData)
    // let newArr = []
    // newArr.push(props.data)
    // let newlist:any = Array.from(new Set(selectedData))
    // console.log("newlist", newlist)
    // setSelectedData(newlist)
  };

  const handleExport = (e: any) => {
    notification.success({
      message: "Downloading File",
      description: "Please wait for the download to complete.",
      placement: "bottomRight",
      bottom: 50,
    });
    getExportCSV({ file: e }).then((res: any) => {
      if (res === "File downloaded") {
      } else {
        notification.error({
          message: "Failed to Downloading File.",
          description: "Error happen while downloading file",
          duration: 0,
          placement: "bottomRight",
          bottom: 50,
        });
      }
    });
  };

  // const changeDatasource = (el:any) =>{
  //     if(el==='allcompany'){
  //         setShowData(el)
  //     }else if(el==="companywithpublications"){
  //         setShowData(el)
  //     }
  // }

  return (
    <>
      <div>
        <div>
          <RT
            hasSelectAll={true}
            data={data}
            cols={[
              {
                fieldname: "name",
                width: "40%",
                label: "Name",
                sortcolumn: "true",
                clickable: "true",
                align: "left",
                filter: "true",
              },
              {
                fieldname: "country",
                width: "20%",
                label: "Country",
                lookup: dropDownCountryOptions,
                sortcolumn: "true",
                align: "left",
                filter: "true",
              },
              {
                fieldname: "newcompnumber",
                width: "15%",
                label: "Company No.",
                align: "center",
                filter: "true",
              },
              {
                fieldname: "industry",
                width: "25%",
                label: "Industry",
                lookup: dropDownIndustryOptions,
                align: "left",
                filter: "true",
              },
            ]}
            pageControl={pageC}
            selectedData={selectedPageData}
            removeData={selectedData}
            checkedData={checkedData}
            handleSelected={handleSelected}
            handleFilter={handleFilter}
            handleLoadData={handleLoadData}
            checkGotanyData={checkGotanyData}
            // changeDatasource={changeDatasource}
            handleDelete={handleDelete}
            deleteConfirmation={deleteConfirmation}
            is_data={is_data}
            handleExport={handleExport}
            rowSelection={"true"}
            leftAction={["export"]}
            action={["delete", "create", "reset"]}
            loading={loading}
            type={"large"}
          ></RT>
        </div>
      </div>
    </>
  );
};

export default Container;
