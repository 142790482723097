import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createNewProfile,
  VerifyProfile,
  checkverifyProfile,
  regenerateCodeProfile,
} from "../controller/profile";
import AlertModal from "../component/AlertMessage";
import RegistrationAccount from "../component/RegistrationAccount";
import VerifyCode from "../component/VerifyCode";
import { Form, Button, Input, notification } from "antd";

const MyComponent = (props: any) => {
  const url = process.env.REACT_APP_URL as string;
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [registerData, setRegisterData]: any = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertmessage, setAlertMessage] = useState("");
  const [alerttype, setAlertType] = useState("");
  const [verify, setVerify] = useState(false);
  const [disable, setDisable] = useState(false);
  const [form] = Form.useForm();

  const formData = [
    {
      key: "name",
      name: "name",
      label: (
        <>
          <span style={{ marginRight: "75px" }}>Name</span>
        </>
      ),
      labelCol: { style: { fontWeight: "600", paddingRight: "5px" } },
      rules: [{ required: true, message: "Please enter your name" }],
      validateTrigger: "onBlur",
      component: (
        // <div className="input-form">
        //   <label className="label-form">Name</label>
        <Input placeholder="Name" />
        // </div>
      ),
    },
    {
      key: "email",
      name: "email",
      label: (
        <>
          <span style={{ marginRight: "79px" }}>Email</span>
        </>
      ),
      labelCol: { style: { fontWeight: "600", paddingRight: "5px" } },
      rules: [
        { required: true, message: "Please enter your email" },
        {
          type: "email",
          message: "Please enter a valid email",
        },
        {
          validator: async (rule: any, value: any) => {
            if (value) {
              let checkavailable: any = await checkverifyProfile({
                email: value,
              });
              if (checkavailable.data.length > 0) {
                if (checkavailable.data["0"].verify === false) {
                  return Promise.reject(
                    <>
                      Email address already exists, Please click for{" "}
                      <a
                        style={{
                          color: "#2B65EC",
                          textDecoration: "underline",
                        }}
                        href={
                          url +
                          "verify?id=" +
                          checkavailable.data["0"].randomcode
                        }
                      >
                        Verification
                      </a>
                    </>
                  );
                } else if (
                  checkavailable.data["0"].adminApproval === "Declined"
                ) {
                  return Promise.reject(
                    "This email has already been blocked from register. Please contact your administrator."
                  );
                } else {
                  return Promise.reject(
                    "This email address is already being used."
                  );
                }
              }
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      validateTrigger: "onBlur",
      component: (
        // <div className="input-form">
        //   <label className="label-form">Email</label>
        <Input placeholder="Email" />
        // </div>
      ),
    },
    {
      key: "password",
      name: "password",
      label: (
        <>
          <span style={{ marginRight: "54px" }}>Password</span>
        </>
      ),
      labelCol: { style: { fontWeight: "600", paddingRight: "5px" } },
      rules: [
        { required: true, message: "Please enter your password" },
        // { min: 8, message: "Password must be at least 8 characters!" },
        // password must contain at least one uppercase letter, one lowercase letter, one number, and one special character
        {
          validator: (rule: any, value: any) => {
            if (
              value !== undefined &&
              !value.match(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/
              )
            ) {
              return Promise.reject(
                "Password must contain minimum 8 characters including one uppercase, one lowercase, one number and one special character."
              );
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      validateTrigger: "onBlur",
      component: (
        // <div className="input-form">
        //   <label className="label-form">Password</label>
        <Input.Password placeholder="Password" />
        // </div>
      ),
    },
    {
      key: "confirmPassword",
      name: "confirmPassword",
      label: (
        <>
          <span>Confirm Password</span>
        </>
      ),
      labelCol: { style: { fontWeight: "600", paddingRight: "5px" } },
      rules: [
        { required: true, message: "" },
        {
          // validate the password and confirm password
          validator: (rule: any, value: any) => {
            if (
              value !== undefined &&
              value !== form.getFieldsValue().password
            ) {
              return Promise.reject("Password does not match");
            }
            // else if(value !== undefined){
            //   return Promise.reject("Password do not match");
            // }
            else {
              return Promise.resolve();
            }
          },
        },
      ],
      validateTrigger: "onBlur",
      component: (
        // <div className="input-form">
        //   <label className="label-form">Confirm Password</label>
        <Input.Password placeholder="Confirm Password" />
        // </div>
      ),
    },
  ];

  useEffect(() => {
    if (registerData?.email) {
      setVerify(true);
    }
  }, [registerData]);

  const handleRegister = (el: any) => {
    let _data: any = { ...el };
    createNewProfile(el).then((res: any) => {
      if (res.data) {
        setRegisterData(res.data);
        handleAlertType("Success");
        handleAlertMessage(
          `Thank you. We have send you email to ${_data["email"]}. Please check and insert the code to activate your account.`
        );
      } else {
        checkverifyProfile({ email: _data["email"] }).then((resp: any) => {
          if (resp.data[0]["verify"] == false) {
            setRegisterData(resp.data[0]);
            handleAlertType("Account Verfication");
            handleAlertMessage(
              `This email has already been exists but haven't verify. The Activation code has been sent to your email. Please check and key in the code to activate your account`
            );
          } else {
            if (resp.data[0]["adminApproval"] === "Approved") {
              handleAlertType("Alert");
              handleAlertMessage(
                `This email has already been exists. Please try a different email to register or proceed to login.`
              );
            } else if (resp.data[0]["adminApproval"] === "Pending") {
              handleAlertType("Alert");
              handleAlertMessage(
                `This email has already been exists. Please try a different email to register or Please wait for your administrator to approve before you can login.`
              );
            } else if (resp.data[0]["adminApproval"] === "Declined") {
              handleAlertType("Alert");
              handleAlertMessage(
                `This email has already been blocked from register. Please contact your administrator.`
              );
            }
            // handleAlertType("Alert")
            // handleAlertMessage(`This email has already been exists. Please try a different email to register`)
          }
        });
      }
    });
  };

  const handleCode = (el: any) => {
    if (registerData["verify"] === false) {
      if (
        el === registerData["verifycode"] &&
        registerData["adminApproval"] === "Pending"
      ) {
        VerifyProfile(registerData).then((res: any) => {
          if (res.data) {
            handleAlertType("Registration completed");
            handleAlertMessage(
              `Verification has been successful. Please wait for your administrator to approve before you can login.`
            );
            setTimeout(() => {
              navigate("/login", { replace: true });
            }, 3000);
          } else {
            handleAlertType("Alert");
            handleAlertMessage(`Fail to activate.`);
          }
        });
      } else if (
        el === registerData["verifycode"] &&
        registerData["adminApproval"] === "Declined"
      ) {
        handleAlertType("Account Verfication");
        handleAlertMessage(
          `This email has already been blocked from register. Please contact your administrator.`
        );
      } else if (
        el === registerData["verifycode"] &&
        registerData["adminApproval"] === "Approved"
      ) {
        handleAlertType("Account Verfication");
        handleAlertMessage(
          `This email has already been verified. Please continue with login.`
        );
        setTimeout(() => {
          navigate("/login", { replace: true });
        }, 3000);
      } else {
        handleAlertType("Alert");
        handleAlertMessage(
          `Wrong code. Please check and insert the correct code to activate your account.`
        );
      }
    } else {
      handleAlertType("Alert");
      handleAlertMessage(`This email has already been verified.`);
    }
  };

  const generateCode = () => {
    checkverifyProfile({ email: registerData["email"] }).then((res: any) => {
      if (res.data[0]["verify"] === false) {
        regenerateCodeProfile({ email: registerData["email"] }).then(
          (resp: any) => {
            if (resp.data) {
              setRegisterData(resp.data);
              handleAlertType("Account Verfication");
              handleAlertMessage(
                `New code has been sent to your email.  Please check and key in the code to activate your account`
              );
            } else {
              handleAlertType("Alert");
              handleAlertMessage(`Fail to send email`);
            }
          }
        );
      } else if (
        res.data[0]["verify"] === true &&
        res.data[0]["adminApproval"] === "Pending"
      ) {
        handleAlertType("Account Verfication");
        handleAlertMessage(
          `This email has already been verified. Please wait for your administrator to approve before you can login.`
        );
      } else if (
        res.data[0]["verify"] === true &&
        res.data[0]["adminApproval"] === "Declined"
      ) {
        handleAlertType("Account Verfication");
        handleAlertMessage(
          `This email has already been blocked from register. Please contact your administrator.`
        );
      } else {
        handleAlertType("Alert");
        handleAlertMessage(
          `This email has already been verified. Please continue with login.`
        );
      }
    });
  };

  const handleAlertMessage = (el: any) => {
    setAlertMessage(el);
    setShowAlert(true);
    off();
  };

  const handleAlertType = (el: any) => {
    setAlertType(el);
    setShowAlert(true);
    off();
  };

  const off = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  const closeAlert = () => {
    setAlertType("");
    setAlertMessage("");
    setShowAlert(false);
  };

  // handle form when submit
  const onFinish = (values: any) => {
    // onSubmit(values);
    createNewProfile(values).then((res: any) => {
      if (res.success === true) {
        notification.success({
          message: "User Added!",
          description: "Please check your email to verify your account",
          placement: "top",
          duration: 3,
          top: 85,
        });
        navigate(`/verify?id=${res.data.randomcode}`);
      } else {
        notification.error({
          message: "Fail to Add User!",
          description: res.message,
          placement: "top",
          duration: 3,
          top: 85,
        });
      }
    });
  };

  // handle fail submit form
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      {/* <AlertModal
        show={showAlert}
        onClose={closeAlert}
        alertmessage={alertmessage}
        alerttype={alerttype}
        alertmsg
      /> */}
      {/* {!verify && <RegistrationAccount handleRegister={handleRegister} />}
      {verify && (
        <VerifyCode
          handleCode={handleCode}
          generateCode={generateCode}
          registerData={registerData}
        />
      )} */}
      <div className="register-page">
        <div className="form-box">
          <h1 style={{ textAlign: "center" }}>Account Registration</h1>
          <hr style={{ margin: "10px 0px 30px 0px" }} />
          <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            {/* loop form to display items */}
            {formData.map((item: any) => (
              <Form.Item {...item}>{item.component}</Form.Item>
            ))}

            {/* create the submit and reset button */}
            {/* <Form.Item
						style={{
							display: "flex",
							justifyContent: "flex-end",
							marginBottom: "0px",
							width: "100%",
						}}
					> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                type="link"
                style={{
                  marginRight: "auto",
                  paddingLeft: "0px",

                  color: "rgba(0, 124, 117, 0.74)",
                }}
                onClick={() => {
                  navigate("/login", { replace: true });
                }}
              >
                <span style={{ textDecoration: "underline" }}>Log in here</span>
              </Button>

              <div>
                {/* <Button
                  type="link"
                  style={{ margin: "5px" }}
                  // onClick={onReset}
                >
                  Reset
                </Button> */}
                <Button
                  className="button-green"
                  htmlType="submit"
                  style={{ margin: "auto" }}
                >
                  Submit Registration
                </Button>
              </div>
            </div>
            {/* </Form.Item> */}
          </Form>
        </div>
      </div>
    </>
  );
};

export default MyComponent;
