import React, { useEffect, useState } from "react";
import SearchCompanyResult from "./CompanySideResult";
import Details from "./CompanyDetails";
import { getSearchLimitResult } from "../controller/search";
import { createNewCompany } from "../controller/create";
import { updateCompanyDetail } from "../controller/update";
import { deleteCompany } from "../controller/delete";
import { addLog, updateRoleProfile } from "../controller/profile";
import { getCountDocument } from "../controller/company";
import "./DetailsModal.css";
import { notification } from "antd";
import IO from "../lib/socketio";
let searchObj: any = {};

const MyComponent = (props: any) => {
  const [searchResult, setSearchResult] = useState([]);
  const [cdata, setCdata] = useState([]);
  const [isdata, setISdata] = useState([]);
  const [sdata, setSdata] = useState([]);
  const [editdata, setEditdata] = useState([]);
  const [chooseData, setChooseData] = useState([]);
  const [loading, setLoading] = useState(false);
  let pdata: any = props.data ? props.data : [];
  let countrylist: any = props.countrylist ? props.countrylist : [];
  let industrylist: any = props.industrylist ? props.industrylist : [];
  let companylist: any = props.companylist ? props.companylist : [];
  let rolelist: any = props.rolelist ? props.rolelist : [];
  let searchStr: any = null;

  // console.log("cdata", cdata);
  // console.log("isdata", isdata);
  // console.log("sdata", sdata);
  // console.log("sds", props.loading);

  useEffect(() => {
    IO.on("close_company_modal", (msg: any) => {
      close();
    });
    return () => {
      IO.off("close_company_modal");
    };
  });

  useEffect(() => {
    setCdata(props.data);
    setSdata(props.selected);
    setLoading(props.loading);
    // setChooseData(props.data);
  }, [props.data, props.selected, props.loading]);

  const close = () => {
    searchStr = null;
    searchObj = {};
    setSearchResult([]);
    setCdata([]);
    setEditdata([]);
    setChooseData([]);
    props.onClose();
  };

  if (!props.show) {
    return null;
  }

  const searchNameChange = (d: any) => {
    searchStr = d;
    searchObj["name"] = d;
    if (searchStr && searchStr.trim().length > 2) {
      search();
    }
  };
  const searchCountryChange = (d: any) => {
    searchStr = d;
    searchObj["country"] = d;
    search();
  };
  const searchCompanyNumberChange = (d: any) => {
    searchStr = d;
    searchObj["compnumber"] = parseInt(d);
    search();
  };
  const search = () => {
    if (searchStr) {
      getSearchLimitResult(searchObj).then((res: any) => {
        setSearchResult(res.data);
      });
    } else {
      setSearchResult([]);
    }
  };
  const selectpredata = (d: any) => {
    setLoading(true);
    getCountDocument({ compnumber: d.compnumber })
      .then((res: any) => {
        if (res.data) {
          const mergedObj = Object.assign({}, d, res.data);
          // console.log("mergedObj", mergedObj);
          if (props.selected) {
            setCdata(mergedObj);
            setChooseData([]);
            setLoading(false);
          } else {
            setCdata(mergedObj);
            setISdata(mergedObj);
            setChooseData([]);
            setLoading(false);
          }
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const resetData = () => {
    setCdata([]);
  };
  const createData = (data: any) => {
    if (data) {
      createNewCompany(data).then((res: any) => {
        if (res.data) {
          ReData();
          let user: any = localStorage.getItem("user");
          let log: any = {
            page: window.location.pathname,
            action: "Create",
            datetime: new Date(),
            details: `Create Company ${res.data.name}`,
            email: user,
          };
          addLog(log);
          notification.success({
            message: "Company created!",
            description: <>{res.data.name} has been created successfully.</>,
            placement: "bottomRight",
            bottom: 50,
          });
          // GetAlertType("Success");
          // GetAlertMessage(`${res.data.name} has been created`);
        } else {
          notification.error({
            message: "Company created!",
            description: <>{res.data.name} has been failed created.</>,
            placement: "bottomRight",
            bottom: 50,
          });
          // GetAlertType("Alert");
          // GetAlertMessage(
          //   `Duplicate Company Number.Company Number must be unique and numeric.`
          // );
        }
      });
    }
  };
  const handleRemoveData = (data: any) => {
    if (data === true) {
      ReData();
    }
  };
  const saveData = (data: any) => {
    if (data) {
      updateCompanyDetail(data).then((res: any) => {
        if (res.data) {
          ReData();
          let user: any = localStorage.getItem("user");
          let log: any = {
            page: window.location.pathname,
            action: "Update",
            datetime: new Date(),
            details: `Update Company ${res.data.name}`,
            email: user,
          };
          addLog(log);
          notification.success({
            message: "Company updated!",
            description: <>{res.data.name} has been updated successfully.</>,
            placement: "bottomRight",
            bottom: 50,
          });
          // GetAlertType("Success");
          // GetAlertMessage(`${res.data.name} has been updated`);
        } else {
          // GetAlertType("Alert");
          // GetAlertMessage(`update function error`);
          notification.error({
            message: "Company updated!",
            description: <>{res.data.name} has been failed updated.</>,
            placement: "bottomRight",
            bottom: 50,
          });
        }
      });
    }
  };
  const saveUserData = (data: any) => {
    if (data) {
      // console.log("saved", data);
      updateRoleProfile(data).then((res: any) => {
        if (res.data) {
          // console.log("res", res.data);
          ReData();
          let user: any = localStorage.getItem("user");
          let log: any = {
            page: window.location.pathname,
            action: "Update",
            datetime: new Date(),
            details: `Update User ${res.data.email}`,
            email: user,
          };
          addLog(log);
          notification.success({
            message: "User updated!",
            description: <>{res.data.name} has been updated successfully.</>,
            placement: "bottomRight",
            bottom: 50,
          });
          // GetAlertType("Success");
          // GetAlertMessage(`${res.data.name} has been updated`);
        } else {
          // GetAlertType("Alert");
          // GetAlertMessage(`update function error`);
          notification.error({
            message: "User updated!",
            description: <>{res.data.name} has been failed updated.</>,
            placement: "bottomRight",
            bottom: 50,
          });
        }
      });
    }
  };
  const removeData = (data: any) => {
    let data_id = data._id;
    if (data_id) {
      deleteCompany(data_id).then((res: any) => {
        if (res.data) {
          ReData();
          let user: any = localStorage.getItem("user");
          let log: any = {
            page: window.location.pathname,
            action: "Delete",
            datetime: new Date(),
            details: `Delete Company ${res.data.name}`,
            email: user,
          };
          addLog(log);
          // GetAlertType("Success");
          // GetAlertMessage(`${res.data.name} has been deleted`);
          notification.success({
            message: "Company deleted!",
            description: <>{res.data.name} has been deleted successfully.</>,
            placement: "bottomRight",
            bottom: 50,
          });
        } else {
          // GetAlertType("Alert");
          // GetAlertMessage(`Delete function error`);
          notification.error({
            message: "Company deleted!",
            description: <>{res.data.name} has been failed deleted.</>,
            placement: "bottomRight",
            bottom: 50,
          });
        }
      });
    }
  };

  const ReData = () => {
    if (props.editedDataHook && typeof props.editedDataHook === "function") {
      props.editedDataHook(true);
    }
  };

  const GetAlertMessage = (el: any) => {
    if (
      props.handleAlertMessage &&
      typeof props.handleAlertMessage === "function"
    ) {
      props.handleAlertMessage(el);
    }
  };

  const GetAlertType = (el: any) => {
    if (props.handleAlertType && typeof props.handleAlertType === "function") {
      props.handleAlertType(el);
    }
  };

  const handleAlertMessage = (el: any) => {
    GetAlertMessage(el);
    GetAlertType("Alert");
  };

  const handleChange = (evt: any) => {
    setChooseData(evt);
  };

  const handleChanges = (data: any) => {
    if (data === true) {
      ReData();
    }
  };

  return (
    <>
      {pdata?.key && props.edit && (
        <div className="modal">
          <div className="modald-content">
            <div className="modald-body">
              <div>
                <Details
                  data={editdata}
                  onClose={close}
                  saveDataHook={saveData}
                  removeDataHook={removeData}
                  countrylist={countrylist}
                  industrylist={industrylist}
                  loading={loading}
                  handleAlertMessage={handleAlertMessage}
                  companyeditor
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {props.create && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-body">
              <div style={{ padding: "0 0 0 10px" }}>
                <Details
                  cdata={{ ...cdata }}
                  sdata={sdata}
                  isdata={isdata}
                  searchResult={searchResult}
                  onClose={close}
                  handleSearchCompany={searchNameChange}
                  handleSearchCountry={searchCountryChange}
                  handleSearchCompanyNumber={searchCompanyNumberChange}
                  saveCreateHook={createData}
                  handleAlertMessage={handleAlertMessage}
                  saveDataHook={saveData}
                  removeDataHook={removeData}
                  resetDataHook={resetData}
                  handleRemoveData={handleRemoveData}
                  handleChanges={handleChanges}
                  loading={loading}
                  countrylist={countrylist}
                  industrylist={industrylist}
                  handleChange={handleChange}
                  companycreator
                />
              </div>
              <div className="searchlistResult">
                <SearchCompanyResult
                  chooseData={chooseData}
                  data={searchResult}
                  sdata={sdata}
                  cdata={cdata}
                  isdata={isdata}
                  handleChooseResult={selectpredata}
                  countrylist={countrylist}
                  industrylist={industrylist}
                  handleAlertMessage={handleAlertMessage}
                  onClose={close}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {props.countryeditor && (
        <div className="modal">
          <div className="modald-content">
            <div className="modald-body">
              <div>
                <Details
                  data={pdata}
                  onClose={close}
                  countrylist={countrylist}
                  industrylist={industrylist}
                  companylist={companylist}
                  countryeditor
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {props.industryeditor && (
        <div className="modal">
          <div className="modald-content">
            <div className="modald-body">
              <div>
                <Details
                  data={pdata}
                  onClose={close}
                  countrylist={countrylist}
                  industrylist={industrylist}
                  companylist={companylist}
                  industryeditor
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {props.publicationeditor && (
        <div className="modal">
          <div className="modald-content">
            <div className="modald-body">
              <div>
                <Details
                  data={pdata}
                  onClose={close}
                  countrylist={countrylist}
                  industrylist={industrylist}
                  companylist={companylist}
                  publicationeditor
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {props.distributoreditor && (
        <div className="modal">
          <div className="modald-content">
            <div className="modald-body">
              <div>
                <Details data={pdata} onClose={close} distributoreditor />
              </div>
            </div>
          </div>
        </div>
      )}
      {props.usereditor && (
        <div className="modal">
          <div className="modald-content">
            <div className="modald-body">
              <div>
                <Details
                  cdata={cdata}
                  onClose={close}
                  rolelist={rolelist}
                  saveDataHook={saveUserData}
                  handleAlertMessage={handleAlertMessage}
                  usereditor
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyComponent;
