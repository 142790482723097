import React, { useState } from "react";
import "./CompanySideResult.css";
import AlertModal from "../component/AlertMessage";
import { notification, Modal } from "antd";

const MyComponent = (props: any) => {
  const [pdata, setData] = React.useState(null);
  let data = props.data ? props.data : [];
  let industries: any = props.industrylist ? props.industrylist : [];
  let countries: any = props.countrylist ? props.countrylist : [];
  const [showCConfirm, setShowCConfirm] = useState(false);

  const selectData = (el: any) => {
    if (props.sdata) {
      if (props.sdata["name"] !== el.name) {
        if (localStorage.getItem("privilage") === "Admin") {
          // let msg = `Please edit ${el.name} details from Company Information table.`;
          // if (
          //   props.handleAlertMessage &&
          //   typeof props.handleAlertMessage === "function"
          // ) {
          //   props.handleAlertMessage(msg);
          // }
          notification.warning({
            message: "Warning",
            description: (
              <>Please edit {el.name} details from Company Information table.</>
            ),
            placement: "top",
            duration: 3,
          });
        } else {
          // let msg = `Please view ${el.name} details from Company Information table.`;
          // if (
          //   props.handleAlertMessage &&
          //   typeof props.handleAlertMessage === "function"
          // ) {
          //   props.handleAlertMessage(msg);
          // }
          notification.warning({
            message: "Warning",
            description: (
              <>Please view {el.name} details from Company Information table.</>
            ),
            placement: "top",
            duration: 3,
          });
        }
      } else {
        setData(el);
        if (
          props.handleChooseResult &&
          typeof props.handleChooseResult === "function"
        ) {
          props.handleChooseResult(el);
        }
      }
    } else {
      setData(el);
      if (
        props.handleChooseResult &&
        typeof props.handleChooseResult === "function"
      ) {
        props.handleChooseResult(el);
      }
    }
  };

  const cancel = () => {
    // console.log(
    //   "JSON.parse(JSON.stringify(props.chooseData))",
    //   JSON.parse(JSON.stringify(props.chooseData))
    // );
    // console.log(
    //   "JSON.parse(JSON.stringify(props.cdata))",
    //   JSON.parse(JSON.stringify(props.cdata))
    // );
    // if(props.sdata){
    //     if(props.chooseData.length!==0){
    //         if(JSON.stringify(props.chooseData)!==JSON.stringify(props.sdata)){
    //             setShowCConfirm(true)
    //         }else{
    //             setTimeout(function(){
    //                 close()
    //             },500)
    //         }
    //     }

    if (props.cdata == null) {
      if (Object.values(props.chooseData).length >= 1) {
        if (
          JSON.parse(JSON.stringify(props.chooseData)) !==
          JSON.parse(JSON.stringify(props.cdata))
        ) {
          setShowCConfirm(true);
        } else {
          // setTimeout(function () {
          close();
          // }, 3000);
        }
      } else {
        // setTimeout(function () {
        close();
        // }, 3000);
      }
    } else if (Object.values(props.chooseData).length > 0) {
      let CSData = Object.values(props.chooseData);
      if (isObjectEmpty(CSData)) {
        if (props.onClose && typeof props.onClose === "function") {
          props.onClose();
        }
      } else {
        Modal.confirm({
          title: "Leave without saving?",
          content: (
            <>
              Are you sure you want to cancel?
              <br />
              You have unsaved changes that will be reverted.
            </>
          ),
          okText: "Ok",
          cancelText: "Cancel",
          onOk() {
            close();
          },
        });
      }
      // CSData.forEach((el: any) => {
      //   if (processElement(el)) {
      //     if (el.trim().length === 0) {
      //       // setTimeout(function () {
      //       if (props.onClose && typeof props.onClose === "function") {
      //         props.onClose();
      //       }
      //       // }, 3000);
      //     } else {
      //       setShowCConfirm(true);
      //     }
      //   }
      // });
    } else {
      // setTimeout(function () {
      close();
      // }, 3000);
    }
  };

  const isObjectEmpty = (obj: any) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (
          value !== null &&
          value !== undefined &&
          value !== "" &&
          !(Array.isArray(value) && value.length === 0)
        ) {
          // If any field has a non-empty value, the object is not empty
          return false;
        }
      }
    }
    // All fields have empty values, so the object is empty
    return true;
  };

  const processElement = (el: any) => {
    if (typeof el === "number") {
      return;
    } else {
      return el;
    }
  };

  const descIndusMapping = (el: any) => {
    let answer = industries.find((o: any) => {
      return o["value"] == el;
    });
    if (!answer) {
      return false;
    }
    if (!answer["label"]) {
      return "";
    }
    return answer["label"];
  };

  const descCountryMapping = (el: any) => {
    let answer = countries.find((o: any) => {
      return o["value"] == el;
    });
    if (!answer) {
      return false;
    }
    if (!answer["label"]) {
      return "";
    }
    return answer["label"];
  };

  const renderRow: any = (data: any) => {
    const mRef: any = React.createRef();
    if (data) {
      return (
        <>
          <div
            key={data._id}
            className="datalist"
            onClick={() => {
              selectData(data);
            }}
          >
            <div>{data.name}</div>
            <div>{descCountryMapping(data.country)}</div>
            <div>{descIndusMapping(data.industry)}</div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const close = () => {
    props.onClose();
  };

  const renderRows: any = () => {
    if (data.length > 0) {
      return (
        <>
          <div
            style={{
              display: "grid",
              width: "37vw",
              height: "30px",
              gridTemplateColumns: "1fr 1fr 1fr",
              position: "fixed",
              textAlign: "left",
              backgroundColor: "grey",
              color: "white",
            }}
          >
            <div>Company</div>
            <div>Country</div>
            <div>Pri.Industry</div>
          </div>
          <div style={{ paddingTop: "35px" }}>
            {data.map((d: any) => {
              return (
                <React.Fragment key={d._id}>{renderRow(d)}</React.Fragment>
              );
            })}
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const closeRemoveAlert = () => {
    setShowCConfirm(false);
  };

  const confirmCancel = () => {
    closeRemoveAlert();
    // setFormData(props.data)
    // setChooseData(props.cdata)
    // setTimeout(function () {
    close();
    // }, 3000);
  };

  return (
    <>
      {/* <AlertModal
        show={showCConfirm}
        onClose={closeRemoveAlert}
        deleteDataHook={confirmCancel}
        confirmCancel
      /> */}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button onClick={cancel} className="open-modal-close-button">
          X Close
        </button>
      </div>
      <p>
        <b>Search Result</b>
      </p>
      <div style={{ height: "60vh", overflow: "auto" }}>{renderRows()}</div>
    </>
  );
};

export default MyComponent;
