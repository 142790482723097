import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { addLog } from "../controller/profile";

const MyComponent = (props: any) => {
  const location = useLocation();
  const [data, setData]: any = useState(null);
  const [breadcrumb, setBreadCrumb]: any = useState([]);
  const getbaseUrl = window.location.pathname;
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state != null) {
      setData(location.state.data);
      setBreadCrumb(location.state.breadcrumb);
    } else {
    }
  }, []);

  useEffect(() => {
    let user: any = localStorage.getItem("user");
    let log: any = {
      page: window.location.pathname,
      action: "Read",
      datetime: new Date(),
      details: "-",
      email: user,
    };
    addLog(log);
  }, []);

  const PublicationRenderRow: any = (el: any) => {
    if (el) {
      let aa: any = [];
      el.map((d: any) => {
        aa.push(String(d));
      });
      aa.sort(function (a: any, b: any) {
        return a.localeCompare(b);
      });
      return (
        <div
          style={{
            height: "150px",
            border: "1px black",
            borderStyle: "none solid solid solid",
            overflow: "auto",
            fontSize: "16px",
            textAlign: "left",
          }}
        >
          {aa.map((d: any) => {
            return (
              <React.Fragment key={d}>{PublicateRenderRow(d)}</React.Fragment>
            );
          })}
        </div>
      );
    }
  };

  const PublicateRenderRow: any = (indus: any) => {
    if (indus) {
      return (
        <>
          <div
            onClick={() => opendistribute(indus)}
            style={{
              paddingLeft: "10px",
              color: "rgba(0, 0, 238, 1)",
              cursor: "pointer",
            }}
          >
            {indus}
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const Publication1RenderRow: any = (el: any) => {
    if (Array.isArray(el)) {
      let aa: any = [];
      el.map((d: any) => {
        aa.push(String(d));
      });
      aa.sort(function (a: any, b: any) {
        return a.localeCompare(b);
      });
      return (
        <div>
          {aa.map((d: any) => {
            return (
              <React.Fragment key={d}>{Publicate1RenderRow(d)}</React.Fragment>
            );
          })}
        </div>
      );
    } else {
      if (el) return replaceLanguageSuffix(el);
    }
  };

  const Publicate1RenderRow: any = (indus: any) => {
    // console.log("indus", indus);
    if (indus) {
      return (
        <>
          <div>{replaceLanguageSuffix(indus)}</div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const convertDate = (el: any) => {
    let d = new Date(el);
    return d.toLocaleDateString("en-GB");
  };

  const opendistribute = (el: any) => {
    if (getbaseUrl === "/publication_report") {
      navigate("/distributor_summary", {
        replace: true,
        state: { el: el, breadcrumb: breadcrumb, data: data },
      });
    }
  };

  const getCOM = (fname: any, fcompnumber: any, index: any) => {
    let newbread = breadcrumb;
    let newArr = newbread.splice(index + 1);
    navigate("/search", {
      replace: true,
      state: {
        data: { name: fname, compnumber: fcompnumber },
        breadcrumb: breadcrumb,
      },
    });
  };

  const breadcrumbCOM = (el: any) => {
    if (el) {
      return el.map((d: any, index: any) => {
        return (
          <React.Fragment key={d._id}>
            {ComRenderRow(d.name, d.compnumber, index)}
          </React.Fragment>
        );
      });
    }
  };

  const ComRenderRow = (name: any, compnumber: any, index: any) => {
    if (name) {
      return (
        <>
          <Breadcrumb.Item>
            <a onClick={() => getCOM(name, compnumber, index)}>{name}</a>
          </Breadcrumb.Item>
        </>
      );
    } else {
      return <></>;
    }
  };

  const replaceLanguageSuffix = (text: any) => {
    let suffixes = ["_JA.pdf", "_DE.pdf", "_EN.pdf", "_ZH.pdf"];
    suffixes.forEach((suffix) => {
      if (text.includes(suffix)) {
        text = text.replace(suffix, "");
      }
    });
    return text;
  };

  return (
    <>
      <div
        style={{
          width: "80%",
          // height: "100%",
          margin: "auto",
          paddingTop: "20px",
        }}
      >
        <Breadcrumb>
          {breadcrumbCOM(breadcrumb)}
          <Breadcrumb.Item>Publication Report</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div
        style={{
          width: "90%",
          // height: "100%",
          margin: "auto",
          paddingTop: "20px",
        }}
      >
        <div
          style={{
            fontWeight: "700",
            fontSize: "22px",
            paddingTop: "10px",
            paddingBottom: "10px",
            textAlign: "center",
          }}
        >
          Publication Report
        </div>
        <div style={{ padding: "0px 100px" }}>
          <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
            <div
              style={{
                border: "1px black",
                borderStyle: "solid none solid solid",
                fontSize: "16px",
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              Document Name
            </div>
            <div
              style={{
                border: "1px black",
                borderStyle: "solid solid solid solid",
                fontWeight: 700,
                fontSize: "16px",
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              {Publication1RenderRow(data?.filename)}
            </div>
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
            <div
              style={{
                border: "1px black",
                borderStyle: "none none solid solid",
                fontSize: "16px",
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              Date
            </div>
            <div
              style={{
                border: "1px black",
                borderStyle: "none solid solid solid",
                fontWeight: 700,
                fontSize: "16px",
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              {convertDate(data?.publishdate) || ""}
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "30% 70%",
              paddingBottom: "50px",
            }}
          >
            <div
              style={{
                border: "1px black",
                borderStyle: "none none solid solid",
                fontSize: "16px",
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              List of Distributors
            </div>
            <div>{PublicationRenderRow(data?.package) || ""}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyComponent;
