import axios from "axios";
import { AuthHeader } from "./header";

const url = process.env.REACT_APP_SERVER_URL as string;

const getregiontype: any = url + "/regiontype";
export const getRegionType = (searchObj: any = undefined) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .get(getregiontype, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const getsregiontype: any = url + "/regiontype/region";
export const getSRegionType = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(getsregiontype, { q: searchObj }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const getcountrylist: any = url + "/countryregion";
export const getCountryRegion = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(getcountrylist, { q: searchObj }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const addcountrylist: any = url + "/countryregion/create";
export const addCountryRegion = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(addcountrylist, { q: searchObj }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const addregion: any = url + "/regiontype/create";
export const addRegion = (searchObj: any, region: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(addregion, { r: region, q: searchObj }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const updateregiontype: any = url + "/regiontype/updateregiontype";
export const updateRegionType = (searchObj: any, region: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .put(updateregiontype, { q: searchObj, r: region }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const updateregion: any = url + "/regiontype/updateregion";
export const updateRegion = (searchObj: any, region: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .put(updateregion, { q: searchObj, r: region }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const deleteMultiRegionTypes: any = url + "/regiontype/deleteManyRT";
export const deleteMultiRegionType = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(deleteMultiRegionTypes, searchObj, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const deleteMultiRegions: any = url + "/regiontype/deleteManyR";
export const deleteMultiRegion = (regiontype: any, searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(deleteMultiRegions, { r: regiontype, s: searchObj }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const deleteMultiCountries: any = url + "/regiontype/deleteManyC";
export const deleteMultiCountry = (regionid: any, country: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(deleteMultiCountries, { r: regionid, s: country }, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};
