import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { notification } from "antd";
import { addLog, checkmailProfile } from "./controller/profile";
import Login from "./containers/LoginPage";
import IndustryPub from "./containers/Industry_Publication";
import DataRelation from "./containers/Data_Relationship";
import MyApp from "./containers/RajangTest";
import CountryPublication from "./containers/Country_Publication";
import CompanyPublication from "./containers/Company_Publication";
import Header from "./component/RajangHeader";
import Footer from "./component/RajangFooter";
import PublicationReport from "./component/Publication_Report";
import DistributorSummary from "./component/Distributor_Summary";
import ConfigurationGUI from "./containers/Configuration_Gui";
import BespokeRegionConfiguration from "./containers/Bespoke_Region_Configuration";
import Registration from "./containers/Registration";
import ForgotPassword from "./containers/ForgetPassword";
import ResetPassword from "./containers/ResetNewPassword";
import UserManagement from "./containers/User_Management";
import PrivateRoutes from "./component/PrivateAuthenticate";
import Unauthorized from "./containers/Unauthorized";
import LayoutComponent from "./component/Layout";
import IndustryMapping from "./containers/Industry_Mapping";
import NotFoundPage from "./containers/NotFoundPage";
import Verify from "./containers/VerifyPage";
import ConfigGUISetting from "./component/ConfigGUISetup";
import CompanyConsolidation from "./containers/Company_Consolidation";
import IO from "./lib/socketio";

function App() {
  const navigate = useNavigate();
  const getbaseUrl = window.location.pathname;
  const [data, setData] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    if (getbaseUrl === "login") {
      localStorage.removeItem("privilage");
      localStorage.removeItem("isLogin");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("configuration_gui");
      localStorage.removeItem("changes");
      localStorage.removeItem("original_configuration");
      localStorage.removeItem("original_company_mergedata");
      localStorage.removeItem("deletedCompany");
    }
  });

  useEffect(() => {
    const currentwindow = window.location.pathname;
    console.log("currentwindow", currentwindow);
    const NonRestricted = [
      "/login",
      "/register",
      "/verify",
      "/forgotpassword",
      "/resetpassword",
      "/unauthorized",
    ];
    if (!localStorage.getItem("isLogin")) {
      if (!NonRestricted.includes(currentwindow)) {
        navigate("/login", { replace: true });
      }
    } else {
      let privilage: any = localStorage.getItem("privilage");
      let user: any = localStorage.getItem("user");
      let login: any = localStorage.getItem("isLogin");
      if (login === "true") {
        checkmailProfile({ email: user }).then((res: any) => {
          if (res === "You are unauthorized to use this resource") {
            let user: any = localStorage.getItem("user");
            let log: any = {
              page: window.location.pathname,
              action: "Logout",
              datetime: new Date(),
              details: "Authorization expired",
              email: user,
            };
            addLog(log);
            setTimeout(() => {
              handleTokenExpired();
            }, 3000);
          } else if (res.data?.[0].role !== privilage) {
            localStorage.setItem("privilage", `${res.data[0].role}`);
          }
        });
      }
    }
  }, [getbaseUrl]);

  const handleTokenExpired = () => {
    notification.warning({
      message: "Warning",
      description: "Your authorization has expired.Please login again.",
      placement: "top",
      duration: 3,
      top: 85,
    });

    setTimeout(() => {
      navigate("/login", { replace: true });
    }, 3000); // delay navigation for 3 seconds
  };

  useEffect(() => {
    IO.on("connect", () => {
      console.log("My socket id:", IO.id);
    });
  }, []);

  // useEffect(() => {
  //   axios
  //     .get("http://localhost:5555/unauthorized")
  //     .then((response) => {
  //       setData(response.data);
  //     })
  //     .catch((error) => {
  //       if (error.response.status === 404) {
  //         setError("The resource you are trying to access is not available");
  //       } else {
  //         setError("An error occurred while retrieving the data");
  //       }
  //     });
  // }, []);

  return (
    <>
      <div className="App">
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route
              path="/home"
              element={
                <LayoutComponent>
                  <MyApp />
                </LayoutComponent>
              }
            />
            <Route
              path="/country_publication_"
              element={
                <LayoutComponent>
                  <CompanyPublication />
                </LayoutComponent>
              }
            />
            <Route
              path="/industry_publication_"
              element={
                <LayoutComponent>
                  <CompanyPublication />
                </LayoutComponent>
              }
            />
            <Route
              path="/industry_publication"
              element={
                <LayoutComponent>
                  <IndustryPub />
                </LayoutComponent>
              }
            />
            <Route
              path="/search"
              element={
                <LayoutComponent>
                  <DataRelation />
                </LayoutComponent>
              }
            />
            <Route
              path="/country_publication"
              element={
                <LayoutComponent>
                  <CountryPublication />
                </LayoutComponent>
              }
            />
            <Route
              path="/publication_report"
              element={
                <LayoutComponent>
                  <PublicationReport />
                </LayoutComponent>
              }
            />
            <Route
              path="/distributor_summary"
              element={
                <LayoutComponent>
                  <DistributorSummary />
                </LayoutComponent>
              }
            />
          </Route>
          <Route element={<PrivateRoutes roles={"Admin"} />}>
            <Route
              path="/configuration_gui"
              element={
                <LayoutComponent>
                  <ConfigurationGUI />
                </LayoutComponent>
              }
            />
            <Route
              path="/config_gui_setting"
              element={
                <LayoutComponent>
                  <ConfigGUISetting />
                </LayoutComponent>
              }
            />
            <Route
              path="/bespoke_region_configuration"
              element={
                <LayoutComponent>
                  <BespokeRegionConfiguration />
                </LayoutComponent>
              }
            />
            <Route
              path="/user_management"
              element={
                <LayoutComponent>
                  <UserManagement />
                </LayoutComponent>
              }
            />
            <Route
              path="/industry_mapping"
              element={
                <LayoutComponent>
                  <IndustryMapping />
                </LayoutComponent>
              }
            />
            <Route
              path="/company_consolidation"
              element={
                <LayoutComponent>
                  <CompanyConsolidation />
                </LayoutComponent>
              }
            />
          </Route>

          <Route
            path="/login"
            element={
              <LayoutComponent>
                <Login />
              </LayoutComponent>
            }
          />
          <Route
            path="/register"
            element={
              <LayoutComponent>
                <Registration />
              </LayoutComponent>
            }
          />
          <Route
            path="/forgotpassword"
            element={
              <LayoutComponent>
                <ForgotPassword />
              </LayoutComponent>
            }
          />
          <Route
            path="/resetpassword"
            element={
              <LayoutComponent>
                <ResetPassword />
              </LayoutComponent>
            }
          />
          <Route
            path="/unauthorized"
            element={
              <LayoutComponent>
                <Unauthorized />
              </LayoutComponent>
            }
          />
          <Route
            path="/verify"
            element={
              <LayoutComponent>
                <Verify />
              </LayoutComponent>
            }
          />
          {/* <Route
            path="*"
            element={
              // <LayoutComponent>
              <NotFoundPage />
              // </LayoutComponent>
            }
          /> */}
          <Route
            path=""
            element={
              localStorage.getItem("isLogin") ? (
                <Navigate to="/home" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
      </div>
    </>
  );
}

export default App;
