import React from 'react'
import './ConfirmationModal.css'

const MyComponent=(props:any)=>{
    let pdata = props.data ? props.data:[]

    const confirm = ()=>{
        if (props.RemoveHook && typeof(props.RemoveHook)==='function') {
            props.RemoveHook(pdata)
        }
    }

    const cancel = () =>{
        props.setDeleteMode(false)

    }

    return (
        <>
        <div className='confirm-modal'>
            <div className='confirm-modal-content'>
                <div className='confirm-modal-header'>
                    Delete Record
                </div>
                <div className='confirm-modal-body'>
                    <div>
                    Do you really want to delete these records? Once the record is deleted, this process cannot be undone.
                    </div>
                </div>
                <div className="confirm-modal-footer">
                        <button onClick={confirm}  className='confirm-modal-button'>Confirm</button>
                        <button onClick={cancel} className='confirm-modal-button'>Cancel</button>
                </div>
            </div>
        </div>
        </>
    )

}

export default MyComponent