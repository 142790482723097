import React, { useState, useEffect } from "react";
import RT, { PageControl } from "./../component/RajangTable";
import { Card, Button, Space, SelectProps, notification } from "antd";
import {
  readAllProfile,
  checkmailProfile,
  deleteProfile,
  addLog,
} from "../controller/profile";
import AlertModal from "../component/AlertMessage";
import { useNavigate } from "react-router-dom";

const MyComponent = (props: any) => {
  const navigate = useNavigate();
  const initialPageControl: PageControl = {
    numRec: 0,
    recPerPage: 10,
    pageNum: 1,
  };
  const [data, setData]: any = useState([]);
  const [oridata, setOriData]: any = useState([]);
  const [pageC, setPageC]: any = useState(initialPageControl);
  const [selectedPageData, setSelectedPageData]: any = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [focusedData, setFocusedData] = useState({});
  const [selectedData, setSelectedData]: any = useState([]);
  const [checkedData, setCheckedData]: any = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertmessage, setAlertMessage] = useState("");
  const [alerttype, setAlertType] = useState("");
  const [regiontype, setRegionType]: any = useState([]);
  const [loading, setLoading] = useState(false);
  const [dropDownNameOptions, setdropDownNameOptions]: any = useState([]);
  const [dropDownEmailOptions, setdropDownEmailOptions]: any = useState([]);
  const [dropDownLastEditByOptions, setdropDownLastEditByOptions]: any =
    useState([]);
  const deleteConfirmation = {
    title: "Delete User",
    content: "Are you sure you want to delete these User?",
  };

  const dropDownRoleOptions: any = [
    { value: "Admin", label: "Admin" },
    { value: "User", label: "User" },
  ];

  const dropDownApprovalOptions: any = [
    { label: "Approved", value: "Approved" },
    { label: "Declined", value: "Declined" },
    { label: "Pending", value: "Pending" },
  ];

  // useEffect(() => {
  //   let privilage: any = localStorage.getItem("privilage");
  //   let user: any = localStorage.getItem("user");
  //   let login: any = localStorage.getItem("isLogin");
  //   if (login === "true") {
  //     checkmailProfile({ email: user }).then((res: any) => {
  //       if (res === "You are unauthorized to use this resource") {
  //         setTimeout(() => {
  //           handleTokenExpired();
  //         }, 3000);
  //       } else if (res.data?.[0].role !== privilage) {
  //         localStorage.setItem("privilage", `${res.data[0].role}`);
  //       }
  //     });
  //   }
  // }, []);

  useEffect(() => {
    let user: any = localStorage.getItem("user");
    let log: any = {
      page: window.location.pathname,
      action: "Read",
      datetime: new Date(),
      details: "-",
      email: user,
    };
    addLog(log);
  }, []);

  useEffect(() => {
    // load the list of Configuration
    setLoading(true);
    readAllProfile()
      .then((res: any) => {
        if (res.data) {
          const newData = res.data.map((rec: any) => ({
            _id: rec._id,
            name: rec.name,
            email: rec.email,
            role: rec.role,
            adminApproval: rec.adminApproval,
            lastEditDate: rec.lastEditDate,
            lastEditBy: usernameMapping(res.data, rec.lastEditBy),
            reason: rec?.reason,
            EmailReceiver: rec?.EmailReceiver,
          }));
          setData(newData);
          setOriData(newData);
          const dropDownNameOption = res.data.map((x: any) => ({
            label: x.name,
            value: x.name,
          }));
          let newdropDownNameOption = dropDownNameOption.filter(
            (ele: any, ind: any) =>
              ind ===
              dropDownNameOption.findIndex(
                (elem: any) =>
                  elem.label === ele.label && elem.value === ele.value
              )
          );
          setdropDownNameOptions(newdropDownNameOption);
          const dropDownEmailOption = res.data.map((x: any) => ({
            label: x.email,
            value: x.email,
          }));
          setdropDownEmailOptions(dropDownEmailOption);
          const dropDownLastEditByOption = res.data.map((x: any) => ({
            label: x.lastEditBy,
            value: x.lastEditBy,
          }));
          setdropDownLastEditByOptions(dropDownLastEditByOption);
        } else if (res === "You are unauthorized to use this resource") {
          handleTokenExpired();
        }
        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
      });
  }, []);

  const handleTokenExpired = () => {
    notification.warning({
      message: "Warning",
      description: "Your authorization has expired.Please login again.",
      placement: "top",
      duration: 3,
    });

    setTimeout(() => {
      navigate("/login", { replace: true });
    }, 3000); // delay navigation for 3 seconds
  };

  const refreshData = () => {
    setLoading(true);
    readAllProfile()
      .then((res: any) => {
        if (res.data) {
          const newData = res.data.map((rec: any) => ({
            _id: rec._id,
            name: rec.name,
            email: rec.email,
            role: rec.role,
            adminApproval: rec.adminApproval,
            lastEditDate: rec.lastEditDate,
            lastEditBy: usernameMapping(res.data, rec.lastEditBy),
            reason: rec?.reason,
            EmailReceiver: rec?.EmailReceiver,
          }));
          setData(newData);
          setOriData(newData);
          const dropDownNameOption = res.data.map((x: any) => ({
            label: x.name,
            value: x.name,
          }));
          let newdropDownNameOption = dropDownNameOption.filter(
            (ele: any, ind: any) =>
              ind ===
              dropDownNameOption.findIndex(
                (elem: any) =>
                  elem.label === ele.label && elem.value === ele.value
              )
          );
          setdropDownNameOptions(newdropDownNameOption);
          const dropDownEmailOption = res.data.map((x: any) => ({
            label: x.email,
            value: x.email,
          }));
          setdropDownEmailOptions(dropDownEmailOption);
          const dropDownLastEditByOption = res.data.map((x: any) => ({
            label: x.lastEditBy,
            value: x.lastEditBy,
          }));
          setdropDownLastEditByOptions(dropDownLastEditByOption);
        }
        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const convertUsername = (el: any) => {
    let answer = data.find((o: any) => {
      return o["_id"] === el;
    });
    if (!answer) {
      return el;
    }
    if (!answer["email"]) {
      return "";
    }
    return answer["email"];
  };

  const handleDelete = (selected: any) => {
    deleteProfile(selected).then((res: any) => {
      if (res.data) {
        let user: any = localStorage.getItem("user");
        let log: any = {
          page: window.location.pathname,
          action: "Delete",
          datetime: new Date(),
          details: `Delete User ${selected
            .map((name: any) => convertUsername(name))
            .join(", ")}`,
          email: user,
        };
        addLog(log);
        notification.success({
          message: "User deleted!",
          description: "User has been deleted successfully.",
          placement: "bottomRight",
          bottom: 50,
        });
        setSelectedData([]);
        setCheckedData([]);
        refreshData();
      } else {
        notification.error({
          message: "Failed to delete User!",
          description: "User has not been deleted.",
          placement: "bottomRight",
          bottom: 50,
        });
      }
    });
  };

  const usernameMapping = (record: any, id: any) => {
    let answer: any = record.find((o: any) => {
      return o["email"] == id;
    });
    if (!answer) {
      return id;
    }
    if (!answer["name"]) {
      return id;
    }
    return answer["name"];
  };

  const handleSelected = (data: any) => {
    selectedData[pageC.pageNum] = data;
    data.forEach((element: any) => {
      selectedData[pageC.pageNum].push(element);
    });
    setCheckedData(data);
  };

  const handleAlertMessage = (el: any) => {
    setAlertMessage(el);
    setShowAlert(true);
    off();
  };

  const handleAlertType = (el: any) => {
    setAlertType(el);
    setShowAlert(true);
    off();
  };

  const off = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  const closeAlert = () => {
    setAlertType("");
    setAlertMessage("");
    setShowAlert(false);
  };

  const handleMultiFilter = (el: any) => {
    if (el) {
      for (const key in el) {
        if (
          Object.prototype.hasOwnProperty.call(el, key) &&
          el[key] === undefined
        ) {
          delete el[key];
        }
      }
      const filteredArr = oridata.filter((d: any) => {
        return Object.entries(el).every(([key, value]) => {
          return d[key] === value;
        });
      });
      // console.log("filteredArr",filteredArr)
      setData(filteredArr);
    }
  };

  return (
    <>
      <div>
        <AlertModal
          show={showAlert}
          onClose={closeAlert}
          alertmessage={alertmessage}
          alerttype={alerttype}
          alertmsg
        />
        <Card
          title={`All User (${data.length})`}
          style={{
            width: "90%",
            maxWidth: "90%",
            margin: "auto",
            textAlign: "left",
          }}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <div>
              <RT
                tablewidth="90%"
                hasSelectAll={true}
                data={data}
                cols={[
                  {
                    fieldname: "name",
                    width: "30%",
                    label: "Name",
                    align: "left",
                    lookup: dropDownNameOptions,
                    sortcolumn: "String",
                    clickable: "true",
                    filter: "true",
                  },
                  {
                    fieldname: "email",
                    width: "25%",
                    label: "Email",
                    sortcolumn: "String",
                    lookup: dropDownEmailOptions,
                    filter: "true",
                    align: "left",
                  },
                  {
                    fieldname: "adminApproval",
                    width: "15%",
                    label: "Status",
                    align: "left",
                    lookup: dropDownApprovalOptions,
                    sortcolumn: "String",
                    filter: "true",
                  },
                  {
                    fieldname: "role",
                    width: "15%",
                    label: "Role",
                    align: "left",
                    lookup: dropDownRoleOptions,
                    sortcolumn: "String",
                    filter: "true",
                  },
                  {
                    fieldname: "lastEditDate",
                    width: "15%",
                    label: "Last Edited",
                    align: "left",
                    sortcolumn: "Datetime",
                  },
                ]}
                pageControl={pageC}
                selectedData={selectedPageData}
                checkedData={checkedData}
                removeData={selectedData}
                handleSelected={handleSelected}
                handleMultiFilter={handleMultiFilter}
                handleLoadData={refreshData}
                handleDelete={handleDelete}
                deleteConfirmation={deleteConfirmation}
                action={["delete", "reset"]}
                rowSelection={"true"}
                loading={loading}
                type={"small"}
                listing={[
                  {
                    namelist: dropDownNameOptions,
                    emaillist: dropDownEmailOptions,
                    lastEditBylist: dropDownLastEditByOptions,
                    rolelist: dropDownRoleOptions,
                  },
                ]}
              ></RT>
            </div>
          </Space>
        </Card>
      </div>
    </>
  );
};

export default MyComponent;
