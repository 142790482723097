import React, { useState, useEffect } from "react";
import "./CompanyDetails.css";
import ConfirmationModal from "./ConfirmationModal";
import { FiExternalLink } from "react-icons/fi";
import {
  Select,
  Input,
  Tooltip,
  Spin,
  Checkbox,
  Button,
  Divider,
  Form,
  Col,
  Row,
  Modal,
} from "antd";
import { useNavigate } from "react-router-dom";
import DetailModal from "./DetailsModal";
import { getCSDistributeSummary } from "../controller/search";
import RT, { PageControl } from "./../component/RajangTable";
import { maxCompnumber } from "../controller/company";
import AlertModal from "../component/AlertMessage";
import { SelectOutlined } from "@ant-design/icons";
import Manage_Industry from "../component/Manage_Industry";
import { getAllIndustry } from "../controller/search";

let companyModal: any = {
  key: String,
  name: String,
  truncated_name: String,
  compnumber: Number,
  newcompnumber: String,
  country: Number,
  industry: Number,
  subindustry: [Array],
  total_doc: Number,
  ref: {
    sedol: String,
    bb: String,
    isin: String,
    exchange: String,
    ciq: String,
    ticker: String,
    perm_id: String,
    url: String,
    openfigi: String,
  },
};
const redirectURL = process.env.REACT_APP_URL as string;

const MyComponent = (props: any) => {
  let data_id: any = null;
  const getbaseUrl = window.location.pathname;
  const navigate = useNavigate();
  const initialPageControl: PageControl = {
    numRec: 0,
    recPerPage: 10,
    pageNum: 1,
  };
  const [categoriesModalOpen, setCategoriesModalOpen] =
    useState<boolean>(false);
  const [pageC, setPageC]: any = useState(initialPageControl);
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [formData, setFormData] = useState({ ...props.data });
  const [chooseData, setChooseData]: any = useState({});
  const [isEdited, setEdited] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [industry, setIndustry] = useState("");
  const [industryData, setIndustryData] = useState("");
  const [subindustry, setSubIndustry]: any = useState([]);
  const [compnumber, setCompNumber] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [show, setShow] = useState(false);
  let fileindex: any = props.fileindex ? props.fileindex : [];
  const [showIPModal, setShowIPModal] = useState(false);
  const [pdata, setData] = useState(null);
  const [refdata, setRefData]: any = useState({});
  const [showCConfirm, setShowCConfirm] = useState(false);
  const [loading, setLoading] = useState(props.loading);
  const [width, setWidth] = useState(window.innerWidth);
  const [defaultform, setDefaultform]: any = useState([]);
  const [maxC, setMaxC] = useState(0);
  const [InsertCompanyform]: any = Form.useForm();
  const [EditCompanyform]: any = Form.useForm();
  const [ViewCompanyform]: any = Form.useForm();

  const dropDownApprovalOptions: any = [
    { label: "Approved", value: "Approved" },
    { label: "Declined", value: "Declined" },
    { label: "Pending", value: "Pending" },
  ];

  useEffect(() => {
    const fetchIndustryData = async () => {
      getAllIndustry().then((res: any) => {
        if (res.data) {
          setIndustryData(res.data);
        }
      });
    };
    fetchIndustryData();
  }, []);

  useEffect(() => {
    if (InsertCompanyform) {
      console.log("Resetting");
      InsertCompanyform.resetFields();
      setDefaultform(InsertCompanyform.getFieldsValue());
      // InsertCompanyform.setFieldsValue(chooseData);
    }
  }, [maxC]);

  useEffect(() => {
    if (EditCompanyform) {
      console.log("Resetting");
      EditCompanyform.resetFields();
      EditCompanyform.setFieldsValue(chooseData);
      setDefaultform(EditCompanyform.getFieldsValue());
      // console.log("EditCompanyform", EditCompanyform.getFieldsValue());
    }
  }, [chooseData]);

  useEffect(() => {
    if (ViewCompanyform) {
      console.log("Resetting");
      ViewCompanyform.resetFields();
      ViewCompanyform.setFieldsValue(chooseData);
      // console.log("EditCompanyform", EditCompanyform.getFieldsValue());
    }
  }, [chooseData]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (getbaseUrl == "/home") {
      const _cdata: any = JSON.parse(JSON.stringify(props.cdata));
      _cdata.total_doc = _cdata?.CS + _cdata?.HCS + _cdata?.RCD;
      setChooseData(_cdata);
    } else {
      setChooseData(props.cdata);
    }
    setFormData(props.data);
    setCountryList(props.countrylist);
    setIndustryList(props.industrylist);
    setLoading(props.loading);
    setRoleList(props.rolelist);
    if (props.cdata?.name) {
      if (
        props.handleSearchCompany &&
        typeof props.handleSearchCompany === "function"
      ) {
        props.handleSearchCompany(props.cdata.name);
      }
    }
  }, [props.data?.name, props.cdata?.name, props.loading]);

  const ApprovalMapping = (el: any) => {
    let answer = dropDownApprovalOptions.find((o: any) => {
      return o["value"] === el;
    });
    if (!answer) {
      return "";
    }
    if (!answer["label"]) {
      return "";
    }
    return answer["label"];
  };

  const handleChange = (evt: any) => {
    if (!editMode) setEditMode(true);
    let _data: any = { ...formData };
    _data[evt.target.name] = evt.target.value;
    setFormData(_data);
  };

  const handleCountryChange = (evt: any) => {
    if (!editMode) setEditMode(true);
    let _data: any = { ...formData };
    _data["country"] = evt;
    setFormData(_data);
  };

  const handleIndustryChange = (evt: any) => {
    if (!editMode) setEditMode(true);
    let _data: any = { ...formData };
    if (_data["subindustry"]) {
      if (!_data["subindustry"].includes(evt)) {
        _data["industry"] = evt;
        setFormData(_data);
      } else {
        let msg = "Primary industry cannot be the same as Associated industry";
        if (
          props.handleAlertMessage &&
          typeof props.handleAlertMessage === "function"
        ) {
          props.handleAlertMessage(msg);
        }
      }
    } else {
      _data["industry"] = evt;
      setFormData(_data);
    }
  };

  const handleSubIndustryChange = (evt: any) => {
    if (!editMode) setEditMode(true);
    let _data: any = { ...formData };
    if (!evt.includes(_data["industry"])) {
      evt = evt.filter((value: any) => {
        return value !== _data["industry"];
      });
      _data["subindustry"] = evt;
      setFormData(_data);
    } else {
      let msg = "Associated industry cannot be the same as Primary industry";
      if (
        props.handleAlertMessage &&
        typeof props.handleAlertMessage === "function"
      ) {
        props.handleAlertMessage(msg);
      }
      evt = evt.filter((value: any) => {
        return value !== _data["industry"];
      });
      _data["subindustry"] = evt;
      setFormData(_data);
    }
  };

  const handleXChange = (evt: any) => {
    if (!editMode) setEditMode(true);
    if (
      props.handleSearchCompany &&
      typeof props.handleSearchCompany === "function"
    ) {
      props.handleSearchCompany(evt.target.value);
    }
    let _data: any = { ...chooseData };
    _data[evt.target.name] = evt.target.value;
    setChooseData(_data);
    if (props.handleChange && typeof props.handleChange === "function") {
      props.handleChange(_data);
    }
  };

  const handleXCountryChange = (evt: any) => {
    if (!editMode) setEditMode(true);
    let _data: any = { ...chooseData };
    _data["country"] = evt;
    setChooseData(_data);
    if (props.handleChange && typeof props.handleChange === "function") {
      props.handleChange(_data);
    }
  };

  const handleXIndustryChange = (evt: any) => {
    if (!editMode) setEditMode(true);
    let _data: any = { ...chooseData };
    if (_data["subindustry"]) {
      if (!_data["subindustry"].includes(evt)) {
        _data["industry"] = evt;
        setChooseData(_data);
      } else {
        let msg = "Primary industry cannot be the same as Associated industry";
        if (
          props.handleAlertMessage &&
          typeof props.handleAlertMessage === "function"
        ) {
          props.handleAlertMessage(msg);
        }
      }
    } else {
      _data["industry"] = evt;
      setChooseData(_data);
    }
    if (props.handleChange && typeof props.handleChange === "function") {
      props.handleChange(_data);
    }
  };

  const handleXSubIndustryChange = (evt: any) => {
    if (!editMode) setEditMode(true);
    let _data: any = { ...chooseData };
    if (!evt.includes(_data["industry"])) {
      evt = evt.filter((value: any) => {
        return value !== _data["industry"];
      });
      _data["subindustry"] = evt;
      setChooseData(_data);
    } else {
      let msg = "Associated industry cannot be the same as Primary industry";
      if (
        props.handleAlertMessage &&
        typeof props.handleAlertMessage === "function"
      ) {
        props.handleAlertMessage(msg);
      }
      evt = evt.filter((value: any) => {
        return value !== _data["industry"];
      });
      _data["subindustry"] = evt;
      setChooseData(_data);
    }
    if (props.handleChange && typeof props.handleChange === "function") {
      props.handleChange(_data);
    }
  };

  const handleNewChange = (evt: any) => {
    let _data: any = { ...chooseData };
    _data[evt.target.name] = evt.target.value;
    setChooseData(_data);
    if (props.handleChange && typeof props.handleChange === "function") {
      props.handleChange(_data);
    }
  };

  const handleRefChange = (evt: any) => {
    let _data = { ...chooseData };
    if (_data["ref"]) {
      _data["ref"][evt.target.name] = evt.target.value;
    } else {
      _data["ref"] = {};
      _data["ref"][evt.target.name] = evt.target.value;
    }
    setChooseData(_data);
    if (props.handleChange && typeof props.handleChange === "function") {
      props.handleChange(_data);
    }
  };

  const handleNewCountryChange = (evt: any) => {
    let _data: any = { ...chooseData };
    _data["country"] = evt;
    setChooseData(_data);
    if (props.handleChange && typeof props.handleChange === "function") {
      props.handleChange(_data);
    }
  };

  const handleNewPrimaryIndustryChange = (evt: any) => {
    let _data: any = { ...chooseData };
    if (_data["subindustry"]) {
      if (!subindustry.includes(evt)) {
        setIndustry(evt);
        _data["industry"] = evt;
        setChooseData(_data);
      } else {
        let msg = "Primary industry cannot be the same as Associated industry";
        if (
          props.handleAlertMessage &&
          typeof props.handleAlertMessage === "function"
        ) {
          props.handleAlertMessage(msg);
        }
      }
    } else {
      setIndustry(evt);
      _data["industry"] = evt;
      setChooseData(_data);
    }
    if (props.handleChange && typeof props.handleChange === "function") {
      props.handleChange(_data);
    }
  };

  const handleNewSecondaryIndustryChange = (evt: any) => {
    let _data: any = { ...chooseData };
    if (!evt.includes(industry)) {
      setSubIndustry(evt);
      _data["subindustry"] = evt;
      setChooseData(_data);
    } else {
      let msg = "Associated industry cannot be the same as Primary industry";
      if (
        props.handleAlertMessage &&
        typeof props.handleAlertMessage === "function"
      ) {
        props.handleAlertMessage(msg);
      }
    }
    if (props.handleChange && typeof props.handleChange === "function") {
      props.handleChange(_data);
    }
  };

  const remove = () => {
    setShow(true);
    setDeleteMode(true);
  };
  const removeCData = () => {
    setShow(true);
    setDeleteMode(true);
  };
  const confirmdelete = (d: any) => {
    if (props.removeDataHook && typeof props.removeDataHook === "function") {
      props.removeDataHook(d);
    }
  };
  const confirm = () => {
    setEdited(true);
    setEditedData(formData);
    setEditMode(false);
  };
  const save = () => {
    setEdited(true);
    setEditedData(formData);
    setEditMode(false);
    // let _data:any = {...chooseData}
    // console.log("_data",chooseData)
    // if(!_data["industry"]){
    //     let msg="Primary Industry is compulsory field"
    //     if (props.handleAlertMessage && typeof(props.handleAlertMessage)==='function') {
    //         props.handleAlertMessage(msg)
    //     }
    // }else if(!_data["compnumber"]){
    //     let msg="Company Number is compulsory field"
    //     if (props.handleAlertMessage && typeof(props.handleAlertMessage)==='function') {
    //         props.handleAlertMessage(msg)
    //     }
    // }else if(!_data["name"]){
    //     let msg="Company Name is compulsory field"
    //     if (props.handleAlertMessage && typeof(props.handleAlertMessage)==='function') {
    //         props.handleAlertMessage(msg)
    //     }
    // }else{
    //     if (props.saveDataHook && typeof(props.saveDataHook)==='function') {
    //         props.saveDataHook(_data)
    //         setTimeout(function(){
    //             if (props.onClose && typeof(props.onClose)==='function') {
    //                 props.onClose()
    //             }
    //         },1000)
    //     }
    // }
    if (props.saveDataHook && typeof props.saveDataHook === "function") {
      props.saveDataHook(formData);
      // setTimeout(function () {
      if (props.onClose && typeof props.onClose === "function") {
        props.onClose();
      }
      // }, 3000);
    }
  };
  const confirmCData = async () => {
    setEdited(true);
    setEditedData(chooseData);
    setEditMode(false);
    // let _data: any = { ...chooseData };
    // if (!_data["industry"]) {
    //   let msg = "Primary Industry is compulsory field";
    //   if (
    //     props.handleAlertMessage &&
    //     typeof props.handleAlertMessage === "function"
    //   ) {
    //     props.handleAlertMessage(msg);
    //   }
    // } else if (!_data["country"]) {
    //   let msg = "Country is compulsory field";
    //   if (
    //     props.handleAlertMessage &&
    //     typeof props.handleAlertMessage === "function"
    //   ) {
    //     props.handleAlertMessage(msg);
    //   }
    // } else if (!_data["compnumber"]) {
    //   let msg = "Company Number is compulsory field";
    //   if (
    //     props.handleAlertMessage &&
    //     typeof props.handleAlertMessage === "function"
    //   ) {
    //     props.handleAlertMessage(msg);
    //   }
    // } else if (!_data["name"]) {
    //   let msg = "Company Name is compulsory field";
    //   if (
    //     props.handleAlertMessage &&
    //     typeof props.handleAlertMessage === "function"
    //   ) {
    //     props.handleAlertMessage(msg);
    //   }
    // } else if (_data["name"] !== props.cdata["name"]) {
    //   if (props.searchResult?.length != 0) {
    //     let result = props.searchResult.find(
    //       (k: any) =>
    //         k.name === _data["name"] && k.compnumber !== _data["compnumber"]
    //     );
    //     if (result) {
    //       let msg = "Duplicate Company Name is not allowed.";
    //       if (
    //         props.handleAlertMessage &&
    //         typeof props.handleAlertMessage === "function"
    //       ) {
    //         props.handleAlertMessage(msg);
    //       }
    //     } else {
    //       if (props.saveDataHook && typeof props.saveDataHook === "function") {
    //         props.saveDataHook(_data);
    //         // setTimeout(function () {
    //         if (props.onClose && typeof props.onClose === "function") {
    //           props.onClose();
    //         }
    //         // }, 3000);
    //       }
    //     }
    //   } else {
    //     if (props.saveDataHook && typeof props.saveDataHook === "function") {
    //       props.saveDataHook(_data);
    //       // setTimeout(function () {
    //       if (props.onClose && typeof props.onClose === "function") {
    //         props.onClose();
    //       }
    //       // }, 3000);
    //     }
    //   }
    // } else {
    //   if (props.saveDataHook && typeof props.saveDataHook === "function") {
    //     props.saveDataHook(_data);
    //     // setTimeout(function () {
    //     if (props.onClose && typeof props.onClose === "function") {
    //       props.onClose();
    //     }
    //     // }, 3000);
    //   }
    // }
    try {
      const values = await EditCompanyform.validateFields();
      const currentValues = EditCompanyform.getFieldsValue();
      const updatedValues = {
        ...currentValues,
        // newcompnumber: maxComp(),
      };
      console.log("lol", updatedValues);
      if (props.saveDataHook && typeof props.saveDataHook === "function") {
        props.saveDataHook(updatedValues);
        // setTimeout(function () {
        if (props.onClose && typeof props.onClose === "function") {
          props.onClose();
        }
        // }, 3000);
      }
    } catch (error) {
      console.error("Form validation failed:", error);
    }

    // if (props.saveDataHook && typeof(props.saveDataHook)==='function') {
    //     props.saveDataHook(chooseData)
    //     setTimeout(function(){
    //         if (props.onClose && typeof(props.onClose)==='function') {
    //             props.onClose()
    //         }
    //     },500)
    // }
  };

  const maxComp = () => {
    var anything = maxC + 1;
    var anyStr = anything.toString();
    return anyStr.padStart(8, "0");
  };

  useEffect(() => {
    maxCompnumber()
      .then((res: any) => {
        // console.log("maxcomp", res.data.data);
        setMaxC(res.data.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const create = async () => {
    // let _data: any = { ...chooseData };
    // _data["ref"] = refdata;
    // _data["key"] = "reportal";
    // _data["compnumber"] = maxC + 1;
    // _data["newcompnumber"] = maxComp();
    // if (!_data["industry"]) {
    //   let msg = "Primary Industry is compulsory field";
    //   if (
    //     props.handleAlertMessage &&
    //     typeof props.handleAlertMessage === "function"
    //   ) {
    //     props.handleAlertMessage(msg);
    //   }
    // } else if (!_data["country"]) {
    //   let msg = "Country is compulsory field";
    //   if (
    //     props.handleAlertMessage &&
    //     typeof props.handleAlertMessage === "function"
    //   ) {
    //     props.handleAlertMessage(msg);
    //   }
    // } else if (!_data["compnumber"]) {
    //   let msg = "Company Number is compulsory field";
    //   if (
    //     props.handleAlertMessage &&
    //     typeof props.handleAlertMessage === "function"
    //   ) {
    //     props.handleAlertMessage(msg);
    //   }
    // } else if (!_data["name"]) {
    //   let msg = "Company Name is compulsory field";
    //   if (
    //     props.handleAlertMessage &&
    //     typeof props.handleAlertMessage === "function"
    //   ) {
    //     props.handleAlertMessage(msg);
    //   }
    // } else {
    //   if (props.saveCreateHook && typeof props.saveCreateHook === "function") {
    //     props.saveCreateHook(_data);
    //     // setTimeout(function () {
    //     if (props.onClose && typeof props.onClose === "function") {
    //       props.onClose();
    //     }
    //     // }, 3000);
    //   }
    // }
    try {
      // Validate all form fields
      const values = await InsertCompanyform.validateFields();

      // If validation is successful, proceed to the next function
      // You can call your next function here, passing the form values if needed
      // For example:
      // Modal.confirm({
      //   title: "Confirm Save",
      //   content: (
      //     <div>
      //       Are you sure you want to merge?
      //       <br />
      //       <i>This merge is irreversible and cannot be undone.</i>
      //     </div>
      //   ),
      //   okText: "Yes",
      //   cancelText: "No",
      //   onOk() {
      //     handleMerge();
      //   },
      // });
      // _data["key"] = "reportal";
      // _data["compnumber"] = maxC + 1;
      // _data["newcompnumber"] = maxComp();
      const currentValues = InsertCompanyform.getFieldsValue();
      const updatedValues = {
        ...currentValues,
        key: "reportal",
        newcompnumber: maxComp(),
      };
      if (props.saveCreateHook && typeof props.saveCreateHook === "function") {
        props.saveCreateHook(updatedValues);
        // setTimeout(function () {
        if (props.onClose && typeof props.onClose === "function") {
          props.onClose();
        }
        // }, 3000);
      }
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };

  const redirectCompanyConsolidation = () => {
    const data = { name: chooseData.name, compnumber: chooseData.compnumber };
    // navigate("/company_consolidation", {
    //   replace: true,
    //   state: {
    //     data: data,
    //   },
    // });
    const queryParams = new URLSearchParams(data).toString();
    const encodedStateData = encodeURIComponent(JSON.stringify(data));
    const url = `${redirectURL}company_consolidation?data=${encodedStateData}`;
    // const url = "/data_relationship?" + queryParams;
    const newTab: any = window.open(url, "_blank");

    if (newTab) {
      newTab.focus();
    }
  };

  const closeRemoveAlert = () => {
    setShowCConfirm(false);
  };

  const openDataRelationship = () => {
    let data = {};
    if (!formData?.key && !chooseData?.key && props.companycreator) {
      data = {
        name: InsertCompanyform.getFieldsValue().name,
        compnumber: InsertCompanyform.getFieldsValue().compnumber,
      };
    } else if (
      !formData?.key &&
      chooseData?.key &&
      props.companycreator &&
      localStorage.getItem("privilage") === "Admin"
    ) {
      data = {
        name: EditCompanyform.getFieldsValue().name,
        compnumber: EditCompanyform.getFieldsValue().compnumber,
      };
    } else if (
      !formData?.key &&
      chooseData?.key &&
      props.companycreator &&
      localStorage.getItem("privilage") === "User"
    ) {
      data = {
        name: ViewCompanyform.getFieldsValue().name,
        compnumber: ViewCompanyform.getFieldsValue().compnumber,
      };
    }
    const queryParams = new URLSearchParams(data).toString();
    const encodedStateData = encodeURIComponent(JSON.stringify(data));
    const url = `${redirectURL}search?data=${encodedStateData}`;
    // const url = "/data_relationship?" + queryParams;
    const newTab: any = window.open(url, "_blank");

    if (newTab) {
      newTab.focus();
    }
  };

  const confirmCancel = () => {
    closeRemoveAlert();
    setEditMode(false);
    if (props.onClose && typeof props.onClose === "function") {
      props.onClose();
    }
  };

  const cancel = () => {
    // const { total_doc, ...chooseDataWithoutTotalDoc } = chooseData;
    // console.log("default", defaultform);
    // console.log("props.sdata", props.sdata);
    // console.log("props.cdata", props.cdata);
    if (defaultform?.name === "") {
      if (
        JSON.stringify(InsertCompanyform.getFieldsValue()) !==
        JSON.stringify(defaultform)
      ) {
        Modal.confirm({
          title: "Leave without saving?",
          content: (
            <>
              Are you sure you want to cancel?
              <br />
              You have unsaved changes that will be reverted.
            </>
          ),
          okText: "Ok",
          cancelText: "Cancel",
          onOk() {
            confirmCancel();
          },
        });
      } else {
        if (props.onClose && typeof props.onClose === "function") {
          props.onClose();
        }
      }
    } else {
      if (
        JSON.stringify(EditCompanyform.getFieldsValue()) !==
        JSON.stringify(defaultform)
      ) {
        Modal.confirm({
          title: "Leave without saving?",
          content: (
            <>
              Are you sure you want to cancel?
              <br />
              You have unsaved changes that will be reverted.
            </>
          ),
          okText: "Ok",
          cancelText: "Cancel",
          onOk() {
            confirmCancel();
          },
        });
      } else {
        if (props.onClose && typeof props.onClose === "function") {
          props.onClose();
        }
      }
    }
    // if (props.sdata && Object.keys(props.sdata).length !== 0) {
    //   console.log("1");
    //   if (JSON.stringify(chooseData) !== JSON.stringify(props.sdata)) {
    //     setShowCConfirm(true);
    //   } else {
    //     setEditMode(false);
    //     // setTimeout(function () {
    //     if (props.onClose && typeof props.onClose === "function") {
    //       props.onClose();
    //     }
    //     // }, 3000);
    //   }
    // } else if (props.cdata && Object.keys(props.cdata).length !== 0) {
    //   console.log("2");
    //   if (JSON.stringify(chooseData) !== JSON.stringify(props.cdata)) {
    //     setShowCConfirm(true);
    //   } else {
    //     setEditMode(false);
    //     // setTimeout(function () {
    //     if (props.onClose && typeof props.onClose === "function") {
    //       props.onClose();
    //     }
    //     // }, 3000);
    //   }
    // }
    // else if (chooseData && Object.keys(chooseData).length !== 0) {
    //   console.log("3");
    //   let CSData = Object.values(chooseData);
    //   CSData.forEach((el: any) => {
    //     if (el.trim().length === 0) {
    //       // setTimeout(function () {
    //       if (props.onClose && typeof props.onClose === "function") {
    //         props.onClose();
    //       }
    //       // }, 3000);
    //     } else {
    //       setShowCConfirm(true);
    //     }
    //   });
    // }
    // else if (
    //   InsertCompanyform.getFieldsValue().name !== "" ||
    //   InsertCompanyform.getFieldsValue().country !== "" ||
    //   InsertCompanyform.getFieldsValue().industry !== ""
    // ) {
    //   setShowCConfirm(true);
    // } else {
    //   console.log("last");
    //   setEditMode(false);
    //   // setTimeout(function () {
    //   if (props.onClose && typeof props.onClose === "function") {
    //     props.onClose();
    //   }
    //   // }, 3000);
    // }
  };

  const cancelUser = () => {
    if (props.cdata) {
      if (JSON.stringify(chooseData) !== JSON.stringify(props.cdata)) {
        setShowCConfirm(true);
      } else {
        setEditMode(false);
        // setTimeout(function () {
        if (props.onClose && typeof props.onClose === "function") {
          props.onClose();
        }
        // }, 3000);
      }
    } else if (chooseData) {
      let CSData = Object.values(chooseData);
      CSData.forEach((el: any) => {
        if (el.trim().length === 0) {
          // setTimeout(function () {
          if (props.onClose && typeof props.onClose === "function") {
            props.onClose();
          }
          // }, 3000);
        } else {
          setShowCConfirm(true);
        }
      });
    } else {
      setEditMode(false);
      // setTimeout(function () {
      if (props.onClose && typeof props.onClose === "function") {
        props.onClose();
      }
      // }, 3000);
    }
  };

  const updateUser = () => {
    if (props.cdata) {
      let _data: any = { ...chooseData };
      if (JSON.stringify(chooseData) !== JSON.stringify(props.cdata)) {
        if (chooseData.adminApproval === "Declined") {
          if (typeof chooseData.reason === "undefined" && !chooseData.reason) {
            let msg = "Reject reason is compulsory field";
            if (
              props.handleAlertMessage &&
              typeof props.handleAlertMessage === "function"
            ) {
              props.handleAlertMessage(msg);
            }
          } else {
            _data["lastEditBy"] = localStorage.getItem("user");
            _data["lastEditDate"] = Date.now();
            setEdited(true);
            setEditedData(chooseData);
            setEditMode(false);
            if (
              props.saveDataHook &&
              typeof props.saveDataHook === "function"
            ) {
              props.saveDataHook(_data);
              // setTimeout(function () {
              if (props.onClose && typeof props.onClose === "function") {
                props.onClose();
              }
              // }, 3000);
            }
          }
        } else {
          _data["lastEditBy"] = localStorage.getItem("user");
          _data["lastEditDate"] = Date.now();
          setEdited(true);
          setEditedData(chooseData);
          setEditMode(false);
          if (props.saveDataHook && typeof props.saveDataHook === "function") {
            props.saveDataHook(_data);
            // setTimeout(function () {
            if (props.onClose && typeof props.onClose === "function") {
              props.onClose();
            }
            // }, 3000);
          }
        }
      } else {
        close();
      }
    }
  };

  const reset = () => {
    // console.log("props.sdata", props.sdata);
    // console.log("props.isdata", props.isdata);
    if (props.sdata) {
      if (
        JSON.parse(JSON.stringify(chooseData)) !==
        JSON.parse(JSON.stringify(props.sdata))
      ) {
        const _sdata: any = JSON.parse(JSON.stringify(props.sdata));
        _sdata.total_doc = _sdata?.CS + _sdata?.HCS + _sdata?.RCD;
        setChooseData(_sdata);
        // setChooseData(JSON.parse(JSON.stringify(props.sdata)));
        if (props.sdata?.name) {
          if (
            props.handleSearchCompany &&
            typeof props.handleSearchCompany === "function"
          ) {
            props.handleSearchCompany(props.sdata.name);
          }
        }
      }
    } else if (props.isdata) {
      if (
        JSON.parse(JSON.stringify(chooseData)) !==
        JSON.parse(JSON.stringify(props.isdata))
      ) {
        const _isdata: any = JSON.parse(JSON.stringify(props.isdata));
        _isdata.total_doc = _isdata.CS + _isdata.HCS + _isdata.RCD;
        setChooseData(_isdata);
        if (props.isdata?.name) {
          if (
            props.handleSearchCompany &&
            typeof props.handleSearchCompany === "function"
          ) {
            props.handleSearchCompany(props.isdata.name);
          }
        }
      }
    }
    setEditMode(false);
  };

  const createNew = () => {
    if (props.resetDataHook && typeof props.resetDataHook === "function") {
      props.resetDataHook(chooseData);
    }
    setEditMode(false);
  };

  const close = () => {
    if (props.onClose && typeof props.onClose === "function") {
      props.onClose();
    }
  };
  const handleSearchCompany = (el: any) => {
    handleNewChange(el);
    setName(el.target.value);
    if (
      props.handleSearchCompany &&
      typeof props.handleSearchCompany === "function"
    ) {
      props.handleSearchCompany(el.target.value);
    }
  };
  const handleSearchCountry = (el: any) => {
    handleNewCountryChange(el);
    setCountry(el);
    if (
      props.handleSearchCountry &&
      typeof props.handleSearchCountry === "function"
    ) {
      props.handleSearchCountry(el);
    }
  };

  const handleSearchCompanyNumber = (el: any) => {
    handleNewChange(el);
    setCompNumber(el.target.value);
    if (
      props.handleSearchCompanyNumber &&
      typeof props.handleSearchCompanyNumber === "function"
    ) {
      props.handleSearchCompanyNumber(el.target.value);
    }
  };

  const viewIndustry_CompanyPublication = () => {
    navigate("/industry_publication_", {
      replace: true,
      state: {
        _id: formData?._id,
        data: formData?.compnumber,
        companylist: props.companylist,
        baseurl: "/industry_publication_",
      },
    });
  };

  const viewCountry_CompanyPublication = () => {
    navigate("/country_publication_", {
      replace: true,
      state: {
        _id: formData?._id,
        data: formData?.compnumber,
        companylist: props.companylist,
        baseurl: "/country_publication_",
      },
    });
  };

  const fileIndexCompanyMapping = (el: any) => {
    let answer = props.companylist.find((o: any) => {
      return o["value"] == el;
    });
    if (!answer) {
      return el;
    }
    if (!answer["label"]) {
      return el;
    }
    return answer["label"];
  };

  const CompanyRenderRow: any = (el: any) => {
    if (el) {
      let aa: any = [];
      el.map((d: any) => {
        aa.push(String(fileIndexCompanyMapping(d)));
      });
      aa.sort(function (a: any, b: any) {
        return a.localeCompare(b);
      });
      return (
        <div
          style={{
            height: "90px",
            border: "1px solid black",
            overflow: "auto",
            fontSize: "14px",
            textAlign: "left",
          }}
        >
          {aa.map((d: any) => {
            return <React.Fragment>{ComRenderRow(d)}</React.Fragment>;
          })}
        </div>
      );
    }
  };
  const fileIndexIndustryMapping = (el: any) => {
    let answer = props.industrylist.find((o: any) => {
      return o["value"] == el;
    });
    if (!answer) {
      return el;
    }
    if (!answer["label"]) {
      return el;
    }
    return answer["label"];
  };
  const IndustryRenderRow: any = (el: any) => {
    if (el) {
      let aa: any = [];
      el.map((d: any) => {
        aa.push(String(fileIndexIndustryMapping(d)));
      });
      aa.sort(function (a: any, b: any) {
        return a.localeCompare(b);
      });
      return (
        <div
          style={{
            height: "90px",
            border: "1px solid black",
            overflow: "auto",
            fontSize: "14px",
            textAlign: "left",
          }}
        >
          {aa.map((d: any) => {
            return <React.Fragment key={d}>{IndRenderRow(d)}</React.Fragment>;
          })}
        </div>
      );
    }
  };

  const fileIndexCountryMapping = (el: any) => {
    let answer = props.countrylist.find((o: any) => {
      return o["value"] == el;
    });
    if (!answer) {
      return el;
    }
    if (!answer["label"]) {
      return el;
    }
    return answer["label"];
  };

  const CountryRenderRow: any = (el: any) => {
    if (el) {
      let aa: any = [];
      el.map((d: any) => {
        aa.push(String(fileIndexCountryMapping(d)));
      });
      aa.sort(function (a: any, b: any) {
        return a.localeCompare(b);
      });
      return (
        <div
          style={{
            height: "90px",
            border: "1px solid black",
            overflow: "auto",
            fontSize: "14px",
            textAlign: "left",
          }}
        >
          {aa.map((d: any) => {
            return <React.Fragment key={d}>{CounRenderRow(d)}</React.Fragment>;
          })}
        </div>
      );
    }
  };

  const PublicationRenderRow: any = (el: any) => {
    if (el) {
      let aa: any = [];
      el.map((d: any) => {
        aa.push(String(d));
      });
      aa.sort(function (a: any, b: any) {
        return a.localeCompare(b);
      });
      return (
        <div
          style={{
            height: "150px",
            border: "1px black",
            borderStyle: "none solid solid solid",
            overflow: "auto",
            fontSize: "16px",
            textAlign: "left",
          }}
        >
          {aa.map((d: any) => {
            return (
              <React.Fragment key={d}>{PublicateRenderRow(d)}</React.Fragment>
            );
          })}
        </div>
      );
    }
  };

  const Publication1RenderRow: any = (el: any) => {
    if (Array.isArray(el)) {
      let aa: any = [];
      el.map((d: any) => {
        aa.push(String(d));
      });
      aa.sort(function (a: any, b: any) {
        return a.localeCompare(b);
      });
      return (
        <div>
          {aa.map((d: any) => {
            return (
              <React.Fragment key={d}>{Publicate1RenderRow(d)}</React.Fragment>
            );
          })}
        </div>
      );
    } else {
      return el;
    }
  };

  const replaceSpacesWithPercentage = (string: any) => {
    // console.log("string", string);
    return string.replace(/ /g, "%20");
  };

  const openFIGI = () => {
    // console.log("formdata", formData);
    // console.log("choosedata", chooseData);
    // console.log("companyformdata", InsertCompanyform);
    if (chooseData !== undefined && chooseData.name) {
      let query = replaceSpacesWithPercentage(chooseData?.name);
      let country = chooseData?.country;
      let url: any;
      if (country) {
        url = `https://www.openfigi.com/search#!?page=1&marketSector=Equity&facets=CNTRY_ISSUE_ISO:,,${country}&SECURITY_TYP:,,Common%20Stock&simpleSearchString=${query}`;
      } else {
        url = `https://www.openfigi.com/search#!?page=1&marketSector=Equity&facets=SECURITY_TYP:,,Common%20Stock&simpleSearchString=${query}`;
      }

      const newTab: any = window.open(url, "_blank");
      if (newTab) {
        newTab.focus();
      }
    } else if (formData !== undefined && formData.name) {
      let query = replaceSpacesWithPercentage(formData?.name);
      let country = formData?.country;
      let url: any;
      if (country) {
        url = `https://www.openfigi.com/search#!?page=1&marketSector=Equity&facets=CNTRY_ISSUE_ISO:,,${country}&SECURITY_TYP:,,Common%20Stock&simpleSearchString=${query}`;
      } else {
        url = `https://www.openfigi.com/search#!?page=1&marketSector=Equity&facets=SECURITY_TYP:,,Common%20Stock&simpleSearchString=${query}`;
      }
      const newTab: any = window.open(url, "_blank");
      if (newTab) {
        newTab.focus();
      }
    } else {
      let query = replaceSpacesWithPercentage(
        InsertCompanyform.getFieldsValue().name
      );
      let country = InsertCompanyform.getFieldsValue().country;
      let url: any;
      if (country) {
        url = `https://www.openfigi.com/search#!?page=1&marketSector=Equity&facets=CNTRY_ISSUE_ISO:,,${country}&SECURITY_TYP:,,Common%20Stock&simpleSearchString=${query}`;
      } else {
        url = `https://www.openfigi.com/search#!?page=1&marketSector=Equity&facets=SECURITY_TYP:,,Common%20Stock&simpleSearchString=${query}`;
      }
      const newTab: any = window.open(url, "_blank");
      if (newTab) {
        newTab.focus();
      }
    }
  };

  const insertcompanyformdata: any = [
    {
      key: "name",
      name: "name",
      label: "Company Name",
      rules: [{ required: true, message: "" }],
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      component: <Input placeholder="Enter Company Name" />,
    },
    {
      key: "truncated_name",
      name: "truncated_name",
      label: "Truncated Name",
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      component: <Input placeholder="Enter Truncated Name" />,
    },
    {
      key: "compnumber",
      name: "compnumber",
      label: "Company Number",
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      component: (
        <Input
          readOnly
          suffix={
            <Tooltip
              placement="top"
              title={"Click to view company details on data relationship"}
            >
              <SelectOutlined
                style={{ transform: "scaleX(-1)" }}
                onClick={openDataRelationship}
              />
            </Tooltip>
          }
        />
      ),
    },
    {
      key: "country",
      name: "country",
      label: "Country",
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      rules: [{ required: true, message: "" }],
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          showSearch
          placeholder="Search to Select"
          optionFilterProp="children"
          // value={CountryMapping(dataMerge.country)}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={countryList}
        />
      ),
    },
    {
      key: "industry",
      name: "industry",
      label: "Primary Industry",
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      rules: [
        { required: true, message: "" },
        {
          validator: async (rule: any, value: any) => {
            if (InsertCompanyform.getFieldsValue().subindustry) {
              if (
                !InsertCompanyform.getFieldsValue().subindustry.includes(value)
              ) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  "Primary industry cannot be the same as Associated industry."
                );
              }
            }
          },
        },
      ],
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          showSearch
          placeholder="Search to Select"
          optionFilterProp="children"
          // value={IndustryMapping(dataMerge.industry)}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={industryList}
          dropdownRender={(option: any) => (
            <>
              {option}
              <Divider
                style={{
                  margin: "auto",
                  borderTop: "1px solid grey",
                }}
              />
              <Button
                className="manageCategoriesButton"
                type="text"
                onClick={() => setCategoriesModalOpen(true)}
              >
                Manage Industries
              </Button>
            </>
          )}
        />
      ),
    },
    {
      key: "subindustry",
      name: "subindustry",
      label: "Associated Industry",
      rules: [
        {
          validator: async (rule: any, value: any) => {
            if (
              InsertCompanyform.getFieldsValue().subindustry?.length > 0 &&
              InsertCompanyform.getFieldsValue().industry !== ""
            ) {
              if (value.length > 0) {
                // return Promise.resolve();
                if (
                  !value.includes(InsertCompanyform.getFieldsValue().industry)
                ) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    "Associated industry cannot be the same as Primary industry."
                  );
                }
              } else {
                return Promise.reject();
              }
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          mode="multiple"
          showSearch
          placeholder="Search to Select"
          // value={dataMerge.subindustry}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={industryList}
        />
      ),
    },
    {
      key: "sedol",
      name: ["ref", "sedol"],
      label: "SEDOL",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter SEDOL" />,
    },
    {
      key: "bb",
      name: ["ref", "bb"],
      label: "BB",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter BB" />,
    },
    {
      key: "isin",
      name: ["ref", "isin"],
      label: "ISIN",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter ISIN" />,
    },
    {
      key: "exchange",
      name: ["ref", "exchange"],
      label: "Exchange",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter Exchange" />,
    },
    {
      key: "ciq",
      name: ["ref", "ciq"],
      label: "CIQ",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter CIQ" />,
    },
    {
      key: "ticker",
      name: ["ref", "ticker"],
      label: "Ticker",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter TICKER" />,
    },
    {
      key: "perm_id",
      name: ["ref", "perm_id"],
      label: "PermID",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter PermID" />,
    },
    {
      key: "url",
      name: ["ref", "url"],
      label: "URL",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter URL" />,
    },
    {
      key: "openfigi",
      name: ["ref", "openfigi"],
      label: "OpenFIGI",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 16,
      },
      component: (
        <Input
          // size="small"
          placeholder="Enter OpenFIGI"
          suffix={
            <Tooltip placement="top" title={"Click to redirect to openFIGI"}>
              <SelectOutlined
                style={{ transform: "scaleX(-1)" }}
                onClick={openFIGI}
              />
            </Tooltip>
          }
        />
      ),
    },
  ];

  const editcompanyformdata: any = [
    { key: "_id", name: "_id", hidden: true },
    { key: "key", name: "key", hidden: true },

    {
      key: "name",
      name: "name",
      label: "Company Name",
      rules: [{ required: true, message: "" }],
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      component: <Input placeholder="Enter Company Name" />,
    },
    {
      key: "truncated_name",
      name: "truncated_name",
      label: "Truncated Name",
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      component: <Input placeholder="Enter Truncated Name" />,
    },
    {
      key: "compnumber",
      name: "compnumber",
      label: "Company Number",
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      component: (
        <Input
          readOnly
          suffix={
            <Tooltip
              placement="top"
              title={"Click to view company details on data relationship"}
            >
              <SelectOutlined
                style={{ transform: "scaleX(-1)" }}
                onClick={openDataRelationship}
              />
            </Tooltip>
          }
        />
      ),
    },
    {
      key: "country",
      name: "country",
      label: "Country",
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      rules: [{ required: true, message: "" }],
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          showSearch
          placeholder="Search to Select"
          optionFilterProp="children"
          // value={CountryMapping(dataMerge.country)}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={countryList}
        />
      ),
    },
    {
      key: "industry",
      name: "industry",
      label: "Primary Industry",
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      rules: [
        { required: true, message: "" },
        {
          validator: async (rule: any, value: any) => {
            if (EditCompanyform.getFieldsValue().subindustry) {
              if (
                !EditCompanyform.getFieldsValue().subindustry.includes(value)
              ) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  "Primary industry cannot be the same as Associated industry."
                );
              }
            }
          },
        },
      ],
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          showSearch
          placeholder="Search to Select"
          optionFilterProp="children"
          // value={IndustryMapping(dataMerge.industry)}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={industryList}
          dropdownRender={(option: any) => (
            <>
              {option}
              <Divider
                style={{
                  margin: "auto",
                  borderTop: "1px solid grey",
                }}
              />
              <Button
                className="manageCategoriesButton"
                type="text"
                onClick={() => setCategoriesModalOpen(true)}
              >
                Manage Industries
              </Button>
            </>
          )}
        />
      ),
    },
    {
      key: "subindustry",
      name: "subindustry",
      label: "Associated Industry",
      rules: [
        {
          validator: async (rule: any, value: any) => {
            if (
              EditCompanyform.getFieldsValue().subindustry?.length > 0 &&
              EditCompanyform.getFieldsValue().industry !== ""
            ) {
              if (value.length > 0) {
                // return Promise.resolve();
                if (
                  !value.includes(EditCompanyform.getFieldsValue().industry)
                ) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    "Associated industry cannot be the same as Primary industry."
                  );
                }
              } else {
                return Promise.reject();
              }
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          mode="multiple"
          showSearch
          placeholder="Search to Select"
          // value={dataMerge.subindustry}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={industryList}
        />
      ),
    },
    {
      key: "total_document",
      name: "total_doc",
      label: "Total Docs Published",
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      component: (
        <Tooltip
          title={
            <>
              Total of Documents <br />
              Company Snapshots: {chooseData?.CS}
              <br />
              Historical Company Snapshots: {chooseData?.HCS}
              <br />
              Company Documents: {chooseData?.RCD}
            </>
          }
        >
          <Input
            value={chooseData?.CS + chooseData?.HCS + chooseData?.RCD}
            readOnly
          />
        </Tooltip>
      ),
    },
    {
      key: "sedol",
      name: ["ref", "sedol"],
      label: "SEDOL",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter SEDOL" />,
    },
    {
      key: "bb",
      name: ["ref", "bb"],
      label: "BB",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter BB" />,
    },
    {
      key: "isin",
      name: ["ref", "isin"],
      label: "ISIN",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter ISIN" />,
    },
    {
      key: "exchange",
      name: ["ref", "exchange"],
      label: "Exchange",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter Exchange" />,
    },
    {
      key: "ciq",
      name: ["ref", "ciq"],
      label: "CIQ",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter CIQ" />,
    },
    {
      key: "ticker",
      name: ["ref", "ticker"],
      label: "Ticker",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter TICKER" />,
    },
    {
      key: "perm_id",
      name: ["ref", "perm_id"],
      label: "PermID",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter PermID" />,
    },
    {
      key: "url",
      name: ["ref", "url"],
      label: "URL",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter URL" />,
    },
    {
      key: "openfigi",
      name: ["ref", "openfigi"],
      label: "OpenFIGI",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 16,
      },
      component: (
        <Input
          // size="small"
          placeholder="Enter OpenFIGI"
          suffix={
            <Tooltip placement="top" title={"Click to redirect to openFIGI"}>
              <SelectOutlined
                style={{ transform: "scaleX(-1)" }}
                onClick={openFIGI}
              />
            </Tooltip>
          }
        />
      ),
    },
  ];

  const viewcompanyformdata: any = [
    { key: "_id", name: "_id", hidden: true },
    { key: "key", name: "key", hidden: true },

    {
      key: "name",
      name: "name",
      label: "Company Name",
      rules: [{ required: true, message: "" }],
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      component: <Input placeholder="Enter Company Name" readOnly />,
    },
    {
      key: "truncated_name",
      name: "truncated_name",
      label: "Truncated Name",
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      component: <Input placeholder="Enter Truncated Name" readOnly />,
    },
    {
      key: "compnumber",
      name: "compnumber",
      label: "Company Number",
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      component: (
        <Input
          readOnly
          suffix={
            <Tooltip
              placement="top"
              title={"Click to view company details on data relationship"}
            >
              <SelectOutlined
                style={{ transform: "scaleX(-1)" }}
                onClick={openDataRelationship}
              />
            </Tooltip>
          }
        />
      ),
    },
    {
      key: "country",
      name: "country",
      label: "Country",
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      rules: [{ required: true, message: "" }],
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          showSearch
          placeholder="Search to Select"
          optionFilterProp="children"
          // value={CountryMapping(dataMerge.country)}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={countryList}
          disabled
        />
      ),
    },
    {
      key: "industry",
      name: "industry",
      label: "Primary Industry",
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      rules: [
        { required: true, message: "" },
        {
          validator: async (rule: any, value: any) => {
            if (EditCompanyform.getFieldsValue().subindustry) {
              if (
                !EditCompanyform.getFieldsValue().subindustry.includes(value)
              ) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  "Primary industry cannot be the same as Associated industry."
                );
              }
            }
          },
        },
      ],
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          showSearch
          placeholder="Search to Select"
          optionFilterProp="children"
          // value={IndustryMapping(dataMerge.industry)}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={industryList}
          dropdownRender={(option: any) => (
            <>
              {option}
              <Divider
                style={{
                  margin: "auto",
                  borderTop: "1px solid grey",
                }}
              />
              <Button
                className="manageCategoriesButton"
                type="text"
                onClick={() => setCategoriesModalOpen(true)}
              >
                Manage Industries
              </Button>
            </>
          )}
          disabled
        />
      ),
    },
    {
      key: "subindustry",
      name: "subindustry",
      label: "Associated Industry",
      rules: [
        {
          validator: async (rule: any, value: any) => {
            if (
              EditCompanyform.getFieldsValue().subindustry?.length > 0 &&
              EditCompanyform.getFieldsValue().industry !== ""
            ) {
              if (value.length > 0) {
                // return Promise.resolve();
                if (
                  !value.includes(EditCompanyform.getFieldsValue().industry)
                ) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    "Associated industry cannot be the same as Primary industry."
                  );
                }
              } else {
                return Promise.reject();
              }
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          mode="multiple"
          showSearch
          placeholder="Search to Select"
          // value={dataMerge.subindustry}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={industryList}
          disabled
        />
      ),
    },
    {
      key: "total_document",
      name: "total_doc",
      label: "Total Docs Published",
      labelCol: {
        style: {
          fontWeight: "600",
          width: "150px",
          marginRight: "10px",
          textAlign: "start",
        },
      },
      component: (
        <Tooltip
          title={
            <>
              Total of Documents <br />
              Company Snapshots: {chooseData?.CS}
              <br />
              Historical Company Snapshots: {chooseData?.HCS}
              <br />
              Company Documents: {chooseData?.RCD}
            </>
          }
        >
          <Input
            value={chooseData?.CS + chooseData?.HCS + chooseData?.RCD}
            disabled
          />
        </Tooltip>
      ),
    },
    {
      key: "sedol",
      name: ["ref", "sedol"],
      label: "SEDOL",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter SEDOL" readOnly />,
    },
    {
      key: "bb",
      name: ["ref", "bb"],
      label: "BB",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter BB" readOnly />,
    },
    {
      key: "isin",
      name: ["ref", "isin"],
      label: "ISIN",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter ISIN" readOnly />,
    },
    {
      key: "exchange",
      name: ["ref", "exchange"],
      label: "Exchange",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter Exchange" readOnly />,
    },
    {
      key: "ciq",
      name: ["ref", "ciq"],
      label: "CIQ",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter CIQ" readOnly />,
    },
    {
      key: "ticker",
      name: ["ref", "ticker"],
      label: "Ticker",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter TICKER" readOnly />,
    },
    {
      key: "perm_id",
      name: ["ref", "perm_id"],
      label: "PermID",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter PermID" readOnly />,
    },
    {
      key: "url",
      name: ["ref", "url"],
      label: "URL",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 18,
      },
      component: <Input placeholder="Enter URL" readOnly />,
    },
    {
      key: "openfigi",
      name: ["ref", "openfigi"],
      label: "OpenFIGI",
      labelCol: { span: 6, style: { textAlign: "start" } },
      wrapperCol: {
        offset: 2,
        span: 16,
      },
      component: (
        <Input
          // size="small"
          placeholder="Enter OpenFIGI"
          suffix={
            <Tooltip placement="top" title={"Click to redirect to openFIGI"}>
              <SelectOutlined
                style={{ transform: "scaleX(-1)" }}
                onClick={openFIGI}
              />
            </Tooltip>
          }
          readOnly
        />
      ),
    },
  ];

  const handleFormChange = () => {
    let tempupdatedCompany = InsertCompanyform.getFieldsValue();
    // console.log("tempupdatedCompany", tempupdatedCompany);
    if (props.handleChange && typeof props.handleChange === "function") {
      props.handleChange(InsertCompanyform.getFieldsValue());
    }
    if (InsertCompanyform.getFieldsValue().name) {
      if (
        props.handleSearchCompany &&
        typeof props.handleSearchCompany === "function"
      ) {
        props.handleSearchCompany(InsertCompanyform.getFieldsValue().name);
      }
    }
    if (InsertCompanyform.getFieldsValue().country) {
      if (
        props.handleSearchCountry &&
        typeof props.handleSearchCountry === "function"
      ) {
        props.handleSearchCountry(InsertCompanyform.getFieldsValue().country);
      }
    }
    // console.log("tempupdatedCompany", tempupdatedCompany);
    // for (let key in companyModal) {
    //   if (key === "compnumber") {
    //     const formattedValue = companyformdata
    //       .getFieldsValue()
    //       .compnumber.toString()
    //       .padStart(8, "0");
    //     // let updatedCompany = test.getFieldsValue();
    //     tempupdatedCompany["newcompnumber"] = formattedValue;
    //   }
    // }
  };

  const handleEditFormChange = () => {
    let tempupdatedCompany = EditCompanyform.getFieldsValue();
    // console.log("tempupdatedCompany", tempupdatedCompany);
    if (props.handleChange && typeof props.handleChange === "function") {
      props.handleChange(EditCompanyform.getFieldsValue());
    }
    if (EditCompanyform.getFieldsValue().name) {
      if (
        props.handleSearchCompany &&
        typeof props.handleSearchCompany === "function"
      ) {
        props.handleSearchCompany(EditCompanyform.getFieldsValue().name);
      }
    }
    if (EditCompanyform.getFieldsValue().country) {
      if (
        props.handleSearchCountry &&
        typeof props.handleSearchCountry === "function"
      ) {
        props.handleSearchCountry(EditCompanyform.getFieldsValue().country);
      }
    }
    // console.log("tempupdatedCompany", tempupdatedCompany);
    // for (let key in companyModal) {
    //   if (key === "compnumber") {
    //     const formattedValue = companyformdata
    //       .getFieldsValue()
    //       .compnumber.toString()
    //       .padStart(8, "0");
    //     // let updatedCompany = test.getFieldsValue();
    //     tempupdatedCompany["newcompnumber"] = formattedValue;
    //   }
    // }
  };

  const Publicate1RenderRow: any = (indus: any) => {
    if (indus) {
      return (
        <>
          <div>{indus}</div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const IndRenderRow: any = (indus: any) => {
    if (indus) {
      return (
        <>
          <div style={{ paddingLeft: "10px" }}>{indus}</div>
        </>
      );
    } else {
      return <></>;
    }
  };
  const ComRenderRow: any = (indus: any) => {
    if (indus) {
      return (
        <>
          <div style={{ paddingLeft: "10px" }}>{indus}</div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const CounRenderRow: any = (indus: any) => {
    if (indus) {
      return (
        <>
          <div style={{ paddingLeft: "10px" }}>{indus}</div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const PublicateRenderRow: any = (indus: any) => {
    if (indus) {
      return (
        <>
          <div
            onClick={() => opendistribute(indus)}
            style={{
              paddingLeft: "10px",
              color: "rgba(0, 0, 238, 1)",
              cursor: "pointer",
            }}
          >
            {indus}
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const convertDate = (el: any) => {
    let d = new Date(el);
    return d.toLocaleDateString("en-GB");
  };

  const closeIPModal = () => {
    setData(null);
    setShowIPModal(false);
  };

  const opendistribute = (el: any) => {
    getCSDistributeSummary(el).then((data: any) => {
      setData(data);
      setShowIPModal(true);
    });
  };

  const handleRemoveData = (data: any) => {
    if (chooseData) {
      for (let x = 0; x < data.length; x++) {
        if (chooseData?.industry && chooseData?.industry === data[x]) {
          chooseData.industry = null;
          props.sdata.industry = null;
          if (
            props.handleRemoveData &&
            typeof props.handleRemoveData == "function"
          ) {
            props.handleRemoveData(true);
          }
        }

        if (
          chooseData?.subindustry &&
          chooseData?.subindustry.includes(data[x])
        ) {
          const indexToRemove = chooseData.subindustry.indexOf(data[x]);
          if (indexToRemove !== -1) {
            chooseData.subindustry.splice(indexToRemove, 1);
            props.sdata.subindustry.splice(indexToRemove, 1);
            if (
              props.handleRemoveData &&
              typeof props.handleRemoveData == "function"
            ) {
              props.handleRemoveData(true);
            }
          }
        }
      }
    }
  };

  const handleCategoriesModalCancel = () => {
    setCategoriesModalOpen(!categoriesModalOpen);
  };

  const handleIndustry = (data: any) => {
    // console.log("data", data);
    setIndustryData(data);
    const dropDownIndustryOption = data.map((x: any, i: any) => ({
      label: x.description,
      value: x.industrycode,
    }));
    setIndustryList(dropDownIndustryOption);
  };

  const handleChanges = (data: any) => {
    if (props.handleChanges && typeof props.handleChanges == "function") {
      props.handleChanges(true);
    }
  };

  return (
    <>
      <AlertModal
        show={showCConfirm}
        onClose={closeRemoveAlert}
        deleteDataHook={confirmCancel}
        confirmCancel
      />
      <DetailModal
        onClose={closeIPModal}
        show={showIPModal}
        data={pdata}
        distributoreditor
      />
      {categoriesModalOpen && (
        <Manage_Industry
          open={categoriesModalOpen}
          all_data={industryData}
          set_all_data={handleIndustry}
          // new_data={newCategories}
          // set_new_data={setNewCategories}
          // // wordpress_data_id={id}
          handleCancel={handleCategoriesModalCancel}
          // type={"categories"}
          handleRemoveData={handleRemoveData}
          handleChanges={handleChanges}
        />
      )}
      {/* {formData?.key && !chooseData?.key && props.companyeditor && (
        <div>
          <Spin spinning={loading}>
            <div>
              {deleteMode && (
                <ConfirmationModal
                  onClose={close}
                  show={show}
                  setDeleteMode={setDeleteMode}
                  data={formData}
                  RemoveHook={confirmdelete}
                />
              )}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button onClick={cancel} className="open-modal-close-button">
                  X Close
                </button>
              </div>

              <div
                style={{
                  fontWeight: "700",
                  fontSize: "18px",
                  paddingTop: "20px",
                  paddingBottom: "10px",
                }}
              >
                {formData?.name}
              </div>
              <hr style={{ height: "1px", width: "90%" }} />
              <div style={{ padding: "20px 100px 0 100px" }}>
                <div
                  style={{ display: "grid", gridTemplateColumns: "50% 50%" }}
                >
                  <div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "2fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">Company Name</label>
                      <Input
                        type="text"
                        name="name"
                        value={formData?.name || ""}
                        onChange={handleChange}
                        style={{
                          width: "90%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "2fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">Truncated Name</label>
                      <Input
                        type="text"
                        name="truncated_name"
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            truncated_name: e.target.value,
                          });
                        }}
                        value={formData?.truncated_name || ""}
                        style={{
                          width: "90%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        placeholder="Enter Truncated Name"
                      />
                    </div>
                  </div>

                  <div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "2fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">Company Number</label>
                      <Input
                        type="text"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        name="newcompnumber"
                        value={formData?.compnumber || ""}
                        readOnly
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "2fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">Country</label>
                      <Select
                        listHeight={160}
                        getPopupContainer={(trigger) => trigger.parentElement}
                        showSearch
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        onChange={handleCountryChange}
                        value={formData?.country}
                        filterOption={(input: any, option: any) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={countryList}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 4fr" }}
                >
                  <label className="open-modal-label">Primary Industry</label>
                  <Select
                    listHeight={160}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    showSearch
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={handleIndustryChange}
                    value={formData?.industry}
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={industryList}
                    // dropdownRender={(option: any) => (
                    //   <>
                    //     {option}
                    //     <Divider
                    //       style={{
                    //         margin: "auto",
                    //         borderTop: "1px solid grey",
                    //       }}
                    //     />
                    //     <Button
                    //       className="manageCategoriesButton"
                    //       type="text"
                    //       // onClick={()=>setCategoriesModalOpen(true)}
                    //     >
                    //       Manage categories
                    //     </Button>
                    //   </>
                    // )}
                  />
                </div>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 4fr" }}
                >
                  <label className="open-modal-label">
                    Associated Industry
                  </label>
                  <Select
                    listHeight={160}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    mode="multiple"
                    showSearch
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: "auto",
                      paddingBottom: "20px",
                    }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={handleSubIndustryChange}
                    value={formData?.subindustry}
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={industryList}
                  />
                </div>
                <div style={{ backgroundColor: "#E0E0E0" }}>
                  <h3
                    style={{
                      textAlign: "left",
                      marginBottom: "20px",
                      padding: "1px 0 2px 5px",
                    }}
                  >
                    <b>Company Reference</b>
                  </h3>
                </div>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 4fr",
                      }}
                    >
                      <label className="open-modal-label">SEDOL</label>
                      <Input
                        type="text"
                        style={{
                          width: "90%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        placeholder="Enter SEDOL"
                        name="sedol"
                        value={formData?.ref?.sedol}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            ref: {
                              ...formData.ref,
                              sedol: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 4fr",
                      }}
                    >
                      <label className="open-modal-label">ISIN</label>
                      <Input
                        type="text"
                        style={{
                          width: "90%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        placeholder="Enter ISIN"
                        name="isin"
                        value={formData?.ref?.isin}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            ref: {
                              ...formData.ref,
                              isin: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 4fr",
                      }}
                    >
                      <label className="open-modal-label">CIQ</label>
                      <Input
                        type="text"
                        style={{
                          width: "90%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        placeholder="Enter CIQ"
                        name="ciq"
                        value={formData?.ref?.ciq}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            ref: {
                              ...formData.ref,
                              ciq: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 4fr",
                      }}
                    >
                      <label className="open-modal-label">PermID</label>
                      <Input
                        type="text"
                        style={{
                          width: "90%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        placeholder="Enter PermID"
                        name="perm_id"
                        value={formData?.ref?.perm_id}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            ref: {
                              ...formData.ref,
                              perm_id: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 4fr",
                      }}
                    >
                      <label className="open-modal-label">BB</label>
                      <Input
                        type="text"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        placeholder="Enter BB"
                        name="bb"
                        value={formData?.ref?.bb}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            ref: {
                              ...formData.ref,
                              bb: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 4fr",
                      }}
                    >
                      <label className="open-modal-label">
                        {width > 1400 ? <>Exchange</> : <>Exch</>}
                      </label>
                      <Input
                        type="text"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        placeholder="Enter Exchange"
                        name="exchange"
                        value={formData?.ref?.exchange}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            ref: {
                              ...formData.ref,
                              exchange: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 4fr",
                      }}
                    >
                      <label className="open-modal-label">Ticker</label>
                      <Input
                        type="text"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        placeholder="Enter Ticker"
                        name="ticker"
                        value={formData?.ref?.ticker}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            ref: {
                              ...formData.ref,
                              ticker: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 4fr",
                      }}
                    >
                      <label className="open-modal-label">URL</label>
                      <Input
                        type="text"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        placeholder="Enter URL"
                        name="url"
                        value={formData?.ref?.url}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            ref: {
                              ...formData.ref,
                              url: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="open-modal-footer-sp">
              <div></div>
              <div style={{ padding: "5px 0 5px 47%" }}>
                <button onClick={cancel} className="open-modal-reset-button">
                  Cancel
                </button>
                <button
                  onClick={save}
                  className="open-modal-save-button"
                  style={{ cursor: "pointer" }}
                >
                  Save
                </button>
              </div>
            </div>
          </Spin>
        </div>
      )} */}
      {!formData?.key && !chooseData?.key && props.companycreator && (
        <div>
          <Spin spinning={loading}>
            <div style={{ paddingTop: "10px" }}>&nbsp;</div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}>
              <div></div>
              <div>
                <p>
                  <b>Insert New Company Details</b>
                </p>
              </div>
            </div>
            <Form
              form={InsertCompanyform}
              className="company-form"
              style={{
                width: "100%",
                height: "60vh",
                // paddingTop: "15px",
                paddingRight: "10px",
                overflowY: "auto",
              }}
              labelCol={{ span: 16 }}
              wrapperCol={{ flex: 24 }}
              onValuesChange={handleFormChange}
              initialValues={{
                name: "",
                truncated_name: "",
                compnumber: maxC + 1,
                newcompnumber: maxComp(),
                country: "",
                industry: "",
                subindustry: [],
                ref: {
                  sedol: "",
                  bb: "",
                  isin: "",
                  exchange: "",
                  ciq: "",
                  ticker: "",
                  perm_id: "",
                  url: "",
                  openfigi: "",
                },
              }}
            >
              <Row gutter={16}>
                {insertcompanyformdata
                  .slice(0, 6)
                  .map((item: any, index: any) => (
                    <React.Fragment key={index}>
                      <Col span={24}>
                        <Form.Item {...item}>{item.component}</Form.Item>
                      </Col>
                    </React.Fragment>
                  ))}
                <div
                  style={{
                    backgroundColor: "#E0E0E0",
                    display: "flex",
                    justifyContent: "space-between",
                    height: "30px",
                    marginBottom: "15px",
                    width: "100%",
                    marginLeft: "1%",
                    marginRight: "1%",
                  }}
                >
                  <div>
                    <h3
                      style={{
                        textAlign: "left",
                        marginBottom: "20px",
                        padding: "1px 0 2px 5px",
                      }}
                    >
                      <b>Company Reference</b>
                    </h3>
                  </div>
                </div>
                {insertcompanyformdata
                  .slice(6, 15)
                  .map((item: any, index: any) => (
                    <React.Fragment key={index}>
                      <Col span={12}>
                        <Form.Item {...item}>{item.component}</Form.Item>
                      </Col>
                    </React.Fragment>
                  ))}
              </Row>
            </Form>
            {/* 
            <div>
              <div>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                >
                  <label className="open-modal-label">Company Name</label>
                  <Input
                    type="text"
                    name="name"
                    onChange={handleSearchCompany}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: "10px",
                      // border: isValid ? "1px solid #ccc" : "1px solid red",
                    }}
                    placeholder="Enter Company Name"
                    required
                  />
                </div>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                >
                  <label className="open-modal-label">Truncated Name</label>
                  <Input
                    type="text"
                    name="truncated_name"
                    onChange={(e: any) => {
                      setChooseData({
                        ...chooseData,
                        truncated_name: e.target.value,
                      });
                    }}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                    placeholder="Enter Truncated Name"
                  />
                </div>

                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                >
                  <label className="open-modal-label">Company Number</label>
                  <Input
                    type="text"
                    name="compnumber"
                    onChange={handleSearchCompanyNumber}
                    value={maxComp()}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                    readOnly
                  />
                </div>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                >
                  <label className="open-modal-label">Country</label>
                  <Select
                    listHeight={160}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    showSearch
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={handleSearchCountry}
                    value={country}
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={countryList}
                  />
                </div>
              </div>

              <div style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}>
                <label className="open-modal-label">Primary Industry</label>
                <Select
                  listHeight={160}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  showSearch
                  style={{
                    width: "100%",
                    textAlign: "left",
                    marginBottom: "10px",
                  }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  onChange={handleNewPrimaryIndustryChange}
                  value={industry || ""}
                  filterOption={(input: any, option: any) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={industryList}
                  dropdownRender={(option: any) => (
                    <>
                      {option}
                      <Divider
                        style={{
                          margin: "auto",
                          borderTop: "1px solid grey",
                        }}
                      />
                      <Button
                        className="manageCategoriesButton"
                        type="text"
                        onClick={() => setCategoriesModalOpen(true)}
                      >
                        Manage Industries
                      </Button>
                    </>
                  )}
                />
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}>
                <label className="open-modal-label">Associated Industry</label>
                <Select
                  listHeight={160}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  mode="multiple"
                  showSearch
                  style={{
                    width: "100%",
                    textAlign: "left",
                    marginBottom: "10px",
                    paddingBottom: "10px",
                  }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  onChange={handleNewSecondaryIndustryChange}
                  value={subindustry}
                  filterOption={(input: any, option: any) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={industryList}
                />
              </div>

              <div
                style={{
                  backgroundColor: "#E0E0E0",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "30px",
                  marginBottom: "25px",
                }}
              >
                <div>
                  <h3
                    style={{
                      textAlign: "left",
                      marginBottom: "20px",
                      padding: "1px 0 2px 5px",
                    }}
                  >
                    <b>Company Reference</b>
                  </h3>
                </div>
              </div>

              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                  >
                    <label className="open-modal-label">SEDOL</label>
                    <Input
                      type="text"
                      name="sedol"
                      onChange={(e: any) => {
                        setRefData({
                          ...refdata,
                          sedol: e.target.value,
                        });
                      }}
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                      placeholder="Enter SEDOL"
                    />
                  </div>

                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                  >
                    <label className="open-modal-label">ISIN</label>
                    <Input
                      type="text"
                      name="isin"
                      onChange={(e: any) => {
                        setRefData({
                          ...refdata,
                          isin: e.target.value,
                        });
                      }}
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                      placeholder="Enter ISIN"
                    />
                  </div>

                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                  >
                    <label className="open-modal-label">CIQ</label>
                    <Input
                      type="text"
                      name="ciq"
                      onChange={(e: any) => {
                        setRefData({
                          ...refdata,
                          ciq: e.target.value,
                        });
                      }}
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                      placeholder="Enter CIQ"
                    />
                  </div>

                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                  >
                    <label className="open-modal-label">PermID</label>
                    <Input
                      type="text"
                      name="perm_id"
                      onChange={(e: any) => {
                        setRefData({
                          ...refdata,
                          perm_id: e.target.value,
                        });
                      }}
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                      placeholder="Enter PermID"
                    />
                  </div>
                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                  >
                    <label className="open-modal-label">OpenFIGI</label>
                    <Input
                      type="text"
                      name="openfigi"
                      placeholder="Enter OpenFIGI"
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                      onChange={(e: any) => {
                        setRefData({
                          ...refdata,
                          openfigi: e.target.value,
                        });
                      }}
                      suffix={
                        <Tooltip
                          placement="top"
                          title={"Click to redirect to openFIGI"}
                        >
                          <SelectOutlined
                            style={{ transform: "scaleX(-1)" }}
                            onClick={openFIGI}
                          />
                        </Tooltip>
                      }
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    padding: " 0 0 0 10px",
                  }}
                >
                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                  >
                    <label className="open-modal-label">BB</label>
                    <Input
                      type="text"
                      name="bb"
                      onChange={(e: any) => {
                        setRefData({
                          ...refdata,
                          bb: e.target.value,
                        });
                      }}
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                      placeholder="Enter BB"
                    />
                  </div>

                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                  >
                    <label className="open-modal-label">
                      {width > 1400 ? <>Exchange</> : <>Exch</>}
                    </label>
                    <Input
                      type="text"
                      name="exchange"
                      onChange={(e: any) => {
                        setRefData({
                          ...refdata,
                          exchange: e.target.value,
                        });
                      }}
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                      placeholder="Enter Exchange"
                    />
                  </div>

                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                  >
                    <label className="open-modal-label">Ticker</label>
                    <Input
                      type="text"
                      name="ticker"
                      onChange={(e: any) => {
                        setRefData({
                          ...refdata,
                          ticker: e.target.value,
                        });
                      }}
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                      placeholder="Enter Ticker"
                    />
                  </div>

                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                  >
                    <label className="open-modal-label">URL</label>
                    <Input
                      type="text"
                      name="url"
                      onChange={(e: any) => {
                        setRefData({
                          ...refdata,
                          url: e.target.value,
                        });
                      }}
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                      placeholder="Enter URL"
                    />
                  </div>
                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                  >
                    <label className="open-modal-label"></label>

                    <Input
                      type="text"
                      name="perm_id"
                      // value={chooseData?.ref?.perm_id || ""}
                      // placeholder="Enter PermID"
                      disabled
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: "10px",
                        border: "0px",
                        cursor: "default",
                      }}
                      // onChange={handleRefChange}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                onClick={cancel}
                className="open-modal-reset-button"
                style={{ padding: "0 2% 0 0" }}
              >
                Cancel
              </button>
              <button onClick={create} className="open-modal-save-button">
                Save
              </button>
            </div>
          </Spin>
        </div>
      )}
      {!formData?.key &&
        chooseData?.key &&
        props.companycreator &&
        localStorage.getItem("privilage") === "Admin" && (
          <div>
            <Spin spinning={loading}>
              <div style={{ paddingTop: "10px" }}>&nbsp;</div>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}>
                <div></div>
                <div>
                  <p>
                    <b>Edit Company Details</b>
                  </p>
                </div>
              </div>
              {deleteMode && (
                <ConfirmationModal
                  onClose={close}
                  show={show}
                  data={chooseData}
                  RemoveHook={confirmdelete}
                />
              )}
              {/* <div> */}
              {/* <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                >
                  <label className="open-modal-label">Company Name</label>
                  <Input
                    type="text"
                    name="name"
                    value={chooseData?.name || ""}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                    onChange={handleXChange}
                  />
                </div>

                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                >
                  <label className="open-modal-label">Truncated Name</label>
                  <Input
                    type="text"
                    name="truncated_name"
                    value={chooseData?.truncated_name || ""}
                    onChange={(e: any) => {
                      setChooseData({
                        ...chooseData,
                        truncated_name: e.target.value,
                      });
                    }}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                    placeholder="Enter Truncated Name"
                  />
                </div>

                <div>
                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                  >
                    <label className="open-modal-label">Company Number</label>
                    <Input
                      type="text"
                      name="compnumber"
                      value={chooseData?.compnumber || ""}
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: "10px",
                        // border: "1px solid black",
                      }}
                      readOnly
                      suffix={
                        <Tooltip
                          placement="top"
                          title={
                            "Click to view company details on data relationship"
                          }
                        >
                          <SelectOutlined
                            style={{ transform: "scaleX(-1)" }}
                            onClick={openDataRelationship}
                          />
                        </Tooltip>
                      }
                    />
                  </div>

                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                  >
                    <label className="open-modal-label">Country</label>
                    <Select
                      listHeight={160}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      showSearch
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      onChange={handleXCountryChange}
                      value={chooseData?.country || ""}
                      filterOption={(input: any, option: any) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={countryList}
                    />
                  </div>
                </div> */}

              {/* <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                >
                  <label className="open-modal-label">Primary Industry</label>
                  <Select
                    listHeight={160}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    showSearch
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={handleXIndustryChange}
                    value={chooseData?.industry || ""}
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={industryList}
                    dropdownRender={(option: any) => (
                      <>
                        {option}
                        <Divider
                          style={{
                            margin: "auto",
                            borderTop: "1px solid grey",
                          }}
                        />
                        <Button
                          className="manageCategoriesButton"
                          type="text"
                          onClick={() => setCategoriesModalOpen(true)}
                        >
                          Manage Industries
                        </Button>
                      </>
                    )}
                  />
                </div>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                >
                  <label className="open-modal-label">
                    Associated Industry
                  </label>
                  <Select
                    listHeight={160}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    mode="multiple"
                    showSearch
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginTop: "10px",
                      marginBottom: "auto",
                      paddingBottom: "10px",
                    }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={handleXSubIndustryChange}
                    value={chooseData?.subindustry}
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={industryList}
                  />
                </div> */}

              {/* <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                >
                  <label className="open-modal-label">
                    Total Documents Published{" "}
                  </label>
                  <Tooltip
                    title={
                      <>
                        Total of Documents <br />
                        Company Snapshots: {chooseData?.CS}
                        <br />
                        Historical Company Snapshots: {chooseData?.HCS}
                        <br />
                        Company Documents: {chooseData?.RCD}
                      </>
                    }
                  >
                    <Input
                      type="text"
                      name="truncated_name"
                      value={
                        chooseData?.CS + chooseData?.HCS + chooseData?.RCD || 0
                      }
                      // onChange={(e: any) => {
                      //   setChooseData({
                      //     ...chooseData,
                      //     truncated_name: e.target.value,
                      //   });
                      // }}
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                      placeholder="0"
                      readOnly
                    />
                  </Tooltip>
                </div> */}

              {/* <div
                  style={{
                    backgroundColor: "#E0E0E0",
                    display: "flex",
                    justifyContent: "space-between",
                    height: "30px",
                    marginBottom: "25px",
                  }}
                > */}
              {/* <div>
                    <h3
                      style={{
                        textAlign: "left",
                        marginBottom: "20px",
                        padding: "1px 0 2px 5px",
                      }}
                    >
                      <b>Company Reference</b>
                    </h3>
                  </div> */}
              {/* <div
                  style={{
                    marginRight: "1%",
                    padding: "5px 5px 5px 0px ",
                  }}
                >
                  <SelectOutlined
                    style={{ transform: "scaleX(-1)" }}
                    onClick={openFIGI}
                  />
                </div> */}
              {/* </div> */}

              {/* <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">SEDOL</label>
                      <Input
                        type="text"
                        name="sedol"
                        value={chooseData?.ref?.sedol || ""}
                        placeholder="Enter SEDOL"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        onChange={handleRefChange}
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">ISIN</label>
                      <Input
                        type="text"
                        name="isin"
                        value={chooseData?.ref?.isin || ""}
                        placeholder="Enter ISIN"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        onChange={handleRefChange}
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">CIQ</label>
                      <Input
                        type="text"
                        name="ciq"
                        value={chooseData?.ref?.ciq || ""}
                        placeholder="Enter CIQ"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        onChange={handleRefChange}
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">PermID</label>
                      <Input
                        type="text"
                        name="perm_id"
                        value={chooseData?.ref?.perm_id || ""}
                        placeholder="Enter PermID"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        onChange={handleRefChange}
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">OpenFIGI</label>
                      <Input
                        type="text"
                        name="openfigi"
                        value={chooseData?.ref?.openfigi || ""}
                        placeholder="Enter OpenFIGI"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        onChange={handleRefChange}
                        suffix={
                          <Tooltip
                            placement="top"
                            title={"Click to redirect to openFIGI"}
                          >
                            <SelectOutlined
                              style={{ transform: "scaleX(-1)" }}
                              onClick={openFIGI}
                            />
                          </Tooltip>
                        }
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr",
                      padding: " 0 0 0 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">BB</label>
                      <Input
                        type="text"
                        name="bb"
                        value={chooseData?.ref?.bb || ""}
                        placeholder="Enter BB"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        onChange={handleRefChange}
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">
                        {width > 1400 ? <>Exchange</> : <>Exch</>}
                      </label>
                      <Input
                        type="text"
                        name="exchange"
                        value={chooseData?.ref?.exchange || ""}
                        placeholder="Enter Exchange"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        onChange={handleRefChange}
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">Ticker</label>
                      <Input
                        type="text"
                        name="ticker"
                        value={chooseData?.ref?.ticker || ""}
                        placeholder="Enter Ticker"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        onChange={handleRefChange}
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">URL</label>
                      <Input
                        type="text"
                        name="url"
                        value={chooseData?.ref?.url || ""}
                        placeholder="Enter URL"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        onChange={handleRefChange}
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label"></label>

                      <Input
                        type="text"
                        name="perm_id"
                        // value={chooseData?.ref?.perm_id || ""}
                        // placeholder="Enter PermID"
                        disabled
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                          border: "0px",
                          cursor: "default",
                        }}
                        // onChange={handleRefChange}
                      />
                    </div>
                  </div>
                </div> */}
              {/* </div> */}
              <Form
                form={EditCompanyform}
                className="company-form"
                style={{
                  width: "100%",
                  height: "60vh",
                  // paddingTop: "15px",
                  paddingRight: "10px",
                  overflowY: "auto",
                }}
                labelCol={{ span: 16 }}
                wrapperCol={{ flex: 24 }}
                onValuesChange={handleEditFormChange}
              >
                <Row gutter={16}>
                  {editcompanyformdata
                    .slice(0, 9)
                    .map((item: any, index: any) => (
                      <React.Fragment key={index}>
                        <Col span={24}>
                          <Form.Item {...item}>{item.component}</Form.Item>
                        </Col>
                      </React.Fragment>
                    ))}

                  <div
                    style={{
                      backgroundColor: "#E0E0E0",
                      display: "flex",
                      justifyContent: "space-between",
                      height: "30px",
                      marginBottom: "15px",
                      width: "100%",
                      marginLeft: "1%",
                      marginRight: "1%",
                    }}
                  >
                    <div>
                      <h3
                        style={{
                          textAlign: "left",
                          marginBottom: "20px",
                          padding: "1px 0 2px 5px",
                        }}
                      >
                        <b>Company Reference</b>
                      </h3>
                    </div>
                  </div>
                  {editcompanyformdata
                    .slice(9, 18)
                    .map((item: any, index: any) => (
                      <React.Fragment key={index}>
                        <Col span={12}>
                          <Form.Item {...item}>{item.component}</Form.Item>
                        </Col>
                      </React.Fragment>
                    ))}
                </Row>
              </Form>
              <div style={{ display: "grid", gridTemplateColumns: "70% 30%" }}>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <button onClick={reset} className="open-modal-reset-button">
                    Reset
                  </button>
                  {!props.sdata && (
                    <button
                      onClick={createNew}
                      className="open-modal-reset-button"
                    >
                      Create New
                    </button>
                  )}
                  <button
                    onClick={redirectCompanyConsolidation}
                    className="open-modal-link-button"
                  >
                    Link to Company Consolidation
                  </button>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button onClick={cancel} className="open-modal-cancel-button">
                    Cancel
                  </button>
                  <button
                    onClick={confirmCData}
                    className="open-modal-save-button"
                  >
                    Save
                  </button>
                </div>
              </div>
            </Spin>
          </div>
        )}
      {!formData?.key &&
        chooseData?.key &&
        props.companycreator &&
        localStorage.getItem("privilage") === "User" && (
          <div>
            <Spin spinning={loading}>
              <div style={{ paddingTop: "10px" }}>&nbsp;</div>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}>
                <div></div>
                <div>
                  <p>
                    <b>Company Details</b>
                  </p>
                </div>
              </div>
              {deleteMode && (
                <ConfirmationModal
                  onClose={close}
                  show={show}
                  data={chooseData}
                  RemoveHook={confirmdelete}
                />
              )}
              <Form
                form={ViewCompanyform}
                className="company-form"
                style={{
                  width: "100%",
                  height: "60vh",
                  // paddingTop: "15px",
                  paddingRight: "10px",
                  overflowY: "auto",
                }}
                labelCol={{ span: 16 }}
                wrapperCol={{ flex: 24 }}
                // onValuesChange={handleEditFormChange}
              >
                <Row gutter={16}>
                  {viewcompanyformdata
                    .slice(0, 9)
                    .map((item: any, index: any) => (
                      <React.Fragment key={index}>
                        <Col span={24}>
                          <Form.Item {...item}>{item.component}</Form.Item>
                        </Col>
                      </React.Fragment>
                    ))}
                  <div
                    style={{
                      backgroundColor: "#E0E0E0",
                      display: "flex",
                      justifyContent: "space-between",
                      height: "30px",
                      marginBottom: "15px",
                      width: "100%",
                      marginLeft: "1%",
                      marginRight: "1%",
                    }}
                  >
                    <div>
                      <h3
                        style={{
                          textAlign: "left",
                          marginBottom: "20px",
                          padding: "1px 0 2px 5px",
                        }}
                      >
                        <b>Company Reference</b>
                      </h3>
                    </div>
                  </div>
                  {viewcompanyformdata
                    .slice(9, 18)
                    .map((item: any, index: any) => (
                      <React.Fragment key={index}>
                        <Col span={12}>
                          <Form.Item {...item}>{item.component}</Form.Item>
                        </Col>
                      </React.Fragment>
                    ))}
                </Row>
              </Form>
              {/* <div>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                >
                  <label className="open-modal-label">Company Name</label>
                  <Input
                    type="text"
                    name="name"
                    value={chooseData?.name || ""}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                    onChange={handleXChange}
                    disabled
                  />
                </div>

                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                >
                  <label className="open-modal-label">Truncated Name</label>
                  <Input
                    type="text"
                    name="truncated_name"
                    value={chooseData?.truncated_name || ""}
                    onChange={(e: any) => {
                      setChooseData({
                        ...chooseData,
                        truncated_name: e.target.value,
                      });
                    }}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                    placeholder="Enter Truncated Name"
                    disabled
                  />
                </div>

                <div>
                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                  >
                    <label className="open-modal-label">Company Number</label>
                    <Input
                      type="text"
                      name="compnumber"
                      value={chooseData?.compnumber || ""}
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                      readOnly
                      suffix={
                        <Tooltip
                          placement="top"
                          title={
                            "Click to view company details on data relationship"
                          }
                        >
                          <SelectOutlined
                            style={{ transform: "scaleX(-1)" }}
                            onClick={openDataRelationship}
                          />
                        </Tooltip>
                      }
                    />
                  </div>

                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                  >
                    <label className="open-modal-label">Country</label>
                    <Select
                      listHeight={160}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      showSearch
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      onChange={handleXCountryChange}
                      value={chooseData?.country || ""}
                      filterOption={(input: any, option: any) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={countryList}
                      disabled
                    />
                  </div>
                </div>

                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                >
                  <label className="open-modal-label">Primary Industry</label>
                  <Select
                    listHeight={160}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    showSearch
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={handleXIndustryChange}
                    value={chooseData?.industry || ""}
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={industryList}
                    disabled
                  />
                </div>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                >
                  <label className="open-modal-label">
                    Associated Industry
                  </label>
                  <Select
                    listHeight={160}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    mode="multiple"
                    showSearch
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginTop: "10px",
                      marginBottom: "auto",
                      paddingBottom: "10px",
                    }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={handleXSubIndustryChange}
                    value={chooseData?.subindustry}
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={industryList}
                    disabled
                  />
                </div>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}
                >
                  <label className="open-modal-label">
                    Total Documents Published{" "}
                  </label>
                  <Tooltip
                    title={
                      <>
                        Total of Documents <br />
                        Company Snapshots: {chooseData?.CS}
                        <br />
                        Historical Company Snapshots: {chooseData?.HCS}
                        <br />
                        Company Documents: {chooseData?.RCD}
                      </>
                    }
                  >
                    <Input
                      type="text"
                      name="truncated_name"
                      value={
                        chooseData?.CS + chooseData?.HCS + chooseData?.RCD || 0
                      }
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                      placeholder="0"
                      readOnly
                    />
                  </Tooltip>
                </div>

                <div style={{ backgroundColor: "#E0E0E0" }}>
                  <h3
                    style={{
                      textAlign: "left",
                      marginBottom: "20px",
                      padding: "1px 0 2px 5px",
                    }}
                  >
                    <b>Company Reference</b>
                  </h3>
                </div>

                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">SEDOL</label>
                      <Input
                        type="text"
                        name="sedol"
                        value={chooseData?.ref?.sedol || ""}
                        placeholder="Enter SEDOL"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        onChange={handleRefChange}
                        disabled
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">ISIN</label>
                      <Input
                        type="text"
                        name="isin"
                        value={chooseData?.ref?.isin || ""}
                        placeholder="Enter ISIN"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        onChange={handleRefChange}
                        disabled
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">CIQ</label>
                      <Input
                        type="text"
                        name="ciq"
                        value={chooseData?.ref?.ciq || ""}
                        placeholder="Enter CIQ"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        onChange={handleRefChange}
                        disabled
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">PermID</label>
                      <Input
                        type="text"
                        name="perm_id"
                        value={chooseData?.ref?.perm_id || ""}
                        placeholder="Enter PermID"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        onChange={handleRefChange}
                        disabled
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr",
                      padding: " 0 0 0 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">BB</label>
                      <Input
                        type="text"
                        name="bb"
                        value={chooseData?.ref?.bb || ""}
                        placeholder="Enter BB"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        onChange={handleRefChange}
                        disabled
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">
                        {width > 1400 ? <>Exchange</> : <>Exch</>}
                      </label>
                      <Input
                        type="text"
                        name="exchange"
                        value={chooseData?.ref?.exchange || ""}
                        placeholder="Enter Exchange"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        onChange={handleRefChange}
                        disabled
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">Ticker</label>
                      <Input
                        type="text"
                        name="ticker"
                        value={chooseData?.ref?.ticker || ""}
                        placeholder="Enter Ticker"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        onChange={handleRefChange}
                        disabled
                      />
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr",
                      }}
                    >
                      <label className="open-modal-label">URL</label>
                      <Input
                        type="text"
                        name="url"
                        value={chooseData?.ref?.url || ""}
                        placeholder="Enter URL"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                        onChange={handleRefChange}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div style={{display:"grid",gridTemplateColumns:"50% 50%"}}>
                        <div style={{ display:"flex", justifyContent:"flex-start"}}>
                            <button onClick={reset} className='open-modal-reset-button'>Reset</button>
                            {!props.sdata&&
                                <button onClick={createNew} className='open-modal-reset-button'>Create New</button>
                            }
                        </div>
                        <div style={{ display:"flex", justifyContent:"flex-end"}}>
                            <button onClick={cancel} className='open-modal-cancel-button'>Cancel</button>
                            <button onClick={confirmCData} className='open-modal-save-button'>Save</button>
                        </div>  
                    </div> */}
            </Spin>
          </div>
        )}
      {props.countryeditor && (
        <div>
          <div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button onClick={close} className="open-modal-close-button">
                X Close
              </button>
            </div>
            <div
              style={{
                fontWeight: "700",
                fontSize: "18px",
                paddingTop: "10px",
                paddingBottom: "10px",
                textAlign: "center",
              }}
            >
              Country's Publication Information
            </div>
            <hr style={{ height: "1px", width: "90%" }} />
            <div style={{ padding: "0px 100px 0 100px" }}>
              <div>
                <label className="open-modal-label">Country:</label>
                <input
                  type="text"
                  name="name"
                  value={fileIndexCountryMapping(formData?._id) || ""}
                  className="open-modal-input"
                  readOnly
                />
              </div>
              <div
                style={{ display: "grid", gridTemplateColumns: "5fr 1fr 5fr" }}
              >
                <div>
                  <label className="open-modal-label">Total Companies:</label>
                  <input
                    type="text"
                    name="name"
                    value={formData?.totalcompnumber || 0}
                    className="open-modal-input"
                    readOnly
                  />
                </div>
                <div></div>
                <div>
                  <label className="open-modal-label">Total Industries:</label>
                  <input
                    type="text"
                    name="name"
                    value={formData?.totalindustry || 0}
                    className="open-modal-input"
                    readOnly
                  />
                </div>
              </div>
              <div
                style={{ display: "grid", gridTemplateColumns: "5fr 1fr 5fr" }}
              >
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <label className="open-modal-label">
                        List of Companies
                      </label>
                    </div>
                    <div
                      onClick={viewCountry_CompanyPublication}
                      style={{
                        fontSize: "16px",
                        color: "rgba(92, 152, 186, 1)",
                        cursor: "pointer",
                      }}
                    >
                      <FiExternalLink
                        style={{
                          height: "15px",
                          marginTop: "15px",
                          paddingRight: "2px",
                          color: "rgba(92, 152, 186, 1)",
                        }}
                      />
                      View all
                    </div>
                  </div>
                  <div>{CompanyRenderRow(formData?.compnumber)}</div>
                </div>
                <div></div>
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <label className="open-modal-label">
                        List of Industries
                      </label>
                    </div>
                    <div
                      style={{
                        fontSize: "16px",
                        cursor: "context-menu",
                        color: "#FFFFFF",
                      }}
                    >
                      <FiExternalLink
                        style={{
                          height: "15px",
                          marginTop: "15px",
                          paddingRight: "2px",
                          color: "#FFFFFF",
                        }}
                      />
                      View all
                    </div>
                  </div>
                  <div>{IndustryRenderRow(formData?.industry)}</div>
                </div>
              </div>
              <div style={{ paddingBottom: "10px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <label className="open-modal-label">
                      Total Publications:
                    </label>
                  </div>
                </div>
                <input
                  type="text"
                  name="compnumber"
                  value={formData?.totalpublication || 0}
                  className="modal-input"
                  readOnly
                />
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "5fr 5fr" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label
                    className="abbreviation-type-list-total"
                    style={{ backgroundColor: "#D6D6D6", fontWeight: "700" }}
                  >
                    Type
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.39)",
                      fontWeight: "700",
                    }}
                  >
                    Amount
                  </label>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label
                    className="abbreviation-type-list-total"
                    style={{ backgroundColor: "#D6D6D6", fontWeight: "700" }}
                  >
                    Type
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.39)",
                      fontWeight: "700",
                    }}
                  >
                    Amount
                  </label>
                </div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "5fr 5fr" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Industry Snapshots
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.is || 0}
                  </label>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Acquisdata Industry Reports
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.ir || 0}
                  </label>
                </div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "5fr 5fr" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Global Industry Snapshots
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.gs || 0}
                  </label>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Macrosource Reports
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.mr || 0}
                  </label>
                </div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "5fr 5fr" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Mergers & Acquistion
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.am || 0}
                  </label>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Historical Company Snapshot
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.hcs || 0}
                  </label>
                </div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "5fr 5fr" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Company Snapshots
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.cs || 0}
                  </label>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Company Documents
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.rcd || 0}
                  </label>
                </div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "5fr 5fr" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Executive Snapshots
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.es || 0}
                  </label>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Movements
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.m || 0}
                  </label>
                </div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "5fr 5fr" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Executive Movements
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.em || 0}
                  </label>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total"></label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.industryeditor && (
        <div>
          <div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button onClick={close} className="open-modal-close-button">
                X Close
              </button>
            </div>
            <div
              style={{
                fontWeight: "700",
                fontSize: "18px",
                paddingTop: "10px",
                paddingBottom: "10px",
                textAlign: "center",
              }}
            >
              Industry's Publication Information
            </div>
            <hr style={{ height: "1px", width: "90%" }} />
            <div style={{ padding: "0px 100px 0 100px" }}>
              <div>
                <label className="open-modal-label">Industry:</label>
                <input
                  type="text"
                  name="name"
                  value={fileIndexIndustryMapping(formData?._id) || ""}
                  className="open-modal-input"
                  readOnly
                />
              </div>
              <div
                style={{ display: "grid", gridTemplateColumns: "5fr 1fr 5fr" }}
              >
                <div>
                  <label className="open-modal-label">Total Companies:</label>
                  <input
                    type="text"
                    name="name"
                    value={formData?.totalcompnumber || 0}
                    className="open-modal-input"
                    readOnly
                  />
                </div>
                <div></div>
                <div>
                  <label className="open-modal-label">Total Countries:</label>
                  <input
                    type="text"
                    name="name"
                    value={formData?.totalcountry || 0}
                    className="open-modal-input"
                    readOnly
                  />
                </div>
              </div>
              <div
                style={{ display: "grid", gridTemplateColumns: "5fr 1fr 5fr" }}
              >
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <label className="open-modal-label">
                        List of Companies
                      </label>
                    </div>
                    <div
                      onClick={viewIndustry_CompanyPublication}
                      style={{
                        fontSize: "16px",
                        color: "rgba(92, 152, 186, 1)",
                        cursor: "pointer",
                      }}
                    >
                      <FiExternalLink
                        style={{
                          height: "15px",
                          marginTop: "15px",
                          paddingRight: "2px",
                          color: "rgba(92, 152, 186, 1)",
                        }}
                      />
                      View all
                    </div>
                  </div>
                  <div>{CompanyRenderRow(formData?.compnumber)}</div>
                </div>
                <div></div>
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <label className="open-modal-label">
                        List of Countries
                      </label>
                    </div>
                    <div
                      style={{
                        fontSize: "16px",
                        cursor: "context-menu",
                        color: "#FFFFFF",
                      }}
                    >
                      <FiExternalLink
                        style={{
                          height: "15px",
                          marginTop: "15px",
                          paddingRight: "2px",
                          color: "#FFFFFF",
                        }}
                      />
                      View all
                    </div>
                  </div>
                  <div>{CountryRenderRow(formData?.country)}</div>
                </div>
              </div>
              <div style={{ paddingBottom: "10px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <label className="open-modal-label">
                      Total Publications:
                    </label>
                  </div>
                </div>
                <input
                  type="text"
                  name="compnumber"
                  value={formData?.totalpublication || 0}
                  className="modal-input"
                  readOnly
                />
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "5fr 5fr" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label
                    className="abbreviation-type-list-total"
                    style={{ backgroundColor: "#D6D6D6", fontWeight: "700" }}
                  >
                    Type
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.39)",
                      fontWeight: "700",
                    }}
                  >
                    Amount
                  </label>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label
                    className="abbreviation-type-list-total"
                    style={{ backgroundColor: "#D6D6D6", fontWeight: "700" }}
                  >
                    Type
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.39)",
                      fontWeight: "700",
                    }}
                  >
                    Amount
                  </label>
                </div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "5fr 5fr" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Industry Snapshots
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.is || 0}
                  </label>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Acquisdata Industry Reports
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.ir || 0}
                  </label>
                </div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "5fr 5fr" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Global Industry Snapshots
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.gs || 0}
                  </label>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Macrosource Reports
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.mr || 0}
                  </label>
                </div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "5fr 5fr" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Mergers & Acquistion
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.am || 0}
                  </label>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Historical Company Snapshot
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.hcs || 0}
                  </label>
                </div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "5fr 5fr" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Company Snapshots
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.cs || 0}
                  </label>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Company Documents
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.rcd || 0}
                  </label>
                </div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "5fr 5fr" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Executive Snapshots
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.es || 0}
                  </label>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Movements
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.m || 0}
                  </label>
                </div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "5fr 5fr" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total">
                    Executive Movements
                  </label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  >
                    {formData?.em || 0}
                  </label>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    border: "1px solid black",
                  }}
                >
                  <label className="abbreviation-type-list-total"></label>
                  <label
                    className="abbreviation-amount-list-total"
                    style={{
                      backgroundColor: "rgba(96, 122, 41, 0.26)",
                      fontWeight: "700",
                    }}
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.publicationeditor && (
        <div>
          <div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button onClick={close} className="open-modal-close-button">
                X Close
              </button>
            </div>
            <div
              style={{
                fontWeight: "700",
                fontSize: "22px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              Publication Report
            </div>
            <div style={{ padding: "0px 100px 0 100px" }}>
              <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
                <div
                  style={{
                    border: "1px black",
                    borderStyle: "solid none solid solid",
                    fontSize: "16px",
                    textAlign: "left",
                    paddingLeft: "10px",
                  }}
                >
                  Document Name
                </div>
                <div
                  style={{
                    border: "1px black",
                    borderStyle: "solid solid solid solid",
                    fontWeight: 700,
                    fontSize: "16px",
                    textAlign: "left",
                    paddingLeft: "10px",
                  }}
                >
                  {Publication1RenderRow(formData?.filename) || ""}
                </div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
                <div
                  style={{
                    border: "1px black",
                    borderStyle: "none none solid solid",
                    fontSize: "16px",
                    textAlign: "left",
                    paddingLeft: "10px",
                  }}
                >
                  Date
                </div>
                <div
                  style={{
                    border: "1px black",
                    borderStyle: "none solid solid solid",
                    fontWeight: 700,
                    fontSize: "16px",
                    textAlign: "left",
                    paddingLeft: "10px",
                  }}
                >
                  {convertDate(formData?.publishdate) || ""}
                </div>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "30% 70%",
                  paddingBottom: "50px",
                }}
              >
                <div
                  style={{
                    border: "1px black",
                    borderStyle: "none none solid solid",
                    fontSize: "16px",
                    textAlign: "left",
                    paddingLeft: "10px",
                  }}
                >
                  List of Distributors
                </div>
                <div>{PublicationRenderRow(formData?.package) || ""}</div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.distributoreditor && (
        <div>
          <div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button onClick={close} className="open-modal-close-button">
                X Close
              </button>
            </div>
            <div
              style={{
                fontWeight: "700",
                fontSize: "22px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              Distributor Summary
            </div>
            <div style={{ padding: "0px 100px 0 100px" }}>
              <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
                <div
                  style={{
                    border: "1px black",
                    borderStyle: "solid none solid solid",
                    fontSize: "16px",
                    textAlign: "left",
                    paddingLeft: "10px",
                  }}
                >
                  Distributor
                </div>
                <div
                  style={{
                    border: "1px black",
                    borderStyle: "solid solid solid solid",
                    fontWeight: 700,
                    fontSize: "16px",
                    textAlign: "left",
                    paddingLeft: "10px",
                  }}
                >
                  {formData.data.package || ""}
                </div>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "30% 70%",
                  paddingBottom: "50px",
                }}
              >
                <div
                  style={{
                    border: "1px black",
                    borderStyle: "none none solid solid",
                    fontSize: "16px",
                    textAlign: "left",
                    paddingLeft: "10px",
                  }}
                >
                  List of Uploads
                </div>
                <div
                  style={{
                    height: "500px",
                    border: "1px black",
                    borderStyle: "none solid solid solid",
                    overflow: "auto",
                    fontSize: "16px",
                    textAlign: "left",
                  }}
                >
                  <RT
                    tablewidth="90%"
                    pageControl={pageC}
                    data={formData.data.document}
                    cols={[
                      {
                        fieldname: "publishdate",
                        width: "20%",
                        label: "Publication date",
                        sortcolumn: "Date",
                        clickable: "true",
                        align: "left",
                      },
                      {
                        fieldname: "title",
                        width: "80%",
                        label: "Distributors",
                        align: "left",
                      },
                    ]}
                    type={"small"}
                  ></RT>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.usereditor && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "80px",
            }}
          >
            <button onClick={cancelUser} className="open-modal-close-button">
              X Close
            </button>
          </div>
          <div
            style={{
              fontWeight: "700",
              fontSize: "22px",
              paddingTop: "10px",
              paddingBottom: "10px",
              textAlign: "center",
            }}
          >
            User management
          </div>
          <div>
            <div>
              <hr style={{ height: "1px", width: "85%" }} />
              <div style={{ padding: "20px 100px 0 100px" }}>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 4fr" }}
                >
                  <label className="open-modal-label">Name</label>
                  <Input
                    type="text"
                    name="name"
                    value={chooseData?.name || ""}
                    onChange={(e: any) => {
                      setChooseData({
                        ...chooseData,
                        name: e.target.value,
                      });
                    }}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                  />
                </div>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 4fr" }}
                >
                  <label className="open-modal-label">Email</label>
                  <Input
                    type="text"
                    name="email"
                    value={chooseData?.email || ""}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                    disabled
                  />
                </div>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 4fr" }}
                >
                  <label className="open-modal-label">Approval</label>
                  <Select
                    listHeight={160}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    showSearch
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={(e: any) => {
                      setChooseData({
                        ...chooseData,
                        adminApproval: e,
                      });
                    }}
                    value={ApprovalMapping(chooseData?.adminApproval)}
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={dropDownApprovalOptions}
                  />
                </div>
                {chooseData?.adminApproval === "Declined" && (
                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 4fr" }}
                  >
                    <label className="open-modal-label">Reason</label>
                    <Input
                      type="text"
                      name="reason"
                      value={chooseData?.reason}
                      onChange={(e: any) => {
                        setChooseData({
                          ...chooseData,
                          reason: e.target.value,
                        });
                      }}
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                    />
                  </div>
                )}
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 4fr" }}
                >
                  <label className="open-modal-label">User role</label>
                  <Select
                    listHeight={160}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    showSearch
                    style={{
                      width: "100%",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={(e: any) => {
                      setChooseData({
                        ...chooseData,
                        role: e,
                      });
                    }}
                    value={chooseData?.role}
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={roleList}
                  />
                </div>
                <div
                  style={{ display: "grid", gridTemplateColumns: "1fr 4fr" }}
                >
                  <div></div>
                  {chooseData?.role === "User" && (
                    <div
                      style={{
                        color: "rgba(109, 109, 109, 1)",
                        paddingLeft: "10px",
                      }}
                    >
                      Access and Privileges: Limited access - Read only
                    </div>
                  )}
                  {chooseData?.role === "Admin" && (
                    <div
                      style={{
                        color: "rgba(109, 109, 109, 1)",
                        paddingLeft: "10px",
                      }}
                    >
                      Access and Privileges: Allowed to manage contents and
                      user's data
                    </div>
                  )}
                </div>
                {chooseData?.role === "Admin" && (
                  <div
                    style={{ display: "grid", gridTemplateColumns: "1fr 4fr" }}
                  >
                    <label className="open-modal-label">Action</label>
                    <Checkbox
                      style={{ paddingTop: "8px" }}
                      checked={chooseData?.EmailReceiver}
                      onChange={(e: any) => {
                        setChooseData({
                          ...chooseData,
                          EmailReceiver: e.target.checked,
                        });
                      }}
                    >
                      Receive Approval Email {chooseData?.EmailReceiver}
                    </Checkbox>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "100px",
              }}
            >
              <button
                onClick={cancelUser}
                className="open-modal-reset-button"
                style={{ padding: "0 2% 0 0" }}
              >
                Cancel
              </button>
              <button onClick={updateUser} className="open-modal-save-button">
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyComponent;
