import React, { useState } from "react";
import "./Login.css";
import Google from "../assets/img/google.png";
import Microsoft from "../assets/img/microsoft.png";
import { Input, notification, Form, Button } from "antd";
import { useNavigate } from "react-router-dom";

const MyComponent = (props: any) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  let loginDetail: any = {};
  const [loginData, setLoginData] = useState([]);

  const formData = [
    {
      key: "email",
      name: "email",
      label: (
        <>
          <span style={{ marginRight: "25px" }}>Email</span>
        </>
      ),
      labelCol: { style: { fontWeight: "600", paddingRight: "5px" } },
      rules: [{ required: true, message: "Please input your email!" }],
      // normalize={normalizeInput},
      validateTrigger: "onBlur",

      component: (
        <Input
          style={{
            borderTopRightRadius: "30px",
            borderBottomRightRadius: "30px",
            // border: "1px solid rgba(0,0,0,0.25)",
            // marginLeft: "25px",
            // width: "92%",
          }}
          placeholder="Email"
        />
      ),
    },
    {
      key: "password",
      name: "password",
      label: "Password",
      labelCol: { style: { fontWeight: "600", paddingRight: "5px" } },
      rules: [{ required: true, message: "Please input your password!" }],
      validateTrigger: "onBlur",
      component: (
        <Input.Password
          style={{
            borderTopRightRadius: "30px",
            borderBottomRightRadius: "30px",
            // border: "1px solid black",
          }}
          placeholder="Password"
        />
      ),
    },
  ];

  const normalizeInput = (value: any) => {
    return value.trim();
  };

  const handleName = (el: any) => {
    let _data: any = { ...loginData };
    if (el.target.value.trim().length > 0) {
      _data["email"] = el.target.value;
      setLoginData(_data);
    }
  };

  const handlePassword = (el: any) => {
    let _data: any = { ...loginData };
    if (el.target.value.trim().length > 0) {
      _data["password"] = el.target.value;
      setLoginData(_data);
    }
  };

  const login = () => {
    // if (Object.keys(loginData).length > 0) {
    //   if (props.handlelogin && typeof props.handlelogin === "function") {
    //     props.handlelogin(loginData);
    //   }
    // }
    if (form.getFieldsValue().email && form.getFieldsValue().password) {
      if (props.handlelogin && typeof props.handlelogin === "function") {
        props.handlelogin(form.getFieldsValue());
      }
    } else {
      notification.error({
        message: `Login Verification!`,
        description: `Please enter a valid email and password`,
        placement: "top",
        duration: 3,
      });
    }
  };

  const navigateRegister = () => {
    navigate("/register", { replace: true });
  };

  const navigateForgotPassword = () => {
    navigate("/forgotpassword", { replace: true });
  };

  return (
    <>
      {/* <div className="login-box">
        <div className="login-title">Login</div>
        <hr style={{ height: "1px", width: "70%", marginBottom: "10px" }} />
        <div className="login-message">
          <div>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "2fr 4fr",
                paddingBottom: "15px",
              }}
            >
              <a
                style={{
                  boxSizing: "border-box",
                  paddingTop: "5px",
                  fontSize: "16px",
                  fontWeight: "600",
                  textAlign: "initial",
                  color: "#000000",
                }}
              >
                Email:
              </a>
              <Input
                type="text"
                placeholder="Enter your email"
                onChange={handleName}
                style={{
                  paddingLeft: "10px",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  border: "1px solid rgba(51, 76, 72, 0.61)",
                }}
              />
            </div>

            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "2fr 4fr",
                paddingBottom: "25px",
              }}
            >
              <a
                style={{
                  boxSizing: "border-box",
                  paddingTop: "5px",
                  fontSize: "16px",
                  fontWeight: "600",
                  textAlign: "initial",
                  color: "#000000",
                }}
              >
                Password:
              </a>
              <Input
                type="password"
                placeholder="Enter your password."
                onChange={handlePassword}
                style={{
                  paddingLeft: "10px",
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  border: "1px solid rgba(51, 76, 72, 0.61)",
                }}
                // onKeyPress={login}
              />
            </div>

            <div style={{ paddingBottom: "10px" }}>
              <button
                onClick={login}
                style={{
                  backgroundColor: "rgba(78, 146, 37, 1)",
                  color: "white",
                  width: "100%",
                  height: "33px",
                  borderRadius: "15px",
                  border: "0",
                  marginBottom: "20px",
                }}
              >
                Log In
              </button>
            </div>

            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "8fr 1fr 8fr",
                paddingBottom: "20px",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  paddingLeft: "30%",
                  cursor: "pointer",
                }}
                onClick={navigateForgotPassword}
              >
                Forgot Password
              </div>
              <div>| </div>
              <div
                style={{
                  fontSize: "16px",
                  paddingRight: "10%",
                  color: "rgba(36,169,169,0.74)",
                  cursor: "pointer",
                }}
                onClick={navigateRegister}
              >
                Register a new account
              </div>
            </div>

            <div>
              <div
                style={{
                  fontSize: "14px",
                  paddingBottom: "15px",
                  textAlign: "center",
                }}
              >
                Having problems?{" "}
                <a
                  href="mailto:dev@acq.brixmind.com?subject=ContactUS"
                  style={{ color: "#0E920B", textDecorationLine: "underline" }}
                >
                  Contact us.
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="login-box">
        <h2>Login</h2>
        <hr style={{ margin: "10px 0px 30px 0px", width: "80%" }} />
        <Form
          className="login-form"
          style={{ width: "80%" }}
          form={form}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
        >
          {formData.map((item: any) => (
            <Form.Item {...item}>{item.component}</Form.Item>
          ))}

          {/* Login Button */}
          <Form.Item>
            <Button
              className="button-green"
              style={{
                width: "100%",
                borderRadius: "15px",
                marginTop: "10px",
                fontWeight: "700",
                backgroundColor: "#3d9b70",
                color: "#ffffff",
                borderColor: "#d9d9d9",
              }}
              size={"large"}
              htmlType="submit"
              onClick={login}
            >
              Log in
            </Button>
          </Form.Item>
          <div className="button-container">
            <Button
              type="link"
              className="button-forgot-password-link"
              onClick={() => {
                // navigate to forget password page
                navigate("/forgotpassword");
              }}
            >
              Forget Password
            </Button>
            <div className="button-bar" />
            <Button
              type="link"
              className="button-register-link"
              onClick={() => {
                // navigate to register page
                navigate("/register");
              }}
            >
              Register a New Account
            </Button>
          </div>
          <div>
            <div
              style={{
                fontSize: "14px",
                paddingBottom: "0px",
                paddingTop: "20px",
                textAlign: "center",
              }}
            >
              Having problems?{" "}
              <a
                href="mailto:dev@acq.brixmind.com?subject=ContactUS"
                style={{ color: "#0E920B", textDecorationLine: "underline" }}
              >
                Contact us.
              </a>{" "}
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default MyComponent;
