import axios from "axios";
import { AuthHeader } from "./header";
const url = process.env.REACT_APP_SERVER_URL as string;
const filepath = process.env.REACT_APP_CSV_ABSPATH as string;

export const getAllCompanies = (
  filterControl: any = undefined,
  sortControl: any = undefined
) => {
  let fc: any = undefined;
  let sc: any = undefined;
  if (filterControl.pageControl) {
    fc = {
      skip: {
        $skip:
          (filterControl.pageControl.pageNum - 1) *
          filterControl.pageControl.recPerPage,
      },
      limit: {
        $limit: filterControl.pageControl.recPerPage,
      },
    };
  }
  if (Object.keys(sortControl.sortControl).length !== 0) {
    sc = {
      sort: {
        $sort: sortControl.sortControl,
      },
    };
  }
  let newFilter: any = Object.assign(fc, sc);

  return new Promise((resolve, reject) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(`${url}/company/getAllCompanies`, newFilter, header)
      .then((data: any) => {
        resolve(data.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getSearchCompanies = (
  filterControl: any = undefined,
  searchResult: any = undefined,
  sortControl: any = undefined
) => {
  let fc: any = undefined;
  let sr: any = undefined;
  let sc: any = undefined;
  if (filterControl.pageControl) {
    fc = {
      skip: {
        $skip:
          (filterControl.pageControl.pageNum - 1) *
          filterControl.pageControl.recPerPage,
      },
      limit: {
        $limit: filterControl.pageControl.recPerPage,
      },
    };
  }
  if (Object.keys(sortControl.sortControl).length !== 0) {
    sc = {
      sort: {
        $sort: sortControl.sortControl,
      },
    };
  }
  if (searchResult) {
    sr = searchResult;
  }
  let newFilter: any = Object.assign(fc, sr, sc);

  return new Promise((resolve, reject) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(`${url}/company/getFilterCompanies`, newFilter, header)
      .then((data: any) => {
        resolve(data.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
export const getNumCompanies = (filter: any = {}) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(`${url}/company/getNumCompaniesCount`, filter, header)
      .then((data: any) => {
        resolve(data.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
export const maxCompnumber = () => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .get(`${url}/company/getMaxCompnumber`, header)
      .then((data: any) => {
        resolve(data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

const allnccompanyurl: string = url + "/company/allnccompany";
export const getAllNCCompany = () => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .get(allnccompanyurl, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const limiturl: any = url + "/company/searchnccompany";
export const getSearchLimitResult = () => {
  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(`token`)}`,
    },
  };
  return new Promise((resolve: any, reject: any) => {
    axios
      .get(limiturl, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const getCountDocumenturl: any = url + "/company/getCountDocument";
export const getCountDocument = (searchObj: any) => {
  // console.log("searchObj", searchObj);
  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(`token`)}`,
    },
  };
  return new Promise((resolve: any, reject: any) => {
    axios
      .post(getCountDocumenturl, searchObj, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const updateCompanyIndustryurl: any = url + "/company/industryUpdate";
export const updateCompanyIndustry = (searchObj: any) => {
  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(`token`)}`,
    },
  };
  return new Promise((resolve: any, reject: any) => {
    axios
      .post(updateCompanyIndustryurl, searchObj, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const getExportLatestCSV: any = url + "/downloadFile/export_csv";
export const getExportCSV = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header: any = {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(`${getExportLatestCSV}`, searchObj, header)
      .then((data: any) => {
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data.data]));
          const link = document.createElement("a");
          link.href = url;
          if (searchObj.file === "file_inventory") {
            link.setAttribute("download", `File Inventory.xlsx`); //or any other extension
          } else {
            link.setAttribute("download", `Daily Documents.xlsx`); //or any other extension
          }
          //or any other extension
          document.body.appendChild(link);
          link.click();
        } else {
          console.log("File does not exist.");
        }
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
