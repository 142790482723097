import React from "react";

const MyComponent = (props: any) => {
  return (
    <div
      style={{
        border: "rgba(0, 0, 0, 0.35) 1px solid",
        borderRadius: "5px",
        width: "90%",
        margin: "auto",
        marginTop: "10px",
      }}
    >
      <div
        style={{
          color: "black",
          fontSize: "16px",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        Abbreviation
      </div>
      <div style={{ padding: "20px 50px 20px 50px" }}>
        <div
          style={{
            color: "black",
            fontSize: "14px",
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
          }}
        >
          <div>
            <b>IS</b> = Industry Snapshots
          </div>
          <div>
            <b>CS</b> = Company Snapshots
          </div>
          <div>
            <b>IR </b>= Acquisdata Industry Reports
          </div>
          <div>
            <b>RCD</b> = Company Documents
          </div>
        </div>
        <div
          style={{
            color: "black",
            fontSize: "14px",
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
          }}
        >
          <div>
            <b>GS</b> = Global Industry Snapshots
          </div>
          <div>
            <b>ES </b>= Executive Snapshots
          </div>
          <div>
            <b>MR</b> = Macrosource Reports
          </div>
          <div>
            <b>M</b> = Movements
          </div>
        </div>
        <div
          style={{
            color: "black",
            fontSize: "14px",
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
          }}
        >
          <div>
            <b>AM</b> = Mergers & Acquistion
          </div>
          <div>
            <b>EM</b> = Executive Movements
          </div>
          <div>
            <b>HCS</b> = Historical Company Snapshot
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default MyComponent;
