import {
  Modal,
  Input,
  Card,
  Select,
  SelectProps,
  Switch,
  Button,
  Space,
  DatePicker,
  Form,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { MinusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import AlertModal from "../component/AlertMessage";

const MyComponent: any = (props: any) => {
  const [form] = Form.useForm();
  const myRef: any = useRef();
  const [data, setData]: any = useState({});
  const [openState, setOpenState] = useState(false);
  const [hackUpdate, setHackUpdate] = useState(false);
  const [showAdvanceConfig, setShowAdvanceConfig] = useState(false);
  const [showZipPublications, setShowZipPublications] = useState(false);
  const [showSortBy, setShowSortBy] = useState(false);
  const [showSummarise, setShowSummarise] = useState(false);
  const [showGroupBy, setShowGroupBy] = useState(false);
  const [showSummariseBy, setShowSummariseBy] = useState(false);
  const [showLookBack, setShowLookBack] = useState(false);
  const [showinitialsdate, setShowInitialSdate] = useState(false);
  const [showeditinitialsdate, setShowEditInitialSdate] = useState(false);
  const [showinitialedate, setShowInitialEdate] = useState(false);
  const [showeditinitialedate, setShowEditInitialEdate] = useState(false);
  const [showinitials1date, setShowInitialS1date] = useState(false);
  const [showeditinitials1date, setShowEditInitialS1date] = useState(false);
  const [showinitiale1date, setShowInitialE1date] = useState(false);
  const [showeditinitiale1date, setShowEditInitialE1date] = useState(false);
  const [showinitials2date, setShowInitialS2date] = useState(false);
  const [showeditinitials2date, setShowEditInitialS2date] = useState(false);
  const [showinitiale2date, setShowInitialE2date] = useState(false);
  const [showeditinitiale2date, setShowEditInitialE2date] = useState(false);
  const [showinitials3date, setShowInitialS3date] = useState(false);
  const [showeditinitials3date, setShowEditInitialS3date] = useState(false);
  const [showinitiale3date, setShowInitialE3date] = useState(false);
  const [showeditinitiale3date, setShowEditInitialE3date] = useState(false);
  const [showinitials4date, setShowInitialS4date] = useState(false);
  const [showeditinitials4date, setShowEditInitialS4date] = useState(false);
  const [showinitiale4date, setShowInitialE4date] = useState(false);
  const [showeditinitiale4date, setShowEditInitialE4date] = useState(false);
  const [showCConfirm, setShowCConfirm] = useState(false);

  const packingMode: SelectProps["options"] = [
    { value: "dmspacklegacy", label: "Legacy Mode" },
    { value: "dmspack", label: "Acquisdata XML 2.0" },
    {
      value: "dmspackchapters",
      label: "Acquisdata XML 2.0 (Chapter split)",
    },
    { value: "rixmlpack", label: "RIXML" },
  ];

  const ftpmode: SelectProps["options"] = [
    { value: "ftp", label: "FTP" },
    { value: "sftp", label: "SFTP" },
  ];

  const publicationTypeTags: SelectProps["options"] = [
    { value: "CS", label: "Company Snapshot" },
    { value: "IS", label: "Industry Snapshot" },
    { value: "ISS", label: "Industry Snapshot Summary" },
    { value: "IR", label: "Acquisdata Industry Reports" },
    { value: "GS", label: "Global Industry Snapshot" },
    { value: "ES", label: "Executive Snapshot" },
    { value: "EM", label: "Executive Movement" },
    { value: "AM", label: "Mergers and Acquisition" },
    { value: "AMS", label: "Mergers and Acquisition Summary" },
    { value: "EMS", label: "Executive Movement Summary" },
    { value: "ESS", label: "Executive Snapshot Summary" },
  ];
  const documentTypeTags: SelectProps["options"] = [
    { value: "DOCX", label: "Word Document" },
    { value: "PDF", label: "PDF" },
    { value: "XML", label: "XML" },
  ];
  const SpanToTags: SelectProps["options"] = [
    { value: "em", label: "em" },
    { value: "u", label: "u" },
  ];
  const doccodeTags: SelectProps["options"] = [
    { value: "CS", label: "CS" },
    { value: "IS", label: "IS" },
    { value: "ISS", label: "ISS" },
    { value: "IR", label: "IR" },
    { value: "GS", label: "GS" },
    { value: "ES", label: "ES" },
    { value: "EM", label: "EM" },
    { value: "AM", label: "AM" },
    { value: "AMS", label: "AMS" },
    { value: "EMS", label: "EMS" },
    { value: "ESS", label: "ESS" },
  ];

  const defaultxmlHeaderDoccodeTagMap: any = [
    ["IS", "industrysnapshots"],
    ["IR", "industryreports"],
    ["GS", "globalindustrysnapshot"],
    ["CS", "companysnapshot"],
    ["ES", "executivesnapshot"],
    ["EM", "executivemovement"],
    ["AM", "mergeracquisition"],
  ];

  const defaultxmlTemplates: any = [
    ["IS", "LEGACY-IS-xml-template.xml"],
    ["IR", "LEGACY-IR-xml-template.xml"],
    ["GS", "LEGACY-GS-xml-template.xml"],
    ["CS", "LEGACY-CS-xml-template.xml"],
    ["ISS", "LEGACY-ISS-xml-template.xml"],
    ["ES", "LEGACY-ES-xml-template.xml"],
    ["EM", "LEGACY-EM-xml-template.xml"],
    ["AM", "LEGACY-AM-xml-template.xml"],
    ["AMS", "LEGACY-AMS-xml-template.xml"],
    ["EMS", "LEGACY-EMS-xml-template.xml"],
    ["ESS", "LEGACY-ESS-xml-template.xml"],
  ];

  const defaultxmlSummaryHeaderDoccodeTagMap: any = [
    ["IS", "LEGACY-IS-SUM-xml-template.xml"],
  ];

  const defaultPublicationFilenames: any = [
    ["IS", "IS{documentid}"],
    ["GS", "GS{documentid}"],
    ["CS", "CS{documentid}"],
    ["ISS", "IS{documentid}_wd"],
    ["ES", "ES{documentid}"],
    ["EM", "EM{documentid}"],
    ["AM", "AM{documentid}"],
  ];

  const updateCloseHook: any = () => {
    if (props.handleClose && typeof props.handleClose === "function") {
      props.handleClose();
    }
  };

  const handleCancel: any = () => {
    if (props.data._id) {
      if (JSON.stringify(data) !== JSON.stringify(props.data)) {
        setShowCConfirm(true);
      } else {
        setOpenState(false);
        if (props.handleCancel && typeof props.handleCancel === "function") {
          props.handleCancel();
        }
      }
    } else {
      if (JSON.stringify(data) !== JSON.stringify(props.data)) {
        setShowCConfirm(true);
      } else {
        setOpenState(false);
        if (props.handleCancel && typeof props.handleCancel === "function") {
          props.handleCancel();
        }
      }
    }
  };

  useEffect(() => {
    setOpenState(props.open || false);
    if (props.data) {
      setData(props.data);
      if (props.data.xmlRemoveStringPatterns) {
        if (props.data.xmlRemoveStringPatterns.length) {
          setShowAdvanceConfig(true);
        }
      }
      if (props.data.sortBy) {
        if (props.data.sortBy.length) {
          setShowSortBy(true);
        }
      }
      if (props.data.initialStartDateQ2 && props.data.initialEndDateQ2) {
        if (
          props.data.initialStartDateQ2 !== null &&
          props.data.initialEndDateQ2 !== null
        ) {
          setShowSummarise(true);
        }
      }
      if (props.data.groupBy) {
        if (props.data.groupBy.length) {
          setShowGroupBy(true);
        }
      }
      if (props.data.summariseBy) {
        if (props.data.summariseBy.length) {
          setShowSummariseBy(true);
        }
      }
      if (props.data.lookBack) {
        if (props.data.lookBack.length) {
          setShowLookBack(true);
        }
      }
    }
  }, []);

  const convertDate = (el: any) => {
    if (el) {
      let d = new Date(el);
      return d.toLocaleDateString("en-GB");
    } else {
      return;
    }
  };

  const handleOk: any = () => {
    const current = new Date();
    if (props.handleSaveData && typeof props.handleSaveData === "function") {
      props.handleSaveData({ ...data, lastEditDate: current });
    }
    updateCloseHook();
    setOpenState(false);
  };

  const addNewTemplate: any = () => {
    const d: any = data;
    if (!d.xmlTemplates) {
      d.xmlTemplates = defaultxmlTemplates;
      setData(d);
    } else {
      setData({
        ...data,
        xmlTemplates: [...data.xmlTemplates, ["", ""]],
      });
    }
    setHackUpdate(!hackUpdate);
  };

  const addNewHeaderDoccodeTagMapTemplate: any = () => {
    const d: any = data;
    if (!d.xmlHeaderDoccodeTagMap) {
      d.xmlHeaderDoccodeTagMap = defaultxmlHeaderDoccodeTagMap;
      setData(d);
    } else {
      setData({
        ...data,
        xmlHeaderDoccodeTagMap: [...data.xmlHeaderDoccodeTagMap, ["", ""]],
      });
    }
    setHackUpdate(!hackUpdate);
  };

  const addNewSummaryTemplate: any = () => {
    const d: any = data;
    if (!d.xmlSummaryTemplates) {
      d.xmlSummaryTemplates = defaultxmlSummaryHeaderDoccodeTagMap;
      setData(d);
    } else {
      setData({
        ...data,
        xmlSummaryTemplates: [...data.xmlSummaryTemplates, ["", ""]],
      });
    }
    setHackUpdate(!hackUpdate);
  };

  const addNewPublicationFile: any = () => {
    const d: any = data;
    if (!d.publicationFilenames) {
      d.publicationFilenames = defaultPublicationFilenames;
      setData(d);
    } else {
      setData({
        ...data,
        publicationFilenames: [...data.publicationFilenames, ["", ""]],
      });
    }
    setHackUpdate(!hackUpdate);
  };

  const addNewManifestFile: any = () => {
    const d: any = data;
    if (d.manifestFiles === undefined) d.manifestFiles = [];
    d.manifestFiles.push("");
    setData(d);
    setHackUpdate(!hackUpdate);
  };

  const handleValueChange: any = (field: string, value: any) => {
    const d: any = data;
    d[field] = value;
    setData(d);
    setHackUpdate(!hackUpdate);
  };

  const handleAddNewExpression = () => {
    const d: any = data;
    if (d.xmlRemoveStringPatterns === undefined) d.xmlRemoveStringPatterns = [];
    d.xmlRemoveStringPatterns.push("");
    setData(d);
    setHackUpdate(!hackUpdate);
  };

  const handleAddNewBadUrl = () => {
    const d: any = data;
    if (d.xmlBadUrls === undefined) d.xmlBadUrls = [];
    d.xmlBadUrls.push("");
    setData(d);
    setHackUpdate(!hackUpdate);
  };

  const closeRemoveAlert = () => {
    setShowCConfirm(false);
  };

  const confirmCancel = () => {
    closeRemoveAlert();
    if (props.handleCancel && typeof props.handleCancel === "function") {
      props.handleCancel();
    }
  };

  return (
    <>
      <AlertModal
        show={showCConfirm}
        onClose={closeRemoveAlert}
        deleteDataHook={confirmCancel}
        confirmCancel
      />
      <div style={{ width: "90%" }} ref={myRef}>
        <Modal
          title={<h2>Configuration Detail</h2>}
          centered
          open={openState}
          onOk={handleOk}
          onCancel={handleCancel}
          width={"80vw"}
          bodyStyle={{ height: "75vh" }}
        >
          <div style={{ overflowY: "auto", maxHeight: "100%" }}>
            <Card>
              <Space direction="vertical" style={{ width: "100%" }}>
                <span>Name</span>
                <Input
                  placeholder="Name of Configuration"
                  value={data.name}
                  onChange={(e: any) => {
                    setData({
                      ...data,
                      name: e.target.value,
                    });
                  }}
                />
                <span>Distributor</span>
                <Input
                  placeholder="Name of Distributor"
                  value={data.distributionName}
                  onChange={(e: any) => {
                    setData({
                      ...data,
                      distributionName: e.target.value,
                    });
                  }}
                />
                <span>Package</span>
                <Input
                  placeholder="Name of Package"
                  value={data.package}
                  onChange={(e: any) => {
                    setData({
                      ...data,
                      package: e.target.value,
                    });
                  }}
                />
                <span>Key</span>
                <Input
                  placeholder="Name of Key"
                  defaultValue={data.key}
                  onChange={(e: any) => {
                    setData({
                      ...data,
                      key: e.target.value,
                    });
                  }}
                />
                <span>Comment</span>
                <TextArea
                  placeholder="Comment"
                  value={data.comment}
                  onChange={(e: any) => {
                    setData({
                      ...data,
                      comment: e.target.value,
                    });
                  }}
                />
                <span>Packing Mode</span>
                <Select
                  listHeight={160}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  options={packingMode}
                  value={data.mode}
                  placeholder="Packing Mode"
                  style={{ width: "100%" }}
                  onChange={(option: any) => {
                    setData({ ...data, mode: option });
                  }}
                />
                <span>Publication Types to Include</span>
                <Select
                  listHeight={160}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  mode="tags"
                  showArrow
                  options={publicationTypeTags}
                  value={data.doccodes}
                  placeholder="Publication Types to Include"
                  style={{ width: "100%" }}
                  onChange={(option: any) => {
                    setData({ ...data, doccodes: option });
                  }}
                />
                <span>Documents to Include</span>
                <Select
                  listHeight={160}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  mode="tags"
                  showArrow
                  options={documentTypeTags}
                  value={data.fileTypes}
                  placeholder="Document Types to Include"
                  style={{ width: "100%" }}
                  onChange={(option: any) => {
                    setData({ ...data, fileTypes: option });
                  }}
                />
                <span>Region Configuration</span>
                <Select
                  listHeight={160}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  options={props.regiontype}
                  value={data.regionConfig}
                  placeholder="Region Configuration"
                  style={{ width: "100%" }}
                  onChange={(option: any) => {
                    setData({ ...data, regionConfig: option });
                  }}
                />
                <span>Industry Template</span>
                <Select
                  listHeight={160}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  mode="multiple"
                  allowClear
                  options={props.industrytemplate}
                  value={data.checkIndustryRef}
                  placeholder="Industry Template"
                  style={{ width: "100%" }}
                  onChange={(option: any) => {
                    setData({ ...data, checkIndustryRef: option });
                  }}
                />
                <span>Initial Start Date</span>
                {!showinitialsdate && (
                  <Input
                    value={convertDate(data.initialStartDate)}
                    onClick={() => {
                      setShowInitialSdate(true);
                      setShowEditInitialSdate(true);
                    }}
                  />
                )}
                {showinitialsdate && (
                  <DatePicker
                    open={showeditinitialsdate}
                    style={{ width: "100%" }}
                    format={"DD/MM/YYYY"}
                    onChange={(option: any) => {
                      setData({ ...data, initialStartDate: option });
                      setShowEditInitialSdate(false);
                    }}
                    onFocus={() => {
                      setShowEditInitialSdate(true);
                    }}
                    onBlur={() => {
                      setShowInitialSdate(false);
                    }}
                  />
                )}

                <span>Initial End Date</span>
                {!showinitialedate && (
                  <Input
                    value={convertDate(data.initialEndDate)}
                    onClick={() => {
                      setShowInitialEdate(true);
                      setShowEditInitialEdate(true);
                    }}
                  />
                )}
                {showinitialedate && (
                  <DatePicker
                    open={showeditinitialedate}
                    style={{ width: "100%" }}
                    format={"DD/MM/YYYY"}
                    onChange={(option: any) => {
                      setData({ ...data, initialEndDate: option });
                      setShowEditInitialEdate(false);
                    }}
                    onFocus={() => {
                      setShowEditInitialEdate(true);
                    }}
                    onBlur={() => {
                      setShowInitialEdate(false);
                    }}
                  />
                )}
              </Space>
              <h2 style={{ paddingTop: "10px" }}>Logic</h2>
              <div>
                <Space>
                  <Switch
                    size="default"
                    checkedChildren="Disable HREF/Links"
                    unCheckedChildren="Enable HREF/Links"
                    checked={data.xmlDisableHref ? true : false}
                    onChange={(ev: any) => {
                      handleValueChange("xmlDisableHref", ev);
                    }}
                  ></Switch>
                  <Switch
                    size="default"
                    checkedChildren="Auto FTP"
                    unCheckedChildren="Do Not Auto FTP"
                    checked={data.autoFtp ? true : false}
                    onChange={(ev: any) => {
                      handleValueChange("autoFtp", ev);
                    }}
                  ></Switch>
                  <Switch
                    size="default"
                    checkedChildren="Compress (Zip) Publications"
                    unCheckedChildren="Do not Zip publications"
                    defaultChecked={data.publicationsPerZip ? true : false}
                    onChange={(ev: any) => {
                      setShowZipPublications(ev);
                    }}
                  ></Switch>
                  <Switch
                    size="default"
                    checkedChildren="Show Advance Config"
                    unCheckedChildren="Hide Advance Config"
                    defaultChecked={data.xmlRemoveStringPatterns ? true : false}
                    onChange={(ev: any) => {
                      setShowAdvanceConfig(ev);
                    }}
                  ></Switch>
                  <Switch
                    size="default"
                    checkedChildren="Enable Dry Run"
                    unCheckedChildren="Disable Dry Run"
                    checked={data.dryRun ? true : false}
                    onChange={(ev: any) => {
                      handleValueChange("dryRun", ev);
                    }}
                  ></Switch>
                  <Switch
                    size="default"
                    checkedChildren="Enable Force Initial"
                    unCheckedChildren="Disable Force Initial"
                    checked={data.forceInitial ? true : false}
                    onChange={(ev: any) => {
                      handleValueChange("forceInitial", ev);
                    }}
                  ></Switch>
                </Space>
                <Space>
                  <Switch
                    size="default"
                    checkedChildren="Enable Sort By"
                    unCheckedChildren="Disable Sort By"
                    defaultChecked={data.sortBy ? true : false}
                    onChange={(ev: any) => {
                      setShowSortBy(ev);
                    }}
                  ></Switch>
                  <Switch
                    size="default"
                    checkedChildren="Enable Summarise"
                    unCheckedChildren="Disable Summarise"
                    defaultChecked={data.initialStartDateQ2 ? true : false}
                    onChange={(ev: any) => {
                      setShowSummarise(ev);
                    }}
                  ></Switch>
                  <Switch
                    size="default"
                    checkedChildren="Enable GroupBy"
                    unCheckedChildren="Disable GroupBy"
                    defaultChecked={data.groupBy ? true : false}
                    onChange={(ev: any) => {
                      setShowGroupBy(ev);
                    }}
                  ></Switch>
                  <Switch
                    size="default"
                    checkedChildren="Enable Summarise By"
                    unCheckedChildren="Disable Summarise By"
                    defaultChecked={data.summariseBy ? true : false}
                    onChange={(ev: any) => {
                      setShowSummariseBy(ev);
                    }}
                  ></Switch>
                  <Switch
                    size="default"
                    checkedChildren="Enable Look Back"
                    unCheckedChildren="Disable Look Back"
                    defaultChecked={data.lookBack ? true : false}
                    onChange={(ev: any) => {
                      setShowLookBack(ev);
                    }}
                  ></Switch>
                </Space>
              </div>
              <h2 style={{ paddingTop: "25px" }}>Configuration</h2>
              <div>
                <Space
                  direction="vertical"
                  style={{ width: "100%", paddingTop: "15px" }}
                >
                  <span>Publication FileName</span>
                  <Input
                    placeholder="Publication FileName"
                    value={data.publicationFilename}
                    onChange={(e: any) => {
                      setData({
                        ...data,
                        publicationFilename: e.target.value,
                      });
                    }}
                  />
                  <span>Multi Publication FileNames</span>
                  {data.publicationFilenames !== undefined &&
                    data.publicationFilenames.map((xmlt: any, index: any) => {
                      return (
                        <Space
                          key={index}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1.5fr 0.2fr",
                            width: "100%",
                          }}
                        >
                          <div>
                            <Select
                              listHeight={160}
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                              options={publicationTypeTags}
                              showArrow
                              value={xmlt[0] || undefined}
                              size="large"
                              placeholder="Publication Type"
                              style={{
                                width: "100%",
                              }}
                              onChange={(option: any) => {
                                let d = [...data.publicationFilenames];
                                d[index][0] = option;
                                setData({
                                  ...data,
                                  publicationFilenames: d,
                                });
                              }}
                            ></Select>
                          </div>
                          <div>
                            <Input
                              size="large"
                              placeholder="XML File"
                              value={xmlt[1]}
                              onChange={(e: any) => {
                                let d = [...data.publicationFilenames];
                                d[index][1] = e.target.value;
                                setData({
                                  ...data,
                                  publicationFilenames: d,
                                });
                              }}
                            />
                          </div>
                          <div>
                            <Button
                              style={{
                                width: "100%",
                              }}
                              size="large"
                              onClick={() => {
                                let d = data.publicationFilenames;
                                d.splice(index, 1);
                                setData({
                                  ...data,
                                  publicationFilenames: d,
                                });
                              }}
                            >
                              <MinusOutlined />
                            </Button>
                          </div>
                        </Space>
                      );
                    })}
                  <Button
                    onClick={addNewPublicationFile}
                    style={{ width: "100%" }}
                  >
                    Add New Publication Filename
                  </Button>

                  <span>XML Header Doccode Tag Map</span>
                  {data.xmlHeaderDoccodeTagMap !== undefined &&
                    data.xmlHeaderDoccodeTagMap.map((xmlt: any, index: any) => {
                      return (
                        <Space
                          key={index}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1.5fr 0.2fr",
                            width: "100%",
                          }}
                        >
                          <div>
                            <Select
                              listHeight={160}
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                              options={publicationTypeTags}
                              showArrow
                              value={xmlt[0]}
                              size="large"
                              placeholder="Publication Type"
                              style={{
                                width: "100%",
                              }}
                              onChange={(option: any) => {
                                let d = [...data.xmlHeaderDoccodeTagMap];
                                d[index][0] = option;
                                setData({
                                  ...data,
                                  xmlHeaderDoccodeTagMap: d,
                                });
                              }}
                            ></Select>
                          </div>
                          <div>
                            <Input
                              size="large"
                              placeholder="XML File"
                              value={xmlt[1]}
                              onChange={(e: any) => {
                                let d = [...data.xmlHeaderDoccodeTagMap];
                                d[index][1] = e.target.value;
                                setData({
                                  ...data,
                                  xmlHeaderDoccodeTagMap: d,
                                });
                              }}
                            />
                          </div>
                          <div>
                            <Button
                              style={{
                                width: "100%",
                              }}
                              size="large"
                              onClick={() => {
                                let d = data.xmlHeaderDoccodeTagMap;
                                d.splice(index, 1);
                                setData({
                                  ...data,
                                  xmlHeaderDoccodeTagMap: d,
                                });
                              }}
                            >
                              <MinusOutlined />
                            </Button>
                          </div>
                        </Space>
                      );
                    })}
                  <Button
                    onClick={addNewHeaderDoccodeTagMapTemplate}
                    style={{ width: "100%" }}
                  >
                    Add New Header Doccode Tag Map Template
                  </Button>

                  <span>XML Main Template</span>
                  <Input
                    placeholder="Main Template"
                    value={data.xmlTemplate}
                    onChange={(e: any) => {
                      setData({
                        ...data,
                        xmlTemplate: e.target.value,
                      });
                    }}
                  />
                  <span>XML Templates</span>
                  {data.xmlTemplates !== undefined &&
                    data.xmlTemplates.map((xmlt: any, index: any) => {
                      return (
                        <Space
                          key={index}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1.5fr 0.2fr",
                            width: "100%",
                          }}
                        >
                          <div>
                            <Select
                              listHeight={160}
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                              options={publicationTypeTags}
                              showArrow
                              value={xmlt[0]}
                              size="large"
                              placeholder="Publication Type"
                              style={{
                                width: "100%",
                              }}
                              onChange={(option: any) => {
                                let d = [...data.xmlTemplates];
                                d[index][0] = option;
                                setData({
                                  ...data,
                                  xmlTemplates: d,
                                });
                              }}
                            ></Select>
                          </div>
                          <div>
                            <Input
                              size="large"
                              placeholder="XML File"
                              value={xmlt[1]}
                              onChange={(e: any) => {
                                let d = [...data.xmlTemplates];
                                d[index][1] = e.target.value;
                                setData({
                                  ...data,
                                  xmlTemplates: d,
                                });
                              }}
                            />
                          </div>
                          <div>
                            <Button
                              style={{
                                width: "100%",
                              }}
                              size="large"
                              onClick={() => {
                                let d = data.xmlTemplates;
                                d.splice(index, 1);
                                setData({
                                  ...data,
                                  xmlTemplates: d,
                                });
                              }}
                            >
                              <MinusOutlined />
                            </Button>
                          </div>
                        </Space>
                      );
                    })}
                  <Button onClick={addNewTemplate} style={{ width: "100%" }}>
                    Add New Template
                  </Button>

                  <span>XML Summary Templates</span>
                  {data.xmlSummaryTemplates !== undefined &&
                    data.xmlSummaryTemplates.map((xmlt: any, index: any) => {
                      return (
                        <Space
                          key={index}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1.5fr 0.2fr",
                            width: "100%",
                          }}
                        >
                          <div>
                            <Select
                              listHeight={160}
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                              options={publicationTypeTags}
                              showArrow
                              value={xmlt[0] || undefined}
                              size="large"
                              placeholder="Publication Type"
                              style={{
                                width: "100%",
                              }}
                              onChange={(option: any) => {
                                let d = [...data.xmlSummaryTemplates];
                                d[index][0] = option;
                                setData({
                                  ...data,
                                  xmlSummaryTemplates: d,
                                });
                              }}
                            ></Select>
                          </div>
                          <div>
                            <Input
                              size="large"
                              placeholder="XML File"
                              value={xmlt[1]}
                              onChange={(e: any) => {
                                let d = [...data.xmlSummaryTemplates];
                                d[index][1] = e.target.value;
                                setData({
                                  ...data,
                                  xmlSummaryTemplates: d,
                                });
                              }}
                            />
                          </div>
                          <div>
                            <Button
                              style={{
                                width: "100%",
                              }}
                              size="large"
                              onClick={() => {
                                let d = data.xmlSummaryTemplates;
                                d.splice(index, 1);
                                setData({
                                  ...data,
                                  xmlSummaryTemplates: d,
                                });
                              }}
                            >
                              <MinusOutlined />
                            </Button>
                          </div>
                        </Space>
                      );
                    })}
                  <Button
                    onClick={addNewSummaryTemplate}
                    style={{ width: "100%" }}
                  >
                    Add New Summary Template
                  </Button>
                </Space>
                <Space
                  direction="vertical"
                  style={{ width: "100%", paddingTop: "15px" }}
                >
                  <h3>Manifest</h3>
                  <span>Manifest Xml Container</span>
                  <Input
                    placeholder="Manifest Xml Container"
                    value={data.manifestXmlContainer}
                    onChange={(e: any) => {
                      setData({
                        ...data,
                        manifestXmlContainer: e.target.value,
                      });
                    }}
                  />
                  <span>Manifest Xml Content</span>
                  <Input
                    placeholder="Manifest Xml Content"
                    value={data.manifestXmlContent}
                    onChange={(e: any) => {
                      setData({
                        ...data,
                        manifestXmlContent: e.target.value,
                      });
                    }}
                  />
                  <span>Manifest Files</span>
                  {data.manifestFiles && (
                    <>
                      {data.manifestFiles.map((s: any, index: any) => {
                        return (
                          <Space
                            key={index}
                            style={{
                              display: "grid",
                              gridTemplateColumns: "2.5fr 0.1fr",
                              width: "100%",
                              marginBottom: "8px",
                            }}
                          >
                            <Input
                              key={`x-${index}`}
                              size="large"
                              placeholder="Manifest File"
                              value={s}
                              width="100%"
                              style={{
                                width: "100%",
                              }}
                              onChange={(e: any) => {
                                let d = [...data.manifestFiles];
                                d[index] = e.target.value;
                                setData({
                                  ...data,
                                  manifestFiles: d,
                                });
                              }}
                            />
                            <Button
                              size="large"
                              onClick={() => {
                                let d = data.manifestFiles;
                                d.splice(index, 1);
                                setData({
                                  ...data,
                                  manifestFiles: d,
                                });
                              }}
                            >
                              <MinusOutlined />
                            </Button>
                          </Space>
                        );
                      })}
                    </>
                  )}
                  <Button
                    onClick={addNewManifestFile}
                    style={{
                      width: "100%",
                      marginBottom: "8px",
                    }}
                  >
                    Add New Manifest File
                  </Button>
                </Space>
                <Space
                  direction="vertical"
                  style={{ width: "100%", paddingTop: "15px" }}
                >
                  <span>Last and Publication</span>
                  <Input
                    size="large"
                    type="Number"
                    placeholder="0"
                    value={data.lastnPublication}
                    onChange={(e: any) => {
                      setData({
                        ...data,
                        lastnPublication: e.target.value,
                      });
                    }}
                  />
                  <span>Tag to Span</span>
                  <Select
                    listHeight={160}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    mode="tags"
                    options={SpanToTags}
                    showArrow
                    // size="large"
                    value={data.tagToSpan}
                    placeholder="Tags to Include"
                    style={{ width: "100%" }}
                    onChange={(option: any) => {
                      setData({ ...data, tagToSpan: option });
                    }}
                  />
                  <span>Xdoccode-Map:</span>
                  <Select
                    listHeight={160}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    mode="tags"
                    options={doccodeTags}
                    showArrow
                    // size="large"
                    value={data.xdoccodeMap}
                    placeholder="Xdoccode Map"
                    style={{ width: "100%" }}
                    onChange={(option: any) => {
                      setData({ ...data, xdoccodeMap: option });
                    }}
                  />
                </Space>
                <Space
                  direction="vertical"
                  style={{ width: "100%", paddingTop: "15px" }}
                >
                  <h3>Entitlement</h3>
                  <span>Entitlement Type:</span>
                  <Input
                    size="large"
                    placeholder="Entitlement Type"
                    value={data.entitlement?.entitlementType}
                    onChange={(e: any) => {
                      setData({
                        ...data,
                        entitlement: {
                          ...data.entitlement,
                          entitlementType: e.target.value,
                        },
                      });
                    }}
                  />
                  <span>Entitlement Context:</span>
                  <Input
                    size="large"
                    placeholder="Entitlement Context"
                    value={data.entitlement?.entitlementContext}
                    onChange={(e: any) => {
                      setData({
                        ...data,
                        entitlement: {
                          ...data.entitlement,
                          entitlementContext: e.target.value,
                        },
                      });
                    }}
                  />
                  <span>Entitlement Text:</span>
                  <Input
                    size="large"
                    placeholder="Entitlement Text"
                    value={data.entitlement?.entitlementText}
                    onChange={(e: any) => {
                      setData({
                        ...data,
                        entitlement: {
                          ...data.entitlement,
                          entitlementText: e.target.value,
                        },
                      });
                    }}
                  />
                </Space>
                {showSortBy && (
                  <>
                    <Space
                      direction="vertical"
                      style={{ width: "100%", paddingTop: "15px" }}
                    >
                      <span>Sort By</span>
                      <Input
                        size="large"
                        placeholder="SortBy FieldName"
                        value={data.sortBy}
                        onChange={(e: any) => {
                          setData({
                            ...data,
                            sortBy: e.target.value,
                          });
                        }}
                      />
                    </Space>
                  </>
                )}
                {showSummariseBy && (
                  <>
                    <Space
                      direction="vertical"
                      style={{ width: "100%", paddingTop: "15px" }}
                    >
                      <span>Summarise By</span>
                      <Input
                        size="large"
                        placeholder="Summarise By FieldName"
                        value={data.summariseBy}
                        onChange={(e: any) => {
                          setData({
                            ...data,
                            summariseBy: e.target.value,
                          });
                        }}
                      />
                    </Space>
                  </>
                )}
                {showGroupBy && (
                  <>
                    <Space
                      direction="vertical"
                      style={{ width: "100%", paddingTop: "15px" }}
                    >
                      <span>Group By</span>
                      <Input
                        size="large"
                        placeholder="GroupBy FieldName"
                        value={data.groupBy}
                        onChange={(e: any) => {
                          setData({
                            ...data,
                            groupBy: e.target.value,
                          });
                        }}
                      />
                    </Space>
                  </>
                )}
                {showLookBack && (
                  <>
                    <Space
                      direction="vertical"
                      style={{ width: "100%", paddingTop: "15px" }}
                    >
                      <span>Look Back</span>
                      <Input
                        size="large"
                        placeholder="look-back"
                        value={data.lookBack}
                        onChange={(e: any) => {
                          setData({
                            ...data,
                            lookBack: e.target.value,
                          });
                        }}
                      />
                    </Space>
                  </>
                )}
                {showSummarise && (
                  <>
                    <Space
                      direction="vertical"
                      style={{ width: "100%", paddingTop: "15px" }}
                    >
                      <h3>Initial Custom Date</h3>
                      <span>Initial Start Date Q1</span>
                      {!showinitials1date && (
                        <Input
                          value={convertDate(data.initialStartDateQ1)}
                          onClick={() => {
                            setShowInitialS1date(true);
                            setShowEditInitialS1date(true);
                          }}
                        />
                      )}
                      {showinitials1date && (
                        <DatePicker
                          open={showeditinitials1date}
                          style={{ width: "100%" }}
                          format={"DD/MM/YYYY"}
                          onChange={(option: any) => {
                            setData({ ...data, initialStartDateQ1: option });
                            setShowEditInitialS1date(false);
                          }}
                          onFocus={() => {
                            setShowEditInitialS1date(true);
                          }}
                          onBlur={() => {
                            setShowInitialS1date(false);
                          }}
                        />
                      )}

                      <span>Initial End Date Q1</span>
                      {!showinitiale1date && (
                        <Input
                          value={convertDate(data.initialEndDateQ1)}
                          onClick={() => {
                            setShowInitialE1date(true);
                            setShowEditInitialE1date(true);
                          }}
                        />
                      )}
                      {showinitiale1date && (
                        <DatePicker
                          open={showeditinitiale1date}
                          style={{ width: "100%" }}
                          format={"DD/MM/YYYY"}
                          onChange={(option: any) => {
                            setData({ ...data, initialEndDateQ1: option });
                            setShowEditInitialE1date(false);
                          }}
                          onFocus={() => {
                            setShowEditInitialE1date(true);
                          }}
                          onBlur={() => {
                            setShowInitialE1date(false);
                          }}
                        />
                      )}
                      <span>Initial Start Date Q2</span>
                      {!showinitials2date && (
                        <Input
                          value={convertDate(data.initialStartDateQ2)}
                          onClick={() => {
                            setShowInitialS2date(true);
                            setShowEditInitialS2date(true);
                          }}
                        />
                      )}
                      {showinitials2date && (
                        <DatePicker
                          open={showeditinitials2date}
                          style={{ width: "100%" }}
                          format={"DD/MM/YYYY"}
                          onChange={(option: any) => {
                            setData({ ...data, initialStartDateQ2: option });
                            setShowEditInitialS2date(false);
                          }}
                          onFocus={() => {
                            setShowEditInitialS2date(true);
                          }}
                          onBlur={() => {
                            setShowInitialS2date(false);
                          }}
                        />
                      )}

                      <span>Initial End Date Q2</span>
                      {!showinitiale2date && (
                        <Input
                          value={convertDate(data.initialEndDateQ2)}
                          onClick={() => {
                            setShowInitialE2date(true);
                            setShowEditInitialE2date(true);
                          }}
                        />
                      )}
                      {showinitiale2date && (
                        <DatePicker
                          open={showeditinitiale2date}
                          style={{ width: "100%" }}
                          format={"DD/MM/YYYY"}
                          onChange={(option: any) => {
                            setData({ ...data, initialEndDateQ2: option });
                            setShowEditInitialE2date(false);
                          }}
                          onFocus={() => {
                            setShowEditInitialE2date(true);
                          }}
                          onBlur={() => {
                            setShowInitialE2date(false);
                          }}
                        />
                      )}
                      <span>Initial Start Date Q3</span>
                      {!showinitials3date && (
                        <Input
                          value={convertDate(data.initialStartDateQ3)}
                          onClick={() => {
                            setShowInitialS3date(true);
                            setShowEditInitialS3date(true);
                          }}
                        />
                      )}
                      {showinitials3date && (
                        <DatePicker
                          open={showeditinitials3date}
                          style={{ width: "100%" }}
                          format={"DD/MM/YYYY"}
                          onChange={(option: any) => {
                            setData({ ...data, initialStartDateQ3: option });
                            setShowEditInitialS3date(false);
                          }}
                          onFocus={() => {
                            setShowEditInitialS3date(true);
                          }}
                          onBlur={() => {
                            setShowInitialS3date(false);
                          }}
                        />
                      )}

                      <span>Initial End Date Q3</span>
                      {!showinitiale3date && (
                        <Input
                          value={convertDate(data.initialEndDateQ3)}
                          onClick={() => {
                            setShowInitialE3date(true);
                            setShowEditInitialE3date(true);
                          }}
                        />
                      )}
                      {showinitiale3date && (
                        <DatePicker
                          open={showeditinitiale3date}
                          style={{ width: "100%" }}
                          format={"DD/MM/YYYY"}
                          onChange={(option: any) => {
                            setData({ ...data, initialEndDateQ3: option });
                            setShowEditInitialE3date(false);
                          }}
                          onFocus={() => {
                            setShowEditInitialE3date(true);
                          }}
                          onBlur={() => {
                            setShowInitialE3date(false);
                          }}
                        />
                      )}
                      <span>Initial Start Date Q4</span>
                      {!showinitials4date && (
                        <Input
                          value={convertDate(data.initialStartDateQ4)}
                          onClick={() => {
                            setShowInitialS4date(true);
                            setShowEditInitialS4date(true);
                          }}
                        />
                      )}
                      {showinitials4date && (
                        <DatePicker
                          open={showeditinitials4date}
                          style={{ width: "100%" }}
                          format={"DD/MM/YYYY"}
                          onChange={(option: any) => {
                            setData({ ...data, initialStartDateQ4: option });
                            setShowEditInitialS4date(false);
                          }}
                          onFocus={() => {
                            setShowEditInitialS4date(true);
                          }}
                          onBlur={() => {
                            setShowInitialS4date(false);
                          }}
                        />
                      )}

                      <span>Initial End Date Q4</span>
                      {!showinitiale4date && (
                        <Input
                          value={convertDate(data.initialEndDateQ4)}
                          onClick={() => {
                            setShowInitialE4date(true);
                            setShowEditInitialE4date(true);
                          }}
                        />
                      )}
                      {showinitiale4date && (
                        <DatePicker
                          open={showeditinitiale4date}
                          style={{ width: "100%" }}
                          format={"DD/MM/YYYY"}
                          onChange={(option: any) => {
                            setData({ ...data, initialEndDateQ4: option });
                            setShowEditInitialE4date(false);
                          }}
                          onFocus={() => {
                            setShowEditInitialE4date(true);
                          }}
                          onBlur={() => {
                            setShowInitialE4date(false);
                          }}
                        />
                      )}
                    </Space>
                  </>
                )}
                {data.autoFtp && (
                  <Space
                    direction="vertical"
                    style={{ width: "100%", paddingTop: "15px" }}
                  >
                    <h3>FTP Configuration</h3>
                    <span>Mode</span>
                    <Select
                      listHeight={160}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      options={ftpmode}
                      value={data.ftp?.mode}
                      placeholder="Mode"
                      style={{ width: "100%" }}
                      onChange={(e: any) => {
                        setData({
                          ...data,
                          ftp: {
                            ...data.ftp,
                            mode: e.target.value,
                          },
                        });
                      }}
                    />
                    <span>Server</span>
                    <Input
                      size="large"
                      placeholder="Name of FTP Server"
                      value={data.ftp?.server}
                      onChange={(e: any) => {
                        setData({
                          ...data,
                          ftp: {
                            ...data.ftp,
                            server: e.target.value,
                          },
                        });
                      }}
                    />
                    <span>Username</span>
                    <Input
                      size="large"
                      placeholder="Username"
                      value={data.ftp?.user}
                      onChange={(e: any) => {
                        setData({
                          ...data,
                          ftp: {
                            ...data.ftp,
                            user: e.target.value,
                          },
                        });
                      }}
                    />
                    <span>Password</span>
                    <Input
                      size="large"
                      type="password"
                      placeholder="Password"
                      value={data.ftp?.password}
                      onChange={(e: any) => {
                        setData({
                          ...data,
                          ftp: {
                            ...data.ftp,
                            password: e.target.value,
                          },
                        });
                      }}
                    />
                    <span>Path</span>
                    <Input
                      size="large"
                      placeholder="path"
                      value={data.ftp?.path}
                      onChange={(e: any) => {
                        setData({
                          ...data,
                          ftp: {
                            ...data.ftp,
                            path: e.target.value,
                          },
                        });
                      }}
                    />
                    <span>Max FTP Thread</span>
                    <Input
                      size="large"
                      type="number"
                      placeholder="Max Ftp thread"
                      value={data.maxFtpthread}
                      onChange={(e: any) => {
                        setData({
                          ...data,
                          maxFtpthread: e.target.value,
                        });
                      }}
                    />
                  </Space>
                )}
                {showZipPublications && (
                  <>
                    <Space
                      direction="vertical"
                      style={{ width: "100%", paddingTop: "15px" }}
                    >
                      <h3>Zip Filename Template</h3>
                      <span>Template String</span>
                      <Input
                        size="large"
                        placeholder="Template String"
                        value={data.zipFilename}
                        onChange={(e: any) => {
                          setData({
                            ...data,
                            zipFilename: e.target.value,
                          });
                        }}
                      />
                      <span>Publications per Zip</span>
                      <Input
                        size="large"
                        type="Number"
                        placeholder="0"
                        value={data.publicationsPerZip}
                        onChange={(e: any) => {
                          setData({
                            ...data,
                            publicationsPerZip: e.target.value,
                          });
                        }}
                      />
                    </Space>
                  </>
                )}
                {showAdvanceConfig && (
                  <>
                    <Space
                      direction="vertical"
                      style={{ width: "100%", paddingTop: "15px" }}
                    >
                      <h3>Advance Configuration</h3>
                      <Switch
                        size="default"
                        checkedChildren="Enable Remove Acquisdata Ref"
                        unCheckedChildren="Disable Remove Acquisdata Ref"
                        checked={data.xmlRemoveAcqRef ? true : false}
                        onChange={(ev: any) => {
                          handleValueChange("xmlRemoveAcqRef", ev);
                        }}
                      ></Switch>
                      <span>XML Remove String Patterns</span>
                      {data.xmlRemoveStringPatterns && (
                        <>
                          {data.xmlRemoveStringPatterns.map(
                            (s: any, index: any) => {
                              return (
                                <Space
                                  key={index}
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "2.5fr 0.1fr",
                                    width: "100%",
                                    marginBottom: "8px",
                                  }}
                                >
                                  <Input
                                    key={`x-${index}`}
                                    size="large"
                                    placeholder="Regular Expression String"
                                    value={s}
                                    width="100%"
                                    style={{
                                      width: "100%",
                                    }}
                                    onChange={(e: any) => {
                                      let d = [...data.xmlRemoveStringPatterns];
                                      d[index] = e.target.value;
                                      setData({
                                        ...data,
                                        xmlRemoveStringPatterns: d,
                                      });
                                    }}
                                  />
                                  <Button
                                    size="large"
                                    onClick={() => {
                                      let d = data.xmlRemoveStringPatterns;
                                      d.splice(index, 1);
                                      setData({
                                        ...data,
                                        xmlRemoveStringPatterns: d,
                                      });
                                    }}
                                  >
                                    <MinusOutlined />
                                  </Button>
                                </Space>
                              );
                            }
                          )}
                        </>
                      )}
                      <Button
                        onClick={handleAddNewExpression}
                        style={{
                          width: "100%",
                          marginBottom: "8px",
                        }}
                      >
                        Add New Expression
                      </Button>
                      <span>XML Remove Bad Urls</span>
                      {data.xmlBadUrls && (
                        <>
                          {data.xmlBadUrls.map((s: any, index: any) => {
                            return (
                              <Space
                                key={index}
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "2.5fr 0.1fr",
                                  width: "100%",
                                  marginBottom: "8px",
                                }}
                              >
                                <Input
                                  key={`x-${index}`}
                                  size="large"
                                  placeholder="Regular Expression Bad Url"
                                  value={s}
                                  width="100%"
                                  style={{
                                    width: "100%",
                                  }}
                                  onChange={(e: any) => {
                                    let d = [...data.xmlBadUrls];
                                    d[index] = e.target.value;
                                    setData({
                                      ...data,
                                      xmlBadUrls: d,
                                    });
                                  }}
                                />
                                <Button
                                  size="large"
                                  onClick={() => {
                                    let d = data.xmlBadUrls;
                                    d.splice(index, 1);
                                    setData({
                                      ...data,
                                      xmlBadUrls: d,
                                    });
                                  }}
                                >
                                  <MinusOutlined />
                                </Button>
                              </Space>
                            );
                          })}
                        </>
                      )}
                      <Button
                        onClick={handleAddNewBadUrl}
                        style={{
                          width: "100%",
                          marginBottom: "8px",
                        }}
                      >
                        Add New Expression
                      </Button>
                      <hr />
                    </Space>
                  </>
                )}
              </div>
            </Card>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default MyComponent;
