import React, { useState, useEffect } from "react";
import { Card, Button, Space, List, Checkbox, notification } from "antd";
import {
  getRegionType,
  getCountryRegion,
  addCountryRegion,
  addRegion,
  updateRegionType,
  updateRegion,
  getSRegionType,
  deleteMultiRegionType,
  deleteMultiRegion,
  deleteMultiCountry,
} from "../controller/region";
import { getAllCountry } from "../controller/search";
import EditBespokeRegionModal from "../component/EditBespokeRegionModel";
import CreateBespokeRegionModal from "../component/CreateBespokeRegionModel";
import AlertModal from "../component/AlertMessage";
import { MinusCircleOutlined } from "@ant-design/icons";
import { addLog, checkmailProfile } from "../controller/profile";
import { useNavigate } from "react-router-dom";

const MyComponent = (props: any) => {
  const navigate = useNavigate();
  const [checked, setChecked]: any = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [data, setData] = useState([]);
  const [selectRegion, setSelectRegion]: any = useState([]);
  const [country, setCountry]: any = useState([]);
  const [newRegionType, setNewRegionType] = useState([]);
  const [createRegionType, setCreateRegionType] = useState(false);
  const [countrylist, setCountryList] = useState([]);
  const [getregionloading, setGetRegionLoading]: any = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertmessage, setAlertMessage] = useState("");
  const [alerttype, setAlertType] = useState("");
  const [showDConfirm, setShowDConfirm] = useState(false);

  // useEffect(() => {
  //   let privilage: any = localStorage.getItem("privilage");
  //   let user: any = localStorage.getItem("user");
  //   let login: any = localStorage.getItem("isLogin");
  //   if (login === "true") {
  //     checkmailProfile({ email: user }).then((res: any) => {
  //       if (res === "You are unauthorized to use this resource") {
  //         setTimeout(() => {
  //           handleTokenExpired();
  //         }, 3000);
  //       } else if (res.data?.[0].role !== privilage) {
  //         localStorage.setItem("privilage", `${res.data[0].role}`);
  //       }
  //     });
  //   }
  // }, []);

  useEffect(() => {
    let user: any = localStorage.getItem("user");
    let log: any = {
      page: window.location.pathname,
      action: "Read",
      datetime: new Date(),
      details: "-",
      email: user,
    };
    addLog(log);
  }, []);

  useEffect(() => {
    setGetRegionLoading(true);
    getRegionType({})
      .then((res: any) => {
        let snewdata: any = [];
        if (res.data) {
          for (let x = 0; x < res.data.length; x++) {
            let relateddata: any = [];
            for (let i = 0; i < res.data[x].related[0].length; i++) {
              const newdata = {
                regionid: res.data[x].related[0][i].regionid,
                regiontype: res.data[x].related[0][i].regiontype,
              };
              getCountryRegion(newdata)
                .then((resp: any) => {
                  let newrelated = {
                    regionid: res.data[x].related[0][i].regionid,
                    regiontype: res.data[x].related[0][i].regiontype,
                    _id: res.data[x].related[0][i]._id,
                    region: res.data[x].related[0][i].region,
                    country: resp.data,
                  };
                  relateddata.push(newrelated);
                })
                .catch((err: any) => {
                  console.error(err);
                });
            }
            // relateddata.sort((a:any,b:any)=>{a['region'].localeCompare(b['region'])})
            let newregiontype = {
              _id: res.data[x]._id,
              related: relateddata,
            };
            snewdata.push(newregiontype);
          }
        }
        setData(snewdata);
      })
      .catch((err: any) => {
        console.error(err);
      });
    setGetRegionLoading(false);
  }, []);

  useEffect(() => {
    const fetchCountryData = async () => {
      getAllCountry().then((res: any) => {
        if (res.data) {
          setCountryList(res.data);
        } else {
          setCountryList([]);
        }
      });
    };
    fetchCountryData();
  }, []);

  useEffect(() => {
    setIndeterminate(checked.length && checked.length !== data.length);
    setCheckAll(checked.length === data.length - 1);
  }, [checked]);

  const handleTokenExpired = () => {
    notification.warning({
      message: "Warning",
      description: "Your authorization has expired.Please login again.",
      placement: "top",
      duration: 3,
    });

    setTimeout(() => {
      navigate("/login", { replace: true });
    }, 3000); // delay navigation for 3 seconds
  };

  const refreshRegionType = () => {
    setGetRegionLoading(true);
    return new Promise((resolve: any, reject: any) => {
      getRegionType({})
        .then((res: any) => {
          let snewdata: any = [];
          if (res.data) {
            for (let x = 0; x < res.data.length; x++) {
              let relateddata: any = [];
              for (let i = 0; i < res.data[x].related[0].length; i++) {
                const newdata = {
                  regionid: res.data[x].related[0][i].regionid,
                  regiontype: res.data[x].related[0][i].regiontype,
                };
                getCountryRegion(newdata)
                  .then((resp: any) => {
                    let newrelated = {
                      regionid: res.data[x].related[0][i].regionid,
                      regiontype: res.data[x].related[0][i].regiontype,
                      _id: res.data[x].related[0][i]._id,
                      region: res.data[x].related[0][i].region,
                      country: resp.data,
                    };
                    relateddata.push(newrelated);
                  })
                  .catch((err: any) => {
                    console.error(err);
                  });
              }
              // relateddata.sort((a:any,b:any)=>{a.region.localeCompare(b.region)})
              let newregiontype = {
                _id: res.data[x]._id,
                related: relateddata,
              };
              snewdata.push(newregiontype);
            }
          }
          setData(snewdata);
          resolve(snewdata);
        })
        .catch((err: any) => {
          console.error(err);
        });

      setGetRegionLoading(false);
    });
  };

  const onCheckAllChange = (e: any) => {
    // setChecked(e.target.checked ? data.map((item:any) => item._id) : []);
    let newdata: any = [];
    data.map((item: any) => {
      if (item._id !== "DEFAULT") {
        return newdata.push(item);
      }
    });
    setChecked(e.target.checked ? newdata.map((item: any) => item._id) : []);
    setCheckAll(e.target.checked);
  };

  const selectregiontype = (data: any, e: any) => {
    setCreateRegionType(false);
    // setSelectRegion([])
    data.map((k: any) => {
      if (e === k._id) {
        setSelectRegion(k);
        setCountry([]);
        setNewRegionType([]);
        setChecked([]);
      }
    });
  };

  const handleSelectedCountry = (el: any) => {
    if (el) {
      getCountryRegion(el)
        .then((res: any) => {
          setCountry(res.data);
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };

  const addNewRegionType = () => {
    // setNewRegionType(!newRegionType)
    setCreateRegionType(!createRegionType);
    setNewRegionType([]);
    setSelectRegion([]);
    setCountry([]);
  };

  const handleAddMoreCountry = (el: any) => {
    addCountryRegion(el)
      .then((res: any) => {
        if (res) {
          getCountryRegion({
            regionid: el[0].regionid,
            regiontype: el[0].regiontype,
          })
            .then((res: any) => {
              setCountry(res.data);
              handleAlertType("Success");
              handleAlertMessage(`More Country has been added`);
            })
            .catch((err: any) => {
              handleAlertType("Alert");
              handleAlertMessage(`No Country has not been added`);
              console.error(err);
            });
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const handleNewRegionWithCountry = (country: any, region: any) => {
    addRegion(country, region)
      .then((res: any) => {
        handleAlertType("Success");
        handleAlertMessage(` ${region.length} region has been added`);
        if (res) {
          getSRegionType({ regiontype: region[0]["regiontype"] }).then(
            (resp: any) => {
              refreshRegionType();
              let updateres = {
                _id: resp.data[0]._id,
                related: resp.data[0].related[0],
              };
              setSelectRegion(updateres);
              setCountry([]);
              setNewRegionType([]);
            }
          );
        }
      })
      .catch((err: any) => {
        console.error(err);
        handleAlertType("Alert");
        handleAlertMessage(`Region ${region.region} has not been added`);
      });
  };

  const handleNewRegionType = (country: any, region: any) => {
    addRegion(country, region)
      .then((res: any) => {
        if (res) {
          handleAlertType("Success");
          handleAlertMessage(
            ` Region Type Name ${region[0].regiontype} has been created`
          );
          refreshRegionType();
          setSelectRegion([]);
          setCountry([]);
          setNewRegionType([]);
          setCreateRegionType(false);
        }
      })
      .catch((err: any) => {
        console.error(err);
        handleAlertType("Alert");
        handleAlertMessage(
          ` Region Type Name ${region[0].regiontype} fail to create`
        );
      });
  };

  const handleUpdateRegionType = (choose: any, regiontype: any) => {
    updateRegionType(choose, regiontype)
      .then((res: any) => {
        if (res) {
          handleAlertType("Success");
          handleAlertMessage(
            ` Region Type Name ${choose._id} has been changed to ${regiontype}`
          );
          refreshRegionType();
          setSelectRegion([]);
          setCountry([]);
          setNewRegionType([]);
        }
      })
      .catch((err: any) => {
        console.error(err);
        handleAlertType("Alert");
        handleAlertMessage(
          ` Region Type Name ${choose._id} fail to changed to ${regiontype}`
        );
      });
  };

  const handleUpdateRegionName = (regionitem: any, region: any) => {
    updateRegion(regionitem, region)
      .then((res: any) => {
        if (res) {
          handleAlertType("Success");
          handleAlertMessage(
            ` Region Type Name ${regionitem.region} has been changed to ${region}`
          );
          getSRegionType({ regiontype: regionitem["regiontype"] }).then(
            (resp: any) => {
              let updateres = {
                _id: resp.data[0]._id,
                related: resp.data[0].related[0],
              };
              setSelectRegion(updateres);
              setCountry([]);
              setNewRegionType([]);
            }
          );
        }
      })
      .catch((err: any) => {
        console.error(err);
        handleAlertType("Alert");
        handleAlertMessage(
          ` Region Type Name ${regionitem.region} fail to changed to ${region}`
        );
      });
  };

  const handleDeleteRegion = (regiontype: any, regionid: any) => {
    deleteMultiRegion(regiontype, regionid)
      .then((res: any) => {
        if (res) {
          handleAlertType("Success");
          handleAlertMessage(`${regionid.length} region has been deleted`);
          refreshRegionType().then((resp: any) => {
            if (resp) {
              setChecked([]);
              setShowDConfirm(false);
              setCreateRegionType(false);
              selectregiontype(resp, regiontype);
            }
          });
        }
      })
      .catch((err: any) => {
        console.error(err);
        handleAlertType("Alert");
        handleAlertMessage(`Delete function error`);
      });
  };

  const handleDeleteCountry = (
    regionid: any,
    regioncountry: any,
    regiontype: any
  ) => {
    deleteMultiCountry(regionid, regioncountry)
      .then((res: any) => {
        if (res) {
          handleAlertType("Success");
          handleAlertMessage(
            `${regioncountry.length} country has been deleted`
          );
          setChecked([]);
          setShowDConfirm(false);
          setCreateRegionType(false);
          refreshRegionType();
          let _d = {
            regionid: regionid,
            regiontype: regiontype,
          };
          handleSelectedCountry(_d);
        }
      })
      .catch((err: any) => {
        console.error(err);
        handleAlertType("Alert");
        handleAlertMessage(`Delete function error`);
      });
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  const closeRemoveAlert = () => {
    setShowDConfirm(false);
  };

  const handleAlertMessage = (el: any) => {
    setAlertMessage(el);
    setShowAlert(true);
    off();
  };

  const handleAlertType = (el: any) => {
    setAlertType(el);
    setShowAlert(true);
    off();
  };

  const off = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const removeRegionType = () => {
    setShowDConfirm(true);
  };

  const confirmDelete = () => {
    if (checked) {
      deleteMultiRegionType(checked).then((res: any) => {
        if (res.data) {
          handleAlertType("Success");
          handleAlertMessage(`${checked.length} region type has been deleted`);
          setChecked([]);
          setShowDConfirm(false);
          setCreateRegionType(false);
          refreshRegionType();
          selectregiontype(data, null);
        } else {
          handleAlertType("Alert");
          handleAlertMessage(`Delete function error`);
        }
      });
    }
  };

  const handleAddNDeleteCountry = (
    newCountryData: any,
    regionid: any,
    regioncountry: any,
    regiontype: any
  ) => {
    let alert = "";
    addCountryRegion(newCountryData)
      .then((res: any) => {
        if (res) {
          alert = alert + `The following country had been added.` + `\n`;
          for (let i = 0; i < newCountryData.length; i++) {
            alert = alert + (i + 1) + `.` + newCountryData[i].country + `\n`;
          }

          getCountryRegion({
            regionid: newCountryData[0].regionid,
            regiontype: newCountryData[0].regiontype,
          })
            .then((res: any) => {
              setCountry(res.data);
              deleteMultiCountry(regionid, regioncountry).then((resp: any) => {
                if (resp) {
                  alert =
                    alert + `The following country had been deleted.` + `\n`;
                  for (let k = 0; k < regioncountry.length; k++) {
                    alert = alert + (k + 1) + `.` + regioncountry[k] + `\n`;
                  }
                  handleAlertType("Success");
                  handleAlertMessage(`${alert}`);
                  setChecked([]);
                  setShowDConfirm(false);
                  setCreateRegionType(false);
                  refreshRegionType();
                  let _d = {
                    regionid: regionid,
                    regiontype: regiontype,
                  };
                  handleSelectedCountry(_d);
                }
              });
            })
            .catch((err: any) => {
              handleAlertType("Alert");
              handleAlertMessage(`No Country has not been added`);
              console.error(err);
            });
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const handleAddNDeleteRegion = (
    country: any,
    region: any,
    regiontype: any,
    regionid: any
  ) => {
    addRegion(country, region)
      .then((res: any) => {
        if (res) {
          deleteMultiRegion(regiontype, regionid)
            .then((respon: any) => {
              if (respon) {
                handleAlertType("Success");
                handleAlertMessage(
                  `${regionid.length} region has been deleted`
                );
                refreshRegionType().then((resp: any) => {
                  if (resp) {
                    setChecked([]);
                    setShowDConfirm(false);
                    setCreateRegionType(false);
                    selectregiontype(resp, regiontype);
                  }
                });
              }
            })
            .catch((err: any) => {
              console.error(err);
              handleAlertType("Alert");
              handleAlertMessage(`Delete function error`);
            });
          getSRegionType({ regiontype: region[0]["regiontype"] }).then(
            (resp: any) => {
              refreshRegionType();
              let updateres = {
                _id: resp.data[0]._id,
                related: resp.data[0].related[0],
              };
              setSelectRegion(updateres);
              setCountry([]);
              setNewRegionType([]);
            }
          );
        }
      })
      .catch((err: any) => {
        console.error(err);
        handleAlertType("Alert");
        handleAlertMessage(`Region ${region.region} has not been added`);
      });
  };

  const checkSelectRegion = (el: any) => {
    if (el === selectRegion._id) {
      return "underline";
    } else {
      return "none";
    }
  };

  return (
    <>
      <AlertModal
        show={showAlert}
        onClose={closeAlert}
        alertmessage={alertmessage}
        alerttype={alerttype}
        alertmsg
      />
      <AlertModal
        show={showDConfirm}
        onClose={closeRemoveAlert}
        data={checked}
        deleteDataHook={confirmDelete}
        multipleDelete
      />
      {/* <div
        style={{
          textAlign: "left",
          paddingLeft: "45px",
          fontWeight: "700",
          height: "40px",
          paddingTop: "10px",
          backgroundColor: "#66CDAA",
          borderBottom: "1px solid #000000",
        }}
      >
        Bespoke Region Configuration
      </div> */}
      <Space
        direction="horizontal"
        size="middle"
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          textAlign: "center",
          paddingBottom: "20px",
          gap: "16px",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "20% 80%",
            height: "100%",
          }}
        >
          <div
            style={{
              paddingLeft: "20px",
              height: "100%",
              display: "grid",
              gridTemplateColumns: "auto auto",
            }}
          >
            <div>
              <Card
                style={{
                  width: "20vw",
                  textAlign: "left",
                  height: "100%",
                  border: "0px solid #858585",
                }}
              >
                <Card
                  loading={getregionloading}
                  style={{ border: "0px solid rgba(0, 0, 0, 1)" }}
                  headStyle={{
                    color: "#000000",
                    borderBottom: "0px solid rgba(0, 0, 0, 1)",
                  }}
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}
                      >
                        <span style={{ fontWeight: "bold" }}>Region Types</span>
                      </Checkbox>
                      {checked.length > 0 && (
                        <Button
                          style={{
                            textAlign: "right",
                            backgroundColor: "transparent",
                            height: "20px",
                            border: "none",
                          }}
                          onClick={removeRegionType}
                          icon={
                            <MinusCircleOutlined style={{ color: "red" }} />
                          }
                        />
                      )}
                    </div>
                  }
                >
                  <Checkbox.Group
                    style={{ width: "100%", paddingLeft: "16px" }}
                    value={checked}
                    onChange={(checkedValues) => {
                      setChecked(checkedValues);
                    }}
                  >
                    <List
                      itemLayout="horizontal"
                      dataSource={data}
                      renderItem={(item: any) => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={
                              <Checkbox
                                disabled={item._id === "DEFAULT"}
                                value={item._id}
                              />
                            }
                            title={
                              <span
                                onClick={() => selectregiontype(data, item._id)}
                                style={{
                                  cursor: "pointer",
                                  textDecorationLine: checkSelectRegion(
                                    item._id
                                  ),
                                }}
                              >
                                {item._id}
                              </span>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </Checkbox.Group>
                  <Button
                    onClick={addNewRegionType}
                    style={{
                      backgroundColor: "transparent",
                      color: "rgba(44, 134, 218, 1)",
                      width: "100%",
                      border: "1px solid rgba(44, 134, 218, 1)",
                    }}
                  >
                    + Add new Region Type
                  </Button>
                </Card>
              </Card>
            </div>
            <div>
              <hr
                style={{
                  width: "1px",
                  minHeight: "88vh",
                  display: "flex",
                  margin: "auto",
                }}
              />
            </div>
          </div>
          {/* <hr style={{width: "1px" ,height: "88vh" ,display: "flex",margin:"auto"}}/> */}
          {!createRegionType && (
            <div style={{ paddingLeft: "100px", overflow: "none" }}>
              <EditBespokeRegionModal
                selectRegion={selectRegion}
                handleSelectedCountry={handleSelectedCountry}
                country={country}
                countrylist={countrylist}
                newRegionType={newRegionType}
                data={data}
                handleAddMoreCountry={handleAddMoreCountry}
                handleNewRegionWithCountry={handleNewRegionWithCountry}
                handleUpdateRegionType={handleUpdateRegionType}
                handleUpdateRegionName={handleUpdateRegionName}
                handleDeleteRegion={handleDeleteRegion}
                handleDeleteCountry={handleDeleteCountry}
                handleAddNDeleteCountry={handleAddNDeleteCountry}
                handleAddNDeleteRegion={handleAddNDeleteRegion}
              />
            </div>
          )}
          {createRegionType && (
            <div style={{ paddingLeft: "100px" }}>
              <CreateBespokeRegionModal
                country={country}
                countrylist={countrylist}
                newRegionType={newRegionType}
                handleNewRegionType={handleNewRegionType}
                createRegionType={createRegionType}
                data={data}
              />
            </div>
          )}
        </div>
      </Space>
    </>
  );
};

export default MyComponent;
