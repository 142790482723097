import React, { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Button,
  Checkbox,
  Modal,
  notification,
  Badge,
} from "antd";
import {
  DeleteFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  FileTextOutlined,
  BookFilled,
} from "@ant-design/icons";
import "../App.css";
import Header from "./RajangHeader";
import Footer from "./RajangFooter";
import {
  getAlltemplate,
  deleteMultiTemplate,
  getAllRICIndustry,
} from "../controller/industry_mapping";
import { useNavigate, useLocation } from "react-router-dom";
import Events from "../lib/events";
const { Sider } = Layout;
const { SubMenu } = Menu;

const LayoutComponent = (props: any) => {
  const [listRIC, setlistRIC]: any = useState([]);
  const [allRIC, setAllRIC] = useState([]);
  const [templateName, setTemplateName]: any = useState(null);
  const [templateCode, setTemplateCode]: any = useState(null);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [createMode, setCreateMode]: any = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedItems, setSelectedItems]: any = useState([]);

  const [selectedKeys, setSelectedKeys]: any = useState([]);
  const getbaseUrl = window.location.pathname;
  const [openKeys, setOpenKeys]: any = useState([]);
  const deleteConfirmation = {
    title: "Delete Industry Template Configuration",
    content:
      "Are you sure you want to delete these Industry Template Configuration?",
  };

  useEffect(() => {
    getAllRICIndustry()
      .then((res: any) => {
        // console.log("resdata", res.data);
        let groupedTemplate: any = {};
        res.data.forEach((item: any) => {
          const name = item.name;
          if (!groupedTemplate[name]) {
            groupedTemplate[name] = [];
          }

          groupedTemplate[name].push(item);
        });
        setlistRIC(groupedTemplate);
        setAllRIC(res.data);
        setSelectAll(false);
      })
      .catch((err: any) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    // console.log("list", listRIC);
  }, [listRIC]);

  useEffect(() => {
    refreshList();
    if (getbaseUrl !== "/industry_mapping") {
      // console.log("hohoh");
      setSelectedItems([]);
      setSelectAll(false);
      setIndeterminate(false);
    }
  }, [location]);

  const handleMenuOpenChange = (keys: any) => {
    setOpenKeys(keys);
  };

  const confirmDelete = () => {
    setSelectAll(false);
    setIndeterminate(false);
    let deleteIndustryMapping = { _id: selectedItems };
    deleteMultiTemplate(deleteIndustryMapping).then((res: any) => {
      if (res.data) {
        if (
          deleteIndustryMapping._id.forEach((name: any) => {
            notification.success({
              message: "Industry Template deleted!",
              description: (
                <>
                  Industry Template <strong>{name}</strong> has been deleted
                  successfully.
                </>
              ),
              placement: "bottomRight",
              bottom: 50,
            });
          })
        )
          setSelectedItems([]);
        // refreshList();
        navigate("/industry_mapping", {
          replace: false,
          state: {
            mode: null,
          },
        });
        refreshList();
      } else {
        notification.error({
          message: "Failed to delete Industry Template!",
          description: "Industry Template has not been deleted.",
          placement: "bottomRight",
          bottom: 50,
        });
      }
    });
  };

  const refreshList = () => {
    // getAlltemplate()
    //   .then((res: any) => {
    //     // console.log("resdata", res.data);
    //     // let groupedTemplate: any = {};
    //     // res.data.forEach((item: any) => {
    //     //   const name = item.name;
    //     //   if (!groupedTemplate[name]) {
    //     //     groupedTemplate[name] = [];
    //     //   }

    //     //   groupedTemplate[name].push(item);
    //     // });
    //     setlistRIC(res.data);
    //   })
    //   .catch((err: any) => {
    //     console.error(err);
    //   });
    getAllRICIndustry()
      .then((res: any) => {
        // console.log("resdata", res.data);
        let groupedTemplate: any = {};
        res.data.forEach((item: any) => {
          const name = item.name;
          if (!groupedTemplate[name]) {
            groupedTemplate[name] = [];
          }

          groupedTemplate[name].push(item);
        });
        setAllRIC(res.data);
        setlistRIC(groupedTemplate);
      })
      .catch((err: any) => {
        console.error(err);
      });
    if (location.state?.key) {
      setSelectedKeys(location.state.key);
    } else {
    }
  };

  const handleDelete = () => {
    // Perform delete operation based on the selectedItems array
    // console.log("selectedItems", selectedItems);
    if (selectedItems.length > 0) {
      Modal.confirm({
        ...deleteConfirmation,
        okText: "Delete",
        onOk() {
          confirmDelete();
        },
      });
    }
  };

  const hasFalseDraft = (items: any) => {
    return allRIC.some(
      (item: any) => item.name === items && item.draft === "false"
    );
  };

  // const handleCheckboxChange = (itemKey: any, itemName: any) => {
  //   setSelectedItems((prevSelectedItems: any) => {
  //     if (prevSelectedItems.includes(itemKey)) {
  //       return prevSelectedItems.filter((key: any) => key !== itemKey);
  //     } else {
  //       return [...prevSelectedItems, itemKey];
  //     }
  //   });
  //   setSelectedItemsName((prevSelectedItems: any) => {
  //     if (prevSelectedItems.includes(itemName)) {
  //       return prevSelectedItems.filter((key: any) => key !== itemName);
  //     } else {
  //       return [...prevSelectedItems, itemName];
  //     }
  //   });
  //   console.log("Object.keys(listRIC)?.length", Object.keys(listRIC)?.length);
  //   console.log("selectedItems.length", selectedItems.length);
  //   setIndeterminate(
  //     selectedItems.length > -1 &&
  //       selectedItems.length <= Object.keys(listRIC)?.length
  //   );
  //   // if (Object.keys(listRIC)?.length === selectedItems.length) {
  //   //   setSelectAll(true);
  //   // } else {
  //   //   setSelectAll(false); // Uncheck the "Select All" checkbox
  //   // }
  // };

  const handleCheckboxChange = (itemKey: any) => {
    setSelectedItems((prevSelectedItems: any) => {
      let updatedSelectedItems;
      if (prevSelectedItems.includes(itemKey)) {
        updatedSelectedItems = prevSelectedItems.filter(
          (key: any) => key !== itemKey
        );
      } else {
        updatedSelectedItems = [...prevSelectedItems, itemKey];
      }

      const indeterminate =
        updatedSelectedItems.length > 0 &&
        updatedSelectedItems.length < Object.keys(listRIC).length;

      // Calculate select all state based on selected items and total items
      const selectAll =
        updatedSelectedItems.length === Object.keys(listRIC).length;
      // Set select all and indeterminate states
      setSelectAll(selectAll);
      setIndeterminate(indeterminate);

      return updatedSelectedItems;
    });
  };

  useEffect(() => {}, [indeterminate, selectAll]);

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      const allItemKeys = Object.keys(listRIC);
      setSelectedItems(allItemKeys);
    }

    setSelectAll(!selectAll);
    setIndeterminate(false);
  };

  const items = [
    {
      key: "1",
      title: "Menu 1",
      label: (
        <span>
          <BookFilled style={{ marginRight: "5%", color: "green" }} />
          Industry Code Dictionary
        </span>
      ),
      onClick: (e: any) => {
        if (window.location.pathname === "/industry_mapping") {
          let changes: any = localStorage.getItem("industry_mapping");
          let selected: any = localStorage.getItem("template");
          if (changes || selected) {
            localStorage.setItem(
              "redirect",
              JSON.stringify({
                navigate: "industry_mapping",
                replace: false,
                state: { mode: "create", key: ["1"] },
              })
            );
            Events.emit("triggerClose", () => {});
            // e.preventDefault();
          } else {
            setSelectedKeys(["1"]);
            navigate("/industry_mapping", {
              replace: false,
              state: {
                mode: "create",
                key: ["1"],
              },
            });
            refreshList();
          }
        }
      },
    },
    {
      key: "2",
      title: "Menu 2",
      label: (
        <span
          onClick={(e: any) => {
            if (window.location.pathname === "/industry_mapping") {
              let changes: any = localStorage.getItem("industry_mapping");
              let selected: any = localStorage.getItem("template");
              if (changes || selected) {
                localStorage.setItem(
                  "redirect",
                  JSON.stringify({
                    navigate: "industry_mapping",
                    replace: false,
                    state: { mode: null, key: ["2"] },
                  })
                );
                Events.emit("triggerClose", () => {});
                // e.preventDefault();
              } else {
                setSelectedKeys(["2"]);
                navigate("/industry_mapping", {
                  replace: false,
                  state: {
                    mode: null,
                    key: ["2"],
                  },
                });
                refreshList();
              }
            }
          }}
        >
          <FileTextOutlined style={{ marginRight: "5%", color: "green" }} />
          Template Configuration
        </span>
      ),

      children: [
        {
          key: "selectAll",
          title: (
            <>
              <Checkbox
                checked={selectAll}
                indeterminate={indeterminate}
                onChange={handleSelectAllChange}
                style={{ marginLeft: "-8px" }}
              >
                Select All
              </Checkbox>
              {selectedItems?.length > 0 && (
                <DeleteFilled
                  onClick={handleDelete}
                  style={{ marginLeft: 8, cursor: "pointer", color: "red" }}
                />
              )}
            </>
          ),
          label: "Select All",
          onClick: () => {},
        },
        ...(listRIC
          ? Object.keys(listRIC).map((item: any) => ({
              key: item,
              title: (
                <>
                  <Badge
                    status={hasFalseDraft(item) ? "success" : "warning"}
                    style={{ marginRight: "5px" }}
                  />
                  {item}
                </>
              ),
              label: item,
              onClick: (e: any) => {
                if (window.location.pathname === "/industry_mapping") {
                  let changes: any = localStorage.getItem("industry_mapping");
                  let selected: any = localStorage.getItem("template");
                  if (changes || selected) {
                    let parsedData = JSON.parse(selected);
                    if (parsedData.template === item) {
                    } else {
                      localStorage.setItem(
                        "redirect",
                        JSON.stringify({
                          navigate: "industry_mapping",
                          replace: true,
                          state: {
                            _id: item,
                            data: allRIC.filter(
                              (items: any) => items.name === item
                            ),
                            name: item,
                            mode: "edittemplate",
                            key: [`${item}`],
                          },
                        })
                      );
                      Events.emit("triggerClose", () => {});
                    }
                  } else {
                    setSelectedKeys([`${item}`]);
                    setTemplateName(item);
                    navigate("/industry_mapping", {
                      replace: true,
                      state: {
                        _id: item,
                        data: allRIC.filter(
                          (items: any) => items.name === item
                        ),
                        name: item,
                        mode: "edittemplate",
                        key: [`${item}`],
                      },
                    });
                    refreshList();
                  }
                }
              },
              checkbox: (
                <Checkbox
                  checked={selectedItems.includes(item)}
                  onChange={() => handleCheckboxChange(item)}
                />
              ),
            }))
          : []),
        {
          key: 3,
          title: (
            <Button
              type="primary"
              // style={{ border: "2px solid red", borderRadius: "4px" }}
            >
              Add New Template
            </Button>
          ),
          label: (
            <Button
              type="primary"
              style={{ border: "2px solid red", borderRadius: "4px" }}
            >
              Add New Template
            </Button>
          ),
          onClick: (e: any) => {
            if (window.location.pathname === "/industry_mapping") {
              let changes: any = localStorage.getItem("industry_mapping");
              let selected: any = localStorage.getItem("template");
              let parsedData = JSON.parse(selected);
              if (changes || selected) {
                if (parsedData.template === null) {
                } else {
                  localStorage.setItem(
                    "redirect",
                    JSON.stringify({
                      navigate: "industry_mapping",
                      replace: false,
                      state: {
                        mode: "createtemplate",
                        key: ["3"],
                      },
                    })
                  );
                  Events.emit("triggerClose", () => {});
                }
              } else {
                setSelectedKeys(["3"]);
                navigate("/industry_mapping", {
                  replace: false,
                  state: {
                    mode: "createtemplate",
                    key: ["3"],
                  },
                });
                refreshList();
              }
            }
          },
        },
      ],
    },
  ];

  return (
    <Layout style={{ height: "100vh" }}>
      {!(getbaseUrl === "/login" || getbaseUrl === "/") && (
        <Layout.Header className="page-header">
          <Header />
        </Layout.Header>
      )}
      {getbaseUrl === "/bespoke_region_configuration" && (
        <div
          style={{
            textAlign: "left",
            paddingLeft: "45px",
            fontWeight: "700",
            height: "40px",
            paddingTop: "10px",
            backgroundColor: "#66CDAA",
            borderBottom: "1px solid #000000",
          }}
        >
          Bespoke Region Configuration
        </div>
      )}
      {getbaseUrl === "/industry_mapping" && (
        <div
          style={{
            textAlign: "left",
            paddingLeft: "27px",
            fontWeight: "700",
            height: "40px",
            paddingTop: "10px",
            backgroundColor: "#66CDAA",
            borderBottom: "1px solid #000000",
          }}
        >
          Industry Mapping
        </div>
      )}
      <Layout style={{ flex: 5 }}>
        {getbaseUrl === "/bespoke_region_configuration" ||
          (getbaseUrl === "/industry_mapping" && (
            <Layout.Sider width={250} collapsedWidth={0}>
              <Menu
                mode="inline"
                style={{
                  height: "88%",
                  borderRight: 0,
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
                defaultOpenKeys={["2"]}
                openKeys={openKeys}
                onOpenChange={handleMenuOpenChange}
                selectedKeys={selectedKeys}
              >
                {items.map((item: any) => {
                  if (item.children && item.children.length > 0) {
                    return (
                      <SubMenu key={item.key} title={item.label}>
                        {item.children.map((child: any) => (
                          <Menu.Item key={child.key} onClick={child.onClick}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {child.checkbox}
                              <span style={{ marginLeft: 8 }}>
                                {child.title}
                              </span>
                            </div>
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    );
                  } else {
                    return (
                      <Menu.Item key={item.key} onClick={item.onClick}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {item.checkbox}
                          <span style={{ marginLeft: 2 }}>{item.label}</span>
                        </div>
                      </Menu.Item>
                    );
                  }
                })}
              </Menu>
              {openKeys.includes("2") && (
                <div
                  style={{
                    paddingLeft: "10%",
                  }}
                >
                  <li style={{ color: "orange", fontSize: "16px" }}>
                    <span style={{ color: "black", fontSize: "12px" }}>
                      : Incomplete Industry Template
                    </span>
                  </li>
                  <li style={{ color: "green", fontSize: "16px" }}>
                    <span style={{ color: "black", fontSize: "12px" }}>
                      : Completed Industry Template
                    </span>
                  </li>
                </div>
              )}
            </Layout.Sider>
          ))}
        {getbaseUrl === "/industry_mapping" && (
          <Layout.Content className="body-content">
            {props.children}
          </Layout.Content>
        )}
        {getbaseUrl !== "/industry_mapping" && (
          <Layout.Content className="body-content-x">
            {props.children}
          </Layout.Content>
        )}
      </Layout>
      {/* <Layout.Footer className="page-footer">
        <Footer />
      </Layout.Footer> */}
    </Layout>
  );
};

export default LayoutComponent;
