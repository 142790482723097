import React, { useEffect, useState } from "react";
import { Result, Breadcrumb, notification } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import IndustryCodeDictionary from "../component/IndustryCodeDictionary";
import TemplateConfiguration from "../component/TemplateConfiguration";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  getFile,
  saveTemplate,
  updateRICindustry,
} from "../controller/industry_mapping";
import { addLog, checkmailProfile } from "../controller/profile";

const MyComponent = (props: any) => {
  const [mode, setMode]: any = useState(null);
  const [ric, setRic]: any = useState([]);
  const [data, setData]: any = useState([]);
  const [ricparent, setRicParent]: any = useState([]);
  const [ricchild, setRicChild]: any = useState([]);
  const location = useLocation();
  const [selecteddefaultindustrycode, setSeletedDefaultIndustryCode]: any =
    useState(null);
  const [templatedefaultName, settemplateDefaultName]: any = useState(null);
  const [templateid, settemplateDefaultID]: any = useState(null);
  const [breadcrumb, setBreadCrumb]: any = useState([]);
  const [subcode, setSubCode]: any = useState([]);
  const navigate = useNavigate();

  // useEffect(() => {
  //   let privilage: any = localStorage.getItem("privilage");
  //   let user: any = localStorage.getItem("user");
  //   let login: any = localStorage.getItem("isLogin");
  //   if (login === "true") {
  //     checkmailProfile({ email: user }).then((res: any) => {
  //       if (res === "You are unauthorized to use this resource") {
  //         setTimeout(() => {
  //           handleTokenExpired();
  //         }, 3000);
  //       } else if (res.data?.[0].role !== privilage) {
  //         localStorage.setItem("privilage", `${res.data[0].role}`);
  //       }
  //     });
  //   }
  // }, []);

  // useEffect(() => {
  //   let user: any = localStorage.getItem("user");
  //   let log: any = {
  //     page: window.location.pathname,
  //     action: "Read",
  //     datetime: new Date(),
  //     details: "-",
  //     email: user,
  //   };
  //   addLog(log);
  // }, []);

  const handleChange = () => {
    if (props.handleChange && typeof props.handleChange === "function") {
      props.handleChange(true);
    }
  };

  // const handleTokenExpired = () => {
  //   notification.warning({
  //     message: "Warning",
  //     description: "Your authorization has expired.Please login again.",
  //     placement: "top",
  //     duration: 3,
  //   });

  //   setTimeout(() => {
  //     navigate("/login", { replace: true });
  //   }, 3000); // delay navigation for 3 seconds
  // };

  useEffect(() => {
    if (location.state?.mode) {
      if (location.state?.mode === "create") {
        let user: any = localStorage.getItem("user");
        let log: any = {
          page: window.location.pathname,
          action: "Read",
          datetime: new Date(),
          details: "View Industry Code Dictionary",
          email: user,
        };
        addLog(log);
        setMode("code");
        setBreadCrumb([]);
      } else if (location.state?.mode === "edittemplate") {
        let user: any = localStorage.getItem("user");
        let log: any = {
          page: window.location.pathname,
          action: "Read",
          datetime: new Date(),
          details: `View Template${location.state.name}`,
          email: user,
        };
        addLog(log);
        setMode("template");
        setBreadCrumb(location.state.name);
        if (location.state.code) {
          setSeletedDefaultIndustryCode(location.state?.["code"]);
        } else {
          setSeletedDefaultIndustryCode(location.state?.data[0]["code"]);
        }

        settemplateDefaultName(location.state?.name);
        setSubCode(location.state?.data);
        // settemplateDefaultID(location.state._id);
        getFile().then((res: any) => {
          if (res.data) {
            if (location.state?.["code"]) {
              res.data.map((k: any, i: any) => {
                if (k._id === location.state?.["code"]) {
                  setRic(k);
                  setRicChild(k?.child);
                }
              });
            } else {
              res.data.map((k: any, i: any) => {
                if (k._id === location.state?.data[0]["code"]) {
                  setRic(k);
                  setRicChild(k?.child);
                }
              });
            }

            setData(res.data);
            const ricparents = res.data.map((x: any) => ({
              _label: x._id,
              value: x._id,
            }));
            setRicParent(ricparents);
          }
        });
      } else if (location.state?.mode === "createtemplate") {
        let user: any = localStorage.getItem("user");
        let log: any = {
          page: window.location.pathname,
          action: "Read",
          datetime: new Date(),
          details: "Add New Template",
          email: user,
        };
        addLog(log);
        setMode("template");
        setBreadCrumb("Add new template config");
        setSeletedDefaultIndustryCode(null);
        settemplateDefaultName(null);
      }
    } else {
      let user: any = localStorage.getItem("user");
      let log: any = {
        page: window.location.pathname,
        action: "Read",
        datetime: new Date(),
        details: "-",
        email: user,
      };
      addLog(log);
      setMode(null);
    }
  }, [location]);

  useEffect(() => {}, [mode]);

  const changeMode = (d: any) => {
    setMode(d);
  };

  return (
    <>
      {mode == null && (
        <Result
          style={{ marginTop: "10%" }}
          icon={<InboxOutlined style={{ color: "#d0d0d0" }} />}
          title={
            <span style={{ color: "#8c8c8c" }}>
              Please click on any Template Configuration to start
            </span>
          }
        />
      )}
      {mode === "code" && <IndustryCodeDictionary breadcrumb={breadcrumb} />}
      {mode === "template" && (
        <TemplateConfiguration
          breadcrumb={breadcrumb}
          ric={ric}
          data={data}
          ricchild={ricchild}
          selecteddefaultindustrycode={selecteddefaultindustrycode}
          templatedefaultName={templatedefaultName}
          templatedefaultId={templateid}
          subcode={subcode}
          changeMode={changeMode}
          handleChange={handleChange}
        />
      )}
    </>
  );
};

export default MyComponent;
