import React ,{useState}from 'react'

const MyComponent=(props:any)=>{
    const close=()=>{        
        props.onClose()
    }

    if(!props.show){
        return null
    }

    const renderRows:any = ()=>{
        return (
            <>
            {props.copySavedRegionType && 
                    <div>
                        {props.regionlist.map((d:any,index:any)=>{
                            return (
                                <React.Fragment key={d._id} >
                                    {renderRow(d,index)}
                                </React.Fragment>
                                
                            )
                        })}
                    </div>   
            }   
        </>
        )
    }

    const renderRow:any = (data:any,index:any)=>{
        const mRef:any = React.createRef()
            return (
                <>            
                {props.copySavedRegionType &&
                    <div key={data._id}>
                        <div onClick={()=>copySavedRT(data._id)} style={{paddingTop:"15px",borderRight:" 1px solid rgba(0, 0, 0, 0.4)",fontSize:"18px",textAlign:"left",paddingLeft:"15px",cursor:"pointer"}}>{data._id||""}</div>       
                    </div>
                }


                </>
    )}

    const copySavedRT =(el:any)=>{
        props.regionlist.map((k:any,i:any)=>{
            if(el===k._id){
                if(props.handleSelectedRegion&& typeof(props.handleSelectedRegion)=='function'){
                    props.handleSelectedRegion(k.related)
                    close()
                }
            }
        })
    }

    return (
        <>
        {props.copySavedRegionType && 
            <div className='regionmodel'>
                <div className='regionmodel-content'>
                    <div style={{backgroundColor:"#e9e5e3",borderBottom:"1px solid black"}}>
                        <div style={{display:"flex",justifyContent:"flex-end"}}><button onClick={close} className='open-modal-close-button'>X Close</button></div>
                        <div>Copy from saved Region Types</div>
                    </div>
                    <div style={{overflow:"auto"}}>
                        {renderRows()}
                    </div>
    
                </div>
            </div>
        }
        </>
    )
}

export default MyComponent