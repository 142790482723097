import React, { useState, useEffect } from "react";
import {
  Card,
  Input,
  Button,
  Space,
  List,
  Checkbox,
  Breadcrumb,
  notification,
} from "antd";
import AddNewRegionModel from "./AddNewRegionModel";
import AlertModal from "../component/AlertMessage";
import { MinusCircleOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import AddCountry from "./BRCAddCountry";
import { v4 as uuidv4 } from "uuid";

const MyComponent = (props: any) => {
  const [checked, setChecked]: any = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedREC, setCheckedREC]: any = useState([]);
  const [indeterminateREC, setIndeterminateREC] = useState(false);
  const [checkAllREC, setCheckAllREC] = useState(false);
  const [checkedAMC, setCheckedAMC]: any = useState([]);
  const [indeterminateAMC, setIndeterminateAMC] = useState(false);
  const [checkAllAMC, setCheckAllAMC] = useState(false);
  const [checkedANR, setCheckedANR]: any = useState([]);
  const [indeterminateANR, setIndeterminateANR] = useState(false);
  const [checkAllANR, setCheckAllANR] = useState(false);
  const [regionName, setRegionName]: any = useState(null);
  const [tempregionName, setTempRegionName]: any = useState(null);
  const [regionType, setRegionType]: any = useState(null);
  const [tempregionType, setTempRegionType]: any = useState(null);
  const [createNewRegionType, setCreateNewRegionType] = useState(false);
  const [createRegionType, setcreateRegionType]: any = useState([]);
  const [NRTaddregion, setNRTAddRegion]: any = useState(false);
  const [NRTeditregion, setNRTEditRegion]: any = useState(false);
  const [NRTaddcountry, setNRTAddCountry]: any = useState(false);
  const [showCRTModal, setShowCRTModal] = useState(false);
  const [country, setCountry]: any = useState([]);
  const [showDConfirm, setShowDConfirm] = useState(false);
  const [showRConfirm, setShowRConfirm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertmessage, setAlertMessage] = useState("");
  const [alerttype, setAlertType] = useState("");
  const [breadcrumb, setBreadCrumb]: any = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [existcountry, setExistCountry]: any = useState([]);

  useEffect(() => {
    if (props.newRegionType) {
      setCreateNewRegionType(true);
      setChecked([]);
      setCheckedAMC([]);
      setCheckedANR([]);
      setCheckedREC([]);
      setcreateRegionType([]);
      setRegionType(null);
      setTempRegionType(null);
    }
  }, [props.newRegionType]);

  useEffect(() => {
    if (createRegionType?.length > 0) {
      setIndeterminate(
        checked.length && checked.length !== createRegionType.length
      );
      setCheckAll(checked.length === createRegionType.length);
    }
  }, [checked]);

  const onCheckAllChange = (e: any) => {
    setChecked(
      e.target.checked ? createRegionType.map((item: any) => item.region) : []
    );
    setCheckAll(e.target.checked);
  };

  useEffect(() => {
    if (country.country?.length > 0) {
      setIndeterminateREC(
        checkedREC.length && checkedREC.length !== country.country.length
      );
      setCheckAllREC(checkedREC.length === country.country.length);
    }
  }, [checkedREC]);

  const onCheckAllRECChange = (e: any) => {
    setCheckedREC(
      e.target.checked ? country.country.map((item: any) => item.country) : []
    );
    setCheckAllREC(e.target.checked);
    setIndeterminateREC(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (props.countrylist?.length > 0) {
      setIndeterminateAMC(
        checkedAMC.length && checkedAMC.length !== props.countrylist.length
      );
      setCheckAllAMC(checkedAMC.length === props.countrylist.length);
    }
  }, [checkedAMC]);

  const onCheckAllAMCChange = (e: any) => {
    setCheckedAMC(
      e.target.checked ? props.countrylist.map((item: any) => item.name) : []
    );
    setCheckAllAMC(e.target.checked);
    setIndeterminateAMC(false);
  };

  useEffect(() => {
    if (props.countrylist?.length > 0) {
      setIndeterminateANR(
        checkedANR.length && checkedANR.length !== props.countrylist.length
      );
      setCheckAllANR(checkedANR.length === props.countrylist.length);
    }
  }, [checkedANR]);

  const onCheckAllANRChange = (e: any) => {
    setCheckedANR(
      e.target.checked ? props.countrylist.map((item: any) => item.name) : []
    );
    setCheckAllANR(e.target.checked);
    setIndeterminateANR(false);
  };

  const copyRegionType = () => {
    if (regionType === null || regionType.trim().length === 0) {
      handleAlertType("Alert");
      handleAlertMessage(`Please key in a Region Type name before proceed`);
    } else {
      setShowCRTModal(true);
    }
  };

  const addNewRegion = () => {
    if (regionType === null || regionType.trim().length === 0) {
      handleAlertType("Alert");
      handleAlertMessage(`Please key in a Region Type name before proceed`);
    } else {
      setBreadCrumb([breadcrumb[0], "Add new Region"]);
      setNRTAddRegion(true);
    }
  };

  const CancelAddNRT = () => {
    setCreateNewRegionType(false);
    setBreadCrumb([]);
    setRegionType(null);
    setTempRegionType(null);
    setRegionName(null);
    setTempRegionName(null);
    setcreateRegionType([]);
    setChecked([]);
    setCheckedAMC([]);
    setCheckedANR([]);
    setCheckedREC([]);
    setNRTAddRegion(false);
    setNRTAddCountry(false);
  };

  const NewRTcreatenewRegion = () => {
    setBreadCrumb([breadcrumb[0], "Add new Region"]);
    setRegionName(null);
    setTempRegionName(null);
    setNRTAddRegion(true);
    setChecked([]);
    setCheckedAMC([]);
    setCheckedANR([]);
    setCheckedREC([]);
  };

  const NRTaddRegion = () => {
    if (regionName !== null && checkedANR.length > 0) {
      // let newL = createRegionType.length * 10;
      const id = uuidv4();
      let newCountryData: any = [];
      checkedANR.map((k: any) => {
        const newCD = {
          country: k,
          countrycode: null,
          regionid: id,
          regiontype: regionType,
        };
        newCountryData.push(newCD);
      });
      let newSS: any = {
        region: regionName,
        regionid: id,
        regionType: regionType,
        country: newCountryData,
      };
      createRegionType.push(newSS);
      setNRTAddRegion(false);
      setChecked([]);
      setCheckedAMC([]);
      setCheckedANR([]);
      setCheckedREC([]);
      setBreadCrumb([breadcrumb[0], "Regions"]);
    } else if (checkedANR.length === 0) {
      handleAlertType("Alert");
      handleAlertMessage(`At least 1 Country needed with a Region`);
    } else {
      handleAlertType("Alert");
      handleAlertMessage(`Please key in a Region Name before proceed`);
    }
  };

  const selectNRTregion = (el: any) => {
    setBreadCrumb([breadcrumb[0], el.region]);
    setCountry(el);
    let newcountry: any = [];
    el.country.map((d: any) => {
      newcountry.push(d.country);
    });
    setExistCountry(newcountry);
    setNRTAddRegion(false);
    setNRTEditRegion(true);
  };

  const handleAlertMessage = (el: any) => {
    setAlertMessage(el);
    setShowAlert(true);
    off();
  };

  const handleAlertType = (el: any) => {
    setAlertType(el);
    setShowAlert(true);
    off();
  };

  const off = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const backFromArrayRegionCountry = () => {
    if (createRegionType.length > 0) {
      setBreadCrumb([breadcrumb[0], "Regions"]);
    } else {
      setBreadCrumb(breadcrumb.slice(0, 1));
    }

    setNRTAddRegion(false);
    setNRTEditRegion(false);
    setChecked([]);
    setCheckedAMC([]);
    setCheckedANR([]);
    setCheckedREC([]);
    setNRTAddCountry(false);
  };

  const backToRegionCountry = () => {
    setNRTAddCountry(false);
    setNRTEditRegion(true);
    setChecked([]);
    setCheckedAMC([]);
    setCheckedANR([]);
    setCheckedREC([]);
    setBreadCrumb(breadcrumb.slice(0, 2));
  };

  const controlButtRegionName: any = () => {
    if (regionName === null || regionName.trim().length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const controlButtRegionType: any = () => {
    if (regionType === null || regionType.trim().length == 0) {
      return true;
    } else {
      return false;
    }
  };

  const uniqueCountry: any = (el: any) => {
    el.map((d: any) => {
      country.country.map((k: any) => {
        if (d === k.country) {
          el.splice(d, el.length);
        }
      });
    });
    return el;
  };

  const NRTaddCountry = () => {
    if (uniqueCountry(checkedAMC).length === 0) {
      handleAlertType("Alert");
      handleAlertMessage(`Duplicate country cannot be added`);
      setCheckAll(false);
      setCheckAllAMC(false);
      setCheckAllANR(false);
      setCheckAllREC(false);
      setChecked([]);
      setCheckedAMC([]);
      setCheckedANR([]);
      setCheckedREC([]);
    } else {
      // let newL = (createRegionType.length - 1) * 10;
      const id = uuidv4();
      uniqueCountry(checkedAMC).map((k: any) => {
        const newCD = {
          country: k,
          countrycode: null,
          regionid: id,
          regiontype: regionType,
        };
        country.country.push(newCD);
      });
      setNRTEditRegion(true);
      setNRTAddCountry(false);
      setChecked([]);
      setCheckedAMC([]);
      setCheckedANR([]);
      setCheckedREC([]);
      setBreadCrumb(breadcrumb.slice(0, 2));
    }
  };

  const handleCountry = (el: any) => {
    let newarr: any = [];
    // let newL = (createRegionType.length - 1) * 10;
    const id = uuidv4();
    el.map((name: any) => {
      let newel = {
        regiontype: regionType,
        regionid: id,
        country: name,
        countrycode: null,
      };

      country.country.push(newel);
    });
    setExistCountry([...existcountry, ...el]);
  };

  const addNewCountry = () => {
    // setBreadCrumb([...breadcrumb, "Add new Country"]);
    // setNRTAddCountry(true);
    // setNRTEditRegion(false);
    setIsModalOpen(true);
  };

  const saveNewRegionType = () => {
    const latest_data = [...props.data];
    const index = latest_data.findIndex(
      (item: any) => item._id === tempregionType
    );
    if (index !== -1) {
      latest_data.splice(index, 1);
    }
    const newIndex = latest_data.findIndex(
      (item: any) => item._id === regionType
    );
    if (newIndex === -1) {
      let newRegionData: any = [];
      let newCountryData: any = [];
      for (let i = 0; i < createRegionType.length; i++) {
        const newRD = {
          regionid: createRegionType[i]?.regionid,
          region: createRegionType[i]?.region,
          regiontype: regionType,
        };
        newRegionData.push(newRD);
        for (let x = 0; x < createRegionType[i].country.length; x++) {
          const newCD = {
            country: createRegionType[i].country[x].country,
            countrycode: createRegionType[i].country[x].countrycode || null,
            regionid: createRegionType[i].country[x].regionid,
            regiontype: regionType,
          };
          newCountryData.push(newCD);
        }
      }
      if (
        props.handleNewRegionType &&
        typeof props.handleNewRegionType == "function"
      ) {
        props.handleNewRegionType(newCountryData, newRegionData);
      }
    } else {
      notification.warning({
        message: "Warning",
        description: (
          <>
            <strong>{regionType}</strong> already exists. Please try different
            Region Type.
          </>
        ),
        placement: "bottomRight",
        bottom: 50,
      });
    }
  };

  const closeRemoveAlert = () => {
    setShowDConfirm(false);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  const closeModal = () => {
    setShowCRTModal(false);
  };

  const handleSelectedRegion = (el: any) => {
    setcreateRegionType(el);
    setBreadCrumb([...breadcrumb, "Regions"]);
  };

  const removeFromArray = (ori: any, remove: any) => {
    return ori.filter((value: any) => !remove.includes(value.country));
  };

  const removeFromRegionArray = (ori: any, remove: any) => {
    return ori.filter((value: any) => !remove.includes(value.region));
  };

  const removeRegion = () => {
    setShowRConfirm(true);
  };

  const closeRRemoveAlert = () => {
    setShowRConfirm(false);
  };

  const confirmDelete = () => {
    closeRemoveAlert();
    createRegionType.map((k: any, index: any) => {
      if (country.region === k.region) {
        if (checkedREC.length === createRegionType[index].country.length) {
          handleAlertType("Alert");
          handleAlertMessage(`At least 1 Country needed with a Region`);
        } else if (checkedREC.length < createRegionType[index].country.length) {
          handleAlertType("Success");
          handleAlertMessage(`${checkedREC.length} country has been deleted`);
          return (createRegionType[index].country = removeFromArray(
            createRegionType[index].country,
            checkedREC
          ).slice(0));
        }
      }
    });
    setChecked([]);
  };

  const confirmRDelete = () => {
    closeRRemoveAlert();
    if (checked.length === createRegionType.length) {
      handleAlertType("Alert");
      handleAlertMessage(`At least 1 Region needed with a Region Type`);
    } else if (checked.length < createRegionType.length) {
      handleAlertType("Success");
      handleAlertMessage(`${checked.length} region has been deleted`);
      return createRegionType.splice(
        0,
        createRegionType.length,
        ...removeFromRegionArray(createRegionType, checked)
      );
    }
    setChecked([]);
    setCheckedAMC([]);
    setCheckedANR([]);
    setCheckedREC([]);
  };

  const removeCountry = () => {
    setShowDConfirm(true);
  };

  const breadcrumbCOM = (el: any) => {
    if (el) {
      return el.map((d: any, index: any) => {
        return (
          <React.Fragment key={d._id}>{ComRenderRow(d, index)}</React.Fragment>
        );
      });
    }
  };

  const ComRenderRow = (d: any, index: any) => {
    if (d) {
      if (index === 0) {
        return (
          <>
            <Breadcrumb.Item>
              <a onClick={() => backFromArrayRegionCountry()}>{d}</a>
            </Breadcrumb.Item>
          </>
        );
      } else if (index === 1) {
        return (
          <>
            <Breadcrumb.Item>
              <a onClick={() => backToRegionCountry()}>{d}</a>
            </Breadcrumb.Item>
          </>
        );
      } else {
        return (
          <>
            <Breadcrumb.Item>
              <a>{d}</a>
            </Breadcrumb.Item>
          </>
        );
      }
    } else {
      return <></>;
    }
  };

  const filteredArray = props.countrylist.filter((item: any) => {
    return item.name.toLowerCase().includes(searchValue.toLowerCase());
  });

  return (
    <>
      {isModalOpen && (
        <>
          <AddCountry
            open={isModalOpen}
            handleOk={handleOk}
            handleCancel={handleCancel}
            country={props.countrylist}
            name={regionName}
            existCountry={existcountry}
            handleCountry={handleCountry}
          />
        </>
      )}
      <AlertModal
        show={showAlert}
        onClose={closeAlert}
        alertmessage={alertmessage}
        alerttype={alerttype}
        alertmsg
      />
      <AlertModal
        show={showDConfirm}
        onClose={closeRemoveAlert}
        data={checkedREC}
        deleteDataHook={confirmDelete}
        multipleDelete
      />
      <AlertModal
        show={showRConfirm}
        onClose={closeRRemoveAlert}
        data={checked}
        deleteDataHook={confirmRDelete}
        multipleDelete
      />
      {showCRTModal && (
        <AddNewRegionModel
          onClose={closeModal}
          show={showCRTModal}
          regionlist={props.data}
          handleSelectedRegion={handleSelectedRegion}
          copySavedRegionType
        />
      )}
      <div
        style={{
          height: "10%",
          margin: "auto",
          paddingTop: "10px",
          paddingLeft: "50px",
        }}
      >
        <Breadcrumb separator=">">{breadcrumbCOM(breadcrumb)}</Breadcrumb>
      </div>
      {createNewRegionType && (
        <Card
          style={{
            width: "50vw",
            margin: "auto",
            border: "0px solid rgba(0, 0, 0, 1)",
          }}
          headStyle={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            color: "#000000",
          }}
        >
          {createNewRegionType &&
            !NRTaddregion &&
            !NRTeditregion &&
            !NRTaddcountry && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "20% 80%",
                  textAlign: "left",
                  paddingBottom: "10px",
                }}
              >
                <span style={{ fontWeight: "700" }}>Region type</span>
                <Input
                  size="middle"
                  placeholder="Enter region type"
                  value={regionType || ""}
                  onChange={(e: any) => {
                    setRegionType(e.target.value);
                    setBreadCrumb([e.target.value, ...breadcrumb.slice(1)]);
                  }}
                  style={{
                    border: "1px solid rgb(220,220,220)",
                  }}
                />
              </div>
            )}

          {createNewRegionType &&
            createRegionType.length === 0 &&
            !NRTaddregion &&
            !NRTeditregion &&
            !NRTaddcountry && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "20% 80%",
                  textAlign: "left",
                  paddingBottom: "10px",
                }}
              >
                <span style={{ fontWeight: "700" }}>Region</span>
                <Card
                  style={{
                    border: "1px solid rgb(220,220,220)",
                    textAlign: "center",
                  }}
                >
                  <Space direction="vertical" size="middle">
                    <Button
                      onClick={copyRegionType}
                      style={{
                        backgroundColor: "transparent",
                        color: "rgba(44, 134, 218, 1)",
                        width: "100%",
                        border: "1px solid rgba(44, 134, 218, 1)",
                        margin: "auto",
                      }}
                    >
                      Copy from saved Region Types
                    </Button>
                    <Button
                      onClick={addNewRegion}
                      style={{
                        backgroundColor: "transparent",
                        color: "rgba(44, 134, 218, 1)",
                        width: "100%",
                        border: "1px solid rgba(44, 134, 218, 1)",
                        margin: "auto",
                      }}
                    >
                      Add new Region
                    </Button>
                  </Space>
                </Card>
              </div>
            )}
          {createNewRegionType &&
            createRegionType.length > 0 &&
            !NRTaddregion &&
            !NRTeditregion &&
            !NRTaddcountry && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "20% 80%",
                  textAlign: "left",
                  paddingBottom: "10px",
                }}
              >
                <span style={{ fontWeight: "700" }}>Regions</span>
                <Card
                  style={{
                    textAlign: "left",
                    border: "1px solid rgb(220,220,220)",
                  }}
                  headStyle={{
                    color: "#000000",
                    borderBottom: "1px solid rgb(220,220,220)",
                  }}
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}
                      >
                        Select all
                      </Checkbox>
                      {checked.length > 0 && (
                        <Button
                          style={{
                            textAlign: "right",
                            backgroundColor: "transparent",
                            height: "20px",
                            border: "none",
                          }}
                          onClick={removeRegion}
                          icon={
                            <MinusCircleOutlined style={{ color: "red" }} />
                          }
                        />
                      )}
                    </div>
                  }
                >
                  <Checkbox.Group
                    style={{
                      width: "100%",
                      maxHeight: "400px",
                      overflow: "auto",
                      textAlign: "left",
                    }}
                    value={checked}
                    onChange={(checkedValues) => {
                      setChecked(checkedValues);
                    }}
                  >
                    <List
                      itemLayout="horizontal"
                      dataSource={createRegionType}
                      renderItem={(item: any) => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={<Checkbox value={item.region} />}
                            title={
                              <span
                                onClick={() => selectNRTregion(item)}
                                style={{ cursor: "pointer" }}
                              >
                                {item.region}
                              </span>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </Checkbox.Group>
                  <Button
                    onClick={NewRTcreatenewRegion}
                    style={{
                      backgroundColor: "rgba(44, 134, 218, 1)",
                      color: "white",
                      width: "100%",
                    }}
                  >
                    + Add new Region
                  </Button>
                </Card>
              </div>
            )}
          {createNewRegionType && NRTaddregion && (
            <Card>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "20% 80%",
                  textAlign: "left",
                  paddingBottom: "10px",
                }}
              >
                <span>Region Name</span>
                <Input
                  type="text"
                  size="middle"
                  placeholder="Enter region name"
                  style={{ border: "1px solid rgb(220,220,220)" }}
                  onChange={(e: any) => {
                    setRegionName(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "20% 80%",
                  textAlign: "left",
                  paddingBottom: "10px",
                }}
              >
                <span>List of countries</span>

                <Card
                  style={{
                    textAlign: "left",
                    border: "1px solid rgb(220,220,220)",
                  }}
                  headStyle={{
                    borderBottom: "1px solid rgb(220,220,220)",
                  }}
                  title={
                    <Input
                      type="text"
                      defaultValue={""}
                      placeholder={`Search country`}
                      prefix={<SearchOutlined />}
                      onChange={(ev: any) => {
                        setSearchValue(ev.target.value);
                      }}
                      allowClear
                    />
                  }
                >
                  <Checkbox
                    indeterminate={indeterminateANR}
                    onChange={onCheckAllANRChange}
                    checked={checkAllANR}
                  >
                    Select all countries
                  </Checkbox>
                  <Checkbox.Group
                    style={{
                      width: "100%",
                      maxHeight: "400px",
                      overflow: "auto",
                    }}
                    value={checkedANR}
                    onChange={(checkedValues) => {
                      setCheckedANR(checkedValues);
                    }}
                  >
                    <List
                      itemLayout="horizontal"
                      dataSource={filteredArray}
                      renderItem={(item: any) => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={<Checkbox value={item.name} />}
                            title={item.name}
                          />
                        </List.Item>
                      )}
                    />
                  </Checkbox.Group>
                  <Space
                    direction="horizontal"
                    size="middle"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={backFromArrayRegionCountry}
                      style={{ border: "none" }}
                    >
                      <u>Cancel</u>
                    </Button>
                    <Button
                      onClick={NRTaddRegion}
                      style={{
                        backgroundColor: "rgba(44, 134, 218, 1)",
                        color: "white",
                        width: "100%",
                      }}
                    >
                      Save
                    </Button>
                  </Space>
                </Card>
              </div>
            </Card>
          )}
          {createNewRegionType && NRTeditregion && (
            <Card
              style={{ textAlign: "left" }}
              headStyle={{
                backgroundColor: "rgba(217, 217, 217, 1)",
                color: "#000000",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "20% 80%",
                  textAlign: "left",
                  paddingBottom: "10px",
                }}
              >
                <span>Region Name</span>
                <Input
                  type="text"
                  size="middle"
                  value={country.region || ""}
                  placeholder="Enter region type"
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "20% 80%",
                  textAlign: "left",
                  paddingBottom: "10px",
                }}
              >
                <span style={{ fontWeight: "700" }}>List of countries</span>
                <Card
                  style={{
                    textAlign: "left",
                    border: "1px solid rgb(220,220,220)",
                  }}
                  headStyle={{
                    color: "#000000",
                    borderBottom: "1px solid rgb(220,220,220)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Checkbox
                      indeterminate={indeterminateREC}
                      onChange={onCheckAllRECChange}
                      checked={checkAllREC}
                    >
                      Select all
                    </Checkbox>
                    {checkedREC.length > 0 && (
                      <Button
                        style={{
                          textAlign: "right",
                          backgroundColor: "transparent",
                          height: "20px",
                          border: "none",
                        }}
                        onClick={removeCountry}
                        icon={<MinusCircleOutlined style={{ color: "red" }} />}
                      />
                    )}
                  </div>
                  <Checkbox.Group
                    style={{
                      width: "100%",
                      maxHeight: "400px",
                      overflow: "auto",
                    }}
                    value={checkedREC}
                    onChange={(checkedValues) => {
                      setCheckedREC(checkedValues);
                    }}
                  >
                    <List
                      itemLayout="horizontal"
                      dataSource={country.country}
                      renderItem={(item: any) => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={<Checkbox value={item.country} />}
                            title={item.country}
                          />
                        </List.Item>
                      )}
                    />
                  </Checkbox.Group>
                  <Button
                    onClick={addNewCountry}
                    style={{
                      backgroundColor: "rgba(44, 134, 218, 1)",
                      color: "white",
                      width: "100%",
                    }}
                  >
                    + Add new Country
                  </Button>
                </Card>
              </div>
            </Card>
          )}
          {createNewRegionType && NRTaddcountry && (
            <Card>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "20% 80%",
                  textAlign: "left",
                  paddingBottom: "10px",
                }}
              >
                <span>Region Name</span>
                <Input
                  type="text"
                  size="middle"
                  value={country.region || ""}
                  placeholder="Enter region type"
                />
              </div>
              <Card
                style={{
                  textAlign: "left",
                  border: "1px solid rgba(0, 0, 0, 1)",
                }}
                headStyle={{
                  backgroundColor: "rgba(217, 217, 217, 1)",
                  color: "#000000",
                  borderBottom: "1px solid rgba(0, 0, 0, 1)",
                }}
                title={
                  <Checkbox
                    indeterminate={indeterminateAMC}
                    onChange={onCheckAllAMCChange}
                    checked={checkAllAMC}
                  >
                    List of Countries
                  </Checkbox>
                }
              >
                <Checkbox.Group
                  style={{
                    width: "100%",
                    maxHeight: "400px",
                    overflow: "auto",
                  }}
                  value={checkedAMC}
                  onChange={(checkedValues) => {
                    setCheckedAMC(checkedValues);
                  }}
                >
                  <List
                    itemLayout="horizontal"
                    dataSource={props.countrylist}
                    renderItem={(item: any) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<Checkbox value={item.name} />}
                          title={item.name}
                        />
                      </List.Item>
                    )}
                  />
                </Checkbox.Group>
                <Space
                  direction="horizontal"
                  size="middle"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={backToRegionCountry}
                    style={{ border: "none" }}
                  >
                    <u>Cancel</u>
                  </Button>
                  <Button
                    onClick={NRTaddCountry}
                    style={{
                      backgroundColor: "rgba(44, 134, 218, 1)",
                      color: "white",
                      width: "100%",
                    }}
                  >
                    Save
                  </Button>
                </Space>
              </Card>
            </Card>
          )}
          {createNewRegionType && !NRTaddregion && (
            <Space
              direction="horizontal"
              size="middle"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button onClick={CancelAddNRT} style={{ border: "none" }}>
                <u>Cancel</u>
              </Button>
              <Button
                onClick={saveNewRegionType}
                disabled={controlButtRegionType()}
                style={{
                  backgroundColor: "rgba(44, 134, 218, 1)",
                  color: "white",
                  width: "100%",
                }}
              >
                Save
              </Button>
            </Space>
          )}
        </Card>
      )}
    </>
  );
};

export default MyComponent;
