import axios from "axios";
import { AuthHeader } from "./header";
const url = process.env.REACT_APP_SERVER_URL as string;

const createurl: any = url + "/config/create";
export const createNewConfig = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(createurl, searchObj, header)
      .then((res: any) => {
        // console.log("res",res)
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        // console.log("error",err)
        console.error(err);
        reject(err);
      });
  });
};

const loadurl: any = url + "/config";
export const readAllConfig = (searchObj: any = undefined) => {
  return new Promise((resolve: any, reject: any) => {
    // console.log('s',searchObj)
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .get(loadurl, header)
      .then((res: any) => {
        // console.log("res",res)
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        // console.log("error",err)
        console.error(err);
        reject(err);
      });
  });
};

const updateurl: string = url + "/config";
export const updateConfig = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .put(updateurl, searchObj, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.log("error", err);
        console.error(err);
        reject(err);
      });
  });
};

const deleteurl: string = url + "/config/deleteMany";
export const deleteConfig = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(deleteurl, searchObj, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.log("error", err);
        console.error(err);
        reject(err);
      });
  });
};
