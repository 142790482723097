import React, { useState } from "react";
import { Button, Form, Input, notification } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import AlertModal from "../component/AlertMessage";
import { resetPasswordProfile } from "../controller/profile";

const MyComponent = (props: any) => {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [registerData, setRegisterData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertmessage, setAlertMessage] = useState("");
  const [alerttype, setAlertType] = useState("");
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (evt: any) => {
    let _data: any = { ...registerData };
    if (evt.target.value.trim().length > 0) {
      _data[evt.target.name] = evt.target.value;
      setRegisterData(_data);
    }
  };

  const validatePassword = (password: any) => {
    if (password.length < 8) {
      return false;
    }
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/;
    return regex.test(password);
  };

  const submitRegister = () => {
    let _data: any = { ...registerData };

    if (validatePassword(_data["password"]) === false) {
      handleAlertType("Alert");
      handleAlertMessage(
        `Password must contain minimum of 8 characters and must have a combination of one capital letter, small letters and numbers.`
      );
    } else if (_data["password"] !== _data["confirmpassword"]) {
      handleAlertType("Alert");
      handleAlertMessage(
        `Your password and confirmation password do not match`
      );
    } else {
      _data["randomcode"] = searchParams.get("id");
      resetPasswordProfile(_data).then((res: any) => {
        if (res.data) {
          handleAlertType("Password has been reset");
          handleAlertMessage(
            `Your password has successfully being reset. Please login with the new password.`
          );
          setTimeout(() => {
            navigate("/login", { replace: true });
          }, 2000);
        }
      });
    }
  };

  const handleAlertMessage = (el: any) => {
    setAlertMessage(el);
    setShowAlert(true);
    off();
  };

  const handleAlertType = (el: any) => {
    setAlertType(el);
    setShowAlert(true);
    off();
  };

  const off = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  const closeAlert = () => {
    setAlertType("");
    setAlertMessage("");
    setShowAlert(false);
  };

  const formdata = [
    {
      key: "password",
      name: "password",
      label: (
        <>
          <span style={{ marginRight: "55px" }}>Password</span>
        </>
      ),
      labelCol: { style: { fontWeight: "600", paddingRight: "5px" } },
      rules: [
        { required: true, message: "Please enter your password" },
        // { min: 8, message: "Password must be at least 8 characters!" },
        {
          validator: (rule: any, value: any) => {
            if (
              value !== undefined &&
              !value.match(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/
              )
            ) {
              return Promise.reject(
                "Password must contain minimum 8 characters including one uppercase, one lowercase, one number and one special character."
              );
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      validateTrigger: "onBlur",
      component: (
        // <div className="input-form">
        //   <label className="label-form">Password</label>
        <Input.Password placeholder="Please Enter New Password" />
        // </div>
      ),
    },
    {
      key: "confirmPassword",
      name: "confirmPassword",
      label: (
        <>
          <span>Confirm Password</span>
        </>
      ),
      labelCol: { style: { fontWeight: "600", paddingRight: "5px" } },
      rules: [
        { required: true, message: "" },
        {
          // validate the password and confirm password
          validator: (rule: any, value: any) => {
            if (
              value !== undefined &&
              value !== form.getFieldsValue().password
            ) {
              return Promise.reject("Password does not match");
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      validateTrigger: "onBlur",
      component: (
        // <div className="input-form">
        //   <label className="label-form">Confirm Password</label>
        <Input.Password placeholder="Please Confirm Password" />
        // </div>
      ),
    },
  ];

  const onFinish = (values: any) => {
    const id = searchParams.get("id");
    resetPasswordProfile({ randomcode: id, password: values["password"] }).then(
      (res: any) => {
        if (res.data) {
          notification.success({
            message: "Password Reset Successfully!",
            description: "Please login with your new password.",
            placement: "top",
            duration: 3,
            top: 85,
          });
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          notification.error({
            message: "Password Reset Failed!",
            description: res.data.message,
            placement: "top",
            duration: 3,
            top: 85,
          });
        }
      }
    );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      {/* <AlertModal
        show={showAlert}
        onClose={closeAlert}
        alertmessage={alertmessage}
        alerttype={alerttype}
        alertmsg
      />
      <Card
        title={"Reset Password"}
        style={{
          width: "40%",
          maxWidth: "40%",
          margin: "auto",
          textAlign: "center",
          marginTop: "200px",
          border: "1px solid",
        }}
        bordered={true}
      >
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "25% 75%",
            paddingBottom: "25px",
          }}
        >
          <a
            style={{
              boxSizing: "border-box",
              paddingTop: "5px",
              fontSize: "14px",
              fontWeight: "700",
              textAlign: "initial",
              color: "#000000",
            }}
          >
            Password
          </a>
          <Input.Password
            name="password"
            visibilityToggle={{
              visible: passwordVisible,
              onVisibleChange: setPasswordVisible,
            }}
            placeholder="Enter password."
            onChange={handleChange}
            style={{
              paddingLeft: "10px",
              border: "1px solid rgba(51, 76, 72, 0.61)",
            }}
          />
        </div>

        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "25% 75%",
            paddingBottom: "25px",
          }}
        >
          <a
            style={{
              boxSizing: "border-box",
              paddingTop: "5px",
              fontSize: "14px",
              fontWeight: "700",
              textAlign: "initial",
              color: "#000000",
            }}
          >
            Confirm Password
          </a>
          <Input.Password
            name="confirmpassword"
            visibilityToggle={{
              visible: confirmpasswordVisible,
              onVisibleChange: setConfirmPasswordVisible,
            }}
            placeholder="Confirm password."
            onChange={handleChange}
            style={{
              paddingLeft: "10px",
              border: "1px solid rgba(51, 76, 72, 0.61)",
            }}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            style={{
              maxWidth: "400px",
              backgroundColor: "rgba(21, 161, 69, 1)",
              color: "white",
              border: "0px",
            }}
            onClick={submitRegister}
          >
            Confirm Password
          </button>
        </div>
      </Card> */}

      <div className="reset-password-page">
        <div className="reset-password-page-form-box">
          <h1 style={{ textAlign: "center" }}>Reset Password</h1>
          <hr style={{ margin: "10px 0px 10px 0px" }} />
          <div>
            <p style={{ textAlign: "center" }}>
              Please enter your new password to reset your password.
            </p>
          </div>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{}}
          >
            {formdata.map((item: any) => (
              <Form.Item {...item}>{item.component}</Form.Item>
            ))}
            {/* <Form.Item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "0px",
                width: "100%",
              }}
            > */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "10px",
              }}
            >
              <Button
                type="link"
                style={{
                  marginRight: "auto",
                  paddingLeft: "0px",

                  color: "rgba(0, 124, 117, 0.74)",
                }}
                onClick={() => {
                  navigate("/login", { replace: true });
                }}
              >
                <span style={{ textDecoration: "underline" }}>
                  Back to Login Page
                </span>
              </Button>
              <div>
                <Button
                  className="button-green"
                  type="primary"
                  htmlType="submit"
                >
                  Reset Password
                </Button>
              </div>
            </div>
            {/* </Form.Item> */}
          </Form>
        </div>
      </div>
    </>
  );
};

export default MyComponent;
