import React, { useEffect, useState } from "react";
import LoginPage from "../component/Login";
import { useNavigate } from "react-router-dom";
import { LoginProfile, addLog } from "../controller/profile";
import AlertModal from "../component/AlertMessage";
import { Input, notification, Form, Button } from "antd";

const MyComponent = (props: any) => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertmessage, setAlertMessage] = useState("");
  const [alerttype, setAlertType] = useState("");

  useEffect(() => {
    localStorage.removeItem("privilage");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("redirect");
    localStorage.removeItem("template");
    localStorage.removeItem("industry_mapping");
    localStorage.removeItem("configuration_gui");
    localStorage.removeItem("changes");
    localStorage.removeItem("original_configuration");
    localStorage.removeItem("original_company_mergedata");
    localStorage.removeItem("deletedCompany");
  });

  const handlelogin = (data: any) => {
    LoginProfile(data).then((res: any) => {
      if (
        Object.keys(res).length > 0 &&
        res.verify == true &&
        res.adminApproval === "Approved" &&
        res.success === true
      ) {
        localStorage.setItem("privilage", `${res.role}`);
        localStorage.setItem("user", `${res.email}`);
        localStorage.setItem("token", `${res.token}`);
        localStorage.setItem("isLogin", "true");

        notification.success({
          message: "Login successful.",
          description: "Welcome to Acquisdata Document Management System 2.0!",
          placement: "top",
          duration: 3,
          top: 85,
        });
        let user: any = localStorage.getItem("user");
        let log: any = {
          page: window.location.pathname,
          action: "Login",
          datetime: new Date(),
          details: "-",
          email: user,
        };
        addLog(log);
        navigate("/home", { replace: true });
      } else if (Object.keys(res).length > 0 && res.success === false) {
        notification.warning({
          message: "Login Failed.",
          description: <>{res.message}</>,
          placement: "top",
          duration: 3,
          top: 85,
        });
      }
    });
  };

  const handleAlertMessage = (el: any) => {
    setAlertMessage(el);
    setShowAlert(true);
    off();
  };

  const handleAlertType = (el: any) => {
    setAlertType(el);
    setShowAlert(true);
    off();
  };

  const off = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  const closeAlert = () => {
    setAlertType("");
    setAlertMessage("");
    setShowAlert(false);
  };

  return (
    <>
      {/* <AlertModal
        show={showAlert}
        onClose={closeAlert}
        alertmessage={alertmessage}
        alerttype={alerttype}
        alertmsg
      /> */}
      <div className="App">
        <header className="NL-App-header">
          <div className="header-acq-login">
            <div className="header-main-login"></div>
            <div className="header-main-login-title">
              Document Management System 2.0
            </div>
            <div className="header-main-login-address">
              Acquisdata Inc. Wilmington, Delaware, USA
            </div>
          </div>
          <LoginPage handlelogin={handlelogin} />
        </header>
      </div>
    </>
  );
};

export default MyComponent;
