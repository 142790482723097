import React from "react";
import "./AlertMessage.css";

const MyComponent = (props: any) => {
  if (!props.show) {
    return null;
  }

  const confirmdelete = () => {
    if (props.deleteDataHook && typeof props.deleteDataHook === "function") {
      props.deleteDataHook();
    }
  };

  const confirmcancel = () => {
    if (props.onClose && typeof props.onClose === "function") {
      props.onClose();
    }
  };

  return (
    <>
      {props.alertmsg && (
        <div className="alert-box">
          <div className="alert-title">{props.alerttype}!</div>
          <div className="alert-message">{props.alertmessage}</div>
        </div>
      )}
      {props.singleDelete && (
        <div className="alert-box">
          <div className="alert-title">Alert Message</div>
          <hr style={{ height: "1px", width: "90%" }} />
          <div className="alert-message">
            <div>
              <div style={{ paddingBottom: "5px" }}>
                Are you sure you want to{" "}
                <a style={{ color: "red", fontWeight: "bold" }}>delete</a>
              </div>
              <div style={{ paddingBottom: "15px", fontWeight: "bold" }}>
                {props.data.name}?
              </div>
              <div style={{ paddingBottom: "10px", fontSize: "16px" }}>
                Important note: Deleted data cannot be recovered.
              </div>
              <div style={{ paddingBottom: "10px" }}>
                <button
                  onClick={confirmcancel}
                  style={{
                    backgroundColor: "#969696",
                    color: "white",
                    width: "84px",
                    height: "33px",
                    borderRadius: "5px",
                    border: "0",
                  }}
                >
                  Cancel
                </button>
                &nbsp;&nbsp;&nbsp;
                <button
                  onClick={confirmdelete}
                  style={{
                    backgroundColor: "#C20023",
                    color: "white",
                    width: "84px",
                    height: "33px",
                    borderRadius: "5px",
                    border: "0",
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.multipleDelete && (
        <div className="alert-box">
          <div className="alert-title">Alert Message</div>
          <hr style={{ height: "1px", width: "90%" }} />
          <div className="alert-message">
            <div>
              <div style={{ paddingBottom: "5px" }}>
                Are you sure you want to{" "}
                <a style={{ color: "red", fontWeight: "bold" }}>delete</a> all{" "}
                <b>{props.data.length} records?</b>
              </div>
              <div style={{ paddingBottom: "10px", fontSize: "16px" }}>
                Important note: Deleted data cannot be recovered.
              </div>
              <div style={{ paddingBottom: "10px" }}>
                <button
                  onClick={confirmcancel}
                  style={{
                    backgroundColor: "#969696",
                    color: "white",
                    width: "84px",
                    height: "33px",
                    borderRadius: "5px",
                    border: "0",
                  }}
                >
                  Cancel
                </button>
                &nbsp;&nbsp;&nbsp;
                <button
                  onClick={confirmdelete}
                  style={{
                    backgroundColor: "#C20023",
                    color: "white",
                    width: "84px",
                    height: "33px",
                    borderRadius: "5px",
                    border: "0",
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.confirmCancel && (
        <div className="alert-box">
          <div className="alert-title">Alert Message</div>
          <hr style={{ height: "1px", width: "90%" }} />
          <div className="alert-message">
            <div>
              <div style={{ paddingBottom: "5px" }}>
                Are you sure you want to cancel? You have unsaved changes that
                will be reverted.
              </div>
              <div style={{ paddingBottom: "10px" }}>
                <button
                  onClick={confirmcancel}
                  style={{
                    backgroundColor: "#969696",
                    color: "white",
                    width: "84px",
                    height: "33px",
                    borderRadius: "5px",
                    border: "0",
                  }}
                >
                  Cancel
                </button>
                &nbsp;&nbsp;&nbsp;
                <button
                  onClick={confirmdelete}
                  style={{
                    backgroundColor: "#C20023",
                    color: "white",
                    width: "84px",
                    height: "33px",
                    borderRadius: "5px",
                    border: "0",
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyComponent;
