import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  createNewProfile,
  VerifyProfile,
  checkverifyProfile,
  regenerateCodeProfile,
} from "../controller/profile";
import AlertModal from "../component/AlertMessage";
import RegistrationAccount from "../component/RegistrationAccount";
import VerifyCode from "../component/VerifyCode";
import { Form, Button, Input, notification, Tooltip } from "antd";

const MyComponent = (props: any) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [registerData, setRegisterData]: any = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertmessage, setAlertMessage] = useState("");
  const [alerttype, setAlertType] = useState("");
  const [verify, setVerify] = useState(false);
  const [disable, setDisable] = useState(false);
  const [form] = Form.useForm();

  const formData = [
    {
      key: "verificationCode",
      name: "verificationCode",
      rules: [
        {
          required: true,
          message: "Please input your verification code!",
        },
        {
          min: 6,
          message: "Verification code must be 6 digits!",
        },
      ],
      validateTrigger: "onBlur",
      component: (
        <Input
          placeholder="Please Enter Verification Code"
          maxLength={6}
          style={{
            width: "80%",
            textAlign: "center",
            padding: "auto",
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    if (registerData?.email) {
      setVerify(true);
    }
  }, [registerData]);

  const handleRegister = (el: any) => {
    let _data: any = { ...el };
    createNewProfile(el).then((res: any) => {
      if (res.data) {
        setRegisterData(res.data);
        handleAlertType("Success");
        handleAlertMessage(
          `Thank you. We have send you email to ${_data["email"]}. Please check and insert the code to activate your account.`
        );
      } else {
        checkverifyProfile({ email: _data["email"] }).then((resp: any) => {
          if (resp.data[0]["verify"] == false) {
            setRegisterData(resp.data[0]);
            handleAlertType("Account Verfication");
            handleAlertMessage(
              `This email has already been exists but haven't verify. The Activation code has been sent to your email. Please check and key in the code to activate your account`
            );
          } else {
            if (resp.data[0]["adminApproval"] === "Approved") {
              handleAlertType("Alert");
              handleAlertMessage(
                `This email has already been exists. Please try a different email to register or proceed to login.`
              );
            } else if (resp.data[0]["adminApproval"] === "Pending") {
              handleAlertType("Alert");
              handleAlertMessage(
                `This email has already been exists. Please try a different email to register or Please wait for your administrator to approve before you can login.`
              );
            } else if (resp.data[0]["adminApproval"] === "Declined") {
              handleAlertType("Alert");
              handleAlertMessage(
                `This email has already been blocked from register. Please contact your administrator.`
              );
            }
            // handleAlertType("Alert")
            // handleAlertMessage(`This email has already been exists. Please try a different email to register`)
          }
        });
      }
    });
  };

  const handleCode = (el: any) => {
    if (registerData["verify"] === false) {
      if (
        el === registerData["verifycode"] &&
        registerData["adminApproval"] === "Pending"
      ) {
        VerifyProfile(registerData).then((res: any) => {
          if (res.data) {
            handleAlertType("Registration completed");
            handleAlertMessage(
              `Verification has been successful. Please wait for your administrator to approve before you can login.`
            );
            setTimeout(() => {
              navigate("/login", { replace: true });
            }, 3000);
          } else {
            handleAlertType("Alert");
            handleAlertMessage(`Fail to activate.`);
          }
        });
      } else if (
        el === registerData["verifycode"] &&
        registerData["adminApproval"] === "Declined"
      ) {
        handleAlertType("Account Verfication");
        handleAlertMessage(
          `This email has already been blocked from register. Please contact your administrator.`
        );
      } else if (
        el === registerData["verifycode"] &&
        registerData["adminApproval"] === "Approved"
      ) {
        handleAlertType("Account Verfication");
        handleAlertMessage(
          `This email has already been verified. Please continue with login.`
        );
        setTimeout(() => {
          navigate("/login", { replace: true });
        }, 3000);
      } else {
        handleAlertType("Alert");
        handleAlertMessage(
          `Wrong code. Please check and insert the correct code to activate your account.`
        );
      }
    } else {
      handleAlertType("Alert");
      handleAlertMessage(`This email has already been verified.`);
    }
  };

  const generateCode = () => {
    checkverifyProfile({ email: registerData["email"] }).then((res: any) => {
      if (res.data[0]["verify"] === false) {
        regenerateCodeProfile({ email: registerData["email"] }).then(
          (resp: any) => {
            if (resp.data) {
              setRegisterData(resp.data);
              handleAlertType("Account Verfication");
              handleAlertMessage(
                `New code has been sent to your email.  Please check and key in the code to activate your account`
              );
            } else {
              handleAlertType("Alert");
              handleAlertMessage(`Fail to send email`);
            }
          }
        );
      } else if (
        res.data[0]["verify"] === true &&
        res.data[0]["adminApproval"] === "Pending"
      ) {
        handleAlertType("Account Verfication");
        handleAlertMessage(
          `This email has already been verified. Please wait for your administrator to approve before you can login.`
        );
      } else if (
        res.data[0]["verify"] === true &&
        res.data[0]["adminApproval"] === "Declined"
      ) {
        handleAlertType("Account Verfication");
        handleAlertMessage(
          `This email has already been blocked from register. Please contact your administrator.`
        );
      } else {
        handleAlertType("Alert");
        handleAlertMessage(
          `This email has already been verified. Please continue with login.`
        );
      }
    });
  };

  const handleAlertMessage = (el: any) => {
    setAlertMessage(el);
    setShowAlert(true);
    off();
  };

  const handleAlertType = (el: any) => {
    setAlertType(el);
    setShowAlert(true);
    off();
  };

  const off = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  const closeAlert = () => {
    setAlertType("");
    setAlertMessage("");
    setShowAlert(false);
  };

  const onFinish = (values: any) => {
    VerifyProfile({
      verificationCode: values.verificationCode,
      randomCode: searchParams.get("id"),
    }).then((res: any) => {
      if (res.success === true) {
        notification.success({
          message: "Success",
          description: res.message,
          placement: "top",
          duration: 3,
          top: 85,
        });
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        notification.error({
          message: "Incorrect Verification!",
          description: res.message,
          placement: "top",
          duration: 3,
          top: 85,
        });
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    notification.error({
      message: "Error",
      description: "Fail to submit! Please try again!",
      placement: "top",
      duration: 3,
      top: 85,
    });
  };

  // resend link to email
  const handleResendLink = () => {
    const id = searchParams.get("id");
    checkverifyProfile({ randomcode: id }).then((res: any) => {
      if (res.data[0]["verify"] === false) {
        regenerateCodeProfile({ randomcode: id }).then((resp: any) => {
          if (resp.data) {
            notification.success({
              message: "Verification code has been sent!",
              description: "Please check your email!",
              placement: "top",
              duration: 3,
              top: 85,
            });
          } else {
            notification.error({
              message: "Fail to resend verification code!",
              description: "Something went wrong. Please try again!",
              placement: "top",
              duration: 3,
              top: 85,
            });
          }
        });
      } else if (
        res.data[0]["verify"] === true &&
        res.data[0]["adminApproval"] === "Pending"
      ) {
        notification.warning({
          message: "Account Verification!",
          description:
            "This email has already been verified. Please wait for your administrator to approve before you can login.",
          placement: "top",
          duration: 3,
          top: 85,
        });
      } else if (
        res.data[0]["verify"] === true &&
        res.data[0]["adminApproval"] === "Declined"
      ) {
        notification.warning({
          message: "Account Verification!",
          description:
            "This email has already been blocked from register. Please contact your administrator.",
          placement: "top",
          duration: 3,
        });
      } else {
        notification.warning({
          message: "Account Verification!",
          description:
            "This email has already been verified. Please continue with login.",
          placement: "top",
          duration: 3,
          top: 85,
        });
      }
    });
  };

  return (
    <>
      {/* <AlertModal
        show={showAlert}
        onClose={closeAlert}
        alertmessage={alertmessage}
        alerttype={alerttype}
        alertmsg
      /> */}
      {/* {!verify && <RegistrationAccount handleRegister={handleRegister} />}
      {verify && (
        <VerifyCode
          handleCode={handleCode}
          generateCode={generateCode}
          registerData={registerData}
        />
      )} */}
      <div className="verify-page">
        <div className="form-box">
          <h1>Verification</h1>
          <hr style={{ margin: "10px 0px 30px 0px" }} />
          <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <div>
              <p>
                A 6-digit PIN has been sent to your email, please enter the code
                below for verification purposes.
              </p>
            </div>
            {formData.map((item: any) => (
              <Form.Item {...item}>{item.component}</Form.Item>
            ))}
            <Form.Item>
              <Button type="primary" className="button-green" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
            <hr />
            {/* <div className="button-container">
              <Button
                type="link"
                className="button-link"
                onClick={() => navigate("/login")}
              >
                Back to Login Page
              </Button>
              <Tooltip
                title="Resend verification code to your email."
                placement="right"
              >
                <Button
                  type="link"
                  className="button-link"
                  onClick={handleResendLink}
                >
                  Resend Code
                </Button>
              </Tooltip>
            </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "5px",
              }}
            >
              <div>
                Did not receive?&nbsp;{" "}
                <a
                  style={{ textDecoration: "underline" }}
                  onClick={handleResendLink}
                >
                  Resend Code
                </a>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <a
                  style={{ textDecoration: "underline" }}
                  onClick={() => navigate("/login")}
                >
                  Back to Login
                </a>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default MyComponent;
