import React, { useState, useEffect } from "react";
import RT, { PageControl } from "./../component/RajangTable";
import { Card, Button, Space, notification } from "antd";
import ConfigManagerDetail from "../component/ConfigManagerDetail";
import { readAllConfig, createNewConfig } from "../controller/config";
import { getRegionType } from "../controller/region";
import AlertModal from "../component/AlertMessage";
import { addLog, checkmailProfile } from "../controller/profile";
import { useNavigate } from "react-router-dom";
import { deleteConfig } from "../controller/config";
import { getAllActiveRICIndustry } from "../controller/industry_mapping";

const MyComponent = (props: any) => {
  const navigate = useNavigate();
  const initialPageControl: PageControl = {
    numRec: 0,
    recPerPage: 10,
    pageNum: 1,
  };
  const [data, setData]: any = useState([]);
  const [pageC, setPageC]: any = useState(initialPageControl);
  const [checkedData, setCheckedData]: any = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [focusedData, setFocusedData] = useState({});
  const [selectedData, setSelectedData]: any = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertmessage, setAlertMessage] = useState("");
  const [alerttype, setAlertType] = useState("");
  const [regiontype, setRegionType]: any = useState([]);
  const [industrytemplate, setIndustryTemplate]: any = useState([]);
  const deleteConfirmation = {
    title: "Delete Configuration",
    content: "Are you sure you want to delete these Configuration?",
  };

  useEffect(() => {
    let user: any = localStorage.getItem("user");
    let log: any = {
      page: window.location.pathname,
      action: "Read",
      datetime: new Date(),
      details: "-",
      email: user,
    };
    addLog(log);
  }, []);

  // useEffect(() => {
  //   let privilage: any = localStorage.getItem("privilage");
  //   let user: any = localStorage.getItem("user");
  //   let login: any = localStorage.getItem("isLogin");
  //   if (login === "true") {
  //     checkmailProfile({ email: user }).then((res: any) => {
  //       if (res === "You are unauthorized to use this resource") {
  //         setTimeout(() => {
  //           handleTokenExpired();
  //         }, 3000);
  //       } else if (res.data?.[0].role !== privilage) {
  //         localStorage.setItem("privilage", `${res.data[0].role}`);
  //       }
  //     });
  //   }
  // }, []);

  useEffect(() => {
    // load the list of Configuration
    setLoading(true);
    readAllConfig()
      .then((res: any) => {
        if (res.data) {
          const sortedData = res.data.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          );
          setData(sortedData);
          setLoading(false);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
    getRegionType()
      .then((res: any) => {
        if (res.data) {
          const newData = res.data.map((rec: any) => ({
            label: rec._id,
            value: rec._id,
          }));
          setRegionType(newData);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
    getAllActiveRICIndustry()
      .then((res: any) => {
        const newData = res.data.map((rec: any) => ({
          label: rec.name,
          value: rec.name,
        }));
        setIndustryTemplate(newData);
      })
      .catch((err: any) => {
        console.error(err);
      });
  }, []);

  const convertConfigName = (el: any) => {
    let answer = data.find((o: any) => {
      return o["_id"] === el;
    });
    if (!answer) {
      return el;
    }
    if (!answer["name"]) {
      return "";
    }
    return answer["name"];
  };

  // const handleTokenExpired = () => {
  //   notification.warning({
  //     message: "Warning",
  //     description: "Your authorization has expired.Please login again.",
  //     placement: "top",
  //     duration: 3,
  //   });

  //   setTimeout(() => {
  //     navigate("/login", { replace: true });
  //   }, 3000); // delay navigation for 3 seconds
  // };

  const refreshData = () => {
    setLoading(true);
    readAllConfig()
      .then((res: any) => {
        const sortedData = res.data.sort((a: any, b: any) =>
          a.name.localeCompare(b.name)
        );
        setData(sortedData);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
      });
    getRegionType()
      .then((res: any) => {
        if (res.data) {
          const newData = res.data.map((rec: any) => ({
            label: rec._id,
            value: rec._id,
          }));
          setRegionType(newData);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
    getAllActiveRICIndustry()
      .then((res: any) => {
        const newData = res.data.map((rec: any) => ({
          label: rec.name,
          value: rec.name,
        }));
        setIndustryTemplate(newData);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const handleDelete = (selected: any) => {
    // console.log("data", data);
    deleteConfig(selected).then((res: any) => {
      if (res.data) {
        let user: any = localStorage.getItem("user");
        let log: any = {
          page: window.location.pathname,
          action: "Delete",
          datetime: new Date(),
          details: `Delete Config ${selected
            .map((name: any) => convertConfigName(name))
            .join(", ")}`,
          email: user,
        };
        addLog(log);
        notification.success({
          message: "Distribution Config deleted!",
          description: "Distribution Config has been deleted successfully.",
          placement: "bottomRight",
          bottom: 50,
        });
        setSelectedData([]);
        setCheckedData([]);
        refreshData();
      } else {
        notification.error({
          message: "Failed to delete Distribution Config!",
          description: "Distribution Config has not been deleted.",
          placement: "bottomRight",
          bottom: 50,
        });
      }
    });
  };

  const handleAddNewConfig: any = () => {
    // setFocusedData({
    //   name: "",
    //   distributorName: "",
    //   key: "reportal",
    // });
    // setShowDetailModal(true);
    navigate("/config_gui_setting", {
      replace: true,
      state: {
        data: null,
        dataset: data,
      },
    });
  };

  const handleManageDefaultConfig: any = () => {
    setFocusedData({
      name: "DEFAULT",
      distributionName: "DEFAULT",
      package: "DEFAULT",
      key: "reportal",
    });
    setShowDetailModal(true);
  };

  const handleClose: any = () => {
    setShowDetailModal(false);
  };
  const handleSaveData = (data: any) => {
    if (data) {
      createNewConfig(data).then((res: any) => {
        if (res.data) {
          handleAlertType("Success");
          handleAlertMessage(`${res.data.distributionName} has been created`);
          refreshData();
        } else {
          handleAlertType("Alert");
          handleAlertMessage(
            `${res.data.distributionName} has not been created`
          );
          refreshData();
        }
      });
    }
  };
  const handleCancel: any = () => {
    setShowDetailModal(false);
  };

  const handleSelected = (data: any) => {
    selectedData[pageC.pageNum] = data;
    data.forEach((element: any) => {
      selectedData[pageC.pageNum].push(element);
    });
    setCheckedData(data);
  };

  const handleAlertMessage = (el: any) => {
    setAlertMessage(el);
    setShowAlert(true);
    off();
  };

  const handleAlertType = (el: any) => {
    setAlertType(el);
    setShowAlert(true);
    off();
  };

  const off = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  const closeAlert = () => {
    setAlertType("");
    setAlertMessage("");
    setShowAlert(false);
  };

  return (
    <>
      <div>
        {showDetailModal && (
          <>
            <ConfigManagerDetail
              open={showDetailModal}
              handleClose={handleClose}
              handleCancel={handleCancel}
              handleSaveData={handleSaveData}
              data={focusedData}
              regiontype={regiontype}
              industrytemplate={industrytemplate}
            />
          </>
        )}
        <AlertModal
          show={showAlert}
          onClose={closeAlert}
          alertmessage={alertmessage}
          alerttype={alerttype}
          alertmsg
        />
        <Card
          title={`All Distribution Configurations (${data?.length || 0})`}
          style={{
            width: "90%",
            maxWidth: "90%",
            margin: "auto",
            textAlign: "left",
          }}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <div>
              <Space>
                <Button onClick={handleAddNewConfig}>
                  Add New Configuration
                </Button>
                {/* <Button onClick={handleManageDefaultConfig}>
                  Manage Default Configuration
                </Button> */}
              </Space>
            </div>
            <div>
              <RT
                tablewidth="90%"
                hasSelectAll={true}
                data={data}
                cols={[
                  {
                    fieldname: "name",
                    width: "35%",
                    label: "Config Name",
                    clickable: "true",
                    sortcolumn: "String",
                  },
                  {
                    fieldname: "distributionName",
                    width: "35%",
                    label: "Distributor",
                    sortcolumn: "String",
                  },
                  {
                    fieldname: "lastEditDate",
                    width: "15%",
                    label: "Last Edited",
                    sortcolumn: "Datetime",
                  },
                  {
                    fieldname: "lastRunDate",
                    width: "15%",
                    label: "Last Run",
                    sortcolumn: "Datetime",
                  },
                ]}
                pageControl={pageC}
                type={"small"}
                handleLoadData={refreshData}
                handleSelected={handleSelected}
                checkedData={checkedData}
                rowSelection={"true"}
                removeData={selectedData}
                handleDelete={handleDelete}
                deleteConfirmation={deleteConfirmation}
                loading={loading}
                action={["delete", "reset"]}
                regiontype={regiontype}
                industrytemplate={industrytemplate}
              ></RT>
            </div>
          </Space>
        </Card>
      </div>
    </>
  );
};

export default MyComponent;
