import React, { useState, useEffect, useRef } from "react";
import { RiLogoutBoxLine } from "react-icons/ri";
import { useNavigate, NavLink } from "react-router-dom";
import { Menu, MenuProps, Modal, Button } from "antd";
import {
  ExclamationCircleOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Events from "../lib/events";
import { addLog } from "../controller/profile";
const { confirm } = Modal;

const MyComponent = (props: any) => {
  const [current, setCurrent] = useState("mail");
  const [width, setWidth] = useState(window.innerWidth);
  const getbaseUrl = window.location.pathname;
  const navigate = useNavigate();
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const pixelsPerInch = window.innerWidth / window.innerWidth;
      const widthInInches = window.innerWidth / pixelsPerInch;
      setWidth(widthInInches);
      // console.log("handleResize", widthInInches);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {}, [getbaseUrl]);

  // useEffect(() => {
  //   Events.on("changes", () => {
  //     preventRedirect.current = true;
  //     console.log("GET");
  //     Events.emit("triggerClose");
  //   });
  // }, []);

  // useEffect(() => {
  //   const changesHandler = () => {
  //     preventRedirect.current = true;
  //     console.log("GET");
  //   };

  //   Events.on("changes", changesHandler);

  //   return () => {
  //     Events.off("changes", changesHandler);
  //   };
  // }, []);

  const onClick: MenuProps["onClick"] = (e) => {
    // console.log("NavLink clicked:", e.key);
    setCurrent(e.key);
  };

  const handleClick = (event: any) => {
    if (window.location.pathname === "/industry_mapping") {
      let changes: any = localStorage.getItem("industry_mapping");
      let selected: any = localStorage.getItem("template");
      if (changes || selected) {
        event.preventDefault();
        let url = event.target.href;
        const extractedText = url.split("/").pop();
        localStorage.setItem(
          "redirect",
          JSON.stringify({ navigate: extractedText, replace: true })
        );
        Events.emit("triggerClose");
      }
    } else if (window.location.pathname === "/config_gui_setting") {
      let changes: any = localStorage.getItem("configuration_gui");
      let selected: any = localStorage.getItem("template");
      if (changes || selected) {
        // console.log("Check");
        event.preventDefault();
        let url = event.target.href;
        const extractedText = url.split("/").pop();
        localStorage.setItem(
          "redirect",
          JSON.stringify({ navigate: extractedText, replace: true })
        );
        Events.emit("ConfigurationTrigger");
      }
    } else if (window.location.pathname === "/company_consolidation") {
      // let changes: any = localStorage.getItem("changes");
      let selected: any = localStorage.getItem("template");
      if (selected) {
        // console.log("Check");
        event.preventDefault();
        let url = event.target.href;
        const extractedText = url.split("/").pop();
        localStorage.setItem(
          "redirect",
          JSON.stringify({ navigate: extractedText, replace: true })
        );
        Events.emit("ConsolidationTrigger");
      }
    }
  };

  const logout = () => {
    confirm({
      title: "Are you sure you want to log out?",
      onOk() {
        handleLogoutConfirm();
      },
      okText: "Confirm",
      onCancel() {
        handleLogoutCancel();
      },
    });
  };

  const handleLogoutConfirm = () => {
    // perform logout action
    let user: any = localStorage.getItem("user");
    let log: any = {
      page: window.location.pathname,
      action: "Logout",
      datetime: new Date(),
      details: "-",
      email: user,
    };
    addLog(log);
    setIsLogoutModalVisible(false);
    localStorage.removeItem("privilage");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("template");
    localStorage.removeItem("redirect");
    localStorage.removeItem("industry_mapping");
    localStorage.removeItem("configuration_gui");
    localStorage.removeItem("changes");
    localStorage.removeItem("original_configuration");
    localStorage.removeItem("original_company_mergedata");
    localStorage.removeItem("deletedCompany");
    setTimeout(() => {
      navigate("/login", { replace: true });
    }, 1000);
  };

  const handleLogoutCancel = () => {
    setIsLogoutModalVisible(false);
  };

  const checkActive = (el: any) => {
    if (el === getbaseUrl) {
      return "#1890ff";
    } else {
      return "#000000";
    }
  };

  const checkSearch = (el: any) => {
    if (el === getbaseUrl) {
      return "#1890ff";
    } else {
      return "#FFFFFF";
    }
  };

  const checkCountryP = () => {
    if (
      getbaseUrl === "/country_publication_" ||
      getbaseUrl === "/country_publication"
    ) {
      return "#2ADCE9";
    } else {
      // return "#000000";
      return width >= 605 ? "#FFFFFF" : "#000000";
    }
  };

  const checkIndustryP = () => {
    if (
      getbaseUrl === "/industry_publication_" ||
      getbaseUrl === "/industry_publication"
    ) {
      return "#2ADCE9";
    } else {
      // return "#000000";
      return width >= 943 ? "#FFFFFF" : "#000000";
    }
  };

  const checkDataRelation = () => {
    if (
      getbaseUrl === "/search" ||
      getbaseUrl === "/publication_report" ||
      getbaseUrl === "/distributor_summary"
    ) {
      return "#2ADCE9";
    } else {
      // return "#000000";
      return width >= 1251 ? "#FFFFFF" : "#000000";
    }
  };

  const adminitems: MenuProps["items"] = [
    {
      label: (
        <NavLink
          to="/home"
          onClick={handleClick}
          style={{
            color: `${checkSearch("/home")}`,
            cursor: "pointer",
          }}
        >
          Home
        </NavLink>
      ),
      key: "search",
    },
    {
      label: (
        <NavLink
          to="/country_publication"
          onClick={handleClick}
          style={{
            // color: `${checkCountryP()}`,
            cursor: "pointer",
          }}
        >
          Country Publications
        </NavLink>
      ),
      key: "country_pub",
    },
    {
      label: (
        <NavLink
          to="/industry_publication"
          onClick={handleClick}
          style={{
            // color: `${checkIndustryP()}`,
            cursor: "pointer",
          }}
        >
          Industry Publications
        </NavLink>
      ),
      key: "industry_pub",
    },
    {
      label: (
        <NavLink
          to="/search"
          onClick={handleClick}
          style={{
            // color: `${checkDataRelation()}`,
            cursor: "pointer",
          }}
        >
          Search
        </NavLink>
      ),
      key: "data_relations",
    },
    {
      label: "Settings",
      key: "setting",
      children: [
        {
          label: (
            <NavLink
              to="/configuration_gui"
              onClick={handleClick}
              style={{
                color: `${
                  checkActive("/configution_gui") ||
                  checkActive("/config_gui_setting")
                }`,
                cursor: "pointer",
              }}
            >
              GUI Configuration
            </NavLink>
          ),
          key: "gui_config",
        },
        {
          label: (
            <NavLink
              to="/bespoke_region_configuration"
              onClick={handleClick}
              style={{
                color: `${checkActive("/bespoke_region_configuration")}`,
                cursor: "pointer",
              }}
            >
              Bespoke Region Configuration
            </NavLink>
          ),
          key: "bespokeregion_config",
        },
        {
          label: (
            <NavLink
              to="/user_management"
              onClick={handleClick}
              style={{
                color: `${checkActive("/user_management")}`,
                cursor: "pointer",
              }}
            >
              User Management
            </NavLink>
          ),
          key: "user_management",
        },
        {
          label: (
            <NavLink
              to="/industry_mapping"
              // onClick={handleClick}
              style={{
                color: `${checkActive("/industry_mapping")}`,
                cursor: "pointer",
              }}
            >
              Industry Mapping
            </NavLink>
          ),
          key: "industry_mapping",
        },
        {
          label: (
            <NavLink
              to="/company_consolidation"
              // onClick={handleClick}
              style={{
                color: `${checkActive("/company_consolidation")}`,
                cursor: "pointer",
              }}
            >
              Company Consolidation
            </NavLink>
          ),
          key: "company_consolidation",
        },
      ],
    },
  ];
  const useritems: MenuProps["items"] = [
    {
      label: (
        <NavLink
          to="/home"
          style={{
            color: `${checkSearch("/home")}`,
            cursor: "pointer",
          }}
        >
          Home
        </NavLink>
      ),
      key: "search",
    },
    {
      label: (
        <NavLink to="/country_publication" style={{ cursor: "pointer" }}>
          Country Publications
        </NavLink>
      ),
      key: "country_pub",
    },
    {
      label: (
        <NavLink to="/industry_publication" style={{ cursor: "pointer" }}>
          Industry Publications
        </NavLink>
      ),
      key: "industry_pub",
    },
    {
      label: (
        <NavLink to="/search" style={{ cursor: "pointer" }}>
          Search
        </NavLink>
      ),
      key: "data_relations",
    },
  ];
  return (
    <>
      {isLogoutModalVisible && (
        <Modal onOk={handleLogoutConfirm} onCancel={handleLogoutCancel} />
      )}
      {localStorage.getItem("isLogin") &&
        localStorage.getItem("privilage") === "Admin" &&
        !(
          getbaseUrl === "/register" ||
          getbaseUrl === "/forgotpassword" ||
          getbaseUrl === "/resetpassword" ||
          getbaseUrl === "/login" ||
          getbaseUrl === "/verify" ||
          getbaseUrl === "/"
        ) && (
          <div className="header-acq">
            <div
              style={{
                textAlign: "start",
                paddingLeft: "15px",
                paddingTop: "2px",
                fontSize: "26px",
                fontWeight: "600",
              }}
            >
              ACQUISDATA
            </div>
            <Menu
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={adminitems}
              style={{
                backgroundColor: "transparent",
                // color: "white",
                position: "relative",
                display: "flex",
                flex: "auto",
                justifyContent: "center",
              }}
            />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div
                onClick={logout}
                style={{
                  paddingLeft: "100px",
                  fontSize: "14px",
                  cursor: "pointer",
                  display: "inline-flex",
                  alignItems: "center",
                  width: "200px",
                }}
              >
                <RiLogoutBoxLine
                  size={"30px"}
                  style={{ paddingRight: "7px" }}
                />
                Log Out
              </div>
            </div>
          </div>
        )}
      {localStorage.getItem("isLogin") &&
        localStorage.getItem("privilage") === "User" &&
        !(
          getbaseUrl === "/register" ||
          getbaseUrl === "/forgotpassword" ||
          getbaseUrl === "/resetpassword" ||
          getbaseUrl === "/login" ||
          getbaseUrl === "/verify" ||
          getbaseUrl === "/"
        ) && (
          <div className="header-acq">
            <div
              style={{
                textAlign: "start",
                paddingLeft: "15px",
                paddingTop: "2px",
                fontSize: "26px",
                fontWeight: "600",
              }}
            >
              ACQUISDATA
            </div>
            <Menu
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={useritems}
              style={{
                backgroundColor: "transparent",
                // color: "white",
                position: "relative",
                display: "flex",
                flex: "auto",
                justifyContent: "center",
              }}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div
                onClick={logout}
                style={{
                  paddingLeft: "100px",
                  fontSize: "14px",
                  cursor: "pointer",
                  display: "inline-flex",
                  alignItems: "center",
                  width: "200px",
                }}
              >
                <RiLogoutBoxLine
                  size={"30px"}
                  style={{ paddingRight: "7px" }}
                />
                Log Out
              </div>
            </div>
          </div>
        )}
      {!localStorage.getItem("isLogin") &&
        (getbaseUrl === "/register" ||
          getbaseUrl === "/forgotpassword" ||
          getbaseUrl === "/resetpassword" ||
          getbaseUrl === "/verify") && (
          <div className="header-acq-register">
            <div
              style={{
                textAlign: "center",
                paddingLeft: "15px",
                paddingTop: "2px",
                fontSize: "34px",
                fontWeight: "600",
              }}
            >
              ACQUISDATA
            </div>
          </div>
        )}
    </>
  );
};

export default MyComponent;
