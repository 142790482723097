import React, { useEffect, useState } from "react";

const MyComponent = (props: any) => {
  return (
    <>
      <h1>Unauthorized</h1>
      <p>You are unauthorized to access this resource.</p>
    </>
  );
};

export default MyComponent;
