import React, { useEffect, useState, useRef, forwardRef } from "react";
import {
  Breadcrumb,
  Form,
  Input,
  Select,
  Button,
  Tabs,
  Badge,
  Tooltip,
  Steps,
  message,
  SelectProps,
  Radio,
  Space,
  Checkbox,
  DatePicker,
  notification,
  Modal,
} from "antd";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { MinusOutlined } from "@ant-design/icons";
import { getAllCountry, getAllIndustry } from "../controller/search";
import { createNewConfig, updateConfig } from "../controller/config";
import { getAllActiveRICIndustry } from "../controller/industry_mapping";
import { getRegionType } from "../controller/region";
import { addLog } from "../controller/profile";
import Events from "../lib/events";
import moment from "moment";
import _ from "lodash";

let configGUI: any = {
  name: String,
  distributionName: String,
  groupBy: String,
  summariseBy: String,
  notes: String,
  mode: String,
  doccodes: [String],
  fileTypes: [String],
  filter: [Object],
  excludeFilter: [Object],
  forceInitial: Boolean,
  regionMapping: String,
  industryMapping: String,
  dryRun: Boolean,
  xmlDisableHref: Boolean,
  sortBy: String,
  xmlTemplate: String,
  xmlTemplates: [],
  xmlSummaryTemplates: [],
  publicationFilename: String,
  publicationFilenames: [],
  initialStartDate: String,
  initialEndDate: Date,
  useZip: Boolean,
  zipFilename: String,
  language: String,
  last_n_publications: Number,
  xmlHeaderDoccodeTagMap: [],
  lastEditDate: Date,
  lastRunDate: Date,
  lastRunKey: String,
  tagToSpan: [String],
  xmlRemoveAcqRef: Boolean,
  xmlBadUrls: [String],
  xmlRemoveStringPatterns: [String],
  manifestFiles: [String],
  manifestXmlContainer: String,
  manifestXmlContent: String,
  publicationsPerZip: Number,
  maxFtpthread: Number,
  autoFtp: Boolean,
  key: String,
  package: String,
  destFolders: [Object],
  fromMinimumDocumentId: [Object],
  checkIndustryRef: [Object],
  checkCompanyRef: [Object],
  entitlement: {
    entitlementType: String,
    entitlementContext: String,
    entitlementText: String,
  },
  ftp: {
    mode: String,
    server: String,
    user: String,
    password: String,
    path: String,
    // customFtpLocations: [[String, String]],
  },
};

const MyComponent = (props: any) => {
  const { TextArea } = Input;
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const formRef: any = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeKey, setActiveKey]: any = useState(1);
  const [showinitialsdate, setShowInitialSdate] = useState(false);
  const [showeditinitialsdate, setShowEditInitialSdate] = useState(false);
  const [showinitialedate, setShowInitialEdate] = useState(false);
  const [showeditinitialedate, setShowEditInitialEdate] = useState(false);
  const [data, setData]: any = useState({});
  const [oridata, setOriData]: any = useState({});
  const [datachange, setDataChange]: any = useState(false);
  const [countrylist, setCountryList]: any = useState([]);
  const [industrylist, setIndustryList]: any = useState([]);
  const [regiontype, setRegionType]: any = useState([]);
  const [industrytemplate, setIndustryTemplate]: any = useState([]);
  const [selectedIndustryRadio, setSelectedIndustryRadio] = useState("include");
  const [selectedCountryRadio, setSelectedCountryRadio] = useState("include");
  const [autoftp, setAutoftp] = useState(true);
  const [datasetoption, setDataSetOption]: any = useState([]);
  const [copyconfig, setCopyConfig] = useState(null);

  // console.log(
  //   "data",
  //   location.state.data,
  //   location.state.data.ftp,
  //   location.state.data.ftp.mode
  // );
  useEffect(() => {
    // localStorage.setItem("configuration_gui", JSON.stringify(data));
    // console.log("data", data);
  }, [data]);

  useEffect(() => {
    let new_dataset = location.state.dataset.map((index: any) => ({
      value: index["_id"],
      label: index["package"],
    }));
    setDataSetOption(new_dataset);
  }, [location.state.dataset]);

  useEffect(() => {
    const eventHandler = () => {
      // Events.off("triggerClose", eventHandler); // Remove the event listener

      // let industry_mapping: any = localStorage.getItem("configuration_gui");
      let selected: any = localStorage.getItem("template");
      // console.log("location.state.data", location.state.data);
      if (selected) {
        handleRefClose();
        // if (industry_mapping) {

        //   if (location.state.data === null) {
        //     handleRefClose();
        //   } else {
        //     handleRefEditClose();
        //   }
        // } else {
        //   if (location.state.data === null) {
        //     handleRefClose();
        //   } else {
        //     handleRefEditClose();
        //   }
        // }
      }
    };

    Events.on("ConfigurationTrigger", eventHandler);
    return () => {
      Events.off("ConfigurationTrigger", eventHandler); // Make sure to remove the event listener on cleanup
    };
  }, []);

  useEffect(() => {
    if (location.state.data === null) {
      if (
        data.package !== null &&
        data.package !== "" &&
        data.mode !== null &&
        data.mode !== "" &&
        data.doccodes &&
        data.doccodes.length > 0 &&
        data.fileTypes &&
        data.fileTypes.length > 0
      ) {
        localStorage.setItem("configuration_gui", JSON.stringify(data));
        localStorage.setItem("changes", JSON.stringify(datachange));
      } else {
        // localStorage.removeItem("original_configuration");
        localStorage.removeItem("configuration_gui");
        localStorage.removeItem("redirect");
      }
    } else if (location.state.data !== null) {
      // const differences = [];

      // for (const key in data) {
      //   if (data.hasOwnProperty(key)) {
      //     if (
      //       JSON.stringify(data[key]) !==
      //       JSON.stringify(location.state.data[key])
      //     ) {
      //       differences.push(key);
      //     }
      //   }
      // }
      const differences = [];

      for (const key in configGUI) {
        if (configGUI.hasOwnProperty(key)) {
          if (
            JSON.stringify(configGUI[key]) !==
            JSON.stringify(location.state.data[key])
          ) {
            differences.push(key);
          }
        } else if (location.state.data.hasOwnProperty(key)) {
          differences.push(key);
        }
      }

      if (differences.length !== 0 || datachange === true) {
        localStorage.setItem("configuration_gui", JSON.stringify(data));
        localStorage.setItem("changes", JSON.stringify(datachange));
      } else {
        // localStorage.removeItem("original_configuration");
        localStorage.removeItem("configuration_gui");
        localStorage.removeItem("redirect");
      }
    }
  }, [datachange, data]);

  useEffect(() => {
    // if (location.state.data !== null) {
    //   setUnchangedData(location.state.data);
    // }
    const fetchCountryData = async () => {
      getAllCountry().then((res: any) => {
        if (res.data) {
          const mappedCountryData = res.data.map((item: any) => ({
            value: item.countrycode,
            label: item.name,
          }));
          setCountryList(mappedCountryData);
        } else {
          setCountryList([]);
        }
      });
    };
    const fetchIndustryData = async () => {
      getAllIndustry().then((res: any) => {
        if (res.data) {
          const mappedIndustryData = res.data.map((item: any) => ({
            value: item.industrycode,
            label: item.description,
          }));
          setIndustryList(mappedIndustryData);
        } else {
          setIndustryList([]);
        }
      });
    };
    const fetchRegion = async () => {
      getRegionType()
        .then((res: any) => {
          if (res.data) {
            const newData = res.data.map((rec: any) => ({
              label: rec._id,
              value: rec._id,
            }));
            setRegionType(newData);
          }
        })
        .catch((err: any) => {
          console.error(err);
        });
    };
    const fetchIndustryMapping = async () => {
      getAllActiveRICIndustry()
        .then((res: any) => {
          // const newData = res.data.map((rec: any) => ({
          //   label: rec.name,
          //   value: rec.name,
          // }));
          // setIndustryTemplate(newData);
          const data = res.data;

          // Create an object to store the grouped data
          const groupedData: any = {};

          // Iterate through the original data array
          data.forEach((rec: any) => {
            const name = rec.name;

            // If the name is not in the groupedData object, create a new entry
            if (!groupedData[name]) {
              groupedData[name] = {
                label: name,
                value: name,
              };
            } else {
              // If the name is already in the groupedData object, you can update it as needed
              // For example, you can concatenate values or perform any other grouping operation
              // In this example, we'll concatenate the values with a comma
              groupedData[name].value += `, ${name}`;
            }
          });

          // Convert the groupedData object back to an array
          const newData = Object.values(groupedData);

          // Set the newData as your industry template
          setIndustryTemplate(newData);
        })
        .catch((err: any) => {
          console.error(err);
        });
    };

    fetchCountryData();
    fetchIndustryData();
    fetchRegion();
    fetchIndustryMapping();
  }, []);

  useEffect(() => {
    if (location.state.data !== null) {
      setData(location.state.data);
      if (
        location.state.data.filter?.[0]?.$and?.[0]?.industry?.$nin?.length > 0
      ) {
        setSelectedIndustryRadio("include");
      } else if (location.state.data.excludeFilter?.[0]?.industry?.length > 0) {
        setSelectedIndustryRadio("exclude");
      } else {
        setSelectedIndustryRadio("include");
      }
      if (
        location.state.data.filter?.[0]?.$and?.[1]?.country?.$nin?.length > 0
      ) {
        setSelectedCountryRadio("include");
      } else if (location.state.data.excludeFilter?.[0]?.country?.length > 0) {
        setSelectedCountryRadio("exclude");
      } else {
        setSelectedCountryRadio("include");
      }
      if (
        location.state.data?.ftp?.server &&
        location.state.data?.ftp?.user &&
        location.state.data?.ftp?.password
      ) {
        setAutoftp(false);
      }
      localStorage.setItem(
        "original_configuration",
        JSON.stringify(location.state.data)
      );
      localStorage.setItem(
        "template",
        JSON.stringify({
          template: location.state.data.package,
        })
      );
    } else {
      setData({
        language: "EN",
        key: "reportal",
        xmlTemplate: "./templates/STANDARDPOORS-RIXML.jinja",
      });
      localStorage.setItem(
        "template",
        JSON.stringify({
          template: null,
        })
      );
    }
  }, [location.state]);

  const packingMode: SelectProps["options"] = [
    { value: "dmspacklegacy", label: "Legacy Mode" },
    { value: "dmspack", label: "Acquisdata XML 2.0" },
    {
      value: "dmspackchapters",
      label: "Acquisdata XML 2.0 (Chapter split)",
    },
    { value: "rixmlpack", label: "RIXML" },
  ];

  const publicationTypeTags: SelectProps["options"] = [
    { value: "CS", label: "Company Snapshot" },
    { value: "IS", label: "Industry Snapshot" },
    { value: "ISS", label: "Industry Snapshot Summary" },
    { value: "IR", label: "Acquisdata Industry Reports" },
    { value: "GS", label: "Global Industry Snapshot" },
    { value: "ES", label: "Executive Snapshot" },
    { value: "EM", label: "Executive Movement" },
    { value: "AM", label: "Mergers and Acquisition" },
    { value: "AMS", label: "Mergers and Acquisition Summary" },
    { value: "EMS", label: "Executive Movement Summary" },
    { value: "ESS", label: "Executive Snapshot Summary" },
  ];
  const documentTypeTags: SelectProps["options"] = [
    { value: "DOCX", label: "Word Document" },
    { value: "PDF", label: "PDF" },
    { value: "XML", label: "XML" },
  ];

  const defaultPublicationFilenames: any = [
    ["IS", "IS{documentid}"],
    ["GS", "GS{documentid}"],
    ["CS", "CS{documentid}"],
    ["ISS", "IS{documentid}_wd"],
    ["ES", "ES{documentid}"],
    ["EM", "EM{documentid}"],
    ["AM", "AM{documentid}"],
  ];

  const ftpmode: SelectProps["options"] = [
    { value: "ftp", label: "FTP" },
    { value: "sftp", label: "SFTP" },
  ];

  const defaultxmlHeaderDoccodeTagMap: any = [
    ["IS", "industrysnapshots"],
    ["IR", "industryreports"],
    ["GS", "globalindustrysnapshot"],
    ["CS", "companysnapshot"],
    ["ES", "executivesnapshot"],
    ["EM", "executivemovement"],
    ["AM", "mergeracquisition"],
  ];

  const defaultxmlTemplates: any = [
    ["IS", "LEGACY-IS-xml-template.xml"],
    ["IR", "LEGACY-IR-xml-template.xml"],
    ["GS", "LEGACY-GS-xml-template.xml"],
    ["CS", "LEGACY-CS-xml-template.xml"],
    ["ISS", "LEGACY-ISS-xml-template.xml"],
    ["ES", "LEGACY-ES-xml-template.xml"],
    ["EM", "LEGACY-EM-xml-template.xml"],
    ["AM", "LEGACY-AM-xml-template.xml"],
    ["AMS", "LEGACY-AMS-xml-template.xml"],
    ["EMS", "LEGACY-EMS-xml-template.xml"],
    ["ESS", "LEGACY-ESS-xml-template.xml"],
  ];

  const defaultxmlSummaryHeaderDoccodeTagMap: any = [
    ["IS", "LEGACY-IS-SUM-xml-template.xml"],
  ];

  const SpanToTags: SelectProps["options"] = [
    { value: "em", label: "em" },
    { value: "u", label: "u" },
  ];

  const CompanyRef: SelectProps["options"] = [
    { value: "isin", label: "ISIN" },
    { value: "perm_id", label: "Perm ID" },
  ];

  const IndustryRef: SelectProps["options"] = [
    { value: "trbc", label: "TRBC" },
    { value: "naic", label: "NAIC" },
    { value: "isc", label: "SIC" },
  ];

  const groupByRef: SelectProps["options"] = [
    { value: "industry", label: "Industry" },
    { value: "country", label: "Country" },
  ];

  const sortByRef: SelectProps["options"] = [
    { value: "mtime", label: "Modified Time" },
    { value: "publishdate", label: "Published Date" },
    // { value: "ctime", label: "Created Time" },
  ];

  const summariseByRef: SelectProps["options"] = [
    { value: "Q", label: "Quarterly" },
    // { value: "ctime", label: "Created Time" },
  ];

  const handleRefClose = () => {
    let redirect: any = localStorage.getItem("redirect");
    let configuration_gui: any = localStorage.getItem("configuration_gui");
    let original_configuration: any = localStorage.getItem(
      "original_configuration"
    );
    let parsedOriData = JSON.parse(original_configuration);
    let parsedConfigData = JSON.parse(configuration_gui);
    let changes: any = localStorage.getItem("changes");
    let parsedchanges = JSON.parse(changes);
    let parsedData = JSON.parse(redirect);
    // console.log("parsedchanges", parsedchanges);
    // console.log("parsedOriData", parsedOriData);
    if (parsedOriData !== null) {
      if (parsedchanges === true) {
        Modal.confirm({
          title: (
            <>
              Leaving <strong>{parsedConfigData?.package}</strong>
            </>
          ),
          content: (
            <>
              Are you sure you want to leave{" "}
              <strong>{parsedConfigData?.package}</strong> ?{" "}
            </>
          ),
          okText: "Save and Close",
          cancelText: "Remain on page",
          onOk() {
            handleRefSave();
          },
        });
      } else {
        Modal.confirm({
          title: (
            <>
              Leaving
              {/* <strong>{parsedConfigData.package}</strong> */}
            </>
          ),
          content: (
            <>
              Are you sure you want to leave{" "}
              {/* <strong>{parsedConfigData.package}</strong> */}?{" "}
            </>
          ),
          okText: "Yes",
          cancelText: "No",
          onOk() {
            // if (props.changeMode && typeof props.changeMode === "function") {
            //   props.changeMode(null);
            // }
            localStorage.removeItem("industry_mapping");
            navigate(`/${parsedData.navigate}`, {
              replace: parsedData.replace,
            });
            localStorage.removeItem("redirect");
            localStorage.removeItem("template");
            localStorage.removeItem("configuration_gui");
            localStorage.removeItem("changes");
            localStorage.removeItem("original_configuration");
          },
        });
      }
    } else {
      // console.log("data", parsedConfigData.package);
      if (parsedchanges === true) {
        Modal.confirm({
          title: (
            <>
              Leaving
              {/* <strong>{parsedConfigData.package}</strong> */}
            </>
          ),
          content: (
            <>
              Are you sure you want to leave?{" "}
              {/* <strong>{parsedConfigData.package}</strong> ?{" "} */}
            </>
          ),
          okText: "Save and Close",
          cancelText: "Remain on page",
          onOk() {
            handleRefSave();
          },
        });
      } else {
        Modal.confirm({
          title: (
            <>
              Leaving
              {/* <strong>{parsedConfigData.package}</strong> */}
            </>
          ),
          content: (
            <>
              Are you sure you want to leave{" "}
              {/* <strong>{parsedConfigData.package}</strong> */}?{" "}
            </>
          ),
          okText: "Yes",
          cancelText: "No",
          onOk() {
            localStorage.removeItem("industry_mapping");
            navigate(`/${parsedData.navigate}`, {
              replace: parsedData.replace,
            });
            localStorage.removeItem("redirect");
            localStorage.removeItem("template");
            localStorage.removeItem("configuration_gui");
            localStorage.removeItem("changes");
            localStorage.removeItem("original_configuration");
          },
        });
      }
    }
  };

  const handleRefEditClose = () => {
    // console.log("fname", form.getFieldsValue().template_name);
    let redirect: any = localStorage.getItem("redirect");
    let parsedData = JSON.parse(redirect);
    if (location.state.data !== null) {
      if (datachange === true) {
        Modal.confirm({
          title: (
            <>
              Leaving <strong>{data.package}</strong>
            </>
          ),
          content: (
            <>
              Are you sure you want to leave <strong>{data.package}</strong> ?{" "}
            </>
          ),
          okText: "Save and Close",
          cancelText: "Remain on page",
          onOk() {
            handleRefSave();
            localStorage.removeItem("template");
          },
        });
      } else {
        Modal.confirm({
          title: (
            <>
              Leaving <strong>{data.package}</strong>
            </>
          ),
          content: (
            <>
              Are you sure you want to leave <strong>{data.package}</strong> ?{" "}
            </>
          ),
          okText: "Yes",
          cancelText: "No",
          onOk() {
            localStorage.removeItem("industry_mapping");
            navigate(`/${parsedData.navigate}`, {
              replace: parsedData.replace,
            });
            localStorage.removeItem("redirect");
            localStorage.removeItem("template");
          },
        });
      }
    } else {
      Modal.confirm({
        title: (
          <>
            Leaving <strong>{data.package}</strong>
          </>
        ),
        content: (
          <>
            Are you sure you want to leave <strong>{data.package}</strong> ?{" "}
          </>
        ),
        okText: "Yes",
        cancelText: "No",
        onOk() {
          localStorage.removeItem("industry_mapping");
          navigate(`/${parsedData.navigate}`, {
            replace: parsedData.replace,
          });
          localStorage.removeItem("redirect");
          localStorage.removeItem("template");
        },
      });
    }
  };

  const next = () => {
    // console.log(requiredconfig.length, "required");
    // console.log(optionalconfig.length, "optional");
    // console.log(advancedconfig.length, "advanced");
    // console.log("current", current);
    if (current === 0) {
      if (activeKey === 1) {
        form.submit();
      } else if (activeKey !== 1 && activeKey < requiredconfig.length) {
        onTabChange(activeKey + 1);
      } else {
        setCurrent(current + 1);
        onTabChange(1);
      }
    } else if (current === 1) {
      if (activeKey < optionalconfig.length) {
        onTabChange(activeKey + 1);
      } else {
        setCurrent(current + 1);
        onTabChange(1);
      }
    }
    // else if (current === 2) {
    //   if (activeKey < advancedconfig.length) {
    //     onTabChange(activeKey + 1);
    //   } else {
    //     handleSave();
    //   }
    // }
  };

  const prev = () => {
    if (current === 0) {
      if (activeKey > 1) {
        onTabChange(activeKey - 1);
      }
    } else if (current === 1) {
      if (activeKey > 1) {
        onTabChange(activeKey - 1);
      } else {
        setCurrent(current - 1);
        onTabChange(requiredconfig.length);
      }
    }
    //  else if (current === 2) {
    //   if (activeKey > 1) {
    //     onTabChange(activeKey - 1);
    //   } else {
    //     setCurrent(current - 1);
    //     onTabChange(optionalconfig.length);
    //   }
    // }
  };

  // const onTabChange = (key: any) => {
  //   if (current === 0 && activeKey === 1) {
  //     if (
  //       (form.getFieldsValue().package !== null ||
  //         form.getFieldsValue().package !== "") &&
  //       (form.getFieldsValue().mode !== null ||
  //         form.getFieldsValue().mode !== "") &&
  //       (form.getFieldsValue().doccodes !== null ||
  //         form.getFieldsValue().doccodes !== "") &&
  //       (form.getFieldsValue().fileTypes !== null ||
  //         form.getFieldsValue().fileTypes !== "")
  //     ) {
  //       setActiveKey(key);
  //     }
  //   } else {
  //     setActiveKey(key);
  //   }
  // };

  const onChangeCopy = (key: any, selectedoption: any) => {
    if (
      form.getFieldsValue().package !== null &&
      form.getFieldsValue().package !== "" &&
      form.getFieldsValue().mode !== null &&
      form.getFieldsValue().mode !== "" &&
      form.getFieldsValue().doccodes &&
      form.getFieldsValue().doccodes.length > 0 &&
      form.getFieldsValue().fileTypes &&
      form.getFieldsValue().fileTypes.length > 0
    ) {
      Modal.confirm({
        title: "Change Copy Config?",
        content:
          "Are you sure you want to change config value to the selected copy from config?",
        okText: "Yes",
        cancelText: "No",
        onOk() {
          setCopyConfig(selectedoption?.label ?? "");
          for (const item of location.state.dataset) {
            if (item._id === key) {
              const Copy_config = _.cloneDeep(item);
              Copy_config.package = Copy_config.package + "_copy";
              Copy_config.distributionName =
                Copy_config.distributionName + "_copy";
              Copy_config.name = Copy_config.name + "_copy";
              setData(Copy_config);
              form.setFieldsValue({
                package: Copy_config.package,
                mode: Copy_config.mode,
                doccodes: Copy_config.doccodes,
                fileTypes: Copy_config.fileTypes,
                server: Copy_config.ftp.server,
                user: Copy_config.ftp.user,
                password: Copy_config.ftp.password,
              });

              localStorage.setItem(
                "original_configuration",
                JSON.stringify(Copy_config)
              );
              localStorage.setItem(
                "template",
                JSON.stringify({
                  template: Copy_config.package,
                })
              );
              // localStorage.setItem("changes", JSON.stringify(datachange));
              setDataChange(true);
            }
          }
        },
      });
    } else {
      if (data?._id && data?._id !== key) {
        Modal.confirm({
          title: "Change Copy Config?",
          content:
            "Are you sure you want to change the copy from config value?",
          okText: "Yes",
          cancelText: "No",
          onOk() {
            setCopyConfig(selectedoption?.label ?? "");
            for (const item of location.state.dataset) {
              if (item._id === key) {
                const Copy_config = _.cloneDeep(item);
                Copy_config.package = Copy_config.package + "_copy";
                Copy_config.distributionName =
                  Copy_config.distributionName + "_copy";
                Copy_config.name = Copy_config.name + "_copy";
                setData(Copy_config);
                form.setFieldsValue({
                  package: Copy_config.package,
                  mode: Copy_config.mode,
                  doccodes: Copy_config.doccodes,
                  fileTypes: Copy_config.fileTypes,
                  server: Copy_config.ftp.server,
                  user: Copy_config.ftp.user,
                  password: Copy_config.ftp.password,
                });

                localStorage.setItem(
                  "original_configuration",
                  JSON.stringify(Copy_config)
                );
                localStorage.setItem(
                  "template",
                  JSON.stringify({
                    template: Copy_config.package,
                  })
                );
                // localStorage.setItem("changes", JSON.stringify(datachange));
                setDataChange(true);
              }
            }
          },
        });
      } else {
        setCopyConfig(selectedoption?.label ?? "");
        for (const item of location.state.dataset) {
          if (item._id === key) {
            const Copy_config = _.cloneDeep(item);
            Copy_config.package = Copy_config.package + "_copy";
            Copy_config.distributionName =
              Copy_config.distributionName + "_copy";
            Copy_config.name = Copy_config.name + "_copy";
            setData(Copy_config);
            form.setFieldsValue({
              package: Copy_config.package,
              mode: Copy_config.mode,
              doccodes: Copy_config.doccodes,
              fileTypes: Copy_config.fileTypes,
              server: Copy_config.ftp.server,
              user: Copy_config.ftp.user,
              password: Copy_config.ftp.password,
            });

            localStorage.setItem(
              "original_configuration",
              JSON.stringify(Copy_config)
            );
            localStorage.setItem(
              "template",
              JSON.stringify({
                template: Copy_config.package,
              })
            );
            // localStorage.setItem("changes", JSON.stringify(datachange));
            setDataChange(true);
          }
        }
      }
    }
  };

  const onTabChange = (key: any) => {
    if (current === 0 && activeKey === 1) {
      if (
        form.getFieldsValue().package !== null &&
        form.getFieldsValue().package !== "" &&
        form.getFieldsValue().mode !== null &&
        form.getFieldsValue().mode !== "" &&
        form.getFieldsValue().doccodes &&
        form.getFieldsValue().doccodes.length > 0 &&
        form.getFieldsValue().fileTypes &&
        form.getFieldsValue().fileTypes.length > 0
      ) {
        if (location.state.data !== null) {
          if (form.getFieldsValue().package !== location.state.data.package) {
            let a = 0;
            for (const x of location.state.dataset) {
              if (
                form.getFieldsValue().package.toLowerCase() ===
                x.package.toLowerCase()
              ) {
                a += 1;
                form.submit();
              }
            }
            if (a > 0) {
              form.submit();
            } else {
              setData(
                Object.assign({}, data, {
                  package: form.getFieldsValue().package,
                  distributionName: form.getFieldsValue().package,
                  name: form.getFieldsValue().package,
                  mode: form.getFieldsValue().mode,
                  doccodes: form.getFieldsValue().doccodes,
                  fileTypes: form.getFieldsValue().fileTypes,
                })
              );
              setActiveKey(key);
            }
          } else {
            setData(
              Object.assign({}, data, {
                package: form.getFieldsValue().package,
                distributionName: form.getFieldsValue().package,
                name: form.getFieldsValue().package,
                mode: form.getFieldsValue().mode,
                doccodes: form.getFieldsValue().doccodes,
                fileTypes: form.getFieldsValue().fileTypes,
              })
            );
            setActiveKey(key);
          }
        } else {
          let a = 0;
          for (const x of location.state.dataset) {
            if (
              form.getFieldsValue().package.toLowerCase() ===
              x.package.toLowerCase()
            ) {
              a += 1;
              form.submit();
            }
          }
          if (a > 0) {
            form.submit();
          } else {
            setData(
              Object.assign({}, data, {
                package: form.getFieldsValue().package,
                distributionName: form.getFieldsValue().package,
                name: form.getFieldsValue().package,
                mode: form.getFieldsValue().mode,
                doccodes: form.getFieldsValue().doccodes,
                fileTypes: form.getFieldsValue().fileTypes,
              })
            );
            setActiveKey(key);
          }
        }
      } else {
        form.submit();
      }
    } else if (current === 1 && activeKey === 1) {
      if (
        (form.getFieldsValue().server === null ||
          form.getFieldsValue().server === "" ||
          form.getFieldsValue().server === undefined) &&
        (form.getFieldsValue().user === null ||
          form.getFieldsValue().user === "" ||
          form.getFieldsValue().user === undefined) &&
        (form.getFieldsValue().password === null ||
          form.getFieldsValue().password === "" ||
          form.getFieldsValue().password === undefined)
      ) {
        setActiveKey(key);
      } else if (
        form.getFieldsValue().server !== null &&
        form.getFieldsValue().server !== "" &&
        form.getFieldsValue().server !== undefined &&
        form.getFieldsValue().user !== null &&
        form.getFieldsValue().user !== "" &&
        form.getFieldsValue().user !== undefined &&
        form.getFieldsValue().password !== null &&
        form.getFieldsValue().password !== "" &&
        form.getFieldsValue().password !== undefined
      ) {
        console.log("ww");
        setData(
          Object.assign({}, data, {
            "ftp.server": form.getFieldsValue().server,
            "ftp.user": form.getFieldsValue().user,
            "ftp.password": form.getFieldsValue().password,
          })
        );
        setActiveKey(key);
      } else {
        form.submit();
      }
    } else {
      // setData(
      //   Object.assign({}, data, {
      //     package: form.getFieldsValue().package,
      //     distributionName: form.getFieldsValue().package,
      //     name: form.getFieldsValue().package,
      //     mode: form.getFieldsValue().mode,
      //     doccodes: form.getFieldsValue().doccodes,
      //     fileTypes: form.getFieldsValue().fileTypes,
      //   })
      // );
      setActiveKey(key);
    }
  };

  const onStepsChange = (key: any) => {
    if (current === 0 && activeKey === 1) {
      if (
        form.getFieldsValue().package !== null &&
        form.getFieldsValue().package !== "" &&
        form.getFieldsValue().mode !== null &&
        form.getFieldsValue().mode !== "" &&
        form.getFieldsValue().doccodes &&
        form.getFieldsValue().doccodes.length > 0 &&
        form.getFieldsValue().fileTypes &&
        form.getFieldsValue().fileTypes.length > 0
      ) {
        if (location.state.data !== null) {
          if (form.getFieldsValue().package !== location.state.data.package) {
            let a = 0;
            for (const x of location.state.dataset) {
              if (
                form.getFieldsValue().package.toLowerCase() ===
                x.package.toLowerCase()
              ) {
                a += 1;
                form.submit();
              }
            }
            if (a > 0) {
              form.submit();
            } else {
              setData(
                Object.assign({}, data, {
                  package: form.getFieldsValue().package,
                  distributionName: form.getFieldsValue().package,
                  name: form.getFieldsValue().package,
                  mode: form.getFieldsValue().mode,
                  doccodes: form.getFieldsValue().doccodes,
                  fileTypes: form.getFieldsValue().fileTypes,
                })
              );
              setCurrent(key);
              setActiveKey(1);
            }
          } else {
            setData(
              Object.assign({}, data, {
                package: form.getFieldsValue().package,
                distributionName: form.getFieldsValue().package,
                name: form.getFieldsValue().package,
                mode: form.getFieldsValue().mode,
                doccodes: form.getFieldsValue().doccodes,
                fileTypes: form.getFieldsValue().fileTypes,
              })
            );
            setCurrent(key);
            setActiveKey(1);
          }
        } else {
          let a = 0;
          for (const x of location.state.dataset) {
            if (
              form.getFieldsValue().package.toLowerCase() ===
              x.package.toLowerCase()
            ) {
              a += 1;
              form.submit();
            }
          }
          if (a > 0) {
            form.submit();
          } else {
            setData(
              Object.assign({}, data, {
                package: form.getFieldsValue().package,
                distributionName: form.getFieldsValue().package,
                name: form.getFieldsValue().package,
                mode: form.getFieldsValue().mode,
                doccodes: form.getFieldsValue().doccodes,
                fileTypes: form.getFieldsValue().fileTypes,
              })
            );
            setCurrent(key);
            setActiveKey(1);
          }
        }
      } else {
        form.submit();
      }
    } else if (current === 1 && activeKey === 1) {
      if (
        (form.getFieldsValue().server === null ||
          form.getFieldsValue().server === "" ||
          form.getFieldsValue().server === undefined) &&
        (form.getFieldsValue().user === null ||
          form.getFieldsValue().user === "" ||
          form.getFieldsValue().user === undefined) &&
        (form.getFieldsValue().password === null ||
          form.getFieldsValue().password === "" ||
          form.getFieldsValue().password === undefined)
      ) {
        setCurrent(key);
        setActiveKey(1);
      } else if (
        form.getFieldsValue().server !== null &&
        form.getFieldsValue().server !== "" &&
        form.getFieldsValue().server !== undefined &&
        form.getFieldsValue().user !== null &&
        form.getFieldsValue().user !== "" &&
        form.getFieldsValue().user !== undefined &&
        form.getFieldsValue().password !== null &&
        form.getFieldsValue().password !== "" &&
        form.getFieldsValue().password !== undefined
      ) {
        setData(
          Object.assign({}, data, {
            "ftp.server": form.getFieldsValue().server,
            "ftp.user": form.getFieldsValue().user,
            "ftp.password": form.getFieldsValue().password,
          })
        );
        setCurrent(key);
        setActiveKey(1);
      } else {
        form.submit();
      }
    } else {
      setCurrent(key);
      setActiveKey(1);
    }
  };

  const onFinish = (values: any) => {
    if (data?._id) {
      setData(data);
    } else {
      setData(
        Object.assign({}, data, values, {
          distributionName: form.getFieldsValue().package,
          name: form.getFieldsValue().package,
        })
      );
    }

    // console.log(
    //   "new_data",
    //   Object.assign({}, data, values, {
    //     distributionName: form.getFieldsValue().package,
    //     name: form.getFieldsValue().package,
    //   })
    // );
    // onTabChange(activeKey + 1);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const addNewPublicationFile = () => {
    const d: any = data;
    if (!d.publicationFilenames) {
      // d.publicationFilenames = defaultPublicationFilenames;
      // setData(d);
      setData({
        ...data,
        publicationFilenames: [...defaultPublicationFilenames],
      });
    } else {
      setData({
        ...data,
        publicationFilenames: [...data.publicationFilenames, ["", ""]],
      });
    }
  };

  const addNewManifestFile = () => {
    const d: any = data;
    // if (d.manifestFiles === undefined) d.manifestFiles = [];
    // d.manifestFiles.push("");
    // console.log("d", d);
    // setData(d);
    if (!d.manifestFiles) {
      setData({
        ...data,
        manifestFiles: [""],
      });
    } else {
      setData({
        ...data,
        manifestFiles: [...data.manifestFiles, ""],
      });
    }
  };

  const addNewHeaderDoccodeTagMapTemplate = () => {
    const d: any = data;
    setDataChange(true);
    if (!d.xmlHeaderDoccodeTagMap) {
      // d.xmlHeaderDoccodeTagMap = defaultxmlHeaderDoccodeTagMap;
      // setData(d);
      setData({
        ...data,
        xmlHeaderDoccodeTagMap: [...defaultxmlHeaderDoccodeTagMap],
      });
    } else {
      setData({
        ...data,
        xmlHeaderDoccodeTagMap: [...data.xmlHeaderDoccodeTagMap, ["", ""]],
      });
    }
  };

  const addNewSummaryTemplate = () => {
    const d: any = data;
    setDataChange(true);
    if (!d.xmlSummaryTemplates) {
      // d.xmlSummaryTemplates = defaultxmlSummaryHeaderDoccodeTagMap;
      // setData(d);
      setData({
        ...data,
        xmlSummaryTemplates: [...defaultxmlSummaryHeaderDoccodeTagMap],
      });
    } else {
      setData({
        ...data,
        xmlSummaryTemplates: [...data.xmlSummaryTemplates, ["", ""]],
      });
    }
  };

  const addNewTemplate = () => {
    const d: any = data;
    setDataChange(true);
    if (!d.xmlTemplates) {
      // d.xmlTemplates = defaultxmlTemplates;
      // setData(d);
      setData({
        ...data,
        xmlTemplates: [...defaultxmlTemplates],
      });
    } else {
      setData({
        ...data,
        xmlTemplates: [...data.xmlTemplates, ["", ""]],
      });
    }
  };

  const handleAddNewBadUrl = () => {
    const d: any = data;
    setDataChange(true);
    if (d.xmlBadUrls === undefined) {
      setData({
        ...data,
        xmlBadUrls: [""],
      });
    } else {
      setData({
        ...data,
        xmlBadUrls: [...data.xmlBadUrls, ""],
      });
    }
  };

  const handleAddNewExpression = () => {
    const d: any = data;
    setDataChange(true);
    if (d.xmlRemoveStringPatterns === undefined) {
      setData({
        ...data,
        xmlRemoveStringPatterns: [""],
      });
    } else {
      setData({
        ...data,
        xmlRemoveStringPatterns: [...data.xmlRemoveStringPatterns, ""],
      });
    }
  };

  const addNewMinimumDocumentID = () => {
    const d: any = data;
    setDataChange(true);
    if (!d.fromMinimumDocumentId) {
      // d.xmlSummaryTemplates = defaultxmlSummaryHeaderDoccodeTagMap;
      // setData(d);
      setData({
        ...data,
        fromMinimumDocumentId: [["IS", ""]],
      });
    } else {
      setData({
        ...data,
        fromMinimumDocumentId: [...data.fromMinimumDocumentId, ["", ""]],
      });
    }
  };

  const addNewDestination = () => {
    const d: any = data;
    setDataChange(true);
    if (!d.destFolders) {
      // d.xmlSummaryTemplates = defaultxmlSummaryHeaderDoccodeTagMap;
      // setData(d);
      setData({
        ...data,
        destFolders: [["IS", ""]],
      });
    } else {
      setData({
        ...data,
        destFolders: [...data.destFolders, ["", ""]],
      });
    }
  };

  const handleFilterByCountry = (e: any) => {
    const newCountryValue = e;
    // Assuming 'data' is an object with a 'filter' property
    // and 'filter' has the structure you mentioned
    const newData = { ...data };

    if (!newData.filter) {
      // Initialize 'filter' if it doesn't exist
      newData.filter = [];
    }

    if (!newData.filter[0]) {
      // Initialize the first element of 'filter' if it doesn't exist
      newData.filter[0] = { $and: [] };
    }

    if (!newData.filter[0].$and) {
      // Initialize '$and' array if it doesn't exist
      newData.filter[0].$and = [];
    }

    // Create a new filter condition with 'industry' set to $nin
    newData.filter[0].$and[1] = {
      country: { $nin: newCountryValue },
    };

    setData(newData);
    const countryIndex = newData.excludeFilter?.findIndex(
      (item: any) => "country" in item
    );

    if (countryIndex !== undefined && countryIndex !== -1) {
      if (newData.excludeFilter && newData.excludeFilter[0]) {
        newData.excludeFilter[0].country = [];
      }
    } else {
      if (!newData.excludeFilter) {
        newData.excludeFilter = [];
      }
      newData.excludeFilter?.push({ country: [] });
    }
    return newData;
  };

  // const handleFilterByCountry = (e: any) => {
  //   // const d: any = data;
  //   // const newCountryValue = e.target.value;

  //   // // Create a copy of the existing filter object
  //   // const newFilter = { ...data.filter };

  //   // // Update the "country" value in the filter object
  //   // newFilter.$and[1].country.$nin = [newCountryValue];

  //   // // Update the state with the new filter object
  //   // setData({
  //   //   ...data,
  //   //   filter: newFilter,
  //   // });
  //   const newCountryValue: any = e;
  //   const newData = { ...data }; // Create a copy of the data object to avoid mutating it.

  //   // If data.filter does not exist or is not an object, initialize it.
  //   if (!newData.filter || typeof newData.filter !== "object") {
  //     newData.filter = {};
  //   }

  //   // If newData.filter.$and does not exist or is not an array, initialize it as an array.
  //   if (!newData.filter.$and || !Array.isArray(newData.filter[0].$and)) {
  //     newData.filter.$and = {};
  //   }

  //   // If the $and array is empty or its first element does not exist or is not an object, initialize it.
  //   if (
  //     newData.filter.$and.length === 0 ||
  //     typeof newData.filter.$and[1] !== "object"
  //   ) {
  //     newData.filter.$and[1] = {};
  //   }

  //   // Finally, set the desired value.
  //   newData.filter[0].$and[1].country = { $nin: newCountryValue };
  //   // console.log("newdata", newData);
  //   // Update the data object with the modified filter.
  //   setData(newData);
  // };

  // const handleFilterByIndustry = (e: any) => {
  //   const newIndustryValue = e;
  //   const newData = { ...data };

  //   // If data.filter does not exist or is not an object, initialize it.
  //   if (!newData.filter || typeof newData.filter !== "object") {
  //     newData.filter = { $and: [] };
  //   }

  //   // If newData.filter.$and does not exist or is not an array, initialize it as an array.
  //   if (!newData.filter.$and) {
  //     newData.filter.$and = [];
  //   }

  //   // If the $and array is empty or its first element does not exist or is not an object, initialize it.
  //   if (
  //     newData.filter.$and.length === 0 ||
  //     typeof newData.filter.$and[0] !== "object"
  //   ) {
  //     newData.filter.$and[0] = { industry: { $nin: [] } };
  //   }

  //   // Finally, set the desired value.
  //   newData.filter.$and[0].industry = { $nin: newIndustryValue };

  //   // Update the data object with the modified filter.
  //   console.log("newdata", newData);
  //   setData(newData);
  // };

  const handleFilterByIndustry = (e: any) => {
    const newIndustryValue = e;
    // Assuming 'data' is an object with a 'filter' property
    // and 'filter' has the structure you mentioned
    const newData = { ...data };

    if (!newData.filter) {
      // Initialize 'filter' if it doesn't exist
      newData.filter = [];
    }

    if (!newData.filter[0]) {
      // Initialize the first element of 'filter' if it doesn't exist
      newData.filter[0] = { $and: [] };
    }

    if (!newData.filter[0].$and) {
      // Initialize '$and' array if it doesn't exist
      newData.filter[0].$and = [];
    }

    // Create a new filter condition with 'industry' set to $nin
    newData.filter[0].$and[0] = {
      industry: { $nin: newIndustryValue },
    };

    setData(newData);
    const industryIndex = newData.excludeFilter?.findIndex(
      (item: any) => "industry" in item
    );

    if (industryIndex !== undefined && industryIndex !== -1) {
      if (newData.excludeFilter && newData.excludeFilter[0]) {
        newData.excludeFilter[0].industry = [];
      }
    } else {
      if (!newData.excludeFilter) {
        newData.excludeFilter = [];
      }
      newData.excludeFilter?.push({ industry: [] });
    }
    return newData;
  };

  const handleExcludeFilterByIndustry = (e: any) => {
    setData((prevData: any) => {
      // Find the index of the object with 'industry' property in excludeFilter
      const industryIndex = prevData.excludeFilter?.findIndex(
        (item: any) => "industry" in item
      );

      // Create a copy of the data object
      const newData = { ...prevData };
      if (industryIndex !== undefined && industryIndex !== -1) {
        if (newData.excludeFilter && newData.excludeFilter[0]) {
          newData.excludeFilter[0].industry = e;
        }
      } else {
        if (!newData.excludeFilter) {
          newData.excludeFilter = [];
        }
        newData.excludeFilter?.push({ industry: e });
      }
      return newData;
    });
    // Assuming 'data' is an object with a 'filter' property
    // and 'filter' has the structure you mentioned
    const newData = { ...data };

    if (!newData.filter) {
      // Initialize 'filter' if it doesn't exist
      newData.filter = [];
    }

    if (!newData.filter[0]) {
      // Initialize the first element of 'filter' if it doesn't exist
      newData.filter[0] = { $and: [] };
    }

    if (!newData.filter[0].$and) {
      // Initialize '$and' array if it doesn't exist
      newData.filter[0].$and = [];
    }

    // Create a new filter condition with 'industry' set to $nin
    newData.filter[0].$and[0] = {
      industry: { $nin: [] },
    };

    setData(newData);
  };

  const handleExcludeFilterByCountry = (e: any) => {
    setData((prevData: any) => {
      // Find the index of the object with 'industry' property in excludeFilter
      const countryIndex = prevData.excludeFilter?.findIndex(
        (item: any) => "country" in item
      );

      // Create a copy of the data object
      const newData = { ...prevData };
      if (countryIndex !== undefined && countryIndex !== -1) {
        if (newData.excludeFilter && newData.excludeFilter[0]) {
          newData.excludeFilter[0].country = e;
        }
      } else {
        if (!newData.excludeFilter) {
          newData.excludeFilter = [];
        }
        newData.excludeFilter?.push({ country: e });
      }
      return newData;
    });
    // Assuming 'data' is an object with a 'filter' property
    // and 'filter' has the structure you mentioned
    const newData = { ...data };

    if (!newData.filter) {
      // Initialize 'filter' if it doesn't exist
      newData.filter = [];
    }

    if (!newData.filter[0]) {
      // Initialize the first element of 'filter' if it doesn't exist
      newData.filter[0] = { $and: [] };
    }

    if (!newData.filter[0].$and) {
      // Initialize '$and' array if it doesn't exist
      newData.filter[0].$and = [];
    }

    // Create a new filter condition with 'industry' set to $nin
    newData.filter[0].$and[1] = {
      country: { $nin: [] },
    };

    setData(newData);
  };

  // const convertDate = (el: any) => {
  //   // console.log("el", el);
  //   if (el !== null) {
  //     let d = new Date(el);
  //     return d.toLocaleDateString("en-GB");
  //   } else {
  //     // console.log("11");
  //     return "";
  //   }
  // };

  const showDateFormat = (el: any) => {
    // Your original date string
    const originalDateString = el;

    // Parse the original date string into a Date object
    const originalDate = new Date(originalDateString);

    // Get the year, month, and day components
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1 and pad with '0'
    const day = String(originalDate.getDate()).padStart(2, "0");

    // Create the formatted date string
    const formattedDateString = `${year}-${month}-${day}`;
    return formattedDateString;
  };

  const handleSave = () => {
    const currentDate = new Date();
    // console.log("createdata", data);
    if (current === 0 && activeKey === 1) {
      if (
        data.package !== null &&
        data.package !== "" &&
        data.mode !== null &&
        data.mode !== "" &&
        data.doccodes &&
        data.doccodes.length > 0 &&
        data.fileTypes &&
        data.fileTypes.length > 0
      ) {
        if (location.state.data === null) {
          let a = 0;
          for (const x of location.state.dataset) {
            if (data.package.toLowerCase() === x.package.toLowerCase()) {
              a += 1;
              form.submit();
            }
          }
          if (a > 0) {
            form.submit();
          } else {
            if (data) {
              delete data._id;
              createNewConfig({ ...data, lastEditDate: currentDate }).then(
                (res: any) => {
                  if (res.data) {
                    let user: any = localStorage.getItem("user");
                    let log: any = {
                      page: window.location.pathname,
                      action: "Create",
                      datetime: new Date(),
                      details: `Create Config ${data.name}`,
                      email: user,
                    };
                    addLog(log);
                    notification.success({
                      message: `New Configuration created`,
                      description: (
                        <>
                          Configuration GUI <strong>{data.name}</strong> has
                          been created successfully.
                        </>
                      ),
                      placement: "bottomRight",
                      bottom: 50,
                    });
                    navigate("/configuration_gui", {
                      replace: true,
                    });
                    localStorage.removeItem("configuration_gui");
                    localStorage.removeItem("changes");
                    localStorage.removeItem("original_configuration");
                  } else {
                    notification.error({
                      message: `New Configuration created`,
                      description: (
                        <>
                          Configuration GUI <strong>{data.name}</strong> failed
                          to been created.
                        </>
                      ),
                      placement: "bottomRight",
                      bottom: 50,
                    });
                  }
                }
              );
            }
          }
        } else {
          if (data.package !== location.state.data.package) {
            let a = 0;
            for (const x of location.state.dataset) {
              if (data.package.toLowerCase() === x.package.toLowerCase()) {
                // console.log("data.package", data.package);
                // console.log("x.package", x.package);
                a += 1;
                // form.submit();
              }
            }
            if (a > 0) {
              // console.log("1");
              form.submit();
            } else {
              // console.log("2");
              const differences = [];

              for (const key in configGUI) {
                if (configGUI.hasOwnProperty(key)) {
                  if (
                    JSON.stringify(configGUI[key]) !==
                    JSON.stringify(location.state.data[key])
                  ) {
                    differences.push(key);
                  }
                } else if (location.state.data.hasOwnProperty(key)) {
                  differences.push(key);
                }
              }
              // const differences = [];

              // for (const key in data) {
              //   if (data.hasOwnProperty(key)) {
              //     if (
              //       JSON.stringify(data[key]) !==
              //       JSON.stringify(location.state.data[key])
              //     ) {
              //       differences.push(key);
              //     }
              //   }
              // }

              if (differences.length !== 0 || datachange === true) {
                setDataChange(false);
                updateConfig({ ...data, lastEditDate: currentDate }).then(
                  (res: any) => {
                    if (res.data) {
                      let user: any = localStorage.getItem("user");
                      let log: any = {
                        page: window.location.pathname,
                        action: "Update",
                        datetime: new Date(),
                        details: `Update Config ${data.name}`,
                        email: user,
                      };
                      addLog(log);
                      notification.success({
                        message: `New Configuration updated`,
                        description: (
                          <>
                            Configuration GUI <strong>{data.name}</strong> has
                            been updated successfully.
                          </>
                        ),
                        placement: "bottomRight",
                        bottom: 50,
                      });

                      navigate("/configuration_gui", {
                        replace: true,
                      });
                      localStorage.removeItem("configuration_gui");
                      localStorage.removeItem("changes");
                      localStorage.removeItem("original_configuration");
                    } else {
                      notification.error({
                        message: `New Configuration updated`,
                        description: (
                          <>
                            Configuration GUI <strong>{data.name}</strong>{" "}
                            failed to been updated.
                          </>
                        ),
                        placement: "bottomRight",
                        bottom: 50,
                      });
                    }
                  }
                );
              } else {
                navigate("/configuration_gui", {
                  replace: true,
                });
              }
            }
          } else {
            // const differences = [];

            // for (const key in data) {
            //   if (data.hasOwnProperty(key)) {
            //     if (
            //       JSON.stringify(data[key]) !==
            //       JSON.stringify(location.state.data[key])
            //     ) {
            //       differences.push(key);
            //     }
            //   }
            // }
            const differences = [];

            for (const key in configGUI) {
              if (configGUI.hasOwnProperty(key)) {
                if (
                  JSON.stringify(configGUI[key]) !==
                  JSON.stringify(location.state.data[key])
                ) {
                  differences.push(key);
                }
              } else if (location.state.data.hasOwnProperty(key)) {
                differences.push(key);
              }
            }

            if (differences.length !== 0 || datachange === true) {
              setDataChange(false);
              updateConfig({ ...data, lastEditDate: currentDate }).then(
                (res: any) => {
                  if (res.data) {
                    let user: any = localStorage.getItem("user");
                    let log: any = {
                      page: window.location.pathname,
                      action: "Update",
                      datetime: new Date(),
                      details: `Update Config ${data.name}`,
                      email: user,
                    };
                    addLog(log);
                    notification.success({
                      message: `New Configuration updated`,
                      description: (
                        <>
                          Configuration GUI <strong>{data.name}</strong> has
                          been updated successfully.
                        </>
                      ),
                      placement: "bottomRight",
                      bottom: 50,
                    });

                    navigate("/configuration_gui", {
                      replace: true,
                    });
                    localStorage.removeItem("configuration_gui");
                    localStorage.removeItem("changes");
                    localStorage.removeItem("original_configuration");
                  } else {
                    notification.error({
                      message: `New Configuration updated`,
                      description: (
                        <>
                          Configuration GUI <strong>{data.name}</strong> failed
                          to been updated.
                        </>
                      ),
                      placement: "bottomRight",
                      bottom: 50,
                    });
                  }
                }
              );
            } else {
              navigate("/configuration_gui", {
                replace: true,
              });
              localStorage.removeItem("configuration_gui");
              localStorage.removeItem("changes");
              localStorage.removeItem("original_configuration");
            }
          }
        }
      } else {
        form.submit();
      }
    } else if (current === 1 && activeKey === 1) {
      if (location.state.data === null) {
        let a = 0;
        for (const x of location.state.dataset) {
          if (data.package.toLowerCase() === x.package.toLowerCase()) {
            a += 1;
            form.submit();
          }
        }
        if (a > 0) {
          form.submit();
        } else {
          if (data) {
            if (
              !(
                ((form.getFieldsValue().server === null ||
                  form.getFieldsValue().server === "" ||
                  form.getFieldsValue().server === undefined) &&
                  (form.getFieldsValue().user === null ||
                    form.getFieldsValue().user === "" ||
                    form.getFieldsValue().user === undefined) &&
                  (form.getFieldsValue().password === null ||
                    form.getFieldsValue().password === "" ||
                    form.getFieldsValue().password === undefined)) ||
                (form.getFieldsValue().server !== null &&
                  form.getFieldsValue().server !== "" &&
                  form.getFieldsValue().server !== undefined &&
                  form.getFieldsValue().user !== null &&
                  form.getFieldsValue().user !== "" &&
                  form.getFieldsValue().user !== undefined &&
                  form.getFieldsValue().password !== null &&
                  form.getFieldsValue().password !== "" &&
                  form.getFieldsValue().password !== undefined)
              )
            ) {
              form.submit();
            } else {
              setDataChange(false);
              delete data._id;
              createNewConfig({ ...data, lastEditDate: currentDate }).then(
                (res: any) => {
                  if (res.data) {
                    let user: any = localStorage.getItem("user");
                    let log: any = {
                      page: window.location.pathname,
                      action: "Create",
                      datetime: new Date(),
                      details: `Create Config ${data.name}`,
                      email: user,
                    };
                    addLog(log);
                    notification.success({
                      message: `New Configuration created`,
                      description: (
                        <>
                          Configuration GUI <strong>{data.name}</strong> has
                          been created successfully.
                        </>
                      ),
                      placement: "bottomRight",
                      bottom: 50,
                    });
                    navigate("/configuration_gui", {
                      replace: true,
                    });
                    localStorage.removeItem("configuration_gui");
                    localStorage.removeItem("changes");
                    localStorage.removeItem("original_configuration");
                  } else {
                    notification.error({
                      message: `New Configuration created`,
                      description: (
                        <>
                          Configuration GUI <strong>{data.name}</strong> failed
                          to been created.
                        </>
                      ),
                      placement: "bottomRight",
                      bottom: 50,
                    });
                  }
                }
              );
            }
          }
        }
      } else {
        if (data.package !== location.state.data.package) {
          let a = 0;
          for (const x of location.state.dataset) {
            if (data.package.toLowerCase() === x.package.toLowerCase()) {
              a += 1;
              // form.submit();
            }
          }
          if (a > 0) {
            form.submit();
          } else {
            const differences = [];

            for (const key in configGUI) {
              if (configGUI.hasOwnProperty(key)) {
                if (
                  JSON.stringify(configGUI[key]) !==
                  JSON.stringify(location.state.data[key])
                ) {
                  differences.push(key);
                }
              } else if (location.state.data.hasOwnProperty(key)) {
                differences.push(key);
              }
            }
            // const differences = [];

            // for (const key in data) {
            //   if (data.hasOwnProperty(key)) {
            //     if (
            //       JSON.stringify(data[key]) !==
            //       JSON.stringify(location.state.data[key])
            //     ) {
            //       differences.push(key);
            //     }
            //   }
            // }

            if (differences.length !== 0 || datachange === true) {
              if (
                !(
                  ((form.getFieldsValue().server === null ||
                    form.getFieldsValue().server === "" ||
                    form.getFieldsValue().server === undefined) &&
                    (form.getFieldsValue().user === null ||
                      form.getFieldsValue().user === "" ||
                      form.getFieldsValue().user === undefined) &&
                    (form.getFieldsValue().password === null ||
                      form.getFieldsValue().password === "" ||
                      form.getFieldsValue().password === undefined)) ||
                  (form.getFieldsValue().server !== null &&
                    form.getFieldsValue().server !== "" &&
                    form.getFieldsValue().server !== undefined &&
                    form.getFieldsValue().user !== null &&
                    form.getFieldsValue().user !== "" &&
                    form.getFieldsValue().user !== undefined &&
                    form.getFieldsValue().password !== null &&
                    form.getFieldsValue().password !== "" &&
                    form.getFieldsValue().password !== undefined)
                )
              ) {
                // let ftp: any = { server: "", user: "", password: "" };
                // updateConfig({
                //   ...data,
                //   lastEditDate: currentDate,
                //   autoFtp: false,
                //   ftp,
                // }).then((res: any) => {
                //   if (res.data) {
                //     let user: any = localStorage.getItem("user");
                //     let log: any = {
                //       page: window.location.pathname,
                //       action: "Update",
                //       datetime: new Date(),
                //       details: `Update Config ${data.name}`,
                //       email: user,
                //     };
                //     addLog(log);
                //     notification.success({
                //       message: `New Configuration updated`,
                //       description: (
                //         <>
                //           Configuration GUI <strong>{data.name}</strong> has
                //           been updated successfully.
                //         </>
                //       ),
                //       placement: "bottomRight",
                //       bottom: 50,
                //     });
                //     notification.error({
                //       message: `FTP Configuration`,
                //       description: (
                //         <>
                //           FTP stop processing due to changes of imcompleted FTP
                //           details
                //         </>
                //       ),
                //       placement: "bottomRight",
                //       bottom: 50,
                //     });

                //     navigate("/configuration_gui", {
                //       replace: true,
                //     });
                //     localStorage.removeItem("configuration_gui");
                //     localStorage.removeItem("changes");
                //     localStorage.removeItem("original_configuration");
                //   } else {
                //     notification.error({
                //       message: `New Configuration updated`,
                //       description: (
                //         <>
                //           Configuration GUI <strong>{data.name}</strong> failed
                //           to been updated.
                //         </>
                //       ),
                //       placement: "bottomRight",
                //       bottom: 50,
                //     });
                //   }
                // });
                form.submit();
              } else {
                setDataChange(false);
                let ftp: any = {
                  server: form.getFieldsValue().server,
                  user: form.getFieldsValue().user,
                  password: form.getFieldsValue().password,
                };
                updateConfig({ ...data, lastEditDate: currentDate, ftp }).then(
                  (res: any) => {
                    if (res.data) {
                      let user: any = localStorage.getItem("user");
                      let log: any = {
                        page: window.location.pathname,
                        action: "Update",
                        datetime: new Date(),
                        details: `Update Config ${data.name}`,
                        email: user,
                      };
                      addLog(log);
                      notification.success({
                        message: `New Configuration updated`,
                        description: (
                          <>
                            Configuration GUI <strong>{data.name}</strong> has
                            been updated successfully.
                          </>
                        ),
                        placement: "bottomRight",
                        bottom: 50,
                      });

                      navigate("/configuration_gui", {
                        replace: true,
                      });
                      localStorage.removeItem("configuration_gui");
                      localStorage.removeItem("changes");
                      localStorage.removeItem("original_configuration");
                    } else {
                      notification.error({
                        message: `New Configuration updated`,
                        description: (
                          <>
                            Configuration GUI <strong>{data.name}</strong>{" "}
                            failed to been updated.
                          </>
                        ),
                        placement: "bottomRight",
                        bottom: 50,
                      });
                    }
                  }
                );
              }
            } else {
              navigate("/configuration_gui", {
                replace: true,
              });
            }
          }
        } else {
          const differences = [];

          for (const key in configGUI) {
            if (configGUI.hasOwnProperty(key)) {
              if (
                JSON.stringify(configGUI[key]) !==
                JSON.stringify(location.state.data[key])
              ) {
                differences.push(key);
              }
            } else if (location.state.data.hasOwnProperty(key)) {
              differences.push(key);
            }
          }

          if (differences.length !== 0 || datachange === true) {
            if (
              !(
                ((form.getFieldsValue().server === null ||
                  form.getFieldsValue().server === "" ||
                  form.getFieldsValue().server === undefined) &&
                  (form.getFieldsValue().user === null ||
                    form.getFieldsValue().user === "" ||
                    form.getFieldsValue().user === undefined) &&
                  (form.getFieldsValue().password === null ||
                    form.getFieldsValue().password === "" ||
                    form.getFieldsValue().password === undefined)) ||
                (form.getFieldsValue().server !== null &&
                  form.getFieldsValue().server !== "" &&
                  form.getFieldsValue().server !== undefined &&
                  form.getFieldsValue().user !== null &&
                  form.getFieldsValue().user !== "" &&
                  form.getFieldsValue().user !== undefined &&
                  form.getFieldsValue().password !== null &&
                  form.getFieldsValue().password !== "" &&
                  form.getFieldsValue().password !== undefined)
              )
            ) {
              // let ftp: any = { server: "", user: "", password: "" };
              // updateConfig({
              //   ...data,
              //   lastEditDate: currentDate,
              //   autoFtp: false,
              //   ftp,
              // }).then((res: any) => {
              //   if (res.data) {
              //     let user: any = localStorage.getItem("user");
              //     let log: any = {
              //       page: window.location.pathname,
              //       action: "Update",
              //       datetime: new Date(),
              //       details: `Update Config ${data.name}`,
              //       email: user,
              //     };
              //     addLog(log);
              //     notification.success({
              //       message: `New Configuration updated`,
              //       description: (
              //         <>
              //           Configuration GUI <strong>{data.name}</strong> has been
              //           updated successfully.
              //         </>
              //       ),
              //       placement: "bottomRight",
              //       bottom: 50,
              //     });
              //     notification.error({
              //       message: `FTP Configuration`,
              //       description: (
              //         <>
              //           FTP stop processing due to changes of imcompleted FTP
              //           details
              //         </>
              //       ),
              //       placement: "bottomRight",
              //       bottom: 50,
              //     });

              //     navigate("/configuration_gui", {
              //       replace: true,
              //     });
              //     localStorage.removeItem("configuration_gui");
              //     localStorage.removeItem("changes");
              //     localStorage.removeItem("original_configuration");
              //   } else {
              //     notification.error({
              //       message: `New Configuration updated`,
              //       description: (
              //         <>
              //           Configuration GUI <strong>{data.name}</strong> failed to
              //           been updated.
              //         </>
              //       ),
              //       placement: "bottomRight",
              //       bottom: 50,
              //     });
              //   }
              // });
              form.submit();
            } else {
              setDataChange(false);
              let ftp: any = {
                server: form.getFieldsValue().server,
                user: form.getFieldsValue().user,
                password: form.getFieldsValue().password,
              };
              updateConfig({ ...data, lastEditDate: currentDate, ftp }).then(
                (res: any) => {
                  if (res.data) {
                    let user: any = localStorage.getItem("user");
                    let log: any = {
                      page: window.location.pathname,
                      action: "Update",
                      datetime: new Date(),
                      details: `Update Config ${data.name}`,
                      email: user,
                    };
                    addLog(log);
                    notification.success({
                      message: `New Configuration updated`,
                      description: (
                        <>
                          Configuration GUI <strong>{data.name}</strong> has
                          been updated successfully.
                        </>
                      ),
                      placement: "bottomRight",
                      bottom: 50,
                    });

                    navigate("/configuration_gui", {
                      replace: true,
                    });
                    localStorage.removeItem("configuration_gui");
                    localStorage.removeItem("changes");
                    localStorage.removeItem("original_configuration");
                  } else {
                    notification.error({
                      message: `New Configuration updated`,
                      description: (
                        <>
                          Configuration GUI <strong>{data.name}</strong> failed
                          to been updated.
                        </>
                      ),
                      placement: "bottomRight",
                      bottom: 50,
                    });
                  }
                }
              );
            }
          } else {
            navigate("/configuration_gui", {
              replace: true,
            });
            localStorage.removeItem("configuration_gui");
            localStorage.removeItem("changes");
            localStorage.removeItem("original_configuration");
          }
        }

        // console.log("old_data", location.state.data);
        // console.log("data", data);
        // console.log("datachange", datachange);
        // const jsonData = JSON.stringify(data);
        // console.log("jsondata", jsonData);
      }
    } else {
      if (location.state.data === null) {
        if (data) {
          delete data._id;
          createNewConfig({ ...data, lastEditDate: currentDate }).then(
            (res: any) => {
              if (res.data) {
                let user: any = localStorage.getItem("user");
                let log: any = {
                  page: window.location.pathname,
                  action: "Create",
                  datetime: new Date(),
                  details: `Create Config ${data.name}`,
                  email: user,
                };
                addLog(log);
                notification.success({
                  message: `New Configuration created`,
                  description: (
                    <>
                      Configuration GUI <strong>{data.name}</strong> has been
                      created successfully.
                    </>
                  ),
                  placement: "bottomRight",
                  bottom: 50,
                });
                navigate("/configuration_gui", {
                  replace: true,
                });
                localStorage.removeItem("configuration_gui");
                localStorage.removeItem("changes");
                localStorage.removeItem("original_configuration");
              } else {
                notification.error({
                  message: `New Configuration created`,
                  description: (
                    <>
                      Configuration GUI <strong>{data.name}</strong> failed to
                      been created.
                    </>
                  ),
                  placement: "bottomRight",
                  bottom: 50,
                });
              }
            }
          );
        }
      } else {
        // const jsonData = JSON.stringify(data);
        // console.log("jsondata", jsonData);
        if (location.state.data !== null) {
          const differences = [];

          for (const key in configGUI) {
            if (configGUI.hasOwnProperty(key)) {
              if (
                JSON.stringify(configGUI[key]) !==
                JSON.stringify(location.state.data[key])
              ) {
                differences.push(key);
              }
            } else if (location.state.data.hasOwnProperty(key)) {
              differences.push(key);
            }
          }
          // const differences = [];

          // for (const key in data) {
          //   if (data.hasOwnProperty(key)) {
          //     if (
          //       JSON.stringify(data[key]) !==
          //       JSON.stringify(location.state.data[key])
          //     ) {
          //       differences.push(key);
          //     }
          //   }
          // }

          if (differences.length !== 0 || datachange === true) {
            setDataChange(false);
            updateConfig({ ...data, lastEditDate: currentDate }).then(
              (res: any) => {
                if (res.data) {
                  let user: any = localStorage.getItem("user");
                  let log: any = {
                    page: window.location.pathname,
                    action: "Update",
                    datetime: new Date(),
                    details: `Update Config ${data.name}`,
                    email: user,
                  };
                  addLog(log);
                  notification.success({
                    message: `New Configuration updated`,
                    description: (
                      <>
                        Configuration GUI <strong>{data.name}</strong> has been
                        updated successfully.
                      </>
                    ),
                    placement: "bottomRight",
                    bottom: 50,
                  });

                  navigate("/configuration_gui", {
                    replace: true,
                  });
                  localStorage.removeItem("configuration_gui");
                  localStorage.removeItem("changes");
                  localStorage.removeItem("original_configuration");
                } else {
                  notification.error({
                    message: `New Configuration updated`,
                    description: (
                      <>
                        Configuration GUI <strong>{data.name}</strong> failed to
                        been updated.
                      </>
                    ),
                    placement: "bottomRight",
                    bottom: 50,
                  });
                }
              }
            );
          } else {
            navigate("/configuration_gui", {
              replace: true,
            });
          }
        }
      }
    }
  };

  const handleRefSave = () => {
    const currentDate = new Date();
    let redirect: any = localStorage.getItem("redirect");
    let configuration_gui: any = localStorage.getItem("configuration_gui");
    let parsedConfigData = JSON.parse(configuration_gui);
    let parsedData = JSON.parse(redirect);
    if (current === 0 && activeKey === 1) {
      if (parsedConfigData !== null) {
        if (location.state.data === null) {
          let a = 0;
          for (const x of location.state.dataset) {
            if (
              parsedConfigData.package.toLowerCase() === x.package.toLowerCase()
            ) {
              a += 1;
              form.submit();
            }
          }
          if (a > 0) {
            form.submit();
          } else {
            if (parsedConfigData) {
              if (
                !(
                  ((form.getFieldsValue().server === null ||
                    form.getFieldsValue().server === "" ||
                    form.getFieldsValue().server === undefined) &&
                    (form.getFieldsValue().user === null ||
                      form.getFieldsValue().user === "" ||
                      form.getFieldsValue().user === undefined) &&
                    (form.getFieldsValue().password === null ||
                      form.getFieldsValue().password === "" ||
                      form.getFieldsValue().password === undefined)) ||
                  (form.getFieldsValue().server !== null &&
                    form.getFieldsValue().server !== "" &&
                    form.getFieldsValue().server !== undefined &&
                    form.getFieldsValue().user !== null &&
                    form.getFieldsValue().user !== "" &&
                    form.getFieldsValue().user !== undefined &&
                    form.getFieldsValue().password !== null &&
                    form.getFieldsValue().password !== "" &&
                    form.getFieldsValue().password !== undefined)
                )
              ) {
                form.submit();
              } else {
                setDataChange(false);
                delete parsedConfigData._id;
                createNewConfig({
                  ...parsedConfigData,
                  lastEditDate: currentDate,
                }).then((res: any) => {
                  if (res.data) {
                    let user: any = localStorage.getItem("user");
                    let log: any = {
                      page: window.location.pathname,
                      action: "Create",
                      datetime: new Date(),
                      details: `Create Config ${parsedConfigData.name}`,
                      email: user,
                    };
                    addLog(log);
                    notification.success({
                      message: `New Configuration created`,
                      description: (
                        <>
                          Configuration GUI{" "}
                          <strong>{parsedConfigData.name}</strong> has been
                          created successfully.
                        </>
                      ),
                      placement: "bottomRight",
                      bottom: 50,
                    });
                    localStorage.removeItem("configuration_gui");
                    // navigate("/configuration_gui", {
                    //   replace: true,
                    // });
                    navigate(`/${parsedData.navigate}`, {
                      replace: parsedData.replace,
                    });
                    localStorage.removeItem("redirect");
                    localStorage.removeItem("configuration_gui");
                    localStorage.removeItem("changes");
                    localStorage.removeItem("original_configuration");
                    localStorage.removeItem("template");
                  } else {
                    notification.error({
                      message: `New Configuration created`,
                      description: (
                        <>
                          Configuration GUI{" "}
                          <strong>{parsedConfigData.name}</strong> failed to
                          been created.
                        </>
                      ),
                      placement: "bottomRight",
                      bottom: 50,
                    });
                  }
                });
              }
            }
          }
        } else {
          if (parsedConfigData.package !== location.state.data.package) {
            let a = 0;
            for (const x of location.state.dataset) {
              if (
                parsedConfigData.package.toLowerCase() ===
                x.package.toLowerCase()
              ) {
                a += 1;
                // form.submit();
              }
            }
            if (a > 0) {
              form.submit();
            } else {
              const differences = [];

              for (const key in configGUI) {
                if (configGUI.hasOwnProperty(key)) {
                  if (
                    JSON.stringify(configGUI[key]) !==
                    JSON.stringify(location.state.data[key])
                  ) {
                    differences.push(key);
                  }
                } else if (location.state.data.hasOwnProperty(key)) {
                  differences.push(key);
                }
              }

              if (differences.length !== 0 || datachange === true) {
                if (
                  form.getFieldsValue().server === null ||
                  form.getFieldsValue().server === "" ||
                  form.getFieldsValue().server === undefined ||
                  form.getFieldsValue().user === null ||
                  form.getFieldsValue().user === "" ||
                  form.getFieldsValue().user === undefined ||
                  form.getFieldsValue().password === null ||
                  form.getFieldsValue().password === "" ||
                  form.getFieldsValue().password === undefined
                ) {
                  form.submit();
                } else {
                  setDataChange(false);
                  updateConfig({
                    ...parsedConfigData,
                    lastEditDate: currentDate,
                  }).then((res: any) => {
                    if (res.data) {
                      let user: any = localStorage.getItem("user");
                      let log: any = {
                        page: window.location.pathname,
                        action: "Update",
                        datetime: new Date(),
                        details: `Update Config ${parsedConfigData.name}`,
                        email: user,
                      };
                      addLog(log);
                      notification.success({
                        message: `New Configuration updated`,
                        description: (
                          <>
                            Configuration GUI{" "}
                            <strong>{parsedConfigData.name}</strong> has been
                            updated successfully.
                          </>
                        ),
                        placement: "bottomRight",
                        bottom: 50,
                      });

                      navigate(`/${parsedData.navigate}`, {
                        replace: parsedData.replace,
                      });
                      localStorage.removeItem("configuration_gui");
                      localStorage.removeItem("redirect");
                      localStorage.removeItem("original_configuration");
                      localStorage.removeItem("configuration_gui");
                      localStorage.removeItem("changes");
                      localStorage.removeItem("template");
                    } else {
                      notification.error({
                        message: `New Configuration updated`,
                        description: (
                          <>
                            Configuration GUI{" "}
                            <strong>{parsedConfigData.name}</strong> failed to
                            been updated.
                          </>
                        ),
                        placement: "bottomRight",
                        bottom: 50,
                      });
                    }
                  });
                }
              } else {
                localStorage.removeItem("configuration_gui");
                localStorage.removeItem("original_configuration");
                navigate(`/${parsedData.navigate}`, {
                  replace: parsedData.replace,
                });
                localStorage.removeItem("redirect");
                localStorage.removeItem("changes");
                localStorage.removeItem("template");
              }
            }
          } else {
            const differences = [];

            for (const key in configGUI) {
              if (configGUI.hasOwnProperty(key)) {
                if (
                  JSON.stringify(configGUI[key]) !==
                  JSON.stringify(location.state.data[key])
                ) {
                  differences.push(key);
                }
              } else if (location.state.data.hasOwnProperty(key)) {
                differences.push(key);
              }
            }

            // console.log("location", location.state.data);
            // console.log("parsedConfigData", parsedConfigData);

            if (differences.length !== 0 || datachange === true) {
              if (
                !(
                  ((form.getFieldsValue().server === null ||
                    form.getFieldsValue().server === "" ||
                    form.getFieldsValue().server === undefined) &&
                    (form.getFieldsValue().user === null ||
                      form.getFieldsValue().user === "" ||
                      form.getFieldsValue().user === undefined) &&
                    (form.getFieldsValue().password === null ||
                      form.getFieldsValue().password === "" ||
                      form.getFieldsValue().password === undefined)) ||
                  (form.getFieldsValue().server !== null &&
                    form.getFieldsValue().server !== "" &&
                    form.getFieldsValue().server !== undefined &&
                    form.getFieldsValue().user !== null &&
                    form.getFieldsValue().user !== "" &&
                    form.getFieldsValue().user !== undefined &&
                    form.getFieldsValue().password !== null &&
                    form.getFieldsValue().password !== "" &&
                    form.getFieldsValue().password !== undefined)
                )
              ) {
                form.submit();
              } else {
                setDataChange(false);
                updateConfig({
                  ...parsedConfigData,
                  lastEditDate: currentDate,
                }).then((res: any) => {
                  if (res.data) {
                    let user: any = localStorage.getItem("user");
                    let log: any = {
                      page: window.location.pathname,
                      action: "Update",
                      datetime: new Date(),
                      details: `Update Config ${parsedConfigData.name}`,
                      email: user,
                    };
                    addLog(log);
                    notification.success({
                      message: `New Configuration updated`,
                      description: (
                        <>
                          Configuration GUI{" "}
                          <strong>{parsedConfigData.name}</strong> has been
                          updated successfully.
                        </>
                      ),
                      placement: "bottomRight",
                      bottom: 50,
                    });

                    navigate(`/${parsedData.navigate}`, {
                      replace: parsedData.replace,
                    });
                    localStorage.removeItem("configuration_gui");
                    localStorage.removeItem("original_configuration");
                    localStorage.removeItem("redirect");
                    localStorage.removeItem("changes");
                    localStorage.removeItem("template");
                  } else {
                    notification.error({
                      message: `New Configuration updated`,
                      description: (
                        <>
                          Configuration GUI{" "}
                          <strong>{parsedConfigData.name}</strong> failed to
                          been updated.
                        </>
                      ),
                      placement: "bottomRight",
                      bottom: 50,
                    });
                  }
                });
              }
            } else {
              localStorage.removeItem("original_configuration");
              localStorage.removeItem("configuration_gui");
              navigate(`/${parsedData.navigate}`, {
                replace: parsedData.replace,
              });
              localStorage.removeItem("redirect");

              localStorage.removeItem("changes");
              localStorage.removeItem("template");
            }
          }

          // console.log("old_data", location.state.data);
          // console.log("data", data);
          // console.log("datachange", datachange);
          // const jsonData = JSON.stringify(data);
          // console.log("jsondata", jsonData);
        }
      } else {
        form.submit();
      }
    } else if (current === 1 && activeKey === 1) {
      if (parsedConfigData !== null) {
        if (location.state.data === null) {
          let a = 0;
          for (const x of location.state.dataset) {
            if (
              parsedConfigData.package.toLowerCase() === x.package.toLowerCase()
            ) {
              a += 1;
              form.submit();
            }
          }
          if (a > 0) {
            form.submit();
          } else {
            if (parsedConfigData) {
              if (
                !(
                  ((form.getFieldsValue().server === null ||
                    form.getFieldsValue().server === "" ||
                    form.getFieldsValue().server === undefined) &&
                    (form.getFieldsValue().user === null ||
                      form.getFieldsValue().user === "" ||
                      form.getFieldsValue().user === undefined) &&
                    (form.getFieldsValue().password === null ||
                      form.getFieldsValue().password === "" ||
                      form.getFieldsValue().password === undefined)) ||
                  (form.getFieldsValue().server !== null &&
                    form.getFieldsValue().server !== "" &&
                    form.getFieldsValue().server !== undefined &&
                    form.getFieldsValue().user !== null &&
                    form.getFieldsValue().user !== "" &&
                    form.getFieldsValue().user !== undefined &&
                    form.getFieldsValue().password !== null &&
                    form.getFieldsValue().password !== "" &&
                    form.getFieldsValue().password !== undefined)
                )
              ) {
                form.submit();
              } else {
                delete parsedConfigData._id;
                createNewConfig({
                  ...parsedConfigData,
                  lastEditDate: currentDate,
                }).then((res: any) => {
                  if (res.data) {
                    let user: any = localStorage.getItem("user");
                    let log: any = {
                      page: window.location.pathname,
                      action: "Create",
                      datetime: new Date(),
                      details: `Create Config ${parsedConfigData.name}`,
                      email: user,
                    };
                    addLog(log);
                    notification.success({
                      message: `New Configuration created`,
                      description: (
                        <>
                          Configuration GUI{" "}
                          <strong>{parsedConfigData.name}</strong> has been
                          created successfully.
                        </>
                      ),
                      placement: "bottomRight",
                      bottom: 50,
                    });
                    localStorage.removeItem("configuration_gui");
                    // navigate("/configuration_gui", {
                    //   replace: true,
                    // });
                    navigate(`/${parsedData.navigate}`, {
                      replace: parsedData.replace,
                    });
                    localStorage.removeItem("redirect");
                    localStorage.removeItem("configuration_gui");
                    localStorage.removeItem("changes");
                    localStorage.removeItem("original_configuration");
                    localStorage.removeItem("template");
                  } else {
                    notification.error({
                      message: `New Configuration created`,
                      description: (
                        <>
                          Configuration GUI{" "}
                          <strong>{parsedConfigData.name}</strong> failed to
                          been created.
                        </>
                      ),
                      placement: "bottomRight",
                      bottom: 50,
                    });
                  }
                });
              }
            }
          }
        } else {
          if (parsedConfigData.package !== location.state.data.package) {
            let a = 0;
            for (const x of location.state.dataset) {
              if (
                parsedConfigData.package.toLowerCase() ===
                x.package.toLowerCase()
              ) {
                a += 1;
                // form.submit();
              }
            }
            if (a > 0) {
              form.submit();
            } else {
              const differences = [];

              for (const key in configGUI) {
                if (configGUI.hasOwnProperty(key)) {
                  if (
                    JSON.stringify(configGUI[key]) !==
                    JSON.stringify(location.state.data[key])
                  ) {
                    differences.push(key);
                  }
                } else if (location.state.data.hasOwnProperty(key)) {
                  differences.push(key);
                }
              }

              if (differences.length !== 0 || datachange === true) {
                if (
                  !(
                    ((form.getFieldsValue().server === null ||
                      form.getFieldsValue().server === "" ||
                      form.getFieldsValue().server === undefined) &&
                      (form.getFieldsValue().user === null ||
                        form.getFieldsValue().user === "" ||
                        form.getFieldsValue().user === undefined) &&
                      (form.getFieldsValue().password === null ||
                        form.getFieldsValue().password === "" ||
                        form.getFieldsValue().password === undefined)) ||
                    (form.getFieldsValue().server !== null &&
                      form.getFieldsValue().server !== "" &&
                      form.getFieldsValue().server !== undefined &&
                      form.getFieldsValue().user !== null &&
                      form.getFieldsValue().user !== "" &&
                      form.getFieldsValue().user !== undefined &&
                      form.getFieldsValue().password !== null &&
                      form.getFieldsValue().password !== "" &&
                      form.getFieldsValue().password !== undefined)
                  )
                ) {
                  form.submit();
                } else {
                  setDataChange(false);
                  let ftp: any = {
                    server: form.getFieldsValue().server,
                    user: form.getFieldsValue().user,
                    password: form.getFieldsValue().password,
                  };
                  updateConfig({
                    ...parsedConfigData,
                    lastEditDate: currentDate,
                    ftp,
                  }).then((res: any) => {
                    if (res.data) {
                      let user: any = localStorage.getItem("user");
                      let log: any = {
                        page: window.location.pathname,
                        action: "Update",
                        datetime: new Date(),
                        details: `Update Config ${parsedConfigData.name}`,
                        email: user,
                      };
                      addLog(log);
                      notification.success({
                        message: `New Configuration updated`,
                        description: (
                          <>
                            Configuration GUI{" "}
                            <strong>{parsedConfigData.name}</strong> has been
                            updated successfully.
                          </>
                        ),
                        placement: "bottomRight",
                        bottom: 50,
                      });

                      navigate(`/${parsedData.navigate}`, {
                        replace: parsedData.replace,
                      });
                      localStorage.removeItem("configuration_gui");
                      localStorage.removeItem("redirect");
                      localStorage.removeItem("original_configuration");
                      localStorage.removeItem("configuration_gui");
                      localStorage.removeItem("changes");
                      localStorage.removeItem("template");
                    } else {
                      notification.error({
                        message: `New Configuration updated`,
                        description: (
                          <>
                            Configuration GUI{" "}
                            <strong>{parsedConfigData.name}</strong> failed to
                            been updated.
                          </>
                        ),
                        placement: "bottomRight",
                        bottom: 50,
                      });
                    }
                  });
                }
              } else {
                localStorage.removeItem("configuration_gui");
                localStorage.removeItem("original_configuration");
                navigate(`/${parsedData.navigate}`, {
                  replace: parsedData.replace,
                });
                localStorage.removeItem("redirect");
                localStorage.removeItem("changes");
                localStorage.removeItem("template");
              }
            }
          } else {
            const differences = [];

            for (const key in configGUI) {
              if (configGUI.hasOwnProperty(key)) {
                if (
                  JSON.stringify(configGUI[key]) !==
                  JSON.stringify(location.state.data[key])
                ) {
                  differences.push(key);
                }
              } else if (location.state.data.hasOwnProperty(key)) {
                differences.push(key);
              }
            }

            if (differences.length !== 0 || datachange === true) {
              if (
                !(
                  ((form.getFieldsValue().server === null ||
                    form.getFieldsValue().server === "" ||
                    form.getFieldsValue().server === undefined) &&
                    (form.getFieldsValue().user === null ||
                      form.getFieldsValue().user === "" ||
                      form.getFieldsValue().user === undefined) &&
                    (form.getFieldsValue().password === null ||
                      form.getFieldsValue().password === "" ||
                      form.getFieldsValue().password === undefined)) ||
                  (form.getFieldsValue().server !== null &&
                    form.getFieldsValue().server !== "" &&
                    form.getFieldsValue().server !== undefined &&
                    form.getFieldsValue().user !== null &&
                    form.getFieldsValue().user !== "" &&
                    form.getFieldsValue().user !== undefined &&
                    form.getFieldsValue().password !== null &&
                    form.getFieldsValue().password !== "" &&
                    form.getFieldsValue().password !== undefined)
                )
              ) {
                form.submit();
              } else {
                setDataChange(false);
                let ftp: any = {
                  server: form.getFieldsValue().server,
                  user: form.getFieldsValue().user,
                  password: form.getFieldsValue().password,
                };
                updateConfig({
                  ...parsedConfigData,
                  lastEditDate: currentDate,
                  ftp,
                }).then((res: any) => {
                  if (res.data) {
                    let user: any = localStorage.getItem("user");
                    let log: any = {
                      page: window.location.pathname,
                      action: "Update",
                      datetime: new Date(),
                      details: `Update Config ${parsedConfigData.name}`,
                      email: user,
                    };
                    addLog(log);
                    notification.success({
                      message: `New Configuration updated`,
                      description: (
                        <>
                          Configuration GUI{" "}
                          <strong>{parsedConfigData.name}</strong> has been
                          updated successfully.
                        </>
                      ),
                      placement: "bottomRight",
                      bottom: 50,
                    });

                    navigate(`/${parsedData.navigate}`, {
                      replace: parsedData.replace,
                    });
                    localStorage.removeItem("configuration_gui");
                    localStorage.removeItem("original_configuration");
                    localStorage.removeItem("redirect");
                    localStorage.removeItem("changes");
                    localStorage.removeItem("template");
                  } else {
                    notification.error({
                      message: `New Configuration updated`,
                      description: (
                        <>
                          Configuration GUI{" "}
                          <strong>{parsedConfigData.name}</strong> failed to
                          been updated.
                        </>
                      ),
                      placement: "bottomRight",
                      bottom: 50,
                    });
                  }
                });
              }
            } else {
              localStorage.removeItem("original_configuration");
              localStorage.removeItem("configuration_gui");
              navigate(`/${parsedData.navigate}`, {
                replace: parsedData.replace,
              });
              localStorage.removeItem("redirect");

              localStorage.removeItem("changes");
              localStorage.removeItem("template");
            }
          }
        }
      } else {
        form.submit();
      }
    } else {
      if (location.state.data === null) {
        if (parsedConfigData) {
          delete parsedConfigData._id;
          createNewConfig({
            ...parsedConfigData,
            lastEditDate: currentDate,
          }).then((res: any) => {
            if (res.data) {
              let user: any = localStorage.getItem("user");
              let log: any = {
                page: window.location.pathname,
                action: "Create",
                datetime: new Date(),
                details: `Create Config ${parsedConfigData.name}`,
                email: user,
              };
              addLog(log);
              notification.success({
                message: `New Configuration created`,
                description: (
                  <>
                    Configuration GUI <strong>{parsedConfigData.name}</strong>{" "}
                    has been created successfully.
                  </>
                ),
                placement: "bottomRight",
                bottom: 50,
              });
              navigate("/configuration_gui", {
                replace: true,
              });
            } else {
              notification.error({
                message: `New Configuration created`,
                description: (
                  <>
                    Configuration GUI <strong>{parsedConfigData.name}</strong>{" "}
                    failed to been created.
                  </>
                ),
                placement: "bottomRight",
                bottom: 50,
              });
            }
          });
        }
      } else {
        // console.log("old_data", location.state.data);
        // console.log("data", data);
        // console.log("datachange", datachange);
        // const jsonData = JSON.stringify(data);
        // console.log("jsondata", jsonData);
        if (location.state.data !== null) {
          const differences = [];

          for (const key in configGUI) {
            if (configGUI.hasOwnProperty(key)) {
              if (
                JSON.stringify(configGUI[key]) !==
                JSON.stringify(location.state.data[key])
              ) {
                differences.push(key);
              }
            } else if (location.state.data.hasOwnProperty(key)) {
              differences.push(key);
            }
          }

          if (differences.length !== 0 || datachange === true) {
            setDataChange(false);
            updateConfig({
              ...parsedConfigData,
              lastEditDate: currentDate,
            }).then((res: any) => {
              if (res.data) {
                let user: any = localStorage.getItem("user");
                let log: any = {
                  page: window.location.pathname,
                  action: "Update",
                  datetime: new Date(),
                  details: `Update Config ${parsedConfigData.name}`,
                  email: user,
                };
                addLog(log);
                notification.success({
                  message: `New Configuration updated`,
                  description: (
                    <>
                      Configuration GUI <strong>{parsedConfigData.name}</strong>{" "}
                      has been updated successfully.
                    </>
                  ),
                  placement: "bottomRight",
                  bottom: 50,
                });

                navigate("/configuration_gui", {
                  replace: true,
                });
              } else {
                notification.error({
                  message: `New Configuration updated`,
                  description: (
                    <>
                      Configuration GUI <strong>{parsedConfigData.name}</strong>{" "}
                      failed to been updated.
                    </>
                  ),
                  placement: "bottomRight",
                  bottom: 50,
                });
              }
            });
          } else {
            navigate("/configuration_gui", {
              replace: true,
            });
          }
        }
      }
    }
  };

  const handleReset = () => {
    Modal.confirm({
      title: "Cancel Changes?",
      content: "Are you sure you want to cancel?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        if (location.state.data !== null) {
          let original_data: any = localStorage.getItem(
            "original_configuration"
          );
          let parsedConfigData = JSON.parse(original_data);
          setData(parsedConfigData);
          form.setFieldsValue({
            package: parsedConfigData.package,
            distributionName: parsedConfigData.package,
            name: parsedConfigData.package,
            mode: parsedConfigData.mode,
            doccodes: parsedConfigData.doccodes,
            fileTypes: parsedConfigData.fileTypes,
            server: parsedConfigData.ftp.server,
            user: parsedConfigData.ftp.user,
            password: parsedConfigData.ftp.password,
          });
          if (formRef.current !== undefined) {
            formRef.current.resetFields(["server", "user", "password"]);
          }
          setDataChange(false);
          localStorage.removeItem("changes");
          setCopyConfig(null);
        } else {
          setData({});
          form.resetFields();
          setActiveKey(1);
          setCurrent(0);
          if (formRef.current !== undefined) {
            formRef.current.resetFields(["server", "user", "password"]);
          }
          setDataChange(false);
          localStorage.removeItem("changes");
          setCopyConfig(null);
        }
      },
    });
  };

  const backToConfigurationGUI = () => {
    let changes: any = localStorage.getItem("configuration_gui");
    let selected: any = localStorage.getItem("template");
    if (changes || selected) {
      localStorage.setItem(
        "redirect",
        JSON.stringify({ navigate: "configuration_gui", replace: true })
      );
      Events.emit("ConfigurationTrigger");
    }
  };

  const handleFilterIndustry = (e: any) => {
    setSelectedIndustryRadio(e.target.value);
  };

  const handleFilterCountry = (e: any) => {
    setSelectedCountryRadio(e.target.value);
  };

  const formData = [
    {
      key: "package",
      name: "package",
      label: (
        <>
          <span>Package Name</span>
        </>
      ),
      labelCol: { style: { fontWeight: "600" } },
      rules: [
        { required: true, message: "Please enter your package name" },
        {
          validator: async (rule: any, value: any) => {
            if (location.state.data !== null) {
              // console.log(
              //   "location.state.data.package",
              //   location.state.data.package
              // );
              if (value !== location.state.data.package) {
                for (const x of location.state.dataset) {
                  if (value.toLowerCase() === x.package.toLowerCase()) {
                    // console.log("Found a duplicate:", value, x.package);
                    return Promise.reject(
                      "Please rename the package as the package you entered is already in use."
                    );
                  }
                }
                return Promise.resolve();
              } else {
                return Promise.resolve();
              }
            } else {
              for (const x of location.state.dataset) {
                if (value.toLowerCase() === x.package.toLowerCase()) {
                  // console.log("Found a duplicate:", value, x.package);
                  return Promise.reject(
                    "Please rename the package as the package you entered is already in use."
                  );
                }
              }
              return Promise.resolve();
            }
          },
        },
      ],
      validateTrigger: "onBlur",
      component: (
        <Input
          placeholder="Package Name"
          onChange={(e: any) => {
            setDataChange(true);
            setData({
              ...data,
              package: e.target.value,
              name: e.target.value,
              distributionName: e.target.value,
            });
          }}
        />
      ),
    },
    // {
    //   key: "key",
    //   name: "key",
    //   label: (
    //     <>
    //       <span>Key</span>
    //     </>
    //   ),
    //   initialValue: data?.name ? data?.name : "reportal",
    //   labelCol: { style: { fontWeight: "600" } },
    //   rules: [{ required: true, message: "Please enter your key name" }],
    //   validateTrigger: "onBlur",
    //   // initialValue: "reportal",
    //   component: <Input placeholder="Name of Key" defaultValue={"reportal"} />,
    // },
    {
      key: "mode",
      name: "mode",
      label: (
        <>
          <span>Packing Mode</span>
        </>
      ),
      labelCol: { style: { fontWeight: "600" } },
      rules: [{ required: true, message: "Please enter your packing mode" }],
      validateTrigger: "onBlur",
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          options={packingMode}
          allowClear
          // value={data.mode}
          placeholder="Packing Mode"
          style={{
            width: "100%",
            textAlign: "start",
            backgroundColor: "#F0F0F0",
          }}
          onChange={(e: any) => {
            setDataChange(true);
            setData({ ...data, mode: e });
          }}
        ></Select>
      ),
    },
    {
      key: "doccodes",
      name: "doccodes",
      label: (
        <>
          <span>Publication Types to Include</span>
        </>
      ),
      labelCol: { style: { fontWeight: "600" } },
      rules: [
        {
          required: true,
          message: "Please select your publication types to include",
        },
      ],
      validateTrigger: "onBlur",
      component: (
        // <div className="input-form">
        //   <label className="label-form">Name</label>
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          mode="tags"
          showArrow
          options={publicationTypeTags}
          // value={data.mode}
          placeholder="Publication Types to Include"
          style={{
            width: "100%",
            textAlign: "start",
            backgroundColor: "#F0F0F0",
          }}
          onChange={(e: any) => {
            setDataChange(true);
            setData({ ...data, doccodes: e });
          }}
        ></Select>
      ),
    },
    {
      key: "fileTypes",
      name: "fileTypes",
      label: (
        <>
          <span>Documents to Include</span>
        </>
      ),
      labelCol: { style: { fontWeight: "600" } },
      rules: [
        { required: true, message: "Please select your documents to include" },
      ],
      validateTrigger: "onBlur",
      component: (
        // <div className="input-form">
        //   <label className="label-form">Name</label>
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          mode="tags"
          showArrow
          options={documentTypeTags}
          // value={data.mode}
          placeholder="Documents to Include"
          style={{
            width: "100%",
            textAlign: "start",
            backgroundColor: "#F0F0F0",
          }}
          onChange={(e: any) => {
            setDataChange(true);
            setData({ ...data, fileTypes: e });
          }}
        ></Select>
      ),
    },
  ];

  const handleCheckFTP = () => {
    if (
      (form.getFieldsValue().server === null ||
        form.getFieldsValue().server === "" ||
        form.getFieldsValue().server === undefined) &&
      (form.getFieldsValue().user === null ||
        form.getFieldsValue().user === "" ||
        form.getFieldsValue().user === undefined) &&
      (form.getFieldsValue().password === null ||
        form.getFieldsValue().password === "" ||
        form.getFieldsValue().password === undefined)
    ) {
      setAutoftp(true);
      setData({
        ...data,
        autoFtp: false,
        ftp: { ...data.ftp, server: "", user: "", password: "" },
      });
      form.setFields([
        { name: "server", errors: [] },
        { name: "user", errors: [] },
        { name: "password", errors: [] },
      ]);
    } else if (
      form.getFieldsValue().server !== null &&
      form.getFieldsValue().server !== "" &&
      form.getFieldsValue().server !== undefined &&
      form.getFieldsValue().user !== null &&
      form.getFieldsValue().user !== "" &&
      form.getFieldsValue().user !== undefined &&
      form.getFieldsValue().password !== null &&
      form.getFieldsValue().password !== "" &&
      form.getFieldsValue().password !== undefined
    ) {
      setAutoftp(false);
      form.setFields([
        { name: "server", errors: [] },
        { name: "user", errors: [] },
        { name: "password", errors: [] },
      ]);
      setData({
        ...data,
        autoFtp: true,
        ftp: {
          ...data.ftp,
          server: form.getFieldsValue().server,
          user: form.getFieldsValue().user,
          password: form.getFieldsValue().password,
        },
      });
    } else {
      setAutoftp(true);
      setData({
        ...data,
        autoFtp: false,
        ftp: {
          ...data.ftp,
          server: form.getFieldsValue().server,
          user: form.getFieldsValue().user,
          password: form.getFieldsValue().password,
        },
      });
      formRef.current.validateFields(["server", "user", "password"]);
    }
  };

  const FTPformData = [
    {
      key: "server",
      name: "server",
      label: (
        <>
          <span>Server</span>
        </>
      ),
      labelCol: { style: { fontWeight: "600" } },
      rules: [
        {
          validator: async (rule: any, value: any) => {
            if (
              (value === null || value === "" || value === undefined) &&
              (form.getFieldsValue().user === null ||
                form.getFieldsValue().user === "" ||
                form.getFieldsValue().user === undefined) &&
              (form.getFieldsValue().password === null ||
                form.getFieldsValue().password === "" ||
                form.getFieldsValue().password === undefined)
            ) {
              return Promise.resolve();
            } else if (
              value !== null &&
              value !== "" &&
              value !== undefined &&
              form.getFieldsValue().user !== null &&
              form.getFieldsValue().user !== "" &&
              form.getFieldsValue().user !== undefined &&
              form.getFieldsValue().password !== null &&
              form.getFieldsValue().password !== "" &&
              form.getFieldsValue().password !== undefined
            ) {
              return Promise.resolve();
            } else {
              return Promise.reject("Server required for enable FTP service.");
            }
          },
        },
      ],
      validateTrigger: "onBlur",
      component: (
        <Input
          placeholder="Server Name"
          onChange={(e: any) => {
            setDataChange(true);
            // setData({
            //   ...data,
            //   ftp: {
            //     ...data.ftp,
            //     server: e.target.value,
            //   },
            // });
            handleCheckFTP();
          }}
        />
      ),
    },
    {
      key: "user",
      name: "user",
      label: (
        <>
          <span>Username</span>
        </>
      ),
      labelCol: { style: { fontWeight: "600" } },
      rules: [
        {
          validator: async (rule: any, value: any) => {
            if (
              (value === null || value === "" || value === undefined) &&
              (form.getFieldsValue().server === null ||
                form.getFieldsValue().server === "" ||
                form.getFieldsValue().server === undefined) &&
              (form.getFieldsValue().password === null ||
                form.getFieldsValue().password === "" ||
                form.getFieldsValue().password === undefined)
            ) {
              return Promise.resolve();
            } else if (
              form.getFieldsValue().server !== null &&
              form.getFieldsValue().server !== "" &&
              form.getFieldsValue().server !== undefined &&
              value !== null &&
              value !== "" &&
              value !== undefined &&
              form.getFieldsValue().password !== null &&
              form.getFieldsValue().password !== "" &&
              form.getFieldsValue().password !== undefined
            ) {
              return Promise.resolve();
            } else {
              return Promise.reject(
                "Username required for enable FTP service."
              );
            }
          },
        },
      ],
      validateTrigger: "onBlur",
      component: (
        <Input
          placeholder="User Name"
          onChange={(e: any) => {
            setDataChange(true);
            // setData({
            //   ...data,
            //   ftp: {
            //     ...data.ftp,
            //     user: e.target.value,
            //   },
            // });

            handleCheckFTP();
          }}
        />
      ),
    },
    {
      key: "password",
      name: "password",
      label: (
        <>
          <span>Password</span>
        </>
      ),
      labelCol: { style: { fontWeight: "600" } },
      rules: [
        {
          validator: async (rule: any, value: any) => {
            if (
              (value === null || value === "" || value === undefined) &&
              (form.getFieldsValue().server === null ||
                form.getFieldsValue().server === "" ||
                form.getFieldsValue().server === undefined) &&
              (form.getFieldsValue().user === null ||
                form.getFieldsValue().user === "" ||
                form.getFieldsValue().user === undefined)
            ) {
              return Promise.resolve();
            } else if (
              form.getFieldsValue().server !== null &&
              form.getFieldsValue().server !== "" &&
              form.getFieldsValue().server !== undefined &&
              value !== null &&
              value !== "" &&
              value !== undefined &&
              form.getFieldsValue().user !== null &&
              form.getFieldsValue().user !== "" &&
              form.getFieldsValue().user !== undefined
            ) {
              return Promise.resolve();
            } else {
              return Promise.reject(
                "Password required for enable FTP service."
              );
            }
          },
        },
      ],
      validateTrigger: "onBlur",
      component: (
        <Input.Password
          placeholder="Password"
          onChange={(e: any) => {
            setDataChange(true);
            // setData({
            //   ...data,
            //   ftp: {
            //     ...data.ftp,
            //     password: e.target.value,
            //   },
            // });
            handleCheckFTP();
          }}
        />
      ),
    },
  ];

  // const formDataManagement = [    {
  //   key: "sortBy",
  //   name: "sortBy",
  //   label: (
  //     <>
  //       <span>Package</span>
  //     </>
  //   ),
  //   labelCol: { style: { fontWeight: "600" } },
  //   rules: [{ required: true, message: "Please enter your package name" }],
  //   validateTrigger: "onBlur",
  //   component: (
  //     // <div className="input-form">
  //     //   <label className="label-form">Name</label>
  //     <Input placeholder="Name of Package" />
  //     // </div>
  //   ),
  // },    {
  //   key: "package",
  //   name: "package",
  //   label: (
  //     <>
  //       <span>Package</span>
  //     </>
  //   ),
  //   labelCol: { style: { fontWeight: "600" } },
  //   rules: [{ required: true, message: "Please enter your package name" }],
  //   validateTrigger: "onBlur",
  //   component: (
  //     // <div className="input-form">
  //     //   <label className="label-form">Name</label>
  //     <Input placeholder="Name of Package" />
  //     // </div>
  //   ),
  // },    {
  //   key: "package",
  //   name: "package",
  //   label: (
  //     <>
  //       <span>Package</span>
  //     </>
  //   ),
  //   labelCol: { style: { fontWeight: "600" } },
  //   rules: [{ required: true, message: "Please enter your package name" }],
  //   validateTrigger: "onBlur",
  //   component: (
  //     // <div className="input-form">
  //     //   <label className="label-form">Name</label>
  //     <Input placeholder="Name of Package" />
  //     // </div>
  //   ),
  // },]

  const requiredconfig = [
    {
      key: 1,
      title: "Basic Information",
      content: (
        <>
          {" "}
          <Form
            style={{ width: "100%", margin: "auto", textAlign: "start" }}
            form={form}
            layout="vertical"
            labelCol={{ span: 4 }}
            wrapperCol={{ flex: 14 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              package: location.state.data ? location.state.data.package : "",
              mode: location.state.data ? location.state.data.mode : null,
              doccodes: location.state.data ? location.state.data.doccodes : [],
              fileTypes: location.state.data
                ? location.state.data.fileTypes
                : [],
            }}
          >
            {formData.map((item: any) => (
              <Form.Item {...item}>{item.component}</Form.Item>
            ))}
          </Form>
        </>
      ),
    },
    {
      key: 2,
      title: "Data Management",
      content: (
        <>
          {" "}
          <span>Sort By</span>
          {/* <Input
            size="large"
            placeholder="SortBy FieldName"
            style={{ marginBottom: "20px" }}
            value={data.sortBy}
            onChange={(e: any) => {
              setData({
                ...data,
                sortBy: e.target.value,
              });
            }}
          /> */}
          <Select
            listHeight={160}
            getPopupContainer={(trigger) => trigger.parentElement}
            options={sortByRef}
            value={data.sortBy}
            placeholder="Sort By FieldName"
            style={{ width: "100%", marginBottom: "20px" }}
            showArrow
            allowClear
            onChange={(option: any) => {
              const newsortBy = option ? option : null;
              setDataChange(true);
              setData({ ...data, sortBy: newsortBy });
            }}
          />
          <span>Summarise By</span>
          {/* <Input
            size="large"
            placeholder="Summarise By FieldName"
            style={{ marginBottom: "20px" }}
            value={data.summariseBy}
            onChange={(e: any) => {
              setData({
                ...data,
                summariseBy: e.target.value,
              });
            }}
          /> */}
          <Select
            listHeight={160}
            getPopupContainer={(trigger) => trigger.parentElement}
            options={summariseByRef}
            value={data.summariseBy}
            placeholder="Summarise By FieldName"
            style={{ width: "100%", marginBottom: "20px" }}
            showArrow
            allowClear
            onChange={(option: any) => {
              const newSummariseBy = option ? option : null;
              setDataChange(true);
              setData({ ...data, summariseBy: newSummariseBy });
            }}
          />
          <span>Group By</span>
          {/* <Input
            size="large"
            placeholder="GroupBy FieldName"
            style={{ marginBottom: "20px" }}
            value={data.groupBy}
            onChange={(e: any) => {
              setData({
                ...data,
                groupBy: e.target.value,
              });
            }}
          /> */}
          <Select
            listHeight={160}
            getPopupContainer={(trigger) => trigger.parentElement}
            options={groupByRef}
            value={data.groupBy}
            placeholder="Group By FieldName"
            style={{ width: "100%", marginBottom: "20px" }}
            showArrow
            allowClear
            onChange={(option: any) => {
              const newgroupBy = option ? option : null;
              setDataChange(true);
              setData({ ...data, groupBy: newgroupBy });
            }}
          />
        </>
      ),
    },
    {
      key: 3,
      title: "Mapping",
      content: (
        <>
          <span>Region Mapping</span>
          <Select
            listHeight={160}
            getPopupContainer={(trigger) => trigger.parentElement}
            options={regiontype}
            value={data.regionMapping}
            placeholder="Region Configuration"
            style={{ width: "100%", marginBottom: "20px" }}
            showArrow
            allowClear
            onChange={(option: any) => {
              const newregionMapping = option ? option : null;
              setDataChange(true);
              setData({ ...data, regionMapping: newregionMapping });
            }}
          />
          <span>Industry Mapping</span>
          <Select
            listHeight={160}
            getPopupContainer={(trigger) => trigger.parentElement}
            mode="multiple"
            options={industrytemplate}
            value={data.industryMapping}
            placeholder="Industry Template"
            showArrow
            allowClear
            style={{ width: "100%", marginBottom: "20px" }}
            onChange={(option: any) => {
              const newindustryMapping = option ? option : null;
              setDataChange(true);
              setData({ ...data, industryMapping: newindustryMapping });
            }}
          />
        </>
      ),
    },
    {
      key: 4,
      title: "Language",
      content: (
        <>
          {" "}
          <span style={{ display: "block" }}>Publish Language </span>
          <Checkbox
            style={{ paddingTop: "10px" }}
            checked={data.language === "EN"}
            onChange={(ev: any) => {
              setDataChange(true);
              setData({
                ...data,
                language: "EN",
              });
            }}
          >
            English
          </Checkbox>
          <Checkbox
            style={{ paddingTop: "10px" }}
            checked={data.language === "CN"}
            onChange={(ev: any) => {
              setDataChange(true);
              setData({
                ...data,
                language: "CN",
              });
            }}
          >
            Chinese
          </Checkbox>
          <Checkbox
            style={{ paddingTop: "10px" }}
            checked={data.language === "JP"}
            onChange={(ev: any) => {
              setDataChange(true);
              setData({
                ...data,
                language: "JP",
              });
            }}
          >
            Japanese
          </Checkbox>
          <Checkbox
            style={{ paddingTop: "10px" }}
            checked={data.language === "DE"}
            onChange={(ev: any) => {
              setDataChange(true);
              setData({
                ...data,
                language: "DE",
              });
            }}
          >
            German
          </Checkbox>
          <div style={{ whiteSpace: "pre-wrap", paddingBottom: "15%" }}></div>
        </>
      ),
    },
  ];

  const optionalconfig = [
    {
      key: 1,
      title: "FTP Configuration",
      content: (
        <>
          {" "}
          <span>Mode</span>
          <Select
            listHeight={160}
            getPopupContainer={(trigger) => trigger.parentElement}
            options={ftpmode}
            value={
              data && data.ftp && data.ftp.mode !== undefined
                ? data.ftp.mode
                : "ftp"
            }
            placeholder="Mode"
            showArrow
            allowClear
            style={{ width: "100%", marginBottom: "20px" }}
            onChange={(e: any) => {
              setDataChange(true);
              setData({
                ...data,
                ftp: {
                  ...data.ftp,
                  mode: e,
                },
              });
            }}
          />
          {/* <span>Server</span>
          <Input
            size="large"
            placeholder="Name of FTP Server"
            style={{ marginBottom: "20px" }}
            value={data.ftp?.server}
            onChange={(e: any) => {
              setDataChange(true);
              setData({
                ...data,
                ftp: {
                  ...data.ftp,
                  server: e.target.value,
                },
              });
            }}
          />
          <span>Username</span>
          <Input
            size="large"
            placeholder="Username"
            style={{ marginBottom: "20px" }}
            value={data.ftp?.user}
            onChange={(e: any) => {
              setDataChange(true);
              setData({
                ...data,
                ftp: {
                  ...data.ftp,
                  user: e.target.value,
                },
              });
            }}
          />
          <span>Password</span>
          <Input
            size="large"
            type="password"
            placeholder="Password"
            style={{ marginBottom: "20px" }}
            value={data.ftp?.password}
            onChange={(e: any) => {
              setDataChange(true);
              setData({
                ...data,
                ftp: {
                  ...data.ftp,
                  password: e.target.value,
                },
              });
            }}
          /> */}
          <Form
            ref={formRef}
            style={{ width: "100%", margin: "auto", textAlign: "start" }}
            form={form}
            layout="vertical"
            labelCol={{ span: 4 }}
            wrapperCol={{ flex: 14 }}
            // onFinish={onFinishFTP}
            // onFinishFailed={onFinishFailedFTP}
            initialValues={{
              server: location.state.data?.ftp?.server
                ? location.state.data?.ftp?.server
                : "",
              user: location.state.data?.ftp?.user
                ? location.state.data?.ftp?.user
                : "",
              password: location.state.data?.ftp?.password
                ? location.state.data?.ftp?.password
                : "",
            }}
          >
            {FTPformData.map((item: any) => (
              <Form.Item {...item}>{item.component}</Form.Item>
            ))}
          </Form>
          <span>Path</span>
          <Input
            size="large"
            placeholder="Path"
            style={{ marginBottom: "20px" }}
            value={data.ftp?.path}
            onChange={(e: any) => {
              setDataChange(true);
              setData({
                ...data,
                ftp: {
                  ...data.ftp,
                  path: e.target.value,
                },
              });
            }}
          />
          <Checkbox
            style={{ paddingTop: "10px" }}
            checked={data.autoFtp}
            disabled={autoftp}
            onChange={(ev: any) => {
              setDataChange(true);
              if (
                (form.getFieldsValue().server === null ||
                  form.getFieldsValue().server === "" ||
                  form.getFieldsValue().server === undefined) &&
                (form.getFieldsValue().user === null ||
                  form.getFieldsValue().user === "" ||
                  form.getFieldsValue().user === undefined) &&
                (form.getFieldsValue().password === null ||
                  form.getFieldsValue().password === "" ||
                  form.getFieldsValue().password === undefined)
              ) {
                setData({
                  ...data,
                  autoFtp: false,
                });
              } else if (
                form.getFieldsValue().server !== null &&
                form.getFieldsValue().server !== "" &&
                form.getFieldsValue().server !== undefined &&
                form.getFieldsValue().user !== null &&
                form.getFieldsValue().user !== "" &&
                form.getFieldsValue().user !== undefined &&
                form.getFieldsValue().password !== null &&
                form.getFieldsValue().password !== "" &&
                form.getFieldsValue().password !== undefined
              ) {
                setData({
                  ...data,
                  autoFtp: true,
                });
              } else {
                setData({
                  ...data,
                  autoFtp: false,
                });
              }
            }}
          >
            Auto FTP
          </Checkbox>
        </>
      ),
    },
    // {
    //   key: 2,
    //   title: "Publication",
    //   content: (
    //     <>
    //       <span>Publication FileName</span>
    //       <Input
    //         placeholder="Publication FileName"
    //         value={data.publicationFilename}
    //         style={{ marginBottom: "20px" }}
    //         onChange={(e: any) => {
    //           setData({
    //             ...data,
    //             publicationFilename: e.target.value,
    //           });
    //         }}
    //       />
    //       <span>Multi Publication FileNames</span>
    //       {data.publicationFilenames !== undefined &&
    //         data.publicationFilenames.map((xmlt: any, index: any) => {
    //           return (
    //             <Space
    //               key={index}
    //               style={{
    //                 display: "grid",
    //                 gridTemplateColumns: "1fr 1.5fr 0.2fr",
    //                 width: "100%",
    //               }}
    //             >
    //               <div>
    //                 <Select
    //                   listHeight={160}
    //                   getPopupContainer={(trigger) => trigger.parentElement}
    //                   options={publicationTypeTags}
    //                   showArrow
    //                   value={xmlt[0] || undefined}
    //                   size="large"
    //                   placeholder="Publication Type"
    //                   style={{
    //                     width: "100%",
    //                   }}
    //                   onChange={(option: any) => {
    //                     let d = [...data.publicationFilenames];
    //                     d[index][0] = option;
    //                     setData({
    //                       ...data,
    //                       publicationFilenames: d,
    //                     });
    //                   }}
    //                 ></Select>
    //               </div>
    //               <div>
    //                 <Input
    //                   size="large"
    //                   placeholder="XML File"
    //                   value={xmlt[1]}
    //                   onChange={(e: any) => {
    //                     let d = [...data.publicationFilenames];
    //                     d[index][1] = e.target.value;
    //                     setData({
    //                       ...data,
    //                       publicationFilenames: d,
    //                     });
    //                   }}
    //                 />
    //               </div>
    //               <div>
    //                 <Button
    //                   style={{
    //                     width: "100%",
    //                   }}
    //                   size="large"
    //                   onClick={() => {
    //                     let d = data.publicationFilenames;
    //                     d.splice(index, 1);
    //                     setData({
    //                       ...data,
    //                       publicationFilenames: d,
    //                     });
    //                   }}
    //                 >
    //                   <MinusOutlined />
    //                 </Button>
    //               </div>
    //             </Space>
    //           );
    //         })}
    //       <Button onClick={addNewPublicationFile} style={{ width: "100%" }}>
    //         Add New Publication Filename
    //       </Button>
    //       <div style={{ whiteSpace: "pre-wrap", paddingBottom: "20%" }}></div>
    //     </>
    //   ),
    // },
    // {
    //   key: 3,
    //   title: "Manifest",
    //   content: (
    //     <>
    //       {" "}
    //       <span>Manifest Xml Container</span>
    //       <Input
    //         placeholder="Manifest Xml Container"
    //         value={data.manifestXmlContainer}
    //         style={{ marginBottom: "20px" }}
    //         onChange={(e: any) => {
    //           setData({
    //             ...data,
    //             manifestXmlContainer: e.target.value,
    //           });
    //         }}
    //       />
    //       <span>Manifest Xml Content</span>
    //       <Input
    //         placeholder="Manifest Xml Content"
    //         style={{ marginBottom: "20px" }}
    //         value={data.manifestXmlContent}
    //         onChange={(e: any) => {
    //           setData({
    //             ...data,
    //             manifestXmlContent: e.target.value,
    //           });
    //         }}
    //       />
    //       <span>Manifest Files</span>
    //       {data.manifestFiles && (
    //         <>
    //           {data.manifestFiles.map((s: any, index: any) => {
    //             return (
    //               <Space
    //                 key={index}
    //                 style={{
    //                   display: "grid",
    //                   gridTemplateColumns: "2.5fr 0.1fr",
    //                   width: "100%",
    //                   marginBottom: "8px",
    //                 }}
    //               >
    //                 <Input
    //                   key={`x-${index}`}
    //                   size="large"
    //                   placeholder="Manifest File"
    //                   value={s}
    //                   width="100%"
    //                   style={{
    //                     width: "100%",
    //                   }}
    //                   onChange={(e: any) => {
    //                     let d = [...data.manifestFiles];
    //                     d[index] = e.target.value;
    //                     setData({
    //                       ...data,
    //                       manifestFiles: d,
    //                     });
    //                   }}
    //                 />
    //                 <Button
    //                   size="large"
    //                   onClick={() => {
    //                     let d = data.manifestFiles;
    //                     d.splice(index, 1);
    //                     setData({
    //                       ...data,
    //                       manifestFiles: d,
    //                     });
    //                   }}
    //                 >
    //                   <MinusOutlined />
    //                 </Button>
    //               </Space>
    //             );
    //           })}
    //         </>
    //       )}
    //       <Button
    //         onClick={addNewManifestFile}
    //         style={{
    //           width: "100%",
    //           marginBottom: "8px",
    //         }}
    //       >
    //         Add New Manifest File
    //       </Button>
    //       <div style={{ whiteSpace: "pre-wrap", paddingBottom: "5%" }}></div>
    //     </>
    //   ),
    // },
    {
      key: 2,
      title: "XML Template",
      content: (
        <>
          {" "}
          <span>XML Main Template</span>
          <Input
            placeholder="Main Template"
            style={{ marginBottom: "20px" }}
            value={data.xmlTemplate}
            readOnly
            onChange={(e: any) => {
              // setDataChange(true);
              // setData({
              //   ...data,
              //   xmlTemplate: e.target.value,
              // });
            }}
          />
          {/* <span>XML Templates</span>
          {data.xmlTemplates !== undefined &&
            data.xmlTemplates.map((xmlt: any, index: any) => {
              return (
                <Space
                  key={index}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1.5fr 0.2fr",
                    width: "100%",
                  }}
                >
                  <div>
                    <Select
                      listHeight={160}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      options={publicationTypeTags}
                      showArrow
                      value={xmlt[0]}
                      size="large"
                      placeholder="Publication Type"
                      style={{
                        width: "100%",
                      }}
                      onChange={(option: any) => {
                        let d = [...data.xmlTemplates];
                        d[index][0] = option;
                        setDataChange(true);
                        setData({
                          ...data,
                          xmlTemplates: d,
                        });
                      }}
                    ></Select>
                  </div>
                  <div>
                    <Input
                      size="large"
                      placeholder="XML File"
                      value={xmlt[1]}
                      onChange={(e: any) => {
                        let d = [...data.xmlTemplates];
                        d[index][1] = e.target.value;
                        setDataChange(true);
                        setData({
                          ...data,
                          xmlTemplates: d,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      style={{
                        width: "100%",
                      }}
                      size="large"
                      onClick={() => {
                        let d = data.xmlTemplates;
                        d.splice(index, 1);
                        setDataChange(true);
                        setData({
                          ...data,
                          xmlTemplates: d,
                        });
                      }}
                    >
                      <MinusOutlined />
                    </Button>
                  </div>
                </Space>
              );
            })}
          <Button
            onClick={addNewTemplate}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            Add New Template
          </Button>
          <span>XML Summary Templates</span>
          {data.xmlSummaryTemplates !== undefined &&
            data.xmlSummaryTemplates.map((xmlt: any, index: any) => {
              return (
                <Space
                  key={index}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1.5fr 0.2fr",
                    width: "100%",
                  }}
                >
                  <div>
                    <Select
                      listHeight={160}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      options={publicationTypeTags}
                      showArrow
                      value={xmlt[0] || undefined}
                      size="large"
                      placeholder="Publication Type"
                      style={{
                        width: "100%",
                      }}
                      onChange={(option: any) => {
                        let d = [...data.xmlSummaryTemplates];
                        d[index][0] = option;
                        setDataChange(true);
                        setData({
                          ...data,
                          xmlSummaryTemplates: d,
                        });
                      }}
                    ></Select>
                  </div>
                  <div>
                    <Input
                      size="large"
                      placeholder="XML File"
                      value={xmlt[1]}
                      onChange={(e: any) => {
                        let d = [...data.xmlSummaryTemplates];
                        d[index][1] = e.target.value;
                        setDataChange(true);
                        setData({
                          ...data,
                          xmlSummaryTemplates: d,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      style={{
                        width: "100%",
                      }}
                      size="large"
                      onClick={() => {
                        let d = data.xmlSummaryTemplates;
                        d.splice(index, 1);
                        setDataChange(true);
                        setData({
                          ...data,
                          xmlSummaryTemplates: d,
                        });
                      }}
                    >
                      <MinusOutlined />
                    </Button>
                  </div>
                </Space>
              );
            })}
          <Button
            onClick={addNewSummaryTemplate}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            Add New Summary Template
          </Button>
          <span>XML Header Doccode Tag Map</span>
          {data.xmlHeaderDoccodeTagMap !== undefined &&
            data.xmlHeaderDoccodeTagMap.map((xmlt: any, index: any) => {
              return (
                <Space
                  key={index}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1.5fr 0.2fr",
                    width: "100%",
                  }}
                >
                  <div>
                    <Select
                      listHeight={160}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      options={publicationTypeTags}
                      showArrow
                      value={xmlt[0]}
                      size="large"
                      placeholder="Publication Type"
                      style={{
                        width: "100%",
                      }}
                      onChange={(option: any) => {
                        let d = [...data.xmlHeaderDoccodeTagMap];
                        d[index][0] = option;
                        setDataChange(true);
                        setData({
                          ...data,
                          xmlHeaderDoccodeTagMap: d,
                        });
                      }}
                    ></Select>
                  </div>
                  <div>
                    <Input
                      size="large"
                      placeholder="XML File"
                      value={xmlt[1]}
                      onChange={(e: any) => {
                        let d = [...data.xmlHeaderDoccodeTagMap];
                        d[index][1] = e.target.value;
                        setDataChange(true);
                        setData({
                          ...data,
                          xmlHeaderDoccodeTagMap: d,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      style={{
                        width: "100%",
                      }}
                      size="large"
                      onClick={() => {
                        let d = data.xmlHeaderDoccodeTagMap;
                        d.splice(index, 1);
                        setDataChange(true);
                        setData({
                          ...data,
                          xmlHeaderDoccodeTagMap: d,
                        });
                      }}
                    >
                      <MinusOutlined />
                    </Button>
                  </div>
                </Space>
              );
            })}
          <Button
            onClick={addNewHeaderDoccodeTagMapTemplate}
            style={{ width: "100%" }}
          >
            Add New Header Doccode Tag Map Template
          </Button>
          <div style={{ whiteSpace: "pre-wrap", paddingBottom: "10%" }}></div> */}
        </>
      ),
    },
    {
      key: 3,
      title: "Zip Filename Template",
      content: (
        <>
          {" "}
          <span>Zip Filename</span>
          <Input
            size="large"
            placeholder="Zip Filename"
            style={{ marginBottom: "20px" }}
            value={data.zipFilename}
            onChange={(e: any) => {
              setDataChange(true);
              setData({
                ...data,
                zipFilename: e.target.value,
              });
            }}
          />
          <span>Publications per Zip</span>
          <Input
            size="large"
            type="number"
            placeholder="0"
            style={{ marginBottom: "20px" }}
            value={data.publicationsPerZip}
            onChange={(e: any) => {
              const inputValue = parseInt(e.target.value, 10);
              if (!isNaN(inputValue) && inputValue >= 0) {
                setDataChange(true);
                setData({
                  ...data,
                  publicationsPerZip: inputValue,
                });
              } else {
                // If the input is negative or not a valid number, set it to zero
                setDataChange(true);
                setData({
                  ...data,
                  publicationsPerZip: 0,
                });
              }
            }}
          />
          <div style={{ whiteSpace: "pre-wrap", paddingBottom: "20%" }}></div>
        </>
      ),
    },
    {
      key: 4,
      title: "XML Removal",
      content: (
        <>
          {" "}
          <Checkbox
            style={{ paddingBottom: "10px", flex: 1 }}
            checked={data.xmlRemoveAcqRef}
            onChange={(ev: any) => {
              setDataChange(true);
              setData({
                ...data,
                xmlRemoveAcqRef: ev.target.checked,
              });
            }}
          >
            Remove Acquisdata Ref
          </Checkbox>
          <span style={{ display: "block" }}>XML Remove String Patterns</span>
          {data.xmlRemoveStringPatterns && (
            <>
              {data.xmlRemoveStringPatterns.map((s: any, index: any) => {
                return (
                  <Space
                    key={index}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "2.5fr 0.1fr",
                      width: "100%",
                      marginBottom: "8px",
                    }}
                  >
                    <Input
                      key={`x-${index}`}
                      size="large"
                      placeholder="Regular Expression String"
                      value={s}
                      width="100%"
                      style={{
                        width: "100%",
                      }}
                      onChange={(e: any) => {
                        let d = [...data.xmlRemoveStringPatterns];
                        d[index] = e.target.value;
                        setDataChange(true);
                        setData({
                          ...data,
                          xmlRemoveStringPatterns: d,
                        });
                      }}
                    />
                    <Button
                      size="large"
                      onClick={() => {
                        let d = data.xmlRemoveStringPatterns;
                        d.splice(index, 1);
                        setDataChange(true);
                        setData({
                          ...data,
                          xmlRemoveStringPatterns: d,
                        });
                      }}
                    >
                      <MinusOutlined />
                    </Button>
                  </Space>
                );
              })}
            </>
          )}
          <Button
            onClick={handleAddNewExpression}
            style={{
              width: "100%",
              marginBottom: "20px",
            }}
          >
            Add New Expression
          </Button>
          <span>XML Remove Bad Urls</span>
          {data.xmlBadUrls && (
            <>
              {data.xmlBadUrls.map((s: any, index: any) => {
                return (
                  <Space
                    key={index}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "2.5fr 0.1fr",
                      width: "100%",
                      marginBottom: "8px",
                    }}
                  >
                    <Input
                      key={`x-${index}`}
                      size="large"
                      placeholder="Regular Expression Bad Url"
                      value={s}
                      width="100%"
                      style={{
                        width: "100%",
                      }}
                      onChange={(e: any) => {
                        let d = [...data.xmlBadUrls];
                        d[index] = e.target.value;
                        setDataChange(true);
                        setData({
                          ...data,
                          xmlBadUrls: d,
                        });
                      }}
                    />
                    <Button
                      size="large"
                      onClick={() => {
                        let d = data.xmlBadUrls;
                        d.splice(index, 1);
                        setDataChange(true);
                        setData({
                          ...data,
                          xmlBadUrls: d,
                        });
                      }}
                    >
                      <MinusOutlined />
                    </Button>
                  </Space>
                );
              })}
            </>
          )}
          <Button
            onClick={handleAddNewBadUrl}
            style={{
              width: "100%",
              marginBottom: "8px",
            }}
          >
            Add New Expression
          </Button>
          <div style={{ whiteSpace: "pre-wrap", paddingBottom: "15%" }}></div>
        </>
      ),
    },
    {
      key: 5,
      title: "Disable HREF/LINKS",
      content: (
        <>
          {" "}
          <Checkbox
            style={{ paddingTop: "10px" }}
            checked={data.xmlDisableHref}
            onChange={(ev: any) => {
              setDataChange(true);
              setData({
                ...data,
                xmlDisableHref: ev.target.checked,
              });
            }}
          >
            Disable HREF/Links
          </Checkbox>
        </>
      ),
    },

    // {
    //   key: 6,
    //   title: "Entitlement",
    //   content: (
    //     <>
    //       {" "}
    //       <span>Entitlement Type</span>
    //       <Input
    //         size="large"
    //         placeholder="Entitlement Type"
    //         style={{ marginBottom: "20px" }}
    //         value={data.entitlement?.entitlementType}
    //         onChange={(e: any) => {
    //           setDataChange(true);
    //           setData({
    //             ...data,
    //             entitlement: {
    //               ...data.entitlement,
    //               entitlementType: e.target.value,
    //             },
    //           });
    //         }}
    //       />
    //       <span>Entitlement Context</span>
    //       <Input
    //         size="large"
    //         placeholder="Entitlement Context"
    //         style={{ marginBottom: "20px" }}
    //         value={data.entitlement?.entitlementContext}
    //         onChange={(e: any) => {
    //           setDataChange(true);
    //           setData({
    //             ...data,
    //             entitlement: {
    //               ...data.entitlement,
    //               entitlementContext: e.target.value,
    //             },
    //           });
    //         }}
    //       />
    //       <span>Entitlement Text</span>
    //       <Input
    //         size="large"
    //         placeholder="Entitlement Text"
    //         style={{ marginBottom: "20px" }}
    //         value={data.entitlement?.entitlementText}
    //         onChange={(e: any) => {
    //           setDataChange(true);
    //           setData({
    //             ...data,
    //             entitlement: {
    //               ...data.entitlement,
    //               entitlementText: e.target.value,
    //             },
    //           });
    //         }}
    //       />
    //     </>
    //   ),
    // },
    {
      key: 6,
      title: "Set Document Start",
      content: (
        <>
          {/* {" "}
          <Checkbox
            style={{ paddingTop: "10px", marginBottom: "20px" }}
            checked={data.forceInitial}
            onChange={(ev: any) => {
              setDataChange(true);
              setData({
                ...data,
                forceInitial: ev.target.checked,
              });
            }}
          >
            Force Initial
          </Checkbox> */}
          <div style={{ width: "30%" }}>
            <span style={{ display: "block" }}>Initial Start Date</span>
            {/* {!showinitialsdate && (
            <Input
              style={{ marginBottom: "20px" }}
              value={convertDate(data.initialStartDate)}
              onClick={() => {
                setShowInitialSdate(true);
                setShowEditInitialSdate(true);
              }}
            />
          )}
          {showinitialsdate && ( */}
            <DatePicker
              // open={showeditinitialsdate}
              value={
                data.initialStartDate ? moment(data.initialStartDate) : null
              } // Convert to moment.js object
              style={{
                width: "100%",
                marginBottom: "20px",
              }}
              format={"DD/MM/YYYY"}
              allowClear
              onChange={(option: any) => {
                setDataChange(true);
                const newinitialStartDate = option
                  ? showDateFormat(option)
                  : null;
                setData({ ...data, initialStartDate: newinitialStartDate });
                setShowEditInitialSdate(false);
              }}
              onFocus={() => {
                setShowEditInitialSdate(true);
              }}
              onBlur={() => {
                setShowInitialSdate(false);
              }}
            />
          </div>
          {/* )} */}
          <div style={{ width: "30%" }}>
            <span>Initial End Date</span>
            {/* {!showinitialedate && (
            <Input
              value={convertDate(data.initialEndDate)}
              style={{ marginBottom: "20px" }}
              onClick={() => {
                setShowInitialEdate(true);
                setShowEditInitialEdate(true);
              }}
            />
          )}
          {showinitialedate && ( */}
            <DatePicker
              // open={showeditinitialedate}\
              value={data.initialEndDate ? moment(data.initialEndDate) : null} // Convert to moment.js object
              style={{ width: "100%", marginBottom: "20px" }}
              format={"DD/MM/YYYY"}
              allowClear
              onChange={(option: any) => {
                setDataChange(true);
                const newinitialEndDate = option
                  ? showDateFormat(option)
                  : null;
                setData({ ...data, initialEndDate: newinitialEndDate });
                // setData({ ...data, initialEndDate: option });
                setShowEditInitialEdate(false);
              }}
              onFocus={() => {
                setShowEditInitialEdate(true);
              }}
              onBlur={() => {
                setShowInitialEdate(false);
              }}
            />
          </div>
          {/* )} */}
          <span>Set Document Start From DocumentID</span>
          {data.fromMinimumDocumentId !== undefined &&
            data.fromMinimumDocumentId.map((xmlt: any, index: any) => {
              return (
                <Space
                  key={index}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1.5fr 0.2fr",
                    width: "100%",
                  }}
                >
                  <div>
                    <Select
                      listHeight={160}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      options={publicationTypeTags}
                      showArrow
                      value={xmlt[0] || undefined}
                      size="large"
                      placeholder="Publication Type"
                      style={{
                        width: "100%",
                      }}
                      onChange={(option: any) => {
                        let d = [...data.fromMinimumDocumentId];
                        d[index][0] = option;
                        setDataChange(true);
                        setData({
                          ...data,
                          fromMinimumDocumentId: d,
                        });
                      }}
                    ></Select>
                  </div>
                  <div>
                    <Input
                      size="large"
                      placeholder="DocumentID"
                      value={xmlt[1]}
                      onChange={(e: any) => {
                        let d = [...data.fromMinimumDocumentId];
                        d[index][1] = e.target.value;
                        setDataChange(true);
                        setData({
                          ...data,
                          fromMinimumDocumentId: d,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      style={{
                        width: "100%",
                      }}
                      size="large"
                      onClick={() => {
                        let d = data.fromMinimumDocumentId;
                        d.splice(index, 1);
                        setDataChange(true);
                        setData({
                          ...data,
                          fromMinimumDocumentId: d,
                        });
                      }}
                    >
                      <MinusOutlined />
                    </Button>
                  </div>
                </Space>
              );
            })}
          <Button onClick={addNewMinimumDocumentID} style={{ width: "100%" }}>
            Add New Minimum Document
          </Button>
        </>
      ),
    },
    {
      key: 7,
      title: "Check Reference",
      content: (
        <>
          {" "}
          <span>Check Company Reference</span>
          <Select
            listHeight={160}
            getPopupContainer={(trigger) => trigger.parentElement}
            mode="tags"
            options={CompanyRef}
            showArrow
            allowClear
            value={data.checkCompanyRef}
            placeholder="Company Reference"
            style={{ width: "100%", marginBottom: "20px" }}
            onChange={(option: any) => {
              setDataChange(true);
              setData({ ...data, checkCompanyRef: option });
            }}
          />
          <span>Check Industry Reference</span>
          <Select
            listHeight={160}
            getPopupContainer={(trigger) => trigger.parentElement}
            mode="tags"
            options={IndustryRef}
            showArrow
            allowClear
            value={data.checkIndustryRef}
            placeholder="Industry Reference"
            style={{ width: "100%", marginBottom: "20px" }}
            onChange={(option: any) => {
              setDataChange(true);
              setData({ ...data, checkIndustryRef: option });
            }}
          />
        </>
      ),
    },
    {
      key: 8,
      title: "Destination Folder",
      content: (
        <>
          {" "}
          <span>Set Destination Folder</span>
          {data.destFolders !== undefined &&
            data.destFolders.map((xmlt: any, index: any) => {
              return (
                <Space
                  key={index}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1.5fr 0.2fr",
                    width: "100%",
                  }}
                >
                  <div>
                    <Select
                      listHeight={160}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      options={publicationTypeTags}
                      showArrow
                      value={xmlt[0] || undefined}
                      size="large"
                      placeholder="Publication Type"
                      style={{
                        width: "100%",
                      }}
                      onChange={(option: any) => {
                        let d = [...data.destFolders];
                        d[index][0] = option;
                        setDataChange(true);
                        setData({
                          ...data,
                          destFolders: d,
                        });
                      }}
                    ></Select>
                  </div>
                  <div>
                    <Input
                      size="large"
                      placeholder="Destination"
                      value={xmlt[1]}
                      onChange={(e: any) => {
                        let d = [...data.destFolders];
                        d[index][1] = e.target.value;
                        setDataChange(true);
                        setData({
                          ...data,
                          destFolders: d,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      style={{
                        width: "100%",
                      }}
                      size="large"
                      onClick={() => {
                        let d = data.destFolders;
                        d.splice(index, 1);
                        setDataChange(true);
                        setData({
                          ...data,
                          destFolders: d,
                        });
                      }}
                    >
                      <MinusOutlined />
                    </Button>
                  </div>
                </Space>
              );
            })}
          <Button onClick={addNewDestination} style={{ width: "100%" }}>
            Add New Destination
          </Button>
        </>
      ),
    },
    {
      key: 9,
      title: "Filter",
      content: (
        <>
          <div style={{ width: "100%" }}>
            <Radio.Group
              defaultValue="include"
              buttonStyle="solid"
              style={{ marginBottom: "10px" }}
              onChange={handleFilterIndustry}
              value={selectedIndustryRadio}
            >
              <Radio value="include">Filter by Industry</Radio>
              <Radio value="exclude">Exclude Filter by Industry</Radio>
            </Radio.Group>
            {selectedIndustryRadio === "include" && (
              <Select
                listHeight={160}
                getPopupContainer={(trigger) => trigger.parentElement}
                mode="tags"
                options={industrylist}
                showArrow
                allowClear
                // size="large"
                value={
                  data.filter ? data.filter?.[0]?.$and?.[0]?.industry?.$nin : []
                }
                placeholder="Industry Tag to Filter"
                style={{ width: "100%", marginBottom: "20px" }}
                onChange={(e: any) => {
                  setDataChange(true);
                  handleFilterByIndustry(e);
                }}
                filterOption={(input: any, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            )}
            {selectedIndustryRadio === "exclude" && (
              <Select
                listHeight={160}
                getPopupContainer={(trigger) => trigger.parentElement}
                mode="tags"
                options={industrylist}
                showArrow
                allowClear
                // size="large"
                value={data.excludeFilter?.[0]?.industry}
                placeholder="Industry Tag to Filter"
                style={{ width: "100%", marginBottom: "20px" }}
                onChange={(e: any) => {
                  setDataChange(true);
                  handleExcludeFilterByIndustry(e);
                  // setData((prevData: any) => {
                  //   // Find the index of the object with 'industry' property in excludeFilter
                  //   const industryIndex = prevData.excludeFilter?.findIndex(
                  //     (item: any) => "industry" in item
                  //   );

                  //   // Create a copy of the data object
                  //   const newData = { ...prevData };
                  //   if (industryIndex !== undefined && industryIndex !== -1) {
                  //     if (newData.excludeFilter && newData.excludeFilter[0]) {
                  //       newData.excludeFilter[0].industry = e;
                  //     }
                  //   } else {
                  //     if (!newData.excludeFilter) {
                  //       newData.excludeFilter = [];
                  //     }
                  //     newData.excludeFilter?.push({ industry: e });
                  //   }
                  //   return newData;
                  // });
                }}
                filterOption={(input: any, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            )}
          </div>
          <div style={{ width: "100%" }}>
            {/* <span style={{ paddingRight: "20%" }}>Filter by</span> */}
            <Radio.Group
              defaultValue="include"
              buttonStyle="solid"
              style={{ marginBottom: "10px" }}
              onChange={handleFilterCountry}
              value={selectedCountryRadio}
            >
              <Radio value="include">Filter by Country</Radio>
              <Radio value="exclude">Exclude Filter by Country</Radio>
            </Radio.Group>
            {selectedCountryRadio === "include" && (
              <Select
                listHeight={160}
                getPopupContainer={(trigger) => trigger.parentElement}
                mode="tags"
                options={countrylist}
                showArrow
                allowClear
                // showSearch
                // size="large"
                value={
                  data.filter ? data.filter?.[0]?.$and?.[1]?.country?.$nin : []
                }
                placeholder="Country Tag to Filter"
                style={{ width: "100%", marginBottom: "20px" }}
                onChange={(e: any) => {
                  setDataChange(true);
                  handleFilterByCountry(e);
                }}
                filterOption={(input: any, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            )}
            {selectedCountryRadio === "exclude" && (
              <Select
                listHeight={160}
                getPopupContainer={(trigger) => trigger.parentElement}
                mode="tags"
                options={countrylist}
                showArrow
                allowClear
                // showSearch
                // size="large"
                value={data.excludeFilter?.[0]?.country}
                placeholder="Country Tag to Filter"
                style={{ width: "100%", marginBottom: "20px" }}
                onChange={(e: any) => {
                  setDataChange(true);
                  handleExcludeFilterByCountry(e);
                  // setData((prevData: any) => {
                  //   // Find the index of the object with 'industry' property in excludeFilter
                  //   const countryIndex = prevData.excludeFilter?.findIndex(
                  //     (item: any) => "country" in item
                  //   );

                  //   // Create a copy of the data object
                  //   const newData = { ...prevData };
                  //   if (countryIndex !== undefined && countryIndex !== -1) {
                  //     if (newData.excludeFilter && newData.excludeFilter[0]) {
                  //       newData.excludeFilter[0].country = e;
                  //     }
                  //   } else {
                  //     if (!newData.excludeFilter) {
                  //       newData.excludeFilter = [];
                  //     }
                  //     newData.excludeFilter?.push({ country: e });
                  //   }
                  //   return newData;
                  // });
                }}
                filterOption={(input: any, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            )}
          </div>

          {/* <span>Filter by Industry</span>
          <Select
            listHeight={160}
            getPopupContainer={(trigger) => trigger.parentElement}
            mode="tags"
            options={industrylist}
            showArrow
            allowClear
            // size="large"
            value={
              data.filter ? data.filter?.[0]?.$and?.[0]?.industry?.$nin : []
            }
            placeholder="Tags to Include"
            style={{ width: "100%", marginBottom: "20px" }}
            onChange={(e: any) => {
              setDataChange(true);
              handleFilterByIndustry(e);
            }}
          /> */}
          {/* <span>Filter by Country</span>
          <Select
            listHeight={160}
            getPopupContainer={(trigger) => trigger.parentElement}
            mode="tags"
            options={countrylist}
            showArrow
            allowClear
            // size="large"
            value={
              data.filter ? data.filter?.[0]?.$and?.[1]?.country?.$nin : []
            }
            placeholder="Tags to Include"
            style={{ width: "100%", marginBottom: "20px" }}
            onChange={(e: any) => {
              setDataChange(true);
              handleFilterByCountry(e);
            }}
          /> */}
          {/* <span>Exclude Filter by Industry</span>
          <Select
            listHeight={160}
            getPopupContainer={(trigger) => trigger.parentElement}
            mode="tags"
            options={industrylist}
            showArrow
            allowClear
            // size="large"
            value={data.excludeFilter?.[0]?.industry}
            placeholder="Tags to Include"
            style={{ width: "100%", marginBottom: "20px" }}
            onChange={(e: any) => {
              setDataChange(true);
              setData((prevData: any) => {
                // Find the index of the object with 'industry' property in excludeFilter
                const industryIndex = prevData.excludeFilter?.findIndex(
                  (item: any) => "industry" in item
                );

                // Create a copy of the data object
                const newData = { ...prevData };
                if (industryIndex !== undefined && industryIndex !== -1) {
                  if (newData.excludeFilter && newData.excludeFilter[0]) {
                    newData.excludeFilter[0].industry = e;
                  }
                } else {
                  if (!newData.excludeFilter) {
                    newData.excludeFilter = [];
                  }
                  newData.excludeFilter?.push({ industry: e });
                }
                return newData;
              });
            }}
          /> */}
          {/* <span>Exclude Filter by Country</span>
          <Select
            listHeight={160}
            getPopupContainer={(trigger) => trigger.parentElement}
            mode="tags"
            options={countrylist}
            showArrow
            allowClear
            // size="large"
            value={data.excludeFilter?.[0]?.country}
            placeholder="Tags to Include"
            style={{ width: "100%", marginBottom: "20px" }}
            onChange={(e: any) => {
              setDataChange(true);
              setData((prevData: any) => {
                // Find the index of the object with 'industry' property in excludeFilter
                const countryIndex = prevData.excludeFilter?.findIndex(
                  (item: any) => "country" in item
                );

                // Create a copy of the data object
                const newData = { ...prevData };
                if (countryIndex !== undefined && countryIndex !== -1) {
                  if (newData.excludeFilter && newData.excludeFilter[0]) {
                    newData.excludeFilter[0].country = e;
                  }
                } else {
                  if (!newData.excludeFilter) {
                    newData.excludeFilter = [];
                  }
                  newData.excludeFilter?.push({ country: e });
                }
                return newData;
              });
            }}
          /> */}
        </>
      ),
    },
    // {
    //   key: 7,
    //   title: "Date initial",
    //   content: (
    //     <>
    //       <Checkbox
    //         style={{ paddingTop: "10px", marginBottom: "20px" }}
    //         checked={data.forceInitial}
    //         onChange={(ev: any) => {
    //           setData({
    //             ...data,
    //             forceInitial: ev.target.checked,
    //           });
    //         }}
    //       >
    //         Force Initial
    //       </Checkbox>
    //       <span style={{ display: "block" }}>Initial Start Date</span>
    //       {!showinitialsdate && (
    //         <Input
    //           style={{ marginBottom: "20px" }}
    //           value={convertDate(data.initialStartDate)}
    //           onClick={() => {
    //             setShowInitialSdate(true);
    //             setShowEditInitialSdate(true);
    //           }}
    //         />
    //       )}
    //       {showinitialsdate && (
    //         <DatePicker
    //           open={showeditinitialsdate}
    //           style={{ width: "100%" }}
    //           format={"DD/MM/YYYY"}
    //           onChange={(option: any) => {
    //             setData({ ...data, initialStartDate: showDateFormat(option) });
    //             setShowEditInitialSdate(false);
    //           }}
    //           onFocus={() => {
    //             setShowEditInitialSdate(true);
    //           }}
    //           onBlur={() => {
    //             setShowInitialSdate(false);
    //           }}
    //         />
    //       )}

    //       <span>Initial End Date</span>
    //       {!showinitialedate && (
    //         <Input
    //           value={convertDate(data.initialEndDate)}
    //           onClick={() => {
    //             setShowInitialEdate(true);
    //             setShowEditInitialEdate(true);
    //           }}
    //         />
    //       )}
    //       {showinitialedate && (
    //         <DatePicker
    //           open={showeditinitialedate}
    //           style={{ width: "100%" }}
    //           format={"DD/MM/YYYY"}
    //           onChange={(option: any) => {
    //             setData({ ...data, initialEndDate: option });
    //             setShowEditInitialEdate(false);
    //           }}
    //           onFocus={() => {
    //             setShowEditInitialEdate(true);
    //           }}
    //           onBlur={() => {
    //             setShowInitialEdate(false);
    //           }}
    //         />
    //       )}
    //     </>
    //   ),
    // },
    {
      key: 10,
      title: "Dry Run",
      content: (
        <>
          {" "}
          <Checkbox
            style={{ paddingBottom: "10px", flex: 1 }}
            checked={data.dryRun}
            onChange={(ev: any) => {
              setDataChange(true);
              setData({
                ...data,
                dryRun: ev.target.checked,
              });
            }}
          >
            Enable Dry Run
          </Checkbox>
        </>
      ),
    },
    // {
    //   key: 12,
    //   title: "Tag to Span",
    //   content: (
    //     <>
    //       {" "}
    //       {/* <span>Last and Publication</span>
    //       <Input
    //         size="large"
    //         type="Number"
    //         placeholder="0"
    //         style={{ marginBottom: "20px" }}
    //         value={data.last_n_publications}
    //         onChange={(e: any) => {
    //           setDataChange(true);
    //           setData({
    //             ...data,
    //             last_n_publications: e.target.value,
    //           });
    //         }}
    //       /> */}
    //       <span>Tag to Span</span>
    //       <Select
    //         listHeight={160}
    //         getPopupContainer={(trigger) => trigger.parentElement}
    //         mode="tags"
    //         options={SpanToTags}
    //         showArrow
    //         // size="large"
    //         value={data.tagToSpan}
    //         placeholder="Tags to Include"
    //         style={{ width: "100%" }}
    //         onChange={(option: any) => {
    //           setDataChange(true);
    //           setData({ ...data, tagToSpan: option });
    //         }}
    //       />
    //       {/* <div style={{ whiteSpace: "pre-wrap", paddingBottom: "15%" }}></div> */}
    //     </>
    //   ),
    // },
  ];

  // const advancedconfig = [
  //   {
  //     key: 1,
  //     title: "Entitlement",
  //     content: (
  //       <>
  //         {" "}
  //         <span>Entitlement Type</span>
  //         <Input
  //           size="large"
  //           placeholder="Entitlement Type"
  //           style={{ marginBottom: "20px" }}
  //           value={data.entitlement?.entitlementType}
  //           onChange={(e: any) => {
  //             setData({
  //               ...data,
  //               entitlement: {
  //                 ...data.entitlement,
  //                 entitlementType: e.target.value,
  //               },
  //             });
  //           }}
  //         />
  //         <span>Entitlement Context</span>
  //         <Input
  //           size="large"
  //           placeholder="Entitlement Context"
  //           style={{ marginBottom: "20px" }}
  //           value={data.entitlement?.entitlementContext}
  //           onChange={(e: any) => {
  //             setData({
  //               ...data,
  //               entitlement: {
  //                 ...data.entitlement,
  //                 entitlementContext: e.target.value,
  //               },
  //             });
  //           }}
  //         />
  //         <span>Entitlement Text</span>
  //         <Input
  //           size="large"
  //           placeholder="Entitlement Text"
  //           style={{ marginBottom: "20px" }}
  //           value={data.entitlement?.entitlementText}
  //           onChange={(e: any) => {
  //             setData({
  //               ...data,
  //               entitlement: {
  //                 ...data.entitlement,
  //                 entitlementText: e.target.value,
  //               },
  //             });
  //           }}
  //         />
  //       </>
  //     ),
  //   },
  //   {
  //     key: 2,
  //     title: "Set Document Start",
  //     content: (
  //       <>
  //         {" "}
  //         <Checkbox
  //           style={{ paddingTop: "10px", marginBottom: "20px" }}
  //           checked={data.forceInitial}
  //           onChange={(ev: any) => {
  //             setData({
  //               ...data,
  //               forceInitial: ev.target.checked,
  //             });
  //           }}
  //         >
  //           Force Initial
  //         </Checkbox>
  //         <span style={{ display: "block" }}>Initial Start Date</span>
  //         {!showinitialsdate && (
  //           <Input
  //             style={{ marginBottom: "20px" }}
  //             value={convertDate(data.initialStartDate)}
  //             onClick={() => {
  //               setShowInitialSdate(true);
  //               setShowEditInitialSdate(true);
  //             }}
  //           />
  //         )}
  //         {showinitialsdate && (
  //           <DatePicker
  //             open={showeditinitialsdate}
  //             style={{ width: "100%" }}
  //             format={"DD/MM/YYYY"}
  //             onChange={(option: any) => {
  //               setData({ ...data, initialStartDate: showDateFormat(option) });
  //               setShowEditInitialSdate(false);
  //             }}
  //             onFocus={() => {
  //               setShowEditInitialSdate(true);
  //             }}
  //             onBlur={() => {
  //               setShowInitialSdate(false);
  //             }}
  //           />
  //         )}
  //         <span>Initial End Date</span>
  //         {!showinitialedate && (
  //           <Input
  //             value={convertDate(data.initialEndDate)}
  //             style={{ marginBottom: "20px" }}
  //             onClick={() => {
  //               setShowInitialEdate(true);
  //               setShowEditInitialEdate(true);
  //             }}
  //           />
  //         )}
  //         {showinitialedate && (
  //           <DatePicker
  //             open={showeditinitialedate}
  //             style={{ width: "100%" }}
  //             format={"DD/MM/YYYY"}
  //             onChange={(option: any) => {
  //               setData({ ...data, initialEndDate: option });
  //               setShowEditInitialEdate(false);
  //             }}
  //             onFocus={() => {
  //               setShowEditInitialEdate(true);
  //             }}
  //             onBlur={() => {
  //               setShowInitialEdate(false);
  //             }}
  //           />
  //         )}
  //         <span>Set Document Start From DocumentID</span>
  //         {data.fromMinimumDocumentId !== undefined &&
  //           data.fromMinimumDocumentId.map((xmlt: any, index: any) => {
  //             return (
  //               <Space
  //                 key={index}
  //                 style={{
  //                   display: "grid",
  //                   gridTemplateColumns: "1fr 1.5fr 0.2fr",
  //                   width: "100%",
  //                 }}
  //               >
  //                 <div>
  //                   <Select
  //                     listHeight={160}
  //                     getPopupContainer={(trigger) => trigger.parentElement}
  //                     options={publicationTypeTags}
  //                     showArrow
  //                     value={xmlt[0] || undefined}
  //                     size="large"
  //                     placeholder="Publication Type"
  //                     style={{
  //                       width: "100%",
  //                     }}
  //                     onChange={(option: any) => {
  //                       let d = [...data.fromMinimumDocumentId];
  //                       d[index][0] = option;
  //                       setData({
  //                         ...data,
  //                         fromMinimumDocumentId: d,
  //                       });
  //                     }}
  //                   ></Select>
  //                 </div>
  //                 <div>
  //                   <Input
  //                     size="large"
  //                     placeholder="DocumentID"
  //                     value={xmlt[1]}
  //                     onChange={(e: any) => {
  //                       let d = [...data.fromMinimumDocumentId];
  //                       d[index][1] = e.target.value;
  //                       setData({
  //                         ...data,
  //                         fromMinimumDocumentId: d,
  //                       });
  //                     }}
  //                   />
  //                 </div>
  //                 <div>
  //                   <Button
  //                     style={{
  //                       width: "100%",
  //                     }}
  //                     size="large"
  //                     onClick={() => {
  //                       let d = data.fromMinimumDocumentId;
  //                       d.splice(index, 1);
  //                       setData({
  //                         ...data,
  //                         fromMinimumDocumentId: d,
  //                       });
  //                     }}
  //                   >
  //                     <MinusOutlined />
  //                   </Button>
  //                 </div>
  //               </Space>
  //             );
  //           })}
  //         <Button onClick={addNewMinimumDocumentID} style={{ width: "100%" }}>
  //           Add New Minimum Document
  //         </Button>
  //       </>
  //     ),
  //   },
  //   {
  //     key: 3,
  //     title: "Check Reference",
  //     content: (
  //       <>
  //         {" "}
  //         <span>Check Company Reference</span>
  //         <Select
  //           listHeight={160}
  //           getPopupContainer={(trigger) => trigger.parentElement}
  //           mode="tags"
  //           options={CompanyRef}
  //           showArrow
  //           value={data.checkCompanyRef}
  //           placeholder="Company Reference"
  //           style={{ width: "100%", marginBottom: "20px" }}
  //           onChange={(option: any) => {
  //             setData({ ...data, checkCompanyRef: option });
  //           }}
  //         />
  //         <span>Check Industry Reference</span>
  //         <Select
  //           listHeight={160}
  //           getPopupContainer={(trigger) => trigger.parentElement}
  //           mode="tags"
  //           options={IndustryRef}
  //           showArrow
  //           value={data.checkIndustryRef}
  //           placeholder="Company Reference"
  //           style={{ width: "100%", marginBottom: "20px" }}
  //           onChange={(option: any) => {
  //             setData({ ...data, checkIndustryRef: option });
  //           }}
  //         />
  //       </>
  //     ),
  //   },
  //   {
  //     key: 4,
  //     title: "Destination Folder",
  //     content: (
  //       <>
  //         {" "}
  //         <span>Set Destination Folder</span>
  //         {data.destFolders !== undefined &&
  //           data.destFolders.map((xmlt: any, index: any) => {
  //             return (
  //               <Space
  //                 key={index}
  //                 style={{
  //                   display: "grid",
  //                   gridTemplateColumns: "1fr 1.5fr 0.2fr",
  //                   width: "100%",
  //                 }}
  //               >
  //                 <div>
  //                   <Select
  //                     listHeight={160}
  //                     getPopupContainer={(trigger) => trigger.parentElement}
  //                     options={publicationTypeTags}
  //                     showArrow
  //                     value={xmlt[0] || undefined}
  //                     size="large"
  //                     placeholder="Publication Type"
  //                     style={{
  //                       width: "100%",
  //                     }}
  //                     onChange={(option: any) => {
  //                       let d = [...data.destFolders];
  //                       d[index][0] = option;
  //                       setData({
  //                         ...data,
  //                         destFolders: d,
  //                       });
  //                     }}
  //                   ></Select>
  //                 </div>
  //                 <div>
  //                   <Input
  //                     size="large"
  //                     placeholder="Destination"
  //                     value={xmlt[1]}
  //                     onChange={(e: any) => {
  //                       let d = [...data.destFolders];
  //                       d[index][1] = e.target.value;
  //                       setData({
  //                         ...data,
  //                         destFolders: d,
  //                       });
  //                     }}
  //                   />
  //                 </div>
  //                 <div>
  //                   <Button
  //                     style={{
  //                       width: "100%",
  //                     }}
  //                     size="large"
  //                     onClick={() => {
  //                       let d = data.destFolders;
  //                       d.splice(index, 1);
  //                       setData({
  //                         ...data,
  //                         destFolders: d,
  //                       });
  //                     }}
  //                   >
  //                     <MinusOutlined />
  //                   </Button>
  //                 </div>
  //               </Space>
  //             );
  //           })}
  //         <Button onClick={addNewDestination} style={{ width: "100%" }}>
  //           Add New Destination
  //         </Button>
  //       </>
  //     ),
  //   },
  //   {
  //     key: 5,
  //     title: "Filter",
  //     content: (
  //       <>
  //         <span>Filter by Industry</span>
  //         <Select
  //           listHeight={160}
  //           getPopupContainer={(trigger) => trigger.parentElement}
  //           mode="tags"
  //           options={industrylist}
  //           showArrow
  //           // size="large"
  //           // value={
  //           //   data.filter
  //           //     ? data.filter?.[0]?.$and?.[0]?.industry?.$nin?.[0]
  //           //     : []
  //           // }
  //           value={
  //             data.filter ? data.filter?.$and?.[0]?.industry?.$nin?.[0] : []
  //           }
  //           placeholder="Tags to Include"
  //           style={{ width: "100%", marginBottom: "20px" }}
  //           onChange={(e: any) => {
  //             handleFilterByIndustry(e);
  //           }}
  //         />
  //         <span>Filter by Country</span>
  //         <Select
  //           listHeight={160}
  //           getPopupContainer={(trigger) => trigger.parentElement}
  //           mode="tags"
  //           options={countrylist}
  //           showArrow
  //           // size="large"
  //           value={
  //             data.filter ? data.filter?.[0]?.$and?.[1]?.country?.$nin?.[0] : []
  //           }
  //           placeholder="Tags to Include"
  //           style={{ width: "100%", marginBottom: "20px" }}
  //           onChange={(e: any) => {
  //             handleFilterByCountry(e);
  //           }}
  //         />
  //         <span>Exclude Filter by Industry</span>
  //         <Select
  //           listHeight={160}
  //           getPopupContainer={(trigger) => trigger.parentElement}
  //           mode="tags"
  //           options={industrylist}
  //           showArrow
  //           // size="large"
  //           value={data.excludeFilter?.[0]?.industry}
  //           placeholder="Tags to Include"
  //           style={{ width: "100%", marginBottom: "20px" }}
  //           onChange={(e: any) => {
  //             setData((prevData: any) => {
  //               // Find the index of the object with 'industry' property in excludeFilter
  //               const industryIndex = prevData.excludeFilter.findIndex(
  //                 (item: any) => "industry" in item
  //               );

  //               // Create a copy of the data object
  //               const newData = { ...prevData };

  //               if (industryIndex !== -1) {
  //                 // Update the 'industry' property based on the selected items
  //                 newData.excludeFilter[industryIndex].industry = e;
  //               } else {
  //                 // If 'industry' property doesn't exist, create a new object
  //                 newData.excludeFilter.push({ industry: e });
  //               }

  //               return newData;
  //             });
  //           }}
  //         />
  //         <span>Exclude Filter by Country</span>
  //         <Select
  //           listHeight={160}
  //           getPopupContainer={(trigger) => trigger.parentElement}
  //           mode="tags"
  //           options={countrylist}
  //           showArrow
  //           // size="large"
  //           value={data.excludeFilter?.[0]?.country}
  //           placeholder="Tags to Include"
  //           style={{ width: "100%", marginBottom: "20px" }}
  //           onChange={(e: any) => {
  //             setData((prevData: any) => {
  //               // Find the index of the object with 'industry' property in excludeFilter
  //               const countryIndex = prevData.excludeFilter.findIndex(
  //                 (item: any) => "country" in item
  //               );

  //               // Create a copy of the data object
  //               const newData = { ...prevData };

  //               if (countryIndex !== -1) {
  //                 // Update the 'industry' property based on the selected items
  //                 newData.excludeFilter[countryIndex].country = e;
  //               } else {
  //                 // If 'industry' property doesn't exist, create a new object
  //                 newData.excludeFilter.push({ country: e });
  //               }

  //               return newData;
  //             });
  //           }}
  //         />
  //       </>
  //     ),
  //   },
  //   // {
  //   //   key: 7,
  //   //   title: "Date initial",
  //   //   content: (
  //   //     <>
  //   //       <Checkbox
  //   //         style={{ paddingTop: "10px", marginBottom: "20px" }}
  //   //         checked={data.forceInitial}
  //   //         onChange={(ev: any) => {
  //   //           setData({
  //   //             ...data,
  //   //             forceInitial: ev.target.checked,
  //   //           });
  //   //         }}
  //   //       >
  //   //         Force Initial
  //   //       </Checkbox>
  //   //       <span style={{ display: "block" }}>Initial Start Date</span>
  //   //       {!showinitialsdate && (
  //   //         <Input
  //   //           style={{ marginBottom: "20px" }}
  //   //           value={convertDate(data.initialStartDate)}
  //   //           onClick={() => {
  //   //             setShowInitialSdate(true);
  //   //             setShowEditInitialSdate(true);
  //   //           }}
  //   //         />
  //   //       )}
  //   //       {showinitialsdate && (
  //   //         <DatePicker
  //   //           open={showeditinitialsdate}
  //   //           style={{ width: "100%" }}
  //   //           format={"DD/MM/YYYY"}
  //   //           onChange={(option: any) => {
  //   //             setData({ ...data, initialStartDate: showDateFormat(option) });
  //   //             setShowEditInitialSdate(false);
  //   //           }}
  //   //           onFocus={() => {
  //   //             setShowEditInitialSdate(true);
  //   //           }}
  //   //           onBlur={() => {
  //   //             setShowInitialSdate(false);
  //   //           }}
  //   //         />
  //   //       )}

  //   //       <span>Initial End Date</span>
  //   //       {!showinitialedate && (
  //   //         <Input
  //   //           value={convertDate(data.initialEndDate)}
  //   //           onClick={() => {
  //   //             setShowInitialEdate(true);
  //   //             setShowEditInitialEdate(true);
  //   //           }}
  //   //         />
  //   //       )}
  //   //       {showinitialedate && (
  //   //         <DatePicker
  //   //           open={showeditinitialedate}
  //   //           style={{ width: "100%" }}
  //   //           format={"DD/MM/YYYY"}
  //   //           onChange={(option: any) => {
  //   //             setData({ ...data, initialEndDate: option });
  //   //             setShowEditInitialEdate(false);
  //   //           }}
  //   //           onFocus={() => {
  //   //             setShowEditInitialEdate(true);
  //   //           }}
  //   //           onBlur={() => {
  //   //             setShowInitialEdate(false);
  //   //           }}
  //   //         />
  //   //       )}
  //   //     </>
  //   //   ),
  //   // },
  //   {
  //     key: 6,
  //     title: "Dry Run",
  //     content: (
  //       <>
  //         {" "}
  //         <Checkbox
  //           style={{ paddingBottom: "10px", flex: 1 }}
  //           checked={data.dryRun}
  //           onChange={(ev: any) => {
  //             setData({
  //               ...data,
  //               dryRun: ev.target.checked,
  //             });
  //           }}
  //         >
  //           Enable Dry Run
  //         </Checkbox>
  //       </>
  //     ),
  //   },
  //   // {
  //   //   key: 7,
  //   //   title: "Notes",
  //   //   content: (
  //   //     <>
  //   //       {" "}
  //   //       <span>Notes</span>
  //   //       <TextArea
  //   //         placeholder="Comment"
  //   //         value={data.notes}
  //   //         onChange={(e: any) => {
  //   //           setData({
  //   //             ...data,
  //   //             notes: e.target.value,
  //   //           });
  //   //         }}
  //   //       />
  //   //     </>
  //   //   ),
  //   // },
  // ];

  const items: any = [
    {
      key: "1",
      title: "Onboarding Information",
      content: (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Tabs
              tabPosition="left"
              hideAdd={true}
              type="editable-card"
              onChange={onTabChange}
              activeKey={activeKey}
              style={{
                width: "95%",
                margin: "auto",
              }}
              items={requiredconfig.map((tab: any) => ({
                label: tab.title,
                closable: false,
                key: tab.key,
                children: (
                  <>
                    <div
                      style={{
                        width: "95%",
                        textAlign: "start",
                        height: "50vh",
                        margin: "auto",
                      }}
                    >
                      {tab.content}
                      {((location.state.data !== null && datachange === true) ||
                        (location.state.data === null &&
                          data.name &&
                          data.mode &&
                          data.doccodes &&
                          data.fileTypes)) && (
                        <div
                          style={{
                            position: "absolute",
                            right: "0",
                            paddingRight: "2%",
                          }}
                        >
                          <Button
                            // type="primary"
                            className="open-modal-reset-button"
                            onClick={() => {
                              handleReset();
                            }}
                          >
                            Cancel
                          </Button>

                          <Button
                            // type="primary"
                            className="open-modal-save-button"
                            style={{ paddingLeft: "1%" }}
                            onClick={() => {
                              handleSave();
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      )}
                    </div>
                  </>
                ),
              }))}
            />
          </div>
        </>
      ),
      // content: (
      //   <>
      //     {" "}
      //     <Form
      //       style={{ width: "95%", margin: "auto", textAlign: "start" }}
      //       form={form}
      //       layout="vertical"
      //       labelCol={{ span: 4 }}
      //       wrapperCol={{ flex: 14 }}
      //       onFinish={onFinish}
      //       onFinishFailed={onFinishFailed}
      //       initialValues={{
      //         package: location.state.data ? location.state.data.package : "",
      //         mode: location.state.data ? location.state.data.mode : null,
      //         doccodes: location.state.data ? location.state.data.doccodes : [],
      //         fileTypes: location.state.data
      //           ? location.state.data.fileTypes
      //           : [],
      //       }}
      //     >
      //       {formData.map((item: any) => (
      //         <Form.Item {...item}>{item.component}</Form.Item>
      //       ))}

      //       <Form.Item>
      //         <div>
      //           <div className="steps-action">
      //             {current < 3 - 1 && (
      //               <Button
      //                 type="primary"
      //                 htmlType="submit"
      //                 // onClick={() => next()}
      //               >
      //                 Next
      //               </Button>
      //             )}
      //             {current === 3 - 1 && (
      //               <Button
      //                 type="primary"
      //                 onClick={() => message.success("Processing complete!")}
      //               >
      //                 Done
      //               </Button>
      //             )}
      //             {current > 0 && (
      //               <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
      //                 Previous
      //               </Button>
      //             )}
      //           </div>
      //         </div>
      //       </Form.Item>
      //     </Form>
      //   </>
      // ),
    },
    {
      key: "2",
      title: "Optional Information",
      content: (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Tabs
              tabPosition="left"
              hideAdd={true}
              type="editable-card"
              onChange={onTabChange}
              activeKey={activeKey}
              style={{
                width: "95%",
                margin: "auto",
              }}
              items={optionalconfig.map((tab: any) => ({
                label: tab.title,
                closable: false,
                key: tab.key,
                children: (
                  <>
                    <div
                      style={{
                        width: "95%",
                        textAlign: "start",
                        height: "70vh",
                        margin: "auto",
                        // overflow: "hidden",
                      }}
                    >
                      {tab.content}
                      {((location.state.data !== null && datachange === true) ||
                        (location.state.data === null &&
                          data.name &&
                          data.mode &&
                          data.doccodes &&
                          data.fileTypes)) && (
                        <div
                          style={{
                            position: "absolute",
                            right: "0",
                            paddingRight: "2%",
                          }}
                        >
                          <Button
                            // type="primary"
                            className="open-modal-reset-button"
                            onClick={() => {
                              handleReset();
                            }}
                          >
                            Cancel
                          </Button>

                          <Button
                            // type="primary"
                            className="open-modal-save-button"
                            style={{ paddingLeft: "1%" }}
                            onClick={() => {
                              handleSave();
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      )}
                    </div>
                  </>
                ),
              }))}
            />
          </div>
        </>
      ),
    },
    // {
    //   key: "3",
    //   title: "Additional Information",
    //   content: (
    //     <>
    //       {" "}
    //       <div
    //         style={{
    //           display: "flex",
    //           width: "100%",
    //         }}
    //       >
    //         <Tabs
    //           tabPosition="left"
    //           hideAdd={true}
    //           type="editable-card"
    //           // onChange={onTabChange}
    //           activeKey={activeKey}
    //           style={{
    //             width: "95%",
    //             margin: "auto",
    //           }}
    //           items={advancedconfig.map((tab: any) => ({
    //             label: tab.title,
    //             closable: false,
    //             key: tab.key,
    //             children: (
    //               <>
    //                 <div
    //                   style={{
    //                     width: "95%",
    //                     height: "50vh",
    //                     textAlign: "start",
    //                     margin: "auto",
    //                   }}
    //                 >
    //                   {tab.content}
    //                 </div>
    //               </>
    //             ),
    //           }))}
    //         />
    //       </div>
    //       {/* <div className="steps-action">
    //         {current < 3 - 1 && (
    //           <Button type="primary" onClick={() => next()}>
    //             Next
    //           </Button>
    //         )}
    //         {current === 3 - 1 && (
    //           <Button type="primary" onClick={() => handleSave()}>
    //             Done
    //           </Button>
    //         )}
    //         {current > 0 && (
    //           <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
    //             Previous
    //           </Button>
    //         )}
    //       </div> */}
    //     </>
    //   ),
    // },
  ];

  return (
    <>
      <div
        style={{
          width: "90%",
          margin: "auto",
          paddingTop: "20px",
        }}
      >
        <Breadcrumb>
          <Breadcrumb.Item>
            <a onClick={() => backToConfigurationGUI()}>
              List of Distribution Configurations
            </a>
          </Breadcrumb.Item>
          {location.state.data === null && (
            <Breadcrumb.Item>Add New Configuration</Breadcrumb.Item>
          )}
          {location.state.data !== null && (
            <Breadcrumb.Item>{location.state?.data?.name}</Breadcrumb.Item>
          )}
        </Breadcrumb>
      </div>
      {location.state.data === null && (
        <div
          style={{
            width: "90%",
            margin: "auto",
            paddingTop: "15px",
          }}
        >
          Copy From
          <Select
            showSearch
            style={{ width: 500, paddingLeft: "1%" }}
            // placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input: any, option: any) =>
              (option?.label ?? "").includes(input)
            }
            value={copyconfig}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={datasetoption}
            onChange={(e: any, f: any) => {
              onChangeCopy(e, f);
            }}
          />
        </div>
      )}
      <Steps
        current={current}
        items={items}
        className="config_gui_setup"
        onChange={(e: any) => {
          onStepsChange(e);
        }}
      />
      <div className="steps-content">{items[current].content}</div>
      {/* {current !== 0 && ( */}
      {/* {((current === 0 && activeKey === 1) ||
        (current === 1 && activeKey === 10)) && (
        <div className="steps-action-1">
          {(current > 0 || (current === 0 && activeKey > 1)) && (
            <Button style={{ marginRight: "8px" }} onClick={() => prev()}>
              Previous
            </Button>
          )}
          {(current < 2 - 1 || (current === 1 && activeKey < 10)) && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {data.name && data.mode && data.doccodes && data.fileTypes && (
            <Button
              type="primary"
              style={{ marginLeft: "8px" }}
              onClick={() => {
                // form.submit();
                handleSave();
              }}
            >
              Done
            </Button>
          )}
        </div>
      )}

      {((current === 0 && activeKey > 1) ||
        (current === 1 && activeKey < 10)) && (
        <div>
          <div className="steps-action">
            {(current > 0 || (current === 0 && activeKey > 1)) && (
              <Button style={{ marginRight: "8px" }} onClick={() => prev()}>
                Previous
              </Button>
            )}
            {(current < 2 - 1 || (current === 1 && activeKey < 10)) && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
          </div>
          <div className="steps-action-1">
            {data.name && data.mode && data.doccodes && data.fileTypes && (
              <Button
                type="primary"
                // style={{ marginLeft: "8px" }}
                onClick={() => {
                  // form.submit();
                  handleSave();
                }}
              >
                Done
              </Button>
            )}
          </div>
        </div>
      )} */}

      {/* {data.name && data.mode && data.doccodes && data.fileTypes && (
        <div style={{ position: "static", right: "0", paddingRight: "11%" }}>
          <Button
            // type="primary"
            className="open-modal-reset-button"
            onClick={() => {
              handleReset();
            }}
          >
            Cancel
          </Button>

          <Button
            // type="primary"
            className="open-modal-save-button"
            style={{ paddingLeft: "1%" }}
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </Button>
        </div>
      )} */}

      {/* )} */}
    </>
  );
};

export default MyComponent;
