import { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Input,
  Checkbox,
  notification,
  List,
  Divider,
} from "antd";
import axios from "axios";
import isEqual from "lodash/isEqual";
import { ConsoleSqlOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { createNewIndustry } from "../controller/create";
import { deleteMultiIndustry } from "../controller/delete";
import { updateCompanyIndustry } from "../controller/company";
import { updateIndustry } from "../controller/update";
import { addLog } from "../controller/profile";
// import InfiniteScroll from "react-infinite-scroller";

const MyComponent = (props: any) => {
  const [dataModalOpen, setDataModalOpen] = useState<boolean>(false);
  const [allData, setAllData] = useState<any>([]);
  const [currentData, setCurrentData] = useState<any>([]);
  const [tempAllData, setTempAllData] = useState<any>("");
  const [selectedRemoveData, setSelectedRemoveData] = useState<number[]>([]);
  const [newDataInputValue, setNewDataInputValue] = useState<string>("");

  // console.log("open", props.all_data);
  // Notification config
  notification.config({
    placement: "bottomRight",
    bottom: 50,
    duration: 3,
  });
  useEffect(() => {
    setDataModalOpen(props.open);
    // setCurrentData(props.new_data);
    setAllData(props.all_data);
    setTempAllData(props.all_data); // Store the original data to compare with changed data
  }, []);

  // ************************************************************************************************ //
  // Remove data field
  // ************************************************************************************************ //
  // Handle storing the checked check box data
  const handleSelectedRemoveData = (value: number) => {
    if (selectedRemoveData.includes(value)) {
      setSelectedRemoveData(
        selectedRemoveData.filter((data: any) => data !== value)
      );
    } else {
      setSelectedRemoveData([...selectedRemoveData, value]);
    }
  };

  const convert_Mapping = (el: any) => {
    let answer: any = allData.find((o: any) => {
      return o["industrycode"] == el;
    });
    if (!answer) {
      return;
    }
    if (!answer["description"]) {
      return;
    }
    return answer["description"];
  };
  // Handle delete checked checkbox data
  const handleDataRemove = () => {
    //Remove data from to website news database
    const update_website_news_database = (data: any) => {
      updateCompanyIndustry(data).then((res: any) => {
        // notification.destroy();
        // notification.success({
        //   message: "Data deleted",
        //   description: `${selectedRemoveData.length} data deleted successfully from website news database`,
        // });
      });
      // notification.destroy();
      // notification.error({
      //   message: "Error delete data",
      //   description: `${selectedRemoveData.length} data fail to delete from website news database`,
      // });
    };
    update_website_news_database(selectedRemoveData);
    //Remove data from to all tags / categories database

    const update_data_database = (data: any) => {
      deleteMultiIndustry(data).then((res: any) => {
        if (res.data) {
          notification.destroy();
          notification.success({
            message: "Data deleted",
            description: `${selectedRemoveData.length} data deleted successfully from database`,
          });
        } else {
          notification.destroy();
          notification.error({
            message: "Error delete data",
            description: `${selectedRemoveData.length} data fail to delete from database`,
          });
        }
      });
    };
    update_data_database(selectedRemoveData);
    // Update data set variable
    // let newData1 = currentData;
    // for (let x = 0; x < selectedRemoveData.length; x++) {
    //   newData1 = newData1.filter((item: any) => item !== selectedRemoveData[x]);
    // }
    // setCurrentData(newData1);
    // Update all data set variables
    let newData2 = allData;
    for (let x = 0; x < selectedRemoveData.length; x++) {
      newData2 = newData2.filter(
        (item: any) => item.industrycode !== selectedRemoveData[x]
      );
    }
    setAllData(newData2);
    if (props.handleRemoveData && typeof props.handleRemoveData == "function") {
      props.handleRemoveData(selectedRemoveData);
    }

    setSelectedRemoveData([]);
  };

  const handleDataRemoveConfirmation = () => {
    if (selectedRemoveData.length === 1) {
      Modal.confirm({
        title: "Delete Industry",
        content: (
          <>
            Are you sure you want to delete Industry{" "}
            <strong>{convert_Mapping(selectedRemoveData[0])}</strong>?<br />{" "}
            <i>Please note that it will impact associated company details.</i>
          </>
        ),
        okText: "Delete",
        onOk() {
          handleDataRemove();
        },
      });
    } else {
      Modal.confirm({
        title: "Delete Industry",
        content: (
          <>
            Are you sure you want to delete these Industry?
            <br />{" "}
            <i>Please note that it will impact associated company details.</i>
          </>
        ),
        okText: "Delete",
        onOk() {
          handleDataRemove();
        },
      });
    }
  };

  // ************************************************************************************************ //
  // Edit data field
  // ************************************************************************************************ //
  // Handle edit data
  const handleEditData = (input: any, referenceId: any) => {
    // Update all data set variable
    const updatedData = allData.map((item: any) => {
      if (item.industrycode === referenceId) {
        // Replace the item with the updated data
        return {
          ...item,
          industrycode: referenceId,
          description: input.target.value,
          id: item.id,
        };
      }
      return item;
    });

    // Save the updatedData to the allData variable
    setAllData(updatedData);
  };

  // ************************************************************************************************ //
  // Add new data field
  // ************************************************************************************************ //
  // Handle store add data value before press save or 'Enter'
  const handleNewDataInput = (data: any) => {
    setNewDataInputValue(data.target.value);
  };
  // Handle add data
  const handleNewDataInputConfirm = () => {
    const existingData = allData.filter((item: any) =>
      item.description.toLowerCase().includes(newDataInputValue.toLowerCase())
    );

    if (/^\s*$/.test(newDataInputValue) === true) {
      notification.destroy();
      notification.warning({
        message: "Empty Input!",
        description: `Cannot add empty data`,
      });
    } else if (existingData.length !== 0) {
      notification.destroy();
      notification.info({
        message: "Existing Industry!",
        description: `"${newDataInputValue}" is in the industry list`,
      });
    } else {
      // createNewIndustry(data)
      const update_data_database = (data: any) => {
        createNewIndustry(data)
          .then((res: any) => {
            let user: any = localStorage.getItem("user");
            let log: any = {
              page: window.location.pathname,
              action: "Create",
              datetime: new Date(),
              details: `Create Industry ${data.description}`,
              email: user,
            };
            addLog(log);
            notification.destroy();
            notification.success({
              message: "Data added",
              description: `"${data.description}" added successfully to industry database`,
            });
            const updatedData = [
              {
                industrycode: res.data.industrycode,
                description: res.data.description,
                id: res.data.id,
              },
              ...allData,
            ];
            // Sort the updatedData array
            updatedData.sort((a: any, b: any) => {
              // Replace "propertyToSortBy" with the actual property you want to sort by
              return a.industrycode - b.industrycode;
            });
            setAllData(updatedData);
            // setCurrentData([res.data._id, ...currentData]);
          })
          .catch((err: any) => {
            notification.destroy();
            notification.error({
              message: "Fail to add data",
              description: `"${data.description}" does not add to industry database`,
            });
            console.error(err);
          });
      };

      const newItemId = Math.max(
        ...allData
          .filter((item: any) => item.industrycode)
          .map((item: any) => item.industrycode)
      );
      const newData = {
        key: "reportal",
        industrycode: newItemId + 1,
        description: newDataInputValue,
      };
      update_data_database(newData);
      setNewDataInputValue("");
    }
  };

  // ************************************************************************************************ //
  // Close modal
  // ************************************************************************************************ //
  // Handle modal close and update the changes to database
  const handle_data_config_modal = () => {
    if (isEqual(allData, tempAllData) === false) {
      // Check label empty input
      const emptyField = allData.filter(
        (item: any) => /^\s*$/.test(item.industrycode) == true
      );
      const checkDuplicateData: any = [];
      const duplicateLabels: any = [];

      // Check if the current label already exists in the checkDuplicateData array
      allData.forEach((item: any, index: any, array: any) => {
        const isDuplicate = checkDuplicateData.some(
          (elem: any) => elem.industrycode === item.industrycode
        );
        if (isDuplicate) {
          duplicateLabels.push(item.industrycode);
        } else {
          checkDuplicateData.push(item);
        }
      });
      const hasDuplicates = duplicateLabels.length > 0;
      if (hasDuplicates) {
        notification.destroy();
        notification.info({
          message:
            "The list have duplicate industry so cannot save the changes",
          description: (
            <List
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                overflowX: "auto",
              }}
              dataSource={duplicateLabels}
              renderItem={(item, index) => <List.Item>{`${item}`}</List.Item>}
            />
          ),
        });
        return;
      } else if (emptyField.length !== 0) {
        notification.destroy();
        notification.warning({
          message: "Cannot Perform Saving",
          description: "Cannot accept empty data name",
        });
        return;
      } else {
        // Check the edited data
        let editedDataObjectId: any = [];
        let editedDataName: any = [];
        let editedDataId: any = [];
        for (let x = 0; x < allData.length; x++) {
          for (let y = 0; y < tempAllData.length; y++) {
            if (allData[x].industrycode === tempAllData[y].industrycode) {
              if (allData[x].description !== tempAllData[y].description) {
                // console.log("allData[x].industrycode", allData[x].industrycode);
                editedDataObjectId.push(allData[x].industrycode);
                editedDataName.push(allData[x].description);
                editedDataId.push(allData[x].id);
              }
            }
          }
        }
        if (editedDataName.length > 0) {
          Modal.confirm({
            title: "Changes Industry",
            content: (
              <>
                Are you sure you want to change these Industry details <br />
                Industry Code:
                {editedDataObjectId.map((name: any, index: any) => (
                  <span key={index}>
                    <strong>{name}</strong>
                    {index < editedDataObjectId.length - 1 && ", "}
                  </span>
                ))}
                <br />
                <i>
                  Please note that it will impact associated company details.
                </i>
              </>
            ),
            okText: "Comfirm",
            onOk() {
              const update_data_database = (data: any) => {
                updateIndustry(data).then((res: any) => {
                  if (res.data) {
                    notification.success({
                      message: "Edited data saved!",
                      description: `${data.industrycode.length} Changes has been saved to database.`,
                    });
                  } else {
                    notification.error({
                      message: "Save edited data fail!",
                      description: `Error saving the changed data to databases.`,
                    });
                  }
                });
              };
              update_data_database({
                description: editedDataName,
                industrycode: editedDataObjectId,
              });
              setNewDataInputValue("");
              setSelectedRemoveData([]);
              // Sort the allData array
              allData.sort((a: any, b: any) => {
                // Replace "propertyToSortBy" with the actual property you want to sort by
                return a.industrycode - b.industrycode;
              });
              props.set_all_data(allData);
              // props.set_new_data(currentData);
              setDataModalOpen(false);
              if (
                props.handleCancel &&
                typeof props.handleCancel === "function"
              ) {
                props.handleCancel();
              }
              if (
                props.handleChanges &&
                typeof props.handleChanges == "function"
              ) {
                props.handleChanges(true);
              }
            },
          });
        } else {
          setNewDataInputValue("");
          setSelectedRemoveData([]);
          // Sort the allData array
          allData.sort((a: any, b: any) => {
            // Replace "propertyToSortBy" with the actual property you want to sort by
            return a.industrycode - b.industrycode;
          });
          props.set_all_data(allData);
          // props.set_new_data(currentData);
          setDataModalOpen(false);
          if (props.handleCancel && typeof props.handleCancel === "function") {
            props.handleCancel();
          }
          if (props.handleChanges && typeof props.handleChanges == "function") {
            props.handleChanges(true);
          }
        }

        // Update the edit data to all tags / categories database
      }
    } else {
      setNewDataInputValue("");
      setSelectedRemoveData([]);
      // Sort the allData array
      allData.sort((a: any, b: any) => {
        // Replace "propertyToSortBy" with the actual property you want to sort by
        return a.industrycode - b.industrycode;
      });
      props.set_all_data(allData);
      // props.set_new_data(currentData);
      setDataModalOpen(false);
      if (props.handleCancel && typeof props.handleCancel === "function") {
        props.handleCancel();
      }
      if (props.handleChanges && typeof props.handleChanges == "function") {
        props.handleChanges(true);
      }
    }
  };

  return (
    <>
      {dataModalOpen ? (
        <Modal
          style={{ zIndex: "1100", margin: "auto", marginTop: "7%" }}
          width={"40%"}
          open={dataModalOpen}
          title={
            <div style={{ fontWeight: "bold" }}>Industry Configuration</div>
          }
          onCancel={() => handle_data_config_modal()}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Input
                type="text"
                placeholder="Add New Industry..."
                style={{ width: "89%" }}
                value={newDataInputValue}
                allowClear
                onChange={handleNewDataInput}
                onPressEnter={handleNewDataInputConfirm}
              />
              <PlusCircleOutlined
                style={{ width: "6%", fontSize: "110%", paddingLeft: "2%" }}
                onClick={handleNewDataInputConfirm}
              />
            </div>
          }
        >
          {selectedRemoveData.length > 0 ? (
            <div
              style={{
                marginBottom: "2%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="primary"
                danger
                style={{ marginRight: "8%" }}
                onClick={handleDataRemoveConfirmation}
              >
                Delete
              </Button>
            </div>
          ) : null}

          <Checkbox.Group
            style={{ display: "grid", height: "25vh", overflowY: "auto" }}
          >
            {allData
              .filter((item: any) => {
                if (/^\s*$/.test(newDataInputValue) === true) {
                  return item;
                } else if (
                  item.description
                    .toLowerCase()
                    .includes(newDataInputValue.toLowerCase())
                ) {
                  return item;
                }
              })
              .map((data: any, index: any) => {
                return (
                  <div key={index} style={{ display: "block", margin: "8px" }}>
                    {/* <Checkbox
                      value={data.description}
                      checked={selectedRemoveData.includes(data.industrycode)}
                      onChange={() =>
                        handleSelectedRemoveData(data.industrycode)
                      }
                      style={{ width: "5%", marginTop: "5px" }}
                    /> */}
                    <Input
                      addonBefore={data.industrycode}
                      style={{ width: "95%" }}
                      // allowClear
                      readOnly
                      value={data.description}
                      onChange={(e: any) =>
                        handleEditData(e, data.industrycode)
                      }
                    />
                  </div>
                );
              })}
          </Checkbox.Group>
        </Modal>
      ) : null}
    </>
  );
};

export default MyComponent;
