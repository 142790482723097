import React, { useEffect, useState } from "react";
import RT, { PageControl } from "./../component/RajangTable";
import Abbreviation from "../component/Abbreviation";
import { getIndustryFileIndex } from "../controller/fileindex";
import { getAllCountry, getAllIndustry } from "../controller/search";
import { getAllNCCompany } from "../controller/company";
import { addLog, checkmailProfile } from "../controller/profile";
import { Card, Button, Space, List, Checkbox, notification } from "antd";
import { useNavigate } from "react-router-dom";

const MyComponent = (props: any) => {
  const navigate = useNavigate();
  const initialPageControl: PageControl = {
    numRec: 0,
    recPerPage: 10,
    pageNum: 1,
  };
  const [data, setData]: any = useState([]);
  const [pageC, setPageC]: any = useState(initialPageControl);
  const [selectedPageData, setSelectedPageData]: any = useState([]);
  const [dropDownIndustryOptions, setdropDownIndustryOptions]: any = useState(
    []
  );
  const [dropDownCountryOptions, setdropDownCountryOptions]: any = useState([]);
  const [dropDownCompanyOptions, setdropDownCompanyOptions]: any = useState([]);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   let privilage: any = localStorage.getItem("privilage");
  //   let user: any = localStorage.getItem("user");
  //   let login: any = localStorage.getItem("isLogin");
  //   if (login === "true") {
  //     checkmailProfile({ email: user }).then((res: any) => {
  //       if (res === "You are unauthorized to use this resource") {
  //         setTimeout(() => {
  //           handleTokenExpired();
  //         }, 3000);
  //       } else if (res.data?.[0].role !== privilage) {
  //         localStorage.setItem("privilage", `${res.data[0].role}`);
  //       }
  //     });
  //   }
  // }, []);

  useEffect(() => {
    let user: any = localStorage.getItem("user");
    let log: any = {
      page: window.location.pathname,
      action: "Read",
      datetime: new Date(),
      details: "-",
      email: user,
    };
    addLog(log);
  }, []);

  useEffect(() => {
    const fetchCountryData = async () => {
      getAllCountry().then((res: any) => {
        if (res.data) {
          const dropDownCountryOption = res.data.map((x: any) => ({
            label: x.name,
            value: x.countrycode,
          }));
          setdropDownCountryOptions(dropDownCountryOption);
        }
      });
    };
    fetchCountryData();
  }, []);

  useEffect(() => {
    const fetchIndustryData = async () => {
      getAllIndustry().then((res: any) => {
        if (res.data) {
          const dropDownIndustryOption = res.data.map((x: any) => ({
            label: x.description,
            value: x.industrycode,
          }));
          const filteredOptions = dropDownIndustryOption.filter(
            (option: any) => option.label && option.value
          );
          filteredOptions.sort((a: any, b: any) => {
            const labelA = (a.label || a.value).toLowerCase();
            const labelB = (b.label || b.value).toLowerCase();
            if (labelA === labelB) {
              return a._id > b._id ? 1 : -1;
            } else {
              return labelA.localeCompare(labelB, undefined, {
                numeric: true,
                caseFirst: "upper",
              });
            }
          });
          setdropDownIndustryOptions(filteredOptions);
          getIndustryFileIndex({}).then((res: any) => {
            if (res.data) {
              const newData = res.data.map((rec: any) => {
                const matchingOption = dropDownIndustryOption.find(
                  (option: any) => option.value === rec._id
                );
                return {
                  _id: rec._id,
                  totalcompnumber: rec.totalcompnumber,
                  totalcountry: rec.totalcountry,
                  is: fileIndexTotalISMapping(res.data, rec._id),
                  cs: fileIndexTotalCSMapping(res.data, rec._id),
                  ir: fileIndexTotalIRMapping(res.data, rec._id),
                  rcd: fileIndexTotalRCDMapping(res.data, rec._id),
                  gs: fileIndexTotalGSMapping(res.data, rec._id),
                  es: fileIndexTotalESMapping(res.data, rec._id),
                  mr: fileIndexTotalMRMapping(res.data, rec._id),
                  m: fileIndexTotalMMapping(res.data, rec._id),
                  am: fileIndexTotalAMMapping(res.data, rec._id),
                  em: fileIndexTotalEMMapping(res.data, rec._id),
                  hcs: fileIndexTotalHCSMapping(res.data, rec._id),
                  totalpublication: fileIndexTotalPublicationMapping(
                    res.data,
                    rec._id
                  ),
                  compnumber: rec.compnumber,
                  country: rec.country,
                  doccode: rec.doccode,
                  label: matchingOption
                    ? matchingOption?.label || rec._id
                    : rec._id, // Add label property if matchingOption is found, otherwise set to null
                  value: matchingOption ? matchingOption.value : rec._id, // Add value property if matchingOption is found, otherwise set to null
                };
              });
              newData.sort((a: any, b: any) => {
                const labelA = (a.label || a.value).toLowerCase();
                const labelB = (b.label || b.value).toLowerCase();
                if (labelA === labelB) {
                  return a._id > b._id ? 1 : -1;
                } else {
                  return labelA.localeCompare(labelB, undefined, {
                    numeric: true,
                    caseFirst: "upper",
                  });
                }
              });
              setData(newData);
              setLoading(false);
            } else {
              setLoading(false);
            }
          });
        }
      });
    };
    fetchIndustryData();
  }, []);

  useEffect(() => {
    const fetchCompanyData = async () => {
      setLoading(true);
      getAllNCCompany().then((res: any) => {
        if (res.data) {
          const dropDownCompanyOption = res.data.map((x: any) => ({
            label: x.name,
            value: x.compnumber,
          }));
          setdropDownCompanyOptions(dropDownCompanyOption);

          setLoading(false);
        }
      });
    };
    fetchCompanyData();
  }, []);

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {
    setLoading(true);
    getIndustryFileIndex({}).then((res: any) => {
      if (res.data) {
        const newData = res.data.map((rec: any) => ({
          _id: rec._id,
          totalcompnumber: rec.totalcompnumber,
          totalcountry: rec.totalcountry,
          is: fileIndexTotalISMapping(res.data, rec._id),
          cs: fileIndexTotalCSMapping(res.data, rec._id),
          ir: fileIndexTotalIRMapping(res.data, rec._id),
          rcd: fileIndexTotalRCDMapping(res.data, rec._id),
          gs: fileIndexTotalGSMapping(res.data, rec._id),
          es: fileIndexTotalESMapping(res.data, rec._id),
          mr: fileIndexTotalMRMapping(res.data, rec._id),
          m: fileIndexTotalMMapping(res.data, rec._id),
          am: fileIndexTotalAMMapping(res.data, rec._id),
          em: fileIndexTotalEMMapping(res.data, rec._id),
          hcs: fileIndexTotalHCSMapping(res.data, rec._id),
          totalpublication: fileIndexTotalPublicationMapping(res.data, rec._id),
          compnumber: rec.compnumber,
          country: rec.country,
          doccode: rec.doccode,
        }));
        setData(newData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleTokenExpired = () => {
    notification.warning({
      message: "Warning",
      description: "Your authorization has expired.Please login again.",
      placement: "top",
      duration: 3,
    });

    setTimeout(() => {
      navigate("/login", { replace: true });
    }, 3000); // delay navigation for 3 seconds
  };

  const fileIndexTotalPublicationMapping = (fileindex: any, el: any) => {
    let answer: any = fileindex.find((o: any) => {
      return o["_id"] == el;
    });
    if (!answer) {
      return 0;
    }
    if (!answer["doccode"]) {
      return 0;
    }
    return answer["doccode"].length;
  };

  const fileIndexTotalISMapping = (fileindex: any, el: any) => {
    let answer: any = fileindex.find((o: any) => {
      return o["_id"] == el;
    });
    if (!answer) {
      return 0;
    }
    if (!answer["doccode"]) {
      return 0;
    }
    if (answer["doccode"]) {
      let count = 0;
      answer["doccode"].forEach((v: any) => {
        if (v === "IS") {
          count++;
        }
      });
      return count;
    }
  };

  const fileIndexTotalCSMapping = (fileindex: any, el: any) => {
    let answer: any = fileindex.find((o: any) => {
      return o["_id"] == el;
    });
    if (!answer) {
      return 0;
    }
    if (!answer["doccode"]) {
      return 0;
    }
    if (answer["doccode"]) {
      let count = 0;
      answer["doccode"].forEach((v: any) => {
        if (v === "CS") {
          count++;
        }
      });
      return count;
    }
  };

  const fileIndexTotalIRMapping = (fileindex: any, el: any) => {
    let answer: any = fileindex.find((o: any) => {
      return o["_id"] == el;
    });
    if (!answer) {
      return 0;
    }
    if (!answer["doccode"]) {
      return 0;
    }
    if (answer["doccode"]) {
      let count = 0;
      answer["doccode"].forEach((v: any) => {
        if (v === "IR") {
          count++;
        }
      });
      return count;
    }
  };

  const fileIndexTotalRCDMapping = (fileindex: any, el: any) => {
    let answer: any = fileindex.find((o: any) => {
      return o["_id"] == el;
    });
    if (!answer) {
      return 0;
    }
    if (!answer["doccode"]) {
      return 0;
    }
    if (answer["doccode"]) {
      let count = 0;
      answer["doccode"].forEach((v: any) => {
        if (v === "RCD") {
          count++;
        }
      });
      return count;
    }
  };

  const fileIndexTotalGSMapping = (fileindex: any, el: any) => {
    let answer: any = fileindex.find((o: any) => {
      return o["_id"] == el;
    });
    if (!answer) {
      return 0;
    }
    if (!answer["doccode"]) {
      return 0;
    }
    if (answer["doccode"]) {
      let count = 0;
      answer["doccode"].forEach((v: any) => {
        if (v === "GS") {
          count++;
        }
      });
      return count;
    }
  };

  const fileIndexTotalESMapping = (fileindex: any, el: any) => {
    let answer: any = fileindex.find((o: any) => {
      return o["_id"] == el;
    });
    if (!answer) {
      return 0;
    }
    if (!answer["doccode"]) {
      return 0;
    }
    if (answer["doccode"]) {
      let count = 0;
      answer["doccode"].forEach((v: any) => {
        if (v === "ES") {
          count++;
        }
      });
      return count;
    }
  };

  const fileIndexTotalMRMapping = (fileindex: any, el: any) => {
    let answer: any = fileindex.find((o: any) => {
      return o["_id"] == el;
    });
    if (!answer) {
      return 0;
    }
    if (!answer["doccode"]) {
      return 0;
    }
    if (answer["doccode"]) {
      let count = 0;
      answer["doccode"].forEach((v: any) => {
        if (v === "MR") {
          count++;
        }
      });
      return count;
    }
  };

  const fileIndexTotalMMapping = (fileindex: any, el: any) => {
    let answer: any = fileindex.find((o: any) => {
      return o["_id"] == el;
    });
    if (!answer) {
      return 0;
    }
    if (!answer["doccode"]) {
      return 0;
    }
    if (answer["doccode"]) {
      let count = 0;
      answer["doccode"].forEach((v: any) => {
        if (v === "M") {
          count++;
        }
      });
      return count;
    }
  };

  const fileIndexTotalAMMapping = (fileindex: any, el: any) => {
    let answer: any = fileindex.find((o: any) => {
      return o["_id"] == el;
    });
    if (!answer) {
      return 0;
    }
    if (!answer["doccode"]) {
      return 0;
    }
    if (answer["doccode"]) {
      let count = 0;
      answer["doccode"].forEach((v: any) => {
        if (v === "AM") {
          count++;
        }
      });
      return count;
    }
  };

  const fileIndexTotalEMMapping = (fileindex: any, el: any) => {
    let answer: any = fileindex.find((o: any) => {
      return o["_id"] == el;
    });
    if (!answer) {
      return 0;
    }
    if (!answer["doccode"]) {
      return 0;
    }
    if (answer["doccode"]) {
      let count = 0;
      answer["doccode"].forEach((v: any) => {
        if (v === "EM") {
          count++;
        }
      });
      return count;
    }
  };

  const fileIndexTotalHCSMapping = (fileindex: any, el: any) => {
    let answer: any = fileindex.find((o: any) => {
      return o["_id"] == el;
    });
    if (!answer) {
      return 0;
    }
    if (!answer["doccode"]) {
      return 0;
    }
    if (answer["doccode"]) {
      let count = 0;
      answer["doccode"].forEach((v: any) => {
        if (v === "HCS") {
          count++;
        }
      });
      return count;
    }
  };

  return (
    <>
      <div>
        <Abbreviation />
        <div>
          <RT
            tablewidth="90%"
            hasSelectAll={true}
            data={data}
            cols={[
              {
                fieldname: "_id",
                width: "25%",
                label: "Industry",
                lookup: dropDownIndustryOptions,
                sortcolumn: "String",
                clickable: "true",
                align: "left",
                filter: "true",
              },
              {
                fieldname: "totalcompnumber",
                width: "11",
                label: "Total Companies",
                sortcolumn: "Number",
                align: "center",
              },
              {
                fieldname: "totalcountry",
                width: "10%",
                label: "Total Countries",
                sortcolumn: "Number",
                align: "center",
              },
              {
                fieldname: "is",
                width: "4%",
                label: "IS",
                align: "center",
              },
              {
                fieldname: "cs",
                width: "4%",
                label: "CS",
                align: "center",
              },
              {
                fieldname: "ir",
                width: "4%",
                label: "IR",
                align: "center",
              },
              {
                fieldname: "rcd",
                width: "4%",
                label: "RCD",
                align: "center",
              },
              {
                fieldname: "gs",
                width: "4%",
                label: "GS",
                align: "center",
              },
              {
                fieldname: "es",
                width: "4%",
                label: "ES",
                align: "center",
              },
              {
                fieldname: "mr",
                width: "4%",
                label: "MR",
                align: "center",
              },
              {
                fieldname: "m",
                width: "4%",
                label: "M",
                align: "center",
              },
              {
                fieldname: "am",
                width: "4%",
                label: "AM",
                align: "center",
              },
              {
                fieldname: "em",
                width: "4%",
                label: "EM",
                align: "center",
              },
              {
                fieldname: "hcs",
                width: "4%",
                label: "HCS",
                align: "center",
              },
              {
                fieldname: "totalpublication",
                width: "10%",
                label: "Total Publications",
                align: "center",
              },
            ]}
            pageControl={pageC}
            selectedData={selectedPageData}
            loading={loading}
            // handleSelected={handleSelected}
            // handleFilter={handleFilter}
            // handleLoadData={handleLoadData}
            // is_data={is_data}
            // refreshData={refreshData}
            listing={[
              {
                countrylist: dropDownCountryOptions,
                companylist: dropDownCompanyOptions,
                industrylist: dropDownIndustryOptions,
              },
            ]}
            action={["reset"]}
            type={"small"}
          ></RT>
        </div>
      </div>
    </>
  );
};

export default MyComponent;
