import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import RT, { PageControl } from "./../component/RajangTable";
import {
  getCSDistributeSummary,
  getCSDistributerDeliveryDate,
} from "../controller/search";
import { Breadcrumb, Select } from "antd";
import { addLog } from "../controller/profile";

const MyComponent = (props: any) => {
  const initialPageControl: PageControl = {
    numRec: 0,
    recPerPage: 10,
    pageNum: 1,
  };
  const [pageC, setPageC]: any = useState(initialPageControl);
  const location = useLocation();
  const [data, setData]: any = useState([]);
  const [name, setName]: any = useState(null);
  const [loading, setLoading]: any = useState(false);
  const [breadcrumb, setBreadCrumb]: any = useState([]);
  const [PRdata, setPRdata]: any = useState([]);
  const [dropdownOption, setDropdownOption]: any = useState([]);
  const [currentdt, setcurrentdt] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let user: any = localStorage.getItem("user");
    let log: any = {
      page: window.location.pathname,
      action: "Read",
      datetime: new Date(),
      details: "-",
      email: user,
    };
    addLog(log);
  }, []);

  useEffect(() => {
    if (location.state != null) {
      setName(location.state.el);
      setBreadCrumb(location.state.breadcrumb);
      setPRdata(location.state.data);
      let p = {
        package: location.state.el,
        doccode: location.state.data.doccode,
      };
      getCSDistributerDeliveryDate(p).then((res: any) => {
        if (res.data) {
          const dropDownCountryOption = res.data.map((x: any) => ({
            label: convertToUTC(x.currentdt),
            value: x.currentdt,
          }));
          setDropdownOption(dropDownCountryOption);
        }
      });
    } else {
    }
  }, []);

  const convertToUTC = (dateStr: string) => {
    const date = new Date(dateStr);
    const utcDate = new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds()
      )
    );
    return utcDate.toLocaleString("en-GB", { timeZone: "UTC" });
  };

  const selectcurrentdt = (value: any) => {
    setcurrentdt(value);

    let languages = "EN"; // Initialize language variable

    // Check for language suffixes
    if (/-DE/.test(location.state.el)) {
      languages = "DE";
    } else if (/-ZH/.test(location.state.el)) {
      languages = "ZH";
    } else if (/-JA/.test(location.state.el)) {
      languages = "JA";
    }
    let p = {
      package: location.state.el,
      currentdt: value,
      doccode: location.state.data.doccode,
      language: languages,
    };

    setLoading(true);
    getCSDistributeSummary(p).then((data: any) => {
      const newData: any = {
        document: data.data.document,
      };
      setData(newData);
      setLoading(false);
    });
  };

  const getPRData: any = (el: any) => {
    navigate("/publication_report", {
      replace: true,
      state: { data: PRdata, breadcrumb: breadcrumb },
    });
  };

  const getCOM = (fname: any, fcompnumber: any, index: any) => {
    navigate("/search", {
      replace: true,
      state: {
        data: { name: fname, compnumber: fcompnumber },
        breadcrumb: breadcrumb,
      },
    });
  };

  const breadcrumbCOM = (el: any) => {
    if (el) {
      return el.map((d: any, index: any) => {
        return (
          <React.Fragment key={d._id}>
            {ComRenderRow(d.name, d.compnumber, index)}
          </React.Fragment>
        );
      });
    }
  };

  const ComRenderRow = (name: any, compnumber: any, index: any) => {
    if (name) {
      return (
        <>
          <Breadcrumb.Item>
            <a onClick={() => getCOM(name, compnumber, index)}>{name}</a>
          </Breadcrumb.Item>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <div>
        <div
          style={{
            width: "80%",
            height: "100%",
            margin: "auto",
            paddingTop: "20px",
          }}
        >
          <Breadcrumb>
            {breadcrumbCOM(breadcrumb)}
            <Breadcrumb.Item>
              <a onClick={() => getPRData()}>Publication Report</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Distributor Summary</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div style={{ width: "90%", height: "100%", margin: "auto" }}>
          <div
            style={{
              fontWeight: "700",
              fontSize: "22px",
              paddingTop: "10px",
              paddingBottom: "10px",
              textAlign: "center",
            }}
          >
            Distributor Summary
          </div>
          <div style={{ padding: "0px 100px 0 100px" }}>
            <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
              <div
                style={{
                  border: "1px black",
                  borderStyle: "solid none none solid",
                  fontSize: "16px",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                Distributor
              </div>
              <div
                style={{
                  border: "1px black",
                  borderStyle: "solid solid none solid",
                  fontWeight: 700,
                  fontSize: "16px",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                {name || ""}
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
              <div
                style={{
                  border: "1px black",
                  borderStyle: "solid none solid solid",
                  fontSize: "16px",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                Delivery Date:
              </div>
              <div
                style={{
                  border: "1px black",
                  borderStyle: "none none none none",
                  fontWeight: 700,
                  fontSize: "16px",
                  textAlign: "left",
                  // paddingLeft: "10px",
                }}
              >
                <Select
                  listHeight={160}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  showSearch
                  style={{
                    width: "100%",
                    height: "100%",
                    textAlign: "left",
                    border: "1px solid",
                  }}
                  placeholder="Select Delivery Date"
                  optionFilterProp="children"
                  onChange={selectcurrentdt}
                  value={currentdt}
                  filterOption={(input: any, option: any) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={dropdownOption}
                />
              </div>
            </div>
            <div
              style={{
                border: "1px black",
                borderStyle: "none solid solid solid",
                overflow: "auto",
                fontSize: "16px",
                textAlign: "left",
                paddingTop: "2%",
              }}
            >
              <RT
                tablewidth="90%"
                pageControl={pageC}
                data={data.document}
                cols={[
                  {
                    fieldname: "publishdate",
                    width: "20%",
                    label: "Publication date",
                    sortcolumn: "Date",
                    align: "left",
                  },
                  {
                    fieldname: "title",
                    width: "80%",
                    label: "Reports",
                    align: "left",
                  },
                ]}
                loading={loading}
                type={"small"}
              ></RT>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyComponent;
