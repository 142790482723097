import React, { useState, useEffect } from "react";
import {
  Card,
  Input,
  Button,
  Space,
  List,
  Checkbox,
  Breadcrumb,
  notification,
} from "antd";
import AddNewRegionModel from "./AddNewRegionModel";
import AlertModal from "./AlertMessage";
import { MinusCircleOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import AddCountry from "./BRCAddCountry";
import { v4 as uuidv4 } from "uuid";

const MyComponent = (props: any) => {
  const [checked, setChecked]: any = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedREC, setCheckedREC]: any = useState([]);
  const [indeterminateREC, setIndeterminateREC] = useState(false);
  const [checkAllREC, setCheckAllREC] = useState(false);
  const [checkedAMC, setCheckedAMC]: any = useState([]);
  const [indeterminateAMC, setIndeterminateAMC] = useState(false);
  const [checkAllAMC, setCheckAllAMC] = useState(false);
  const [checkedANR, setCheckedANR]: any = useState([]);
  const [indeterminateANR, setIndeterminateANR] = useState(false);
  const [checkAllANR, setCheckAllANR] = useState(false);
  const [selectRegion, setSelectRegion]: any = useState({});
  const [regiondata, setRegionData]: any = useState([]);
  const [country, setCountry]: any = useState([]);
  const [tempcountry, setTempCountry]: any = useState([]);
  const [regionitem, setRegionItem]: any = useState(null);
  const [newRegionType, setNewRegionType]: any = useState(false);
  const [showCRTModal, setShowCRTModal] = useState(false);
  const [addnewRegion, setAddNewRegion] = useState(false);
  const [addmoreCountry, setAddMoreCountry] = useState(false);
  const [regionName, setRegionName]: any = useState(null);
  const [tempregionName, setTempRegionName]: any = useState(null);
  const [regionType, setRegionType]: any = useState(null);
  const [tempregionType, setTempRegionType]: any = useState(null);
  const [createRegionType, setcreateRegionType]: any = useState([]);
  const [checkeditable, setCheckEditable] = useState(false);
  const [showDConfirm, setShowDConfirm] = useState(false);
  const [showDCountryConfirm, setShowDCountryConfirm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertmessage, setAlertMessage] = useState("");
  const [alerttype, setAlertType] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedCountries, setSelectedCountries]: any = useState([]);
  const [checkedMap, setCheckedMap]: any = useState({});
  const [existcountry, setExistCountry]: any = useState([]);
  const [showCConfirm, setShowCConfirm] = useState(false);
  const [tempDelete, setTempDelete]: any = useState([]);
  const [tempCreate, setTempCreate]: any = useState([]);
  const [tempRegionTypeDetail, setTempRegionTypeDetail]: any = useState([]);
  const [showRConfirm, setShowRConfirm] = useState(false);
  const [tempDeleteRegion, setTempDeleteRegion]: any = useState([]);
  const [tempCreateRegion, setTempCreateRegion]: any = useState([]);

  // Condition
  const [createNewRegionType, setCreateNewRegionType] = useState(false);
  const [showcreateNewRegionOption, setShowCreateNewRegionOption] =
    useState(false);
  const [editRegionType, setEditRegionType] = useState(false);
  const [createNewRegion, setCreateNewRegion] = useState(false);
  const [editRegion, setEditRegion] = useState(false);
  const [addcountry, setAddCountry] = useState(false);
  //

  const [breadcrumb, setBreadCrumb]: any = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (createNewRegionType === true) {
      setShowCreateNewRegionOption(true);
      setEditRegionType(false);
      setCreateNewRegion(false);
      setEditRegion(false);
      setAddCountry(false);
      setChecked([]);
      setCheckedAMC([]);
      setCheckedANR([]);
      setCheckedREC([]);
      setcreateRegionType([]);
      setRegionType(null);
    }
  }, [createNewRegionType]);

  useEffect(() => {
    if (editRegionType === true) {
      setCreateNewRegionType(false);
      setCreateNewRegion(false);
      setEditRegion(false);
      setAddCountry(false);
      setChecked([]);
      setCheckedAMC([]);
      setCheckedANR([]);
      setCheckedREC([]);
    }
  }, [editRegionType]);

  useEffect(() => {
    if (createNewRegion === true) {
      setShowCreateNewRegionOption(true);
      setCreateNewRegionType(false);
      setEditRegionType(false);
      setEditRegion(false);
      setAddCountry(false);
      setChecked([]);
      setCheckedAMC([]);
      setCheckedANR([]);
      setCheckedREC([]);
      setBreadCrumb(breadcrumb.slice(0, 1).concat(["Add new region"]));
    }
  }, [createNewRegion]);

  useEffect(() => {
    if (addcountry === true) {
      setCreateNewRegionType(false);
      setEditRegionType(false);
      setCreateNewRegion(false);
      setEditRegion(false);
      setChecked([]);
      setCheckedAMC([]);
      setCheckedANR([]);
      setCheckedREC([]);
      let newArray = breadcrumb.slice(0, 2).concat(["Add new country"]);
      setBreadCrumb(newArray);
    }
  }, [addcountry]);

  useEffect(() => {
    if (editRegion === true) {
      setCreateNewRegionType(false);
      setEditRegionType(false);
      setCreateNewRegion(false);
      setAddCountry(false);
      setChecked([]);
      setCheckedAMC([]);
      setCheckedANR([]);
      setCheckedREC([]);
      let newArray = breadcrumb.slice(0, 1).concat([regionName]);
      setBreadCrumb(newArray);
    }
  }, [editRegion]);

  useEffect(() => {
    setSelectRegion(props.selectRegion);
    setRegionData(props.selectRegion.related);
    setTempRegionTypeDetail(props.selectRegion.related);
    setNewRegionType(props.newRegionType);
    if (props.selectRegion.length !== 0 && props.country.length === 0) {
      setAddNewRegion(false);

      setRegionType(props.selectRegion._id);
      setTempRegionType(props.selectRegion._id);
      setBreadCrumb([props.selectRegion._id, "Regions"]);
      setEditRegionType(true);
    }

    if (props.selectRegion.length === 0 && props.country.length === 0) {
      setEditRegionType(false);
    }
    if (props.newRegionType === true) {
      setCreateNewRegionType(true);
    }
    if (props.newRegionType === false) {
      setCreateNewRegionType(false);
    }
  }, [props.selectRegion, props.newRegionType]);

  useEffect(() => {
    if (props.selectRegion.length !== 0 && props.country.length !== 0) {
      setCountry(props.country);
      setTempCountry(props.country);
      let newcountry: any = [];
      props.country.map((d: any) => {
        newcountry.push(d.country);
      });
      setExistCountry(newcountry);
      setEditRegion(true);
    }
  }, [props.country]);

  useEffect(() => {
    if (regiondata?.length > 0) {
      setIndeterminate(checked.length && checked.length !== regiondata.length);
      setCheckAll(checked.length === regiondata.length);
    }
  }, [checked]);

  const onCheckAllChange = (e: any) => {
    setChecked(e.target.checked ? regiondata.map((item: any) => item) : []);
    setCheckAll(e.target.checked);
    setIndeterminate(false);
  };

  useEffect(() => {
    if (country?.length > 0) {
      setIndeterminateREC(
        checkedREC.length && checkedREC.length !== country.length
      );
      setCheckAllREC(checkedREC.length === country.length);
    }
  }, [checkedREC]);

  const onCheckAllRECChange = (e: any) => {
    setCheckedREC(e.target.checked ? country.map((item: any) => item) : []);
    setCheckAllREC(e.target.checked);
    setIndeterminateREC(false);
  };

  useEffect(() => {
    if (props.countrylist?.length > 0) {
      setIndeterminateAMC(
        checkedAMC.length && checkedAMC.length !== props.countrylist.length
      );
      setCheckAllAMC(checkedAMC.length === props.countrylist.length);
    }
  }, [checkedAMC]);

  const onCheckAllAMCChange = (e: any) => {
    setCheckedAMC(
      e.target.checked ? props.countrylist.map((item: any) => item.name) : []
    );
    setCheckAllAMC(e.target.checked);
    setIndeterminateAMC(false);
  };

  useEffect(() => {
    if (props.countrylist?.length > 0) {
      setIndeterminateANR(
        checkedANR.length && checkedANR.length !== props.countrylist.length
      );
      setCheckAllANR(checkedANR.length === props.countrylist.length);
    }
  }, [checkedANR]);

  const onCheckAllANRChange = (e: any) => {
    setCheckedANR(
      e.target.checked ? props.countrylist.map((item: any) => item.name) : []
    );
    setCheckAllANR(e.target.checked);
    setIndeterminateANR(false);
  };

  const selectregion = (regionid: any, regiontype: any, region: any) => {
    const ACRegion = regiondata.some((obj: any) => {
      return obj.regionid === regionid;
    });
    if (ACRegion) {
      let searchObj: any = {};
      searchObj["regionid"] = regionid;
      searchObj["regiontype"] = regiontype;
      let onHold: any = {};
      onHold["regionid"] = regionid;
      onHold["regiontype"] = regiontype;
      onHold["region"] = region;
      setRegionName(region);
      setTempRegionName(region);
      setRegionItem(onHold);
      if (
        props.handleSelectedCountry &&
        typeof props.handleSelectedCountry == "function"
      ) {
        props.handleSelectedCountry(searchObj);
      }
    } else {
      handleAlertType("Alert");
      handleAlertMessage(`You need to save this region before you can edit.`);
    }
  };

  const closeModal = () => {
    setShowCRTModal(false);
  };

  const createnewRegion = () => {
    setAddNewRegion(true);
    setSelectRegion([]);
    // setRegionData([]);
    setNewRegionType(false);
    setCreateNewRegion(true);
    setChecked([]);
    setCheckedAMC([]);
    setCheckedANR([]);
    setCheckedREC([]);
    setCheckAll(false);
    setCheckAllAMC(false);
    setCheckAllANR(false);
    setCheckAllREC(false);
    setRegionName(null);
    setTempRegionName(null);
  };

  const addmorecountry = () => {
    // setAddMoreCountry(true);
    // setAddCountry(true);
    setIsModalOpen(true);
  };

  const uniqueCountry: any = (el: any) => {
    el.map((d: any) => {
      props.country.map((k: any) => {
        if (d === k.country) {
          el.splice(d, el.length);
        }
      });
    });
    return el;
  };

  const saveMoreCountry = () => {
    let LasttempDelete = tempDelete.filter(
      (item: any) => !tempCreate.includes(item)
    );
    let LasttempCreate = tempCreate.filter(
      (item: any) => !tempDelete.includes(item)
    );
    const nonDuplicates = tempcountry.filter(
      (element: any) =>
        !country.find((item: any) => item.country === element.country)
    );
    let newCountryData: any = [];
    uniqueCountry(nonDuplicates).map((d: any) => {
      let newDD: any = {};
      newDD["country"] = d["country"];
      newDD["regionid"] = country[0].regionid;
      newDD["regiontype"] = country[0].regiontype;
      newCountryData.push(newDD);
    });
    let regionid = country[0].regionid;
    let regiontype = country[0].regiontype;
    let regioncountry: any = [];
    LasttempDelete.map((k: any) => {
      regioncountry.push(k);
    });
    if (LasttempCreate.length > 0 && LasttempDelete.length == 0) {
      if (
        props.handleAddMoreCountry &&
        typeof props.handleAddMoreCountry == "function"
      ) {
        props.handleAddMoreCountry(newCountryData);
        setTempCreate([]);
      }
    }
    if (LasttempDelete.length > 0 && LasttempCreate.length == 0) {
      if (
        props.handleDeleteCountry &&
        typeof props.handleDeleteCountry == "function"
      ) {
        props.handleDeleteCountry(regionid, regioncountry, regiontype);
        setTempDelete([]);
      }
    } else if (LasttempDelete.length > 0 && LasttempCreate.length > 0) {
      if (
        props.handleAddNDeleteCountry &&
        typeof props.handleAddNDeleteCountry == "function"
      ) {
        props.handleAddNDeleteCountry(
          newCountryData,
          regionid,
          regioncountry,
          regiontype
        );
        setTempDelete([]);
        setTempCreate([]);
      }
    }

    setBreadCrumb(breadcrumb.slice(0, 2));
  };

  const addNewRegionWithCountry = () => {
    const latest_data = [...tempRegionTypeDetail];
    const index = latest_data.findIndex(
      (item: any) => item.region === tempregionName
    );
    if (index !== -1) {
      latest_data.splice(index, 1);
    }
    const newIndex = latest_data.findIndex(
      (item: any) => item.region === regionName
    );
    if (newIndex === -1) {
      if (regionName !== null && checkedANR.length > 0) {
        let newCountryData: any = [];
        const id = uuidv4();
        checkedANR.map((d: any) => {
          let newDD: any = {};
          newDD["country"] = d;
          newDD["regionid"] = id;
          newDD["regiontype"] = props.selectRegion._id;
          newCountryData.push(newDD);
        });
        let newRegionData: any = {
          region: regionName,
          regiontype: props.selectRegion._id,
          regionid: id,
          country: newCountryData,
        };
        setTempRegionTypeDetail([...tempRegionTypeDetail, newRegionData]);
        setTempCreateRegion([...tempCreateRegion, newRegionData]);
        setSelectRegion(props.selectRegion._id);
        setCreateNewRegion(false);
        setEditRegionType(true);
      } else if (checkedANR.length === 0) {
        handleAlertType("Alert");
        handleAlertMessage(`At least 1 Country needed with a Region`);
      } else {
        handleAlertType("Alert");
        handleAlertMessage(`Please key in a Region Name before proceed`);
      }
    } else {
      notification.warning({
        message: "Warning",
        description: (
          <>
            <strong>{regionName}</strong> already exists. Please try different
            Region Name.
          </>
        ),
        placement: "bottomRight",
        bottom: 50,
      });
    }
  };

  const handleSelectedRegion = (el: any) => {
    setcreateRegionType(el);
  };

  const updateRegionType = () => {
    const latest_data = [...props.data];
    const index = latest_data.findIndex(
      (item: any) => item._id === tempregionType
    );
    if (index !== -1) {
      latest_data.splice(index, 1);
    }
    const newIndex = latest_data.findIndex(
      (item: any) => item._id === regionType
    );
    if (newIndex === -1) {
      setCheckEditable(false);
      if (
        props.handleUpdateRegionType &&
        typeof props.handleUpdateRegionType == "function"
      ) {
        props.handleUpdateRegionType(selectRegion, regionType);
        setBreadCrumb();
      }
    } else {
      notification.warning({
        message: "Warning",
        description: "Duplicate Region Type",
        placement: "bottomRight",
        bottom: 50,
      });
    }
  };

  const updateRegionName = () => {
    const latest_data = [...tempRegionTypeDetail];
    const index = latest_data.findIndex(
      (item: any) => item.region === tempregionName
    );
    if (index !== -1) {
      latest_data.splice(index, 1);
    }
    const newIndex = latest_data.findIndex(
      (item: any) => item.region === regionName
    );
    if (newIndex === -1) {
      setCheckEditable(false);
      if (
        props.handleUpdateRegionName &&
        typeof props.handleUpdateRegionName == "function"
      ) {
        props.handleUpdateRegionName(regionitem, regionName);
      }
    } else {
      notification.warning({
        message: "Warning",
        description: "Duplicate Region Name",
        placement: "bottomRight",
        bottom: 50,
      });
    }
  };

  const EnableEdit = () => {
    setCheckEditable(true);
  };

  const controlButtRegionName: any = () => {
    if (regionName === null || regionName.trim().length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const controlButtRegionType: any = () => {
    if (regionType === null || regionType.trim().length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const back_showcreateNewRegionOption = () => {
    setEditRegionType(true);
  };

  const back_showeditRegion = () => {
    setEditRegion(true);
  };

  const removeRegion = () => {
    setShowDConfirm(true);
  };

  const removeCountry = () => {
    setShowDCountryConfirm(true);
  };

  const closeRemoveAlert = () => {
    setShowDConfirm(false);
  };

  const closeARemoveAlert = () => {
    setShowCConfirm(false);
  };

  const closeBRemoveAlert = () => {
    setShowRConfirm(false);
  };

  const closeRemoveCountryAlert = () => {
    setShowDCountryConfirm(false);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  const confirmDelete = () => {
    closeRemoveAlert();
    if (checked.length === props.selectRegion.related.length) {
      handleAlertType("Alert");
      handleAlertMessage(`At least 1 region needed with a Region Type`);
    } else if (checked.length < props.selectRegion.related.length) {
      let regiontype = checked[0].regiontype;
      let regionid: any = [];
      checked.map((k: any) => {
        regionid.push(k.regionid);
        let nonDuplicates = tempRegionTypeDetail.filter(
          (element: any) =>
            !checked.find((item: any) => item.regionid === element.regionid)
        );
        let removedDuplicates = tempRegionTypeDetail.filter((element: any) =>
          checked.find((item: any) => item.regionid === element.regionid)
        );
        setTempRegionTypeDetail(nonDuplicates);
        setTempDeleteRegion([...tempDeleteRegion, ...removedDuplicates]);
      });
      // if (
      //   props.handleDeleteRegion &&
      //   typeof props.handleDeleteRegion == "function"
      // ) {
      //   props.handleDeleteRegion(regiontype, regionid);

      // }
      setChecked([]);
    }
  };

  const confirmCountryDelete = () => {
    closeRemoveCountryAlert();
    if (checkedREC.length === country.length) {
      handleAlertType("Alert");
      handleAlertMessage(`At least 1 country needed with a Region`);
    } else if (checkedREC.length < country.length) {
      let regionid = checkedREC[0].regionid;
      let regiontype = checkedREC[0].regiontype;
      let regioncountry: any = [];
      checkedREC.map((k: any) => {
        regioncountry.push(k.country);
        let nonDuplicates = tempcountry.filter(
          (element: any) =>
            !checkedREC.find((item: any) => item.country === element.country)
        );
        setTempCountry(nonDuplicates);
      });
      const newArray = existcountry.filter(
        (item: any) => !regioncountry.includes(item)
      );
      setExistCountry(newArray);
      setTempDelete(regioncountry);
      // if (
      //   props.handleDeleteCountry &&
      //   typeof props.handleDeleteCountry == "function"
      // ) {
      //   props.handleDeleteCountry(regionid, regioncountry, regiontype);
      // }
      setChecked([]);
      setCheckedREC([]);
      setCheckAllREC(false);

      setEditRegion(true);
    }
  };

  const handleAlertMessage = (el: any) => {
    setAlertMessage(el);
    setShowAlert(true);
    off();
  };

  const handleAlertType = (el: any) => {
    setAlertType(el);
    setShowAlert(true);
    off();
  };

  const off = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const breadcrumbCOM = (el: any) => {
    if (el) {
      return el.map((d: any, index: any) => {
        return (
          <React.Fragment key={d._id}>{ComRenderRow(d, index)}</React.Fragment>
        );
      });
    }
  };

  const ComRenderRow = (d: any, index: any) => {
    if (d) {
      if (index === 0) {
        return (
          <>
            <Breadcrumb.Item>
              <a
                onClick={() => (
                  setEditRegionType(true),
                  setBreadCrumb(breadcrumb.slice(0, 1).concat(["Regions"]))
                )}
              >
                {d}
              </a>
            </Breadcrumb.Item>
          </>
        );
      } else if (index === 1) {
        if (breadcrumb.length === 2) {
          return (
            <>
              <Breadcrumb.Item>
                <a onClick={() => back_showcreateNewRegionOption}>{d}</a>
              </Breadcrumb.Item>
            </>
          );
        } else {
          return (
            <>
              <Breadcrumb.Item>
                <a
                  onClick={() => (
                    setEditRegion(true), setBreadCrumb(breadcrumb.slice(0, 4))
                  )}
                >
                  {d}
                </a>
              </Breadcrumb.Item>
            </>
          );
        }
      } else {
        return (
          <>
            <Breadcrumb.Item>
              <a>{d}</a>
            </Breadcrumb.Item>
          </>
        );
      }
    } else {
      return <></>;
    }
  };

  const combinedArray = [
    ...props.countrylist,
    ...checkedANR.map((countryName: any) => {
      return { name: countryName };
    }),
  ];

  const uniqueCountryNames = new Set();

  const filteredArray = combinedArray.filter((item: any) => {
    if (!item.name.toLowerCase().includes(searchValue.toLowerCase())) {
      return false;
    }
    if (uniqueCountryNames.has(item.name)) {
      return false;
    }
    uniqueCountryNames.add(item.name);
    return true;
  });

  const handleCheckboxChange = (event: any) => {
    const countryName = event.target.value;
    const isSelected = event.target.checked;
    setCheckedMap((prevCheckedMap: any) => ({
      ...prevCheckedMap,
      [countryName]: isSelected,
    }));
    if (isSelected) {
      setSelectedCountries([...selectedCountries, countryName]);
    } else {
      setSelectedCountries(
        selectedCountries.filter((name: any) => name !== countryName)
      );
    }
  };

  const checkCountry = (el: any) => {
    let answer: any = selectedCountries.find((o: any) => {
      return o == el;
    });
    if (!answer) {
      return false;
    }
    return true;
  };

  const checkOriRegionData = () => {
    const Ntempcountry = tempcountry.map((obj: any) => obj.country);
    const Ncountry = country.map((obj: any) => obj.country);
    const sortedArray1 = Ntempcountry.sort((a: any, b: any) =>
      a.localeCompare(b)
    );
    const sortedArray2 = Ncountry.sort((a: any, b: any) => a.localeCompare(b));
    if (JSON.stringify(sortedArray1) === JSON.stringify(sortedArray2)) {
      return true;
    } else {
      return false;
    }
  };

  const checkOriRegionTypeData = () => {
    const NtempRegionTypeDetail = tempRegionTypeDetail.map((obj: any) => ({
      region: obj.region,
      country: obj.country,
    }));
    const Nregiondata = regiondata.map((obj: any) => ({
      region: obj.region,
      country: obj.country,
    }));
    const sortedArray1 = NtempRegionTypeDetail.sort((a: any, b: any) =>
      a.region.localeCompare(b.region)
    );
    const sortedArray2 = Nregiondata.sort((a: any, b: any) =>
      a.region.localeCompare(b.region)
    );
    if (JSON.stringify(sortedArray1) === JSON.stringify(sortedArray2)) {
      return true;
    } else {
      return false;
    }
  };

  const handleCountry = (el: any) => {
    let newarr: any = [];
    el.map((name: any) => {
      let newel = {
        regiontype: tempcountry[0].regiontype,
        regionid: tempcountry[0].regionid,
        country: name,
      };

      newarr.push(newel);
    });
    setExistCountry([...existcountry, ...el]);
    setTempCreate(el);
    setTempCountry([...tempcountry, ...newarr]);
  };

  const RestoreRegionState = () => {
    const sortedArray1 = tempcountry.sort((a: any, b: any) =>
      a.country.localeCompare(b.country)
    );
    const sortedArray2 = country.sort((a: any, b: any) =>
      a.country.localeCompare(b.country)
    );

    if (JSON.stringify(sortedArray1) !== JSON.stringify(sortedArray2)) {
      setShowCConfirm(true);
    }
  };

  const RestoreRegionTypeState = () => {
    const sortedArray1 = tempRegionTypeDetail.sort((a: any, b: any) =>
      a.region.localeCompare(b.region)
    );
    const sortedArray2 = regiondata.sort((a: any, b: any) =>
      a.region.localeCompare(b.region)
    );

    if (JSON.stringify(sortedArray1) !== JSON.stringify(sortedArray2)) {
      setShowRConfirm(true);
    }
  };

  const confirmACancel = () => {
    setTempCountry(country);
    setShowCConfirm(false);
  };

  const confirmBCancel = () => {
    setTempRegionTypeDetail(regiondata);
    setShowRConfirm(false);
  };

  const saveRegion = () => {
    let LasttempDelete = tempDeleteRegion.filter(
      (item: any) => !tempCreateRegion.includes(item)
    );
    let LasttempCreate = tempCreateRegion.filter(
      (item: any) => !tempDeleteRegion.includes(item)
    );
    if (LasttempCreate.length > 0 && LasttempDelete.length == 0) {
      let allcountry: any = [];
      LasttempCreate.map((obj: any) =>
        obj.country.map((item: any) => allcountry.push(item))
      );
      let allregion: any = [];
      LasttempCreate.map((obj: any) =>
        allregion.push({
          regionid: obj.regionid,
          regiontype: obj.regiontype,
          region: obj.region,
        })
      );
      if (
        props.handleNewRegionWithCountry &&
        typeof props.handleNewRegionWithCountry == "function"
      ) {
        props.handleNewRegionWithCountry(allcountry, allregion);
        setTempCreateRegion([]);
      }
    }
    if (LasttempDelete.length > 0 && LasttempCreate.length == 0) {
      let allregionid: any = [];
      LasttempDelete.map((obj: any) => allregionid.push(obj.regionid));
      if (
        props.handleDeleteRegion &&
        typeof props.handleDeleteRegion == "function"
      ) {
        props.handleDeleteRegion(LasttempDelete[0].regiontype, allregionid);
        setTempDeleteRegion([]);
      }
    }
    if (LasttempDelete.length > 0 && LasttempCreate.length > 0) {
      let allcountry: any = [];
      LasttempCreate.map((obj: any) =>
        obj.country.map((item: any) => allcountry.push(item))
      );
      let allregion: any = [];
      LasttempCreate.map((obj: any) =>
        allregion.push({
          regionid: obj.regionid,
          regiontype: obj.regiontype,
          region: obj.region,
        })
      );
      let allregionid: any = [];
      LasttempDelete.map((obj: any) => allregionid.push(obj.regionid));
      if (
        props.handleAddNDeleteRegion &&
        typeof props.handleAddNDeleteRegion == "function"
      ) {
        props.handleAddNDeleteRegion(
          allcountry,
          allregion,
          LasttempDelete[0].regiontype,
          allregionid
        );
        setTempDeleteRegion([]);
        setTempCreateRegion([]);
      }
    }
  };

  return (
    <>
      <div
        style={{
          height: "10%",
          margin: "auto",
          paddingTop: "10px",
          paddingLeft: "50px",
        }}
      >
        <Breadcrumb separator=">">{breadcrumbCOM(breadcrumb)}</Breadcrumb>
      </div>
      {isModalOpen && (
        <>
          <AddCountry
            open={isModalOpen}
            handleOk={handleOk}
            handleCancel={handleCancel}
            country={props.countrylist}
            name={regionName}
            existCountry={existcountry}
            handleCountry={handleCountry}
          />
        </>
      )}
      <AlertModal
        show={showCConfirm}
        onClose={closeARemoveAlert}
        deleteDataHook={confirmACancel}
        confirmCancel
      />
      <AlertModal
        show={showRConfirm}
        onClose={closeBRemoveAlert}
        deleteDataHook={confirmBCancel}
        confirmCancel
      />
      <AlertModal
        show={showAlert}
        onClose={closeAlert}
        alertmessage={alertmessage}
        alerttype={alerttype}
        alertmsg
      />
      <AlertModal
        show={showDConfirm}
        onClose={closeRemoveAlert}
        data={checked}
        deleteDataHook={confirmDelete}
        multipleDelete
      />
      <AlertModal
        show={showDCountryConfirm}
        onClose={closeRemoveCountryAlert}
        data={checkedREC}
        deleteDataHook={confirmCountryDelete}
        multipleDelete
      />
      {showCRTModal && (
        <AddNewRegionModel
          onClose={closeModal}
          show={showCRTModal}
          regionlist={props.data}
          handleSelectedRegion={handleSelectedRegion}
          copySavedRegionType
        />
      )}
      <Card
        style={{
          width: "50vw",
          margin: "auto",
          border: "0px solid rgba(0, 0, 0, 1)",
        }}
        headStyle={{
          backgroundColor: "rgba(217, 217, 217, 1)",
          color: "#000000",
        }}
      >
        {editRegionType && (
          <>
            <Card
              style={{
                width: "50vw",
                border: "0px solid rgba(0, 0, 0, 1)",
                margin: "auto",
              }}
            >
              {/* <div style={{textAlign:"left"}}>{selectRegion._id}</div> */}
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "20% 80%",
                  textAlign: "left",
                  paddingBottom: "10px",
                }}
              >
                <span style={{ fontWeight: "700" }}>Region Types</span>
                {checkeditable && (
                  <Input
                    type="text"
                    size="middle"
                    placeholder="Name of Configuration"
                    value={regionType}
                    name="regiontype"
                    onChange={(e: any) => {
                      setRegionType(e.target.value);
                    }}
                    onPressEnter={updateRegionType}
                  />
                )}
                {!checkeditable && (
                  <Button
                    block
                    style={{
                      color: "#000000",
                      whiteSpace: "normal",
                      textAlign: "left",
                    }}
                    onClick={() => EnableEdit()}
                  >
                    {regionType}
                  </Button>
                )}
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "20% 80%",
                  textAlign: "left",
                  paddingBottom: "10px",
                }}
              >
                <span style={{ fontWeight: "700", paddingTop: "15px" }}>
                  Regions
                </span>

                <Card
                  style={{
                    textAlign: "left",
                    border: "1px solid rgb(220,220,220)",
                  }}
                  headStyle={{
                    color: "#000000",
                    borderBottom: "1px solid rgb(220,220,220)",
                  }}
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}
                        style={{ fontWeight: "700" }}
                      >
                        Select all
                      </Checkbox>
                      {checked.length > 0 && (
                        <Button
                          style={{
                            textAlign: "right",
                            backgroundColor: "transparent",
                            height: "20px",
                            border: "none",
                          }}
                          onClick={removeRegion}
                          icon={
                            <MinusCircleOutlined style={{ color: "red" }} />
                          }
                        />
                      )}
                    </div>
                  }
                >
                  <Checkbox.Group
                    style={{
                      width: "100%",
                      maxHeight: "350px",
                      overflow: "auto",
                      textAlign: "left",
                    }}
                    value={checked}
                    onChange={(checkedValues) => {
                      setChecked(checkedValues);
                    }}
                  >
                    <List
                      itemLayout="horizontal"
                      dataSource={tempRegionTypeDetail}
                      renderItem={(item: any) => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={<Checkbox value={item} />}
                            title={
                              <span
                                onClick={() =>
                                  selectregion(
                                    item.regionid,
                                    item.regiontype,
                                    item.region
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {item.region}
                              </span>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </Checkbox.Group>
                  <Button
                    onClick={createnewRegion}
                    style={{
                      backgroundColor: "rgba(44, 134, 218, 1)",
                      color: "white",
                      width: "100%",
                    }}
                  >
                    + Add new Region
                  </Button>
                </Card>
              </div>
            </Card>
            <Space
              direction="horizontal"
              size="middle"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "103%",
              }}
            >
              <Button
                style={{ border: "none" }}
                onClick={RestoreRegionTypeState}
              >
                <u>Cancel</u>
              </Button>
              <Button
                onClick={saveRegion}
                disabled={checkOriRegionTypeData()}
                style={{
                  backgroundColor: checkOriRegionTypeData()
                    ? "rgba(137, 137, 137, 1)"
                    : "rgba(44, 134, 218, 1)",
                  color: "white",
                  width: "100%",
                }}
              >
                Save
              </Button>
            </Space>
          </>
        )}
        {editRegion && (
          <>
            <Card
              style={{
                width: "50vw",
                margin: "auto",
                textAlign: "left",
                border: "0px solid rgba(0, 0, 0, 1)",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "20% 80%",
                  paddingBottom: "10px",
                }}
              >
                <span style={{ fontWeight: "700" }}>Region Name</span>
                {checkeditable && (
                  <Input
                    type="text"
                    size="middle"
                    placeholder="Name of Configuration"
                    value={regionName || ""}
                    name="regiontype"
                    onChange={(e: any) => {
                      setRegionName(e.target.value);
                    }}
                    onPressEnter={updateRegionName}
                  />
                )}
                {!checkeditable && (
                  <Button
                    block
                    style={{
                      color: "#000000",
                      whiteSpace: "normal",
                      textAlign: "left",
                    }}
                    onClick={() => EnableEdit()}
                  >
                    {regionName}
                  </Button>
                )}
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "20% 80%",
                  textAlign: "left",
                  paddingBottom: "10px",
                }}
              >
                <span style={{ fontWeight: "700" }}>List of countries</span>
                <Card
                  style={{ border: "1px solid rgb(220,220,220)" }}
                  headStyle={{
                    color: "#000000",
                    borderBottom: "1px solid rgb(220,220,220)",
                  }}
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Checkbox
                        indeterminate={indeterminateREC}
                        onChange={onCheckAllRECChange}
                        checked={checkAllREC}
                        style={{ fontWeight: "700" }}
                      >
                        Select all
                      </Checkbox>
                      {checkedREC.length > 0 && (
                        <Button
                          style={{
                            textAlign: "right",
                            backgroundColor: "transparent",
                            height: "20px",
                            border: "none",
                          }}
                          onClick={removeCountry}
                          icon={
                            <MinusCircleOutlined style={{ color: "red" }} />
                          }
                        />
                      )}
                    </div>
                  }
                >
                  <Checkbox.Group
                    style={{
                      width: "100%",
                      maxHeight: "350px",
                      overflow: "auto",
                    }}
                    value={checkedREC}
                    onChange={(checkedValues) => {
                      setCheckedREC(checkedValues);
                    }}
                  >
                    <List
                      itemLayout="horizontal"
                      dataSource={tempcountry}
                      renderItem={(item: any) => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={<Checkbox value={item} />}
                            title={item.country}
                          />
                        </List.Item>
                      )}
                    />
                  </Checkbox.Group>
                  <Button
                    onClick={addmorecountry}
                    style={{
                      backgroundColor: "rgba(44, 134, 218, 1)",
                      color: "white",
                      width: "100%",
                    }}
                  >
                    + Add new Country
                  </Button>
                </Card>
              </div>
            </Card>
            <Space
              direction="horizontal"
              size="middle"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "103%",
              }}
            >
              <Button style={{ border: "none" }} onClick={RestoreRegionState}>
                <u>Cancel</u>
              </Button>
              <Button
                onClick={saveMoreCountry}
                disabled={checkOriRegionData()}
                style={{
                  backgroundColor: checkOriRegionData()
                    ? "rgba(137, 137, 137, 1)"
                    : "rgba(44, 134, 218, 1)",
                  color: "white",
                  width: "100%",
                }}
              >
                Save
              </Button>
            </Space>
          </>
        )}
        {addcountry && (
          <Card
            style={{
              width: "50vw",
              margin: "auto",
              border: "0px solid rgba(0, 0, 0, 1)",
            }}
            title="Add More Country"
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 3fr",
                paddingBottom: "10px",
              }}
            >
              <span>Name</span>
              <Input
                size="middle"
                placeholder="Enter region name"
                value={regionName || ""}
                disabled
              />
            </div>
            <Card
              style={{
                textAlign: "left",
                border: "1px solid rgba(0, 0, 0, 1)",
              }}
              headStyle={{
                backgroundColor: "rgba(217, 217, 217, 1)",
                color: "#000000",
                borderBottom: "1px solid rgba(0, 0, 0, 1)",
              }}
              title={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Checkbox
                    indeterminate={indeterminateAMC}
                    onChange={onCheckAllAMCChange}
                    checked={checkAllAMC}
                  >
                    List of Countries
                  </Checkbox>
                </div>
              }
            >
              <Checkbox.Group
                style={{ width: "100%", maxHeight: "350px", overflow: "auto" }}
                value={checkedAMC}
                onChange={(checkedValues) => {
                  setCheckedAMC(checkedValues);
                }}
              >
                <List
                  itemLayout="horizontal"
                  dataSource={props.countrylist}
                  renderItem={(item: any) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Checkbox value={item.name} />}
                        title={item.name}
                      />
                    </List.Item>
                  )}
                />
              </Checkbox.Group>
              <Space
                direction="horizontal"
                size="middle"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Button
                  onClick={back_showeditRegion}
                  style={{ border: "none" }}
                >
                  <u>Cancel</u>
                </Button>
                <Button
                  onClick={saveMoreCountry}
                  style={{
                    backgroundColor: "rgba(44, 134, 218, 1)",
                    color: "white",
                    width: "100%",
                  }}
                >
                  Save
                </Button>
              </Space>
            </Card>
          </Card>
        )}
        {createNewRegion && showcreateNewRegionOption && (
          <Card
            style={{ width: "50vw", margin: "auto", border: "none" }}
            title="Add New Region"
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 3fr",
                paddingBottom: "10px",
              }}
            >
              <span>Region Name</span>
              <Input
                size="middle"
                placeholder="Enter region name"
                onChange={(e: any) => {
                  setRegionName(e.target.value);
                }}
                style={{ border: "1px solid rgb(220,220,220)" }}
              />
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 3fr",
                paddingBottom: "10px",
              }}
            >
              <span>List of countries</span>
              <Card
                style={{
                  textAlign: "left",
                  border: "1px solid rgb(220,220,220)",
                }}
                headStyle={{
                  color: "#000000",
                  borderBottom: "1px solid rgb(220,220,220)",
                }}
                title={
                  <Input
                    type="text"
                    defaultValue={""}
                    placeholder={`Search country`}
                    prefix={<SearchOutlined />}
                    onChange={(ev: any) => {
                      setSearchValue(ev.target.value);
                    }}
                    allowClear
                  />
                }
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Checkbox
                    indeterminate={indeterminateANR}
                    onChange={onCheckAllANRChange}
                    checked={checkAllANR}
                  >
                    List of Countries
                  </Checkbox>
                </div>
                <Checkbox.Group
                  style={{
                    width: "100%",
                    maxHeight: "350px",
                    overflow: "auto",
                  }}
                  value={checkedANR}
                  onChange={(checkedValues) => {
                    setCheckedANR(checkedValues);
                  }}
                >
                  <List
                    itemLayout="horizontal"
                    dataSource={filteredArray}
                    renderItem={(item: any) => {
                      const countryExists = selectedCountries.includes(
                        item.name
                      );
                      return (
                        <List.Item key={item.name}>
                          <List.Item.Meta
                            avatar={
                              <Checkbox
                                value={item.name}
                                checked={checkCountry(item.name)}
                                onChange={handleCheckboxChange}
                                // disabled={countryExists}
                              />
                            }
                            title={item.name}
                          />
                        </List.Item>
                      );
                    }}
                  />
                </Checkbox.Group>
                <Space
                  direction="horizontal"
                  size="middle"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={back_showcreateNewRegionOption}
                    style={{ border: "none" }}
                  >
                    <u>Cancel</u>
                  </Button>
                  <Button
                    onClick={addNewRegionWithCountry}
                    style={{
                      backgroundColor: "rgba(44, 134, 218, 1)",
                      color: "white",
                      width: "100%",
                    }}
                  >
                    Save
                  </Button>
                </Space>
              </Card>
            </div>
          </Card>
        )}
        {/* {props.selectRegion?.length !== 0 && !createNewRegion && (
          <Space
            direction="horizontal"
            size="middle"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "103%",
            }}
          >
            <Button style={{ border: "none" }}>
              <u>Cancel</u>
            </Button>
            <Button
              disabled={controlButtRegionType()}
              style={{
                backgroundColor: "rgba(44, 134, 218, 1)",
                color: "white",
                width: "100%",
              }}
            >
              Save
            </Button>
          </Space>
        )} */}
      </Card>
    </>
  );
};

export default MyComponent;
