import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  Col,
  Row,
  Select,
  Form,
  Input,
  Button,
  notification,
  Modal,
  Radio,
} from "antd";
import { getAllNCCompany } from "../controller/company";
import { getCountDocument } from "../controller/company";
import {
  getSearchResult,
  getAllIndustry,
  getAllCountry,
} from "../controller/search";
import { updateCompanyDetail } from "../controller/update";
import { deleteCompany } from "../controller/delete";
import { MergeCompanyFileIndex } from "../controller/fileindex";
import _ from "lodash";
import Events from "../lib/events";
import { addLog } from "../controller/profile";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import IO from "../lib/socketio";
import { delimiter } from "path";

let companyModal: any = {
  key: String,
  name: String,
  truncated_name: String,
  compnumber: Number,
  newcompnumber: String,
  country: Number,
  industry: Number,
  subindustry: [Array],
  total_doc: Number,
  ref: {
    sedol: String,
    bb: String,
    isin: String,
    exchange: String,
    ciq: String,
    ticker: String,
    perm_id: String,
    url: String,
    openfigi: String,
  },
};

const MyComponent = () => {
  const [loading, setLoading] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [selectloading, setSelectLoading] = useState(false);
  const [dropDownCompanyOptions, setdropDownCompanyOptions]: any = useState([]);
  const [dropDownIndustryOptions, setdropDownIndustryOptions]: any = useState(
    []
  );
  const [dropDownCountryOptions, setdropDownCountryOptions]: any = useState([]);
  const [filteredOptionsLeft, setFilteredOptionsLeft] = useState([]);
  const [filteredOptionsRight, setFilteredOptionsRight] = useState([]);
  const [selectedValueLeft, setSelectedValueLeft]: any = useState(null);
  const [selectedValueRight, setSelectedValueRight]: any = useState(null);
  const [formLeft] = Form.useForm();
  const [formRight] = Form.useForm();
  const [test] = Form.useForm();
  // const values = Form.useWatch([], test);
  const [dataLeft, setDataLeft]: any = useState([]);
  const [dataRight, setDataRight]: any = useState([]);
  const [dataMerge, setDataMerge]: any = useState([]);
  const [dataOriMerge, setDataOriMerge]: any = useState([]);
  // const formRight: any = useRef();
  const scrollContainerRef = useRef(null);
  const [compnumberoption, setCompnumberOption]: any = useState([]);
  const [change, setChange] = useState(false);
  const formRef: any = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const eventHandler = () => {
      let selected: any = localStorage.getItem("template");
      if (selected) {
        handleRefClose();
      }
    };

    Events.on("ConsolidationTrigger", eventHandler);
    return () => {
      Events.off("ConsolidationTrigger", eventHandler); // Make sure to remove the event listener on cleanup
    };
  }, []);

  // useEffect(() => {
  //   if (location.state?.data) {
  //     handleChangeLeft(
  //       location.state.data.name + location.state.data.compnumber
  //     );
  //   }
  //   const handleBeforeUnload = () => {
  //     // Clear the state data when the user clicks refresh
  //     if (location.state) {
  //       window.history.replaceState(null, window.location.pathname);
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     // Cleanup by removing the event listener
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [location.state]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const encodedStateData: any = searchParams.get("data");
    if (encodedStateData) {
      const decodedStateData = JSON.parse(decodeURIComponent(encodedStateData));
      if (decodedStateData) {
        handleChangeLeft(
          decodedStateData.name + "@:!" + decodedStateData.compnumber
        );
        // if (location.state.breadcrumb) {
        //   setBreadCrumb(location.state.breadcrumb);
        // }
      }
    }

    // const handleBeforeUnload = () => {
    //   console.log("reload");
    //   navigate("/company_consolidation", {
    //     replace: true,
    //   });
    //   // window.history.replaceState(null, document.title, newURL);
    //   // setDataLeft([]);
    //   // setSelectedValueLeft(null);
    // };

    // window.addEventListener("beforeunload", handleBeforeUnload);

    // return () => {
    //   // Cleanup by removing the event listener
    //   window.removeEventListener("beforeunload", handleBeforeUnload);
    // };
  }, []);

  useEffect(() => {
    if (selectedValueLeft !== null && selectedValueRight !== null) {
      const Copy_dataLeft = _.cloneDeep(dataLeft);
      const Copy_dataRight = _.cloneDeep(dataRight);
      let copy_merge: any = {};
      for (let key in companyModal) {
        if (!copy_merge[key]) {
          copy_merge[key] = {};
        }
        if (key === "total_doc") {
          copy_merge[key] =
            Copy_dataRight["CS"] +
            Copy_dataRight["RCD"] +
            Copy_dataRight["HCS"] +
            Copy_dataLeft["CS"] +
            Copy_dataLeft["RCD"] +
            Copy_dataLeft["HCS"];
        } else if (key === "subindustry") {
          if (
            Copy_dataLeft[key] &&
            Copy_dataLeft[key].length > 0 &&
            Copy_dataRight[key] &&
            Copy_dataRight[key].length > 0
          ) {
            if (Copy_dataLeft[key].length !== Copy_dataRight[key].length) {
              copy_merge[key] = [];
            } else {
              const areEqual = Copy_dataLeft[key].every(
                (value: any, index: any) => value === Copy_dataRight[key][index]
              );

              if (areEqual) {
                copy_merge[key] = JSON.parse(
                  JSON.stringify(Copy_dataRight[key])
                );
              } else {
                copy_merge[key] = [];
              }
            }
          } else if (Copy_dataRight[key] && Copy_dataRight[key].length > 0) {
            copy_merge[key] = Copy_dataRight[key];
            copy_merge[key] = JSON.parse(JSON.stringify(Copy_dataRight[key]));
          } else if (Copy_dataLeft[key] && Copy_dataLeft[key].length > 0) {
            copy_merge[key] = JSON.parse(JSON.stringify(Copy_dataLeft[key]));
          } else {
            copy_merge[key] = [];
          }
        } else if (key === "key") {
          copy_merge[key] = "reportal";
        } else if (key === "compnumber") {
          copy_merge[key] = "";
          setCompnumberOption([
            {
              label: Copy_dataLeft.compnumber,
              value: Copy_dataLeft.compnumber,
            },
            {
              label: Copy_dataRight.compnumber,
              value: Copy_dataRight.compnumber,
            },
          ]);
        } else if (key === "ref") {
          if (Copy_dataLeft[key] && Copy_dataRight[key]) {
            for (let keys in companyModal.ref) {
              if (!copy_merge[key][keys]) {
                copy_merge[key][keys] = "";
              }
              if (Copy_dataLeft[key][keys] && Copy_dataRight[key][keys]) {
                if (Copy_dataLeft[key][keys] === Copy_dataRight[key][keys]) {
                  copy_merge[key][keys] = JSON.parse(
                    JSON.stringify(Copy_dataLeft[key][keys])
                  );
                } else {
                  copy_merge[key][keys] = JSON.parse(JSON.stringify(""));
                }
              } else if (Copy_dataRight[key][keys]) {
                copy_merge[key][keys] = JSON.parse(
                  JSON.stringify(Copy_dataRight[key][keys])
                );
              } else if (Copy_dataLeft[key][keys]) {
                copy_merge[key][keys] = JSON.parse(
                  JSON.stringify(Copy_dataLeft[key][keys])
                );
              } else {
                copy_merge[key][keys] = JSON.parse(JSON.stringify(""));
              }
            }
          } else if (Copy_dataRight[key]) {
            copy_merge[key] = Copy_dataRight[key];
          } else if (Copy_dataLeft[key]) {
            copy_merge[key] = Copy_dataLeft[key];
          } else {
            copy_merge[key] = {};
          }
        } else {
          if (
            Copy_dataLeft[key] &&
            Copy_dataLeft[key] !== null &&
            Copy_dataLeft[key] !== undefined &&
            Copy_dataRight[key] &&
            Copy_dataRight[key] !== null &&
            Copy_dataRight[key] !== undefined
          ) {
            if (Copy_dataLeft[key] === Copy_dataRight[key]) {
              copy_merge[key] = Copy_dataLeft[key];
            } else {
              copy_merge[key] = "";
            }
          } else if (Copy_dataRight[key] !== null && Copy_dataRight[key]) {
            copy_merge[key] = Copy_dataRight[key];
          } else if (Copy_dataLeft[key] !== null && Copy_dataLeft[key]) {
            copy_merge[key] = Copy_dataLeft[key];
          } else {
            copy_merge[key] = "";
          }
        }
      }
      const OriMergedata = _.cloneDeep(copy_merge);
      setDataOriMerge(OriMergedata);
      localStorage.setItem(
        "original_company_mergedata",
        JSON.stringify(OriMergedata)
      );

      setDataMerge(copy_merge);
      localStorage.setItem("template", JSON.stringify(copy_merge));
    } else {
      setDataMerge([]);
      setDataOriMerge([]);
      localStorage.removeItem("original_company_mergedata");
      localStorage.removeItem("template");
    }
  }, [dataLeft, dataRight]);

  useEffect(() => {
    if (test) {
      console.log("Resetting");
      test.resetFields();
      test.setFieldsValue(dataMerge);
      localStorage.setItem("changes", JSON.stringify(change));

      // setChange(true);
    }
  }, [dataMerge]);

  const handleFormChange = () => {
    let tempupdatedCompany = test.getFieldsValue();
    for (let key in companyModal) {
      if (key === "ref") {
        for (let keys in companyModal.ref) {
          if (test.getFieldsValue().ref.keys !== dataOriMerge.ref[keys]) {
            setChange(true);
          }
        }
      } else if (key === "compnumber") {
        const formattedValue = test
          .getFieldsValue()
          .compnumber.toString()
          .padStart(8, "0");
        // let updatedCompany = test.getFieldsValue();
        tempupdatedCompany["newcompnumber"] = formattedValue;
        if (tempupdatedCompany.compnumber === dataRight.compnumber) {
          tempupdatedCompany["_id"] = dataRight._id;
          localStorage.setItem(
            "deletedCompany",
            JSON.stringify({
              _id: dataLeft._id,
              compnumber: dataLeft.compnumber,
              name: dataLeft.name,
            })
          );
        } else {
          tempupdatedCompany["_id"] = dataLeft._id;
          localStorage.setItem(
            "deletedCompany",
            JSON.stringify({
              _id: dataRight._id,
              compnumber: dataRight.compnumber,
              name: dataRight.name,
            })
          );
        }
      } else {
        if (test.getFieldsValue().key !== dataOriMerge[key]) {
          setChange(true);
        }
      }
    }
    if (
      test.getFieldsValue().name !== null &&
      test.getFieldsValue().name &&
      test.getFieldsValue().compnumber !== null &&
      test.getFieldsValue().compnumber &&
      test.getFieldsValue().country !== null &&
      test.getFieldsValue().country &&
      test.getFieldsValue().industry !== null &&
      test.getFieldsValue().industry
    ) {
      localStorage.setItem("changes", JSON.stringify(change));
    }
    localStorage.setItem("template", JSON.stringify(tempupdatedCompany));
  };

  // useEffect(() => {
  //   // if (change === true) {

  //   // }
  //   console.log("weird", test.getFieldsValue());

  // }, [change]);

  useEffect(() => {
    setLoading(true);
    const fetchCompanyData = async () => {
      setSelectLoading(true);
      getAllNCCompany().then((res: any) => {
        if (res.data) {
          const dropDownCompanyOption = res.data.map((x: any) => ({
            label: x.name,
            value: x.compnumber,
          }));
          setdropDownCompanyOptions(dropDownCompanyOption);
          setFilteredOptionsLeft(dropDownCompanyOption);
          setFilteredOptionsRight(dropDownCompanyOption);
          setLoading(false);
          setSelectLoading(false);
        }
      });
    };
    const fetchIndustryData = async () => {
      getAllIndustry().then((res: any) => {
        if (res.data) {
          const dropDownIndustryOption = res.data.map((x: any) => ({
            label: x.description,
            value: x.industrycode,
          }));
          setdropDownIndustryOptions(dropDownIndustryOption);
        }
      });
    };
    const fetchCountryData = async () => {
      getAllCountry().then((res: any) => {
        if (res.data) {
          const dropDownCountryOption = res.data.map((x: any) => ({
            label: x.name,
            value: x.countrycode,
          }));

          setdropDownCountryOptions(dropDownCountryOption);
        }
      });
    };
    fetchCompanyData();
    fetchIndustryData();
    fetchCountryData();
  }, []);

  const handleRefClose = () => {
    let redirect: any = localStorage.getItem("redirect");
    let parsedData = JSON.parse(redirect);
    let CompanyData: any = localStorage.getItem("template");
    let parsedCompanyData = JSON.parse(CompanyData);
    let original_mergedata: any = localStorage.getItem(
      "original_company_mergedata"
    );
    let parsedOriCompanyData = JSON.parse(original_mergedata);
    let changes: any = localStorage.getItem("changes");
    let parsedchanges = JSON.parse(changes);
    if (parsedOriCompanyData !== null) {
      if (parsedchanges === true) {
        Modal.confirm({
          title: <>Leaving</>,
          content: <>Are you sure you want to leave ? </>,
          okText: "Save and Close",
          cancelText: "Remain on page",
          onOk() {
            confirmMerge();
          },
        });
      } else {
        Modal.confirm({
          title: (
            <>
              Leaving
              {/* <strong>{parsedConfigData.package}</strong> */}
            </>
          ),
          content: (
            <>
              Are you sure you want to leave{" "}
              {/* <strong>{parsedConfigData.package}</strong> */}?{" "}
            </>
          ),
          okText: "Yes",
          cancelText: "No",
          onOk() {
            navigate(`/${parsedData.navigate}`, {
              replace: parsedData.replace,
            });
            localStorage.removeItem("redirect");
            localStorage.removeItem("template");
            localStorage.removeItem("changes");
            localStorage.removeItem("original_company_mergedata");
          },
        });
      }
    } else {
      if (parsedchanges === true) {
        Modal.confirm({
          title: <>Leaving</>,
          content: <>Are you sure you want to leave?</>,
          okText: "Save and Close",
          cancelText: "Remain on page",
          onOk() {
            confirmMerge();
          },
        });
      } else {
        Modal.confirm({
          title: <>Leaving</>,
          content: <>Are you sure you want to leave ?</>,
          okText: "Yes",
          cancelText: "No",
          onOk() {
            navigate(`/${parsedData.navigate}`, {
              replace: parsedData.replace,
            });
            localStorage.removeItem("redirect");
            localStorage.removeItem("template");
            localStorage.removeItem("changes");
            localStorage.removeItem("original_company_mergedata");
          },
        });
      }
    }
  };

  const refreshCompanyList = () => {
    setSelectLoading(true);
    getAllNCCompany().then((res: any) => {
      if (res.data) {
        const dropDownCompanyOption = res.data.map((x: any) => ({
          label: x.name,
          value: x.compnumber,
        }));
        setdropDownCompanyOptions(dropDownCompanyOption);
        setFilteredOptionsLeft(dropDownCompanyOption);
        setFilteredOptionsRight(dropDownCompanyOption);
        setLoading(false);
        setSelectLoading(false);
      }
    });
  };

  const CountryMapping = (el: any) => {
    let answer = dropDownCountryOptions.find((o: any) => {
      return o["value"] == el;
    });
    if (!answer) {
      return el;
    }
    if (!answer["label"]) {
      return el;
    }
    return answer["label"];
  };

  const IndustryMapping = (el: any) => {
    let answer = dropDownIndustryOptions.find((o: any) => {
      return o["value"] == el;
    });
    if (!answer) {
      return el;
    }
    if (!answer["label"]) {
      return el;
    }
    return answer["label"];
  };

  const handleSearchLeft = (newValue: any) => {
    const filtered = dropDownCompanyOptions.filter((option: any) =>
      option.label.toLowerCase().includes(newValue.toLowerCase())
    );
    setFilteredOptionsLeft(filtered);
  };

  const handleClearLeft = () => {
    setSelectedValueLeft(null);
    setDataLeft([]);
    setFilteredOptionsLeft(dropDownCompanyOptions);
  };

  const handleChangeLeft = (newValue: any) => {
    localStorage.setItem("sharedData", "newData");
    window.dispatchEvent(new Event("dataChanged"));
    if (selectedValueLeft !== null && change === true) {
      Modal.confirm({
        title: "Change Company",
        content:
          "Changing Company will cause the loss of all edited field to be change",
        okText: "Confirm",
        onOk() {
          if (selectedValueRight !== null) {
            if (selectedValueRight === newValue) {
              setSelectedValueLeft(selectedValueLeft);
              notification.warning({
                message: "Warning",
                description:
                  "The company has been chosen for another panel. Please consider selecting a different company.",
                placement: "bottomRight",
                bottom: 50,
              });
            } else {
              if (newValue) {
                let delimiter = "@:!";
                // Pattern to match the company name
                // const companyPattern = /^(.*?)\d*$/;

                // // Pattern to match the number
                // const numberPattern = /\d+$/;

                // // Extracting the company name
                // const textPartCC = newValue.match(companyPattern) || "";

                // // Extracting the number
                // const numberPartCC = newValue.match(numberPattern) || "";

                // let textPart = textPartCC[1].trim();
                // let numberPart = parseInt(numberPartCC[0]);
                let parts = newValue.split(delimiter);
                setLoading(true);
                let searchObj: any = {};
                // let NV = numberPart;
                searchObj["name"] = parts[0].trim();
                searchObj["compnumber"] = parseInt(parts[1]);
                setSelectedValueLeft(newValue);
                setChange(false);
                localStorage.setItem("changes", JSON.stringify(false));
                getSearchResult(searchObj).then((res: any) => {
                  if (res.data) {
                    getCountDocument({ compnumber: searchObj["compnumber"] })
                      .then((resp: any) => {
                        if (resp.data) {
                          const mergedObj = [{ ...res.data[0], ...resp.data }];
                          mergedObj[0].total_doc =
                            mergedObj[0].CS +
                            mergedObj[0].HCS +
                            mergedObj[0].RCD;
                          setDataLeft(mergedObj[0]);
                          formLeft.resetFields();
                          formLeft.setFieldsValue(mergedObj[0]);
                        } else {
                          setDataLeft(res.data[0]);
                          formLeft.resetFields();
                          formLeft.setFieldsValue(res.data[0]);
                        }
                      })
                      .catch((err: any) => {
                        console.error(err);
                      });
                  }
                });
              }
            }
          } else {
            if (newValue) {
              // Pattern to match the company name
              let delimiter = "@:!";
              // Pattern to match the company name
              // const companyPattern = /^(.*?)\d*$/;

              // // Pattern to match the number
              // const numberPattern = /\d+$/;

              // // Extracting the company name
              // const textPartCC = newValue.match(companyPattern) || "";

              // // Extracting the number
              // const numberPartCC = newValue.match(numberPattern) || "";

              // let textPart = textPartCC[1].trim();
              // let numberPart = parseInt(numberPartCC[0]);
              let parts = newValue.split(delimiter);
              setLoading(true);
              let searchObj: any = {};
              // let NV = numberPart;
              searchObj["name"] = parts[0].trim();
              searchObj["compnumber"] = parseInt(parts[1]);
              setSelectedValueLeft(newValue);
              setChange(false);
              localStorage.setItem("changes", JSON.stringify(false));
              getSearchResult(searchObj).then((res: any) => {
                if (res.data) {
                  getCountDocument({ compnumber: searchObj["compnumber"] })
                    .then((resp: any) => {
                      if (resp.data) {
                        const mergedObj = [{ ...res.data[0], ...resp.data }];

                        mergedObj[0].total_doc =
                          mergedObj[0].CS + mergedObj[0].HCS + mergedObj[0].RCD;
                        setDataLeft(mergedObj[0]);
                        formLeft.resetFields();
                        formLeft.setFieldsValue(mergedObj[0]);
                      } else {
                        setDataLeft(res.data[0]);
                        formLeft.resetFields();
                        formLeft.setFieldsValue(res.data[0]);
                      }
                    })
                    .catch((err: any) => {
                      console.error(err);
                    });
                }
              });
            }
          }
        },
        onCancel() {
          setSelectedValueLeft(selectedValueLeft);
        },
      });
    } else {
      if (selectedValueRight !== null) {
        if (selectedValueRight === newValue) {
          setSelectedValueLeft(selectedValueLeft);
          notification.warning({
            message: "Warning",
            description:
              "The company has been chosen for another panel. Please consider selecting a different company.",
            placement: "bottomRight",
            bottom: 50,
          });
        } else {
          if (newValue) {
            let delimiter = "@:!";
            // Pattern to match the company name
            // const companyPattern = /^(.*?)\d*$/;

            // // Pattern to match the number
            // const numberPattern = /\d+$/;

            // // Extracting the company name
            // const textPartCC = newValue.match(companyPattern) || "";

            // // Extracting the number
            // const numberPartCC = newValue.match(numberPattern) || "";

            // let textPart = textPartCC[1].trim();
            // let numberPart = parseInt(numberPartCC[0]);
            let parts = newValue.split(delimiter);
            setLoading(true);
            let searchObj: any = {};
            // let NV = numberPart;
            searchObj["name"] = parts[0].trim();
            searchObj["compnumber"] = parseInt(parts[1]);
            setSelectedValueLeft(newValue);
            getSearchResult(searchObj).then((res: any) => {
              if (res.data) {
                getCountDocument({ compnumber: searchObj["compnumber"] })
                  .then((resp: any) => {
                    if (resp.data) {
                      const mergedObj = [{ ...res.data[0], ...resp.data }];
                      mergedObj[0].total_doc =
                        mergedObj[0].CS + mergedObj[0].HCS + mergedObj[0].RCD;
                      setDataLeft(mergedObj[0]);
                      formLeft.resetFields();
                      formLeft.setFieldsValue(mergedObj[0]);
                    } else {
                      setDataLeft(res.data[0]);
                      formLeft.resetFields();
                      formLeft.setFieldsValue(res.data[0]);
                    }
                  })
                  .catch((err: any) => {
                    console.error(err);
                  });
              }
            });
          }
        }
      } else {
        if (newValue) {
          let delimiter = "@:!";
          // Pattern to match the company name
          // const companyPattern = /^(.*?)\d*$/;

          // // Pattern to match the number
          // const numberPattern = /\d+$/;

          // // Extracting the company name
          // const textPartCC = newValue.match(companyPattern) || "";

          // // Extracting the number
          // const numberPartCC = newValue.match(numberPattern) || "";

          // let textPart = textPartCC[1].trim();
          // let numberPart = parseInt(numberPartCC[0]);
          let parts = newValue.split(delimiter);
          setLoading(true);
          let searchObj: any = {};
          // let NV = numberPart;
          searchObj["name"] = parts[0].trim();
          searchObj["compnumber"] = parseInt(parts[1]);
          setSelectedValueLeft(newValue);
          getSearchResult(searchObj).then((res: any) => {
            if (res.data) {
              getCountDocument({ compnumber: searchObj["compnumber"] })
                .then((resp: any) => {
                  if (resp.data) {
                    const mergedObj = [{ ...res.data[0], ...resp.data }];

                    mergedObj[0].total_doc =
                      mergedObj[0].CS + mergedObj[0].HCS + mergedObj[0].RCD;
                    setDataLeft(mergedObj[0]);

                    formLeft.resetFields();
                    formLeft.setFieldsValue(mergedObj[0]);
                  } else {
                    setDataLeft(res.data[0]);
                    formLeft.resetFields();
                    formLeft.setFieldsValue(res.data[0]);
                  }
                })
                .catch((err: any) => {
                  console.error(err);
                });
            }
          });
        }
      }
    }
  };

  const handleSearchRight = (newValue: any) => {
    const filtered = dropDownCompanyOptions.filter((option: any) =>
      option.label.toLowerCase().includes(newValue.toLowerCase())
    );
    setFilteredOptionsRight(filtered);
  };

  const handleClearRight = () => {
    setSelectedValueRight(null);
    setDataRight([]);
    setFilteredOptionsRight(dropDownCompanyOptions);
  };

  const handleChangeRight = (newValue: any) => {
    if (selectedValueRight !== null && change === true) {
      Modal.confirm({
        title: "Change Company",
        content:
          "Changing Company will cause the loss of all edited field to be change",
        okText: "Confirm",
        onOk() {
          if (selectedValueLeft !== null) {
            if (selectedValueLeft === newValue) {
              setSelectedValueRight(selectedValueRight);
              notification.warning({
                message: "Warning",
                description:
                  "The company has been chosen for another panel. Please consider selecting a different company.",
                placement: "bottomRight",
                bottom: 50,
              });
            } else {
              if (newValue) {
                let delimiter = "@:!";
                let parts = newValue.split(delimiter);
                setLoading(true);
                let searchObj: any = {};
                searchObj["name"] = parts[0].trim();
                searchObj["compnumber"] = parseInt(parts[1]);
                setSelectedValueRight(newValue);
                setChange(false);
                localStorage.setItem("changes", JSON.stringify(false));
                getSearchResult(searchObj).then((res: any) => {
                  if (res.data) {
                    getCountDocument({ compnumber: searchObj["compnumber"] })
                      .then((resp: any) => {
                        if (resp.data) {
                          const mergedObj = [{ ...res.data[0], ...resp.data }];
                          mergedObj[0].total_doc =
                            mergedObj[0].CS +
                            mergedObj[0].HCS +
                            mergedObj[0].RCD;
                          setDataRight(mergedObj[0]);
                          formRight.resetFields();
                          formRight.setFieldsValue(mergedObj[0]);
                        } else {
                          setDataRight(res.data[0]);
                          formRight.resetFields();
                          formRight.setFieldsValue(res.data[0]);
                        }
                      })
                      .catch((err: any) => {
                        console.error(err);
                      });
                  }
                });
              }
            }
          } else {
            if (newValue) {
              let delimiter = "@:!";
              // Pattern to match the company name
              // const companyPattern = /^(.*?)\d*$/;

              // // Pattern to match the number
              // const numberPattern = /\d+$/;

              // // Extracting the company name
              // const textPartCC = newValue.match(companyPattern) || "";

              // // Extracting the number
              // const numberPartCC = newValue.match(numberPattern) || "";

              // let textPart = textPartCC[1].trim();
              // let numberPart = parseInt(numberPartCC[0]);
              let parts = newValue.split(delimiter);
              setLoading(true);
              let searchObj: any = {};
              // let NV = numberPart;
              searchObj["name"] = parts[0].trim();
              searchObj["compnumber"] = parseInt(parts[1]);
              setSelectedValueRight(newValue);
              setChange(false);
              localStorage.setItem("changes", JSON.stringify(false));
              getSearchResult(searchObj).then((res: any) => {
                if (res.data) {
                  getCountDocument({ compnumber: searchObj["compnumber"] })
                    .then((resp: any) => {
                      if (resp.data) {
                        const mergedObj = [{ ...res.data[0], ...resp.data }];
                        mergedObj[0].total_doc =
                          mergedObj[0].CS + mergedObj[0].HCS + mergedObj[0].RCD;
                        setDataRight(mergedObj[0]);
                        formRight.resetFields();
                        formRight.setFieldsValue(mergedObj[0]);
                      } else {
                        setDataRight(res.data[0]);
                        formRight.resetFields();
                        formRight.setFieldsValue(res.data[0]);
                      }
                    })
                    .catch((err: any) => {
                      console.error(err);
                    });
                }
              });
            }
          }
        },
        onCancel() {
          setSelectedValueRight(selectedValueRight);
        },
      });
    } else {
      if (selectedValueLeft !== null) {
        if (selectedValueLeft === newValue) {
          setSelectedValueRight(selectedValueRight);
          notification.warning({
            message: "Warning",
            description:
              "The company has been chosen for another panel. Please consider selecting a different company.",
            placement: "bottomRight",
            bottom: 50,
          });
        } else {
          if (newValue) {
            let delimiter = "@:!";
            // Pattern to match the company name
            // const companyPattern = /^(.*?)\d*$/;

            // // Pattern to match the number
            // const numberPattern = /\d+$/;

            // // Extracting the company name
            // const textPartCC = newValue.match(companyPattern) || "";

            // // Extracting the number
            // const numberPartCC = newValue.match(numberPattern) || "";

            // let textPart = textPartCC[1].trim();
            // let numberPart = parseInt(numberPartCC[0]);
            let parts = newValue.split(delimiter);
            setLoading(true);
            let searchObj: any = {};
            // let NV = numberPart;
            searchObj["name"] = parts[0].trim();
            searchObj["compnumber"] = parseInt(parts[1]);
            setSelectedValueRight(newValue);
            setChange(false);
            localStorage.setItem("changes", JSON.stringify(false));
            getSearchResult(searchObj).then((res: any) => {
              if (res.data) {
                getCountDocument({ compnumber: searchObj["compnumber"] })
                  .then((resp: any) => {
                    if (resp.data) {
                      const mergedObj = [{ ...res.data[0], ...resp.data }];
                      mergedObj[0].total_doc =
                        mergedObj[0].CS + mergedObj[0].HCS + mergedObj[0].RCD;
                      setDataRight(mergedObj[0]);
                      formRight.resetFields();
                      formRight.setFieldsValue(mergedObj[0]);
                    } else {
                      setDataRight(res.data[0]);
                      formRight.resetFields();
                      formRight.setFieldsValue(res.data[0]);
                    }
                  })
                  .catch((err: any) => {
                    console.error(err);
                  });
              }
            });
          }
        }
      } else {
        if (newValue) {
          let delimiter = "@:!";
          // Pattern to match the company name
          // const companyPattern = /^(.*?)\d*$/;

          // // Pattern to match the number
          // const numberPattern = /\d+$/;

          // // Extracting the company name
          // const textPartCC = newValue.match(companyPattern) || "";

          // // Extracting the number
          // const numberPartCC = newValue.match(numberPattern) || "";

          // let textPart = textPartCC[1].trim();
          // let numberPart = parseInt(numberPartCC[0]);
          let parts = newValue.split(delimiter);
          setLoading(true);
          let searchObj: any = {};
          // let NV = numberPart;
          searchObj["name"] = parts[0].trim();
          searchObj["compnumber"] = parseInt(parts[1]);
          setSelectedValueRight(newValue);
          setChange(false);
          localStorage.setItem("changes", JSON.stringify(false));
          getSearchResult(searchObj).then((res: any) => {
            if (res.data) {
              getCountDocument({ compnumber: searchObj["compnumber"] })
                .then((resp: any) => {
                  if (resp.data) {
                    const mergedObj = [{ ...res.data[0], ...resp.data }];
                    mergedObj[0].total_doc =
                      mergedObj[0].CS + mergedObj[0].HCS + mergedObj[0].RCD;
                    setDataRight(mergedObj[0]);
                    formRight.resetFields();
                    formRight.setFieldsValue(mergedObj[0]);
                  } else {
                    setDataRight(res.data[0]);
                    formRight.resetFields();
                    formRight.setFieldsValue(res.data[0]);
                  }
                })
                .catch((err: any) => {
                  console.error(err);
                });
            }
          });
        }
      }
    }
  };

  const handleReset = () => {
    if (change === true) {
      Modal.confirm({
        title: "Cancel Changes?",
        content: "Are you sure you want to cancel?",
        okText: "Yes",
        cancelText: "No",
        onOk() {
          const MergeData = _.cloneDeep(dataOriMerge);
          setDataMerge(MergeData);
          setChange(false);
          localStorage.setItem("changes", JSON.stringify(false));
        },
      });
    }
  };

  const confirmMerge = async () => {
    // console.log("test", test.getFieldsValue());
    for (let key in companyModal) {
      if (key === "ref") {
        for (let keys in companyModal.ref) {
          if (test.getFieldsValue().ref[keys] === "") {
            test.validateFields([["ref", keys]]);
          } else {
            test.setFields([{ name: ["ref", keys], errors: [] }]);
          }
        }
      } else {
        if (dataMerge[key] === "") {
          test.validateFields([key]);
        } else {
          test.setFields([{ name: key, errors: [] }]);
        }
      }
    }
    try {
      // Validate all form fields
      const values = await test.validateFields();

      // If validation is successful, proceed to the next function
      // You can call your next function here, passing the form values if needed
      // For example:
      Modal.confirm({
        title: "Confirm Save",
        content: (
          <div>
            Are you sure you want to merge?
            <br />
            <i>This merge is irreversible and cannot be undone.</i>
          </div>
        ),
        okText: "Yes",
        cancelText: "No",
        onOk() {
          handleMerge();
        },
      });
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };

  const handleMerge = () => {
    let deleted: any = localStorage.getItem("deletedCompany");
    let parsedDeletedData = JSON.parse(deleted);
    let template: any = localStorage.getItem("template");
    let parsedChangeData = JSON.parse(template);
    let redirect: any = localStorage.getItem("redirect");
    let parsedData = JSON.parse(redirect);
    // let dataControl: any = localStorage.getItem("dataControl");
    // let parseddataControl = JSON.parse(dataControl);
    updateCompanyDetail(parsedChangeData).then((res: any) => {
      if (res.data) {
        // console.log("res1", res.data);
        // if (parsedChangeData._id === dataLeft._id) {
        //   deleteCompany(parsedDeletedData).then((resp: any) => {
        //     // console.log("resp1", resp.data);
        //     if (resp.data) {
        //       MergeCompanyFileIndex({
        //         oldcompnumber: dataRight.compnumber,
        //         newcompnumber: parsedChangeData.compnumber,
        //       }).then((response: any) => {
        //         // console.log("response1", response.data);
        //         if (response.data) {
        //           if (parsedData) {
        //             notification.success({
        //               message: `Company Merge and Update`,
        //               description: (
        //                 <>
        //                   Company <strong>{parsedChangeData.name}</strong> has
        //                   been updated successfully.
        //                 </>
        //               ),
        //               placement: "bottomRight",
        //               bottom: 50,
        //             });
        //             navigate(`/${parsedData.navigate}`, {
        //               replace: parsedData.replace,
        //             });
        //             localStorage.removeItem("redirect");
        //             localStorage.removeItem("template");
        //             localStorage.removeItem("changes");
        //             localStorage.removeItem("original_company_mergedata");
        //           } else {
        //             notification.success({
        //               message: `Company Merge and Update`,
        //               description: (
        //                 <>
        //                   Company <strong>{parsedChangeData.name}</strong> has
        //                   been updated successfully.
        //                 </>
        //               ),
        //               placement: "bottomRight",
        //               bottom: 50,
        //             });
        //             setDataLeft([]);
        //             setDataRight([]);
        //             setDataMerge([]);
        //             setDataOriMerge([]);
        //             setSelectedValueLeft(null);
        //             setSelectedValueRight(null);
        //             setChange(false);
        //             localStorage.setItem("changes", JSON.stringify(false));
        //             refreshCompanyList();
        //           }
        //         } else {
        //           notification.error({
        //             message: `Company Merge and Update`,
        //             description: (
        //               <>
        //                 Company <strong>{parsedChangeData.name}</strong> has
        //                 fail to update.
        //               </>
        //             ),
        //             placement: "bottomRight",
        //             bottom: 50,
        //           });
        //         }
        //       });
        //     }
        //   });
        // } else {
        let user: any = localStorage.getItem("user");
        let log: any = {
          page: window.location.pathname,
          action: "Update",
          datetime: new Date(),
          details: `Update company ${parsedChangeData.name}(${parsedChangeData.compnumber})`,
          email: user,
        };
        addLog(log);

        deleteCompany(parsedDeletedData).then((resp: any) => {
          // console.log("resp2", resp.data);
          if (resp.data) {
            let user: any = localStorage.getItem("user");
            let log: any = {
              page: window.location.pathname,
              action: "Delete",
              datetime: new Date(),
              details: `Delete company ${parsedDeletedData.name}(${parsedDeletedData.compnumber})`,
              email: user,
            };
            addLog(log);
            MergeCompanyFileIndex({
              oldcompnumber: parsedDeletedData.compnumber,
              newcompnumber: parsedChangeData.compnumber,
            }).then((response: any) => {
              // console.log("response2", response.data);
              if (response.data) {
                let log: any = {
                  page: window.location.pathname,
                  action: "Update",
                  datetime: new Date(),
                  details: `Update FileIndex from ${parsedDeletedData.compnumber} to ${parsedChangeData.compnumber}`,
                  email: user,
                };
                addLog(log);
                if (parsedData) {
                  notification.success({
                    message: `Company Merge and Update`,
                    description: (
                      <>
                        Company <strong>{parsedChangeData.name}</strong> has
                        been updated successfully.
                      </>
                    ),
                    placement: "bottomRight",
                    bottom: 50,
                  });
                  navigate(`/${parsedData.navigate}`, {
                    replace: parsedData.replace,
                  });
                  localStorage.removeItem("redirect");
                  localStorage.removeItem("template");
                  localStorage.removeItem("changes");
                  localStorage.removeItem("original_company_mergedata");
                  localStorage.removeItem("deletedCompany");
                } else {
                  notification.success({
                    message: `Company Merge and Update`,
                    description: (
                      <>
                        Company <strong>{parsedChangeData.name}</strong> has
                        been updated successfully.
                      </>
                    ),
                    placement: "bottomRight",
                    bottom: 50,
                  });
                  setDataLeft([]);
                  setDataRight([]);
                  setDataMerge([]);
                  setDataOriMerge([]);
                  setSelectedValueLeft(null);
                  setSelectedValueRight(null);
                  setChange(false);
                  localStorage.setItem("changes", JSON.stringify(false));
                  localStorage.removeItem("deletedCompany");
                  refreshCompanyList();
                }

                IO.emit("company_merge", (msg: any) => {
                  msg = { name: parsedChangeData.name };
                  // console.log("start company_merge", parseddataControl);
                  console.log("ready to refresh company detail");
                });
              } else {
                notification.error({
                  message: `Company Merge and Update`,
                  description: (
                    <>
                      Company <strong>{parsedChangeData.name}</strong> has fail
                      to update.
                    </>
                  ),
                  placement: "bottomRight",
                  bottom: 50,
                });
              }
            });
          }
        });
        // }
      }
    });
  };

  // const formDataLeft = [
  //   {
  //     key: "name",
  //     name: "name",
  //     component: (
  //       <div className="input-form">
  //         <label className="input-label-form">Company Name</label>
  //         <Input value={dataLeft.name} disabled />
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "normalized_name",
  //     // name: "normalized_name",
  //     // label: (
  //     //   <>
  //     //     <span>Truncated Name</span>
  //     //   </>
  //     // ),
  //     // labelCol: { style: { fontWeight: "600" } },
  //     component: (
  //       <div className="input-form">
  //         <label className="input-label-form">Truncated Name</label>
  //         <Input value={dataLeft.normalized_name} disabled />
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "compnumber",
  //     // name: "compnumber",
  //     // label: (
  //     //   <>
  //     //     <span>Company Number</span>
  //     //   </>
  //     // ),
  //     // labelCol: { style: { fontWeight: "600" } },
  //     component: (
  //       <div className="input-form">
  //         <label className="input-label-form">Company Number</label>
  //         <Input value={dataLeft.compnumber} disabled />
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "country",
  //     // name: "country",
  //     // label: (
  //     //   <>
  //     //     <span>Country</span>
  //     //   </>
  //     // ),
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="Country" disabled />,
  //     component: (
  //       <div className="input-form">
  //         <label className="input-label-form">Country</label>
  //         <Input value={CountryMapping(dataLeft.country)} disabled />
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "industry",
  //     // name: "industry",
  //     // label: (
  //     //   <>
  //     //     <span>Primary Industry</span>
  //     //   </>
  //     // ),
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="Primary Industry" disabled />,
  //     component: (
  //       <div className="input-form">
  //         <label className="input-label-form">Primary Industry</label>
  //         <Input value={IndustryMapping(dataLeft.industry)} disabled />
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "subindustry",
  //     // name: "subindustry",
  //     // label: (
  //     //   <>
  //     //     <span>Associated Industry</span>
  //     //   </>
  //     // ),
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="Associated Industry" disabled />,
  //     component: (
  //       <div className="input-form">
  //         <label className="input-label-form">Associated Industry</label>
  //         {/* <Input value={dataLeft[0]?.subindustry} disabled /> */}
  //         <Select
  //           listHeight={160}
  //           getPopupContainer={(trigger) => trigger.parentElement}
  //           mode="multiple"
  //           showSearch
  //           style={{
  //             width: "100%",
  //             textAlign: "left",
  //             // marginTop: "10px",
  //             marginBottom: "auto",
  //             // paddingBottom: "10px",
  //           }}
  //           placeholder="Search to Select"
  //           // optionFilterProp="children"
  //           // onChange={handleXSubIndustryChange}
  //           value={dataLeft.subindustry}
  //           filterOption={(input: any, option: any) =>
  //             (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  //           }
  //           options={dropDownIndustryOptions}
  //           disabled
  //         ></Select>
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "package",
  //     // name: "package",
  //     // label: (
  //     //   <>
  //     //     <span>Total Documents Published</span>
  //     //   </>
  //     // ),
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="Total Documents Published" disabled />,
  //     component: (
  //       <div className="input-form">
  //         <label className="input-label-form">Total Docs Published</label>
  //         <Input value={dataLeft.CS + dataLeft.HCS + dataLeft.RCD} disabled />
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "sedol",
  //     // name: "sedol",
  //     // label: (
  //     //   <>
  //     //     <span>SEDOL</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.sedol,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">SEDOL</label>
  //         <div className="input-field-container">
  //           <Input value={dataLeft.ref?.sedol} disabled />
  //         </div>
  //       </div>
  //     ),
  //     // component: <Input value={dataLeft[0]?.ref?.sedol} disabled />,
  //   },
  //   {
  //     // key: "bb",
  //     // name: "bb",
  //     // label: (
  //     //   <>
  //     //     <span>BB</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.bb,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input value={dataLeft[0]?.ref?.bb} disabled />,
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">BB</label>
  //         <div className="input-field-container">
  //           <Input value={dataLeft?.ref?.bb} disabled />
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "isin",
  //     // name: "isin",
  //     // label: (
  //     //   <>
  //     //     <span>ISIN</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.isin,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="ISIN" disabled />,
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">ISIN</label>
  //         <div className="input-field-container">
  //           <Input value={dataLeft?.ref?.isin} disabled />
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "exchange",
  //     // name: "exchange",
  //     // label: (
  //     //   <>
  //     //     <span>Exchange</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.exchange,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="Exchange" disabled />,
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">Exchange</label>
  //         <div className="input-field-container">
  //           <Input value={dataLeft?.ref?.exchange} disabled />
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "ciq",
  //     // name: "ciq",
  //     // label: (
  //     //   <>
  //     //     <span>CIQ</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.ciq,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="CIQ" disabled />,
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">CIQ</label>
  //         <div className="input-field-container">
  //           <Input value={dataLeft?.ref?.ciq} disabled />
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "ticker",
  //     // name: "ticker",
  //     // label: (
  //     //   <>
  //     //     <span>Ticker</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.ticker,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="Ticker" disabled />,
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">Ticker</label>
  //         <div className="input-field-container">
  //           <Input value={dataLeft?.ref?.ticker} disabled />
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "perm_id",
  //     // name: "perm_id",
  //     // label: (
  //     //   <>
  //     //     <span>PermID</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.perm_id,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="PermID" disabled />,
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">PermID</label>
  //         <div className="input-field-container">
  //           <Input value={dataLeft?.ref?.perm_id} disabled />
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "url",
  //     // name: "url",
  //     // label: (
  //     //   <>
  //     //     <span>URL</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.url,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="URL" disabled />,
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">URL</label>
  //         <div className="input-field-container">
  //           <Input value={dataLeft?.ref?.url} disabled />
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "openfigi",
  //     // name: "openfigi",
  //     // label: (
  //     //   <>
  //     //     <span>OpenFIGI</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.openfigi,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="OpenFIGI" disabled />,
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">OpenFIGI</label>
  //         <div className="input-field-container">
  //           <Input value={dataLeft?.ref?.openfigi} disabled />
  //         </div>
  //       </div>
  //     ),
  //   },
  // ];

  // const formDataRight = [
  //   {
  //     // key: "name",
  //     // name: "name",
  //     component: (
  //       <div className="input-form">
  //         <label className="input-label-form">Company Name</label>
  //         <Input value={dataRight?.name} disabled />
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "normalized_name",
  //     // name: "normalized_name",
  //     // label: (
  //     //   <>
  //     //     <span>Truncated Name</span>
  //     //   </>
  //     // ),
  //     // labelCol: { style: { fontWeight: "600" } },
  //     component: (
  //       <div className="input-form">
  //         <label className="input-label-form">Truncated Name</label>
  //         <Input value={dataRight?.normalized_name} disabled />
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "compnumber",
  //     // name: "compnumber",
  //     // label: (
  //     //   <>
  //     //     <span>Company Number</span>
  //     //   </>
  //     // ),
  //     // labelCol: { style: { fontWeight: "600" } },
  //     component: (
  //       <div className="input-form">
  //         <label className="input-label-form">Company Number</label>
  //         <Input value={dataRight?.compnumber} disabled />
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "country",
  //     // name: "country",
  //     // label: (
  //     //   <>
  //     //     <span>Country</span>
  //     //   </>
  //     // ),
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="Country" disabled />,
  //     component: (
  //       <div className="input-form">
  //         <label className="input-label-form">Country</label>
  //         <Input value={CountryMapping(dataRight?.country)} disabled />
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "industry",
  //     // name: "industry",
  //     // label: (
  //     //   <>
  //     //     <span>Primary Industry</span>
  //     //   </>
  //     // ),
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="Primary Industry" disabled />,
  //     component: (
  //       <div className="input-form">
  //         <label className="input-label-form">Primary Industry</label>
  //         <Input value={IndustryMapping(dataRight?.industry)} disabled />
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "subindustry",
  //     // name: "subindustry",
  //     // label: (
  //     //   <>
  //     //     <span>Associated Industry</span>
  //     //   </>
  //     // ),
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="Associated Industry" disabled />,
  //     component: (
  //       <div className="input-form">
  //         <label className="input-label-form">Associated Industry</label>
  //         {/* <Input value={dataRight[0]?.subindustry} disabled /> */}
  //         <Select
  //           listHeight={160}
  //           getPopupContainer={(trigger) => trigger.parentElement}
  //           mode="multiple"
  //           showSearch
  //           style={{
  //             width: "100%",
  //             textAlign: "left",
  //             // marginTop: "10px",
  //             marginBottom: "auto",
  //             // paddingBottom: "10px",
  //           }}
  //           placeholder="Search to Select"
  //           // optionFilterProp="children"
  //           // onChange={handleXSubIndustryChange}
  //           value={dataRight?.subindustry}
  //           filterOption={(input: any, option: any) =>
  //             (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  //           }
  //           options={dropDownIndustryOptions}
  //           disabled
  //         />
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "package",
  //     // name: "package",
  //     // label: (
  //     //   <>
  //     //     <span>Total Documents Published</span>
  //     //   </>
  //     // ),
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="Total Documents Published" disabled />,
  //     component: (
  //       <div className="input-form">
  //         <label className="input-label-form">Total Docs Published</label>
  //         <Input
  //           value={dataRight.CS + dataRight.HCS + dataRight.RCD}
  //           disabled
  //         />
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "sedol",
  //     // name: "sedol",
  //     // label: (
  //     //   <>
  //     //     <span>SEDOL</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.sedol,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">SEDOL</label>
  //         <div className="input-field-container">
  //           <Input value={dataRight?.ref?.sedol} disabled />
  //         </div>
  //       </div>
  //     ),
  //     // component: <Input value={dataLeft[0]?.ref?.sedol} disabled />,
  //   },
  //   {
  //     // key: "bb",
  //     // name: "bb",
  //     // label: (
  //     //   <>
  //     //     <span>BB</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.bb,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input value={dataLeft[0]?.ref?.bb} disabled />,
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">BB</label>
  //         <div className="input-field-container">
  //           <Input value={dataRight?.ref?.bb} disabled />
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "isin",
  //     // name: "isin",
  //     // label: (
  //     //   <>
  //     //     <span>ISIN</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.isin,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="ISIN" disabled />,
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">ISIN</label>
  //         <div className="input-field-container">
  //           <Input value={dataRight?.ref?.isin} disabled />
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "exchange",
  //     // name: "exchange",
  //     // label: (
  //     //   <>
  //     //     <span>Exchange</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.exchange,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="Exchange" disabled />,
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">Exchange</label>
  //         <div className="input-field-container">
  //           <Input value={dataRight?.ref?.exchange} disabled />
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "ciq",
  //     // name: "ciq",
  //     // label: (
  //     //   <>
  //     //     <span>CIQ</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.ciq,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="CIQ" disabled />,
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">CIQ</label>
  //         <div className="input-field-container">
  //           <Input value={dataRight?.ref?.ciq} disabled />
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "ticker",
  //     // name: "ticker",
  //     // label: (
  //     //   <>
  //     //     <span>Ticker</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.ticker,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="Ticker" disabled />,
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">Ticker</label>
  //         <div className="input-field-container">
  //           <Input value={dataRight?.ref?.ticker} disabled />
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "perm_id",
  //     // name: "perm_id",
  //     // label: (
  //     //   <>
  //     //     <span>PermID</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.perm_id,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="PermID" disabled />,
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">PermID</label>
  //         <div className="input-field-container">
  //           <Input value={dataRight?.ref?.perm_id} disabled />
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "url",
  //     // name: "url",
  //     // label: (
  //     //   <>
  //     //     <span>URL</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.url,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="URL" disabled />,
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">URL</label>
  //         <div className="input-field-container">
  //           <Input value={dataRight?.ref?.url} disabled />
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     // key: "openfigi",
  //     // name: "openfigi",
  //     // label: (
  //     //   <>
  //     //     <span>OpenFIGI</span>
  //     //   </>
  //     // ),
  //     // initialValue: dataLeft[0]?.ref?.openfigi,
  //     // labelCol: { style: { fontWeight: "600" } },
  //     // component: <Input placeholder="OpenFIGI" disabled />,
  //     component: (
  //       <div className="ref-input-form">
  //         <label className="ref-input-label-form">OpenFIGI</label>
  //         <div className="input-field-container">
  //           <Input value={dataRight?.ref?.openfigi} disabled />
  //         </div>
  //       </div>
  //     ),
  //   },
  // ];

  const formDataLeft = [
    {
      key: "name",
      name: "name",
      label: "Company Name",
      component: <Input disabled />,
    },
    {
      key: "truncated_name",
      name: "truncated_name",

      label: "Truncated Name",
      component: <Input disabled />,
    },
    {
      key: "compnumber",
      name: "compnumber",
      label: "Company Number",
      component: <Input disabled />,
    },
    {
      key: "country",
      name: "country",
      label: "Country",
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          showSearch
          style={{
            width: "100%",
            textAlign: "left",
          }}
          placeholder="Search to Select"
          optionFilterProp="children"
          // value={CountryMapping(dataMerge.country)}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={dropDownCountryOptions}
          disabled
        />
      ),
    },
    {
      key: "industry",
      name: "industry",
      label: "Primary Industry",
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          showSearch
          style={{
            width: "100%",
            textAlign: "left",
          }}
          placeholder="Search to Select"
          optionFilterProp="children"
          // value={IndustryMapping(dataMerge.industry)}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={dropDownIndustryOptions}
          disabled
        />
      ),
    },
    {
      key: "subindustry",
      name: "subindustry",
      label: "Associated Industry",
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          mode="multiple"
          showSearch
          style={{
            width: "100%",
            textAlign: "left",
            marginBottom: "auto",
          }}
          placeholder="Search to Select"
          // value={dataMerge.subindustry}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={dropDownIndustryOptions}
          disabled
        />
      ),
    },
    {
      key: "total_document",
      name: "total_doc",
      label: "Total Documents Published",
      component: <Input disabled />,
    },
    {
      key: "sedol",
      name: ["ref", "sedol"],
      label: "Sedol",
      component: <Input disabled />,
    },
    {
      key: "bb",
      name: ["ref", "bb"],
      label: "BB",
      component: <Input disabled />,
    },
    {
      key: "isin",
      name: ["ref", "isin"],
      label: "ISIN",
      component: <Input disabled />,
    },
    {
      key: "exchange",
      name: ["ref", "exchange"],
      label: "Exchange",
      component: <Input disabled />,
    },
    {
      key: "ciq",
      name: ["ref", "ciq"],
      label: "CIQ",
      component: <Input disabled />,
    },
    {
      key: "ticker",
      name: ["ref", "ticker"],
      label: "Ticker",
      component: <Input disabled />,
    },
    {
      key: "perm_id",
      name: ["ref", "perm_id"],
      label: "Perm ID",
      component: <Input disabled />,
    },
    {
      key: "url",
      name: ["ref", "url"],
      label: "URL",
      component: <Input disabled />,
    },
    {
      key: "openfigi",
      name: ["ref", "openfigi"],
      label: "Openfigi",
      component: <Input disabled />,
    },
  ];

  const formDataRight = [
    {
      key: "name",
      name: "name",
      label: "Company Name",
      component: <Input disabled />,
    },
    {
      key: "truncated_name",
      name: "truncated_name",

      label: "Truncated Name",
      component: <Input disabled />,
    },
    {
      key: "compnumber",
      name: "compnumber",
      label: "Company Number",
      component: <Input disabled />,
    },
    {
      key: "country",
      name: "country",
      label: "Country",
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          showSearch
          style={{
            width: "100%",
            textAlign: "left",
          }}
          placeholder="Search to Select"
          optionFilterProp="children"
          // value={CountryMapping(dataMerge.country)}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={dropDownCountryOptions}
          disabled
        />
      ),
    },
    {
      key: "industry",
      name: "industry",
      label: "Primary Industry",
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          showSearch
          style={{
            width: "100%",
            textAlign: "left",
          }}
          placeholder="Search to Select"
          optionFilterProp="children"
          // value={IndustryMapping(dataMerge.industry)}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={dropDownIndustryOptions}
          disabled
        />
      ),
    },
    {
      key: "subindustry",
      name: "subindustry",
      label: "Associated Industry",
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          mode="multiple"
          showSearch
          style={{
            width: "100%",
            textAlign: "left",
            marginBottom: "auto",
          }}
          placeholder="Search to Select"
          // value={dataMerge.subindustry}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={dropDownIndustryOptions}
          disabled
        />
      ),
    },
    {
      key: "total_document",
      name: "total_doc",
      label: "Total Documents Published",
      component: <Input disabled />,
    },
    {
      key: "sedol",
      name: ["ref", "sedol"],
      label: "Sedol",
      component: <Input disabled />,
    },
    {
      key: "bb",
      name: ["ref", "bb"],
      label: "BB",
      component: <Input disabled />,
    },
    {
      key: "isin",
      name: ["ref", "isin"],
      label: "ISIN",
      component: <Input disabled />,
    },
    {
      key: "exchange",
      name: ["ref", "exchange"],
      label: "Exchange",
      component: <Input disabled />,
    },
    {
      key: "ciq",
      name: ["ref", "ciq"],
      label: "CIQ",
      component: <Input disabled />,
    },
    {
      key: "ticker",
      name: ["ref", "ticker"],
      label: "Ticker",
      component: <Input disabled />,
    },
    {
      key: "perm_id",
      name: ["ref", "perm_id"],
      label: "Perm ID",
      component: <Input disabled />,
    },
    {
      key: "url",
      name: ["ref", "url"],
      label: "URL",
      component: <Input disabled />,
    },
    {
      key: "openfigi",
      name: ["ref", "openfigi"],
      label: "Openfigi",
      component: <Input disabled />,
    },
  ];

  const formDataMerge = [
    {
      key: "name",
      name: "name",
      rules: [
        { required: true, message: "" },
        {
          validator: async (rule: any, value: any) => {
            if (value !== null && value) {
              let delimiter = "@:!";
              // const companyPattern = /^(.*?)\d*$/;
              const textPartRightCC =
                selectedValueRight.split(delimiter)[0].trim() || "";
              const textPartLeftCC =
                selectedValueLeft.split(delimiter)[0].trim() || "";
              let textPartRight = textPartRightCC.trim() || "";
              let textPartLeft = textPartLeftCC.trim() || "";
              if (value !== textPartRight && value !== textPartLeft) {
                for (const x of dropDownCompanyOptions) {
                  if (value.toLowerCase() === x.label.toLowerCase()) {
                    return Promise.reject(
                      "Please rename the company name as the company name you entered is already in use."
                    );
                  }
                }
                return Promise.resolve();
              } else {
                return Promise.resolve();
              }
            } else {
              return Promise.reject();
            }
          },
        },
      ],
      label: "Company Name",
      component: <Input />,
    },
    {
      key: "truncated_name",
      name: "truncated_name",
      rules: [
        {
          validator: async (rule: any, value: any) => {
            // console.log("value", value);
            if (
              (formLeft.getFieldsValue().truncated_name &&
                formLeft.getFieldsValue().truncated_name !== null) ||
              (formRight.getFieldsValue().truncated_name &&
                formRight.getFieldsValue().truncated_name !== null)
            ) {
              if (value && value !== null && value !== "") {
                return Promise.resolve();
              } else {
                return Promise.reject("");
              }
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      label: "Truncated Name",
      component: <Input />,
    },
    {
      key: "compnumber",
      name: "compnumber",
      label: "Company Number",
      rules: [
        { required: true, message: "Please select one of the company number." },
      ],
      component: (
        <Radio.Group
          buttonStyle="solid"
          style={{ margin: "auto" }}
          // onChange={(e: any) => {
          //   const formattedValue = e.target.value.toString().padStart(8, "0");
          //   setDataMerge({
          //     ...dataMerge,
          //     compnumber: e.target.value,
          //     newcompnumber: formattedValue,
          //   });
          //   setChange(true);
          // }}
        >
          <Radio value={compnumberoption?.[0]?.value}>
            {compnumberoption?.[0]?.label}
          </Radio>
          <Radio value={compnumberoption?.[1]?.value}>
            {compnumberoption?.[1]?.label}
          </Radio>
        </Radio.Group>
      ),
    },
    {
      key: "country",
      name: "country",
      label: "Country",
      rules: [{ required: true, message: "" }],
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          showSearch
          style={{
            width: "100%",
            textAlign: "left",
          }}
          placeholder="Search to Select"
          optionFilterProp="children"
          value={CountryMapping(dataMerge.country)}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={dropDownCountryOptions}
        />
      ),
    },
    {
      key: "industry",
      name: "industry",
      label: "Primary Industry",
      rules: [
        { required: true, message: "" },
        {
          validator: async (rule: any, value: any) => {
            if (test.getFieldsValue().subindustry) {
              if (!test.getFieldsValue().subindustry.includes(value)) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  "Primary industry cannot be the same as Associated industry."
                );
              }
            }
          },
        },
      ],
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          showSearch
          style={{
            width: "100%",
            textAlign: "left",
          }}
          placeholder="Search to Select"
          optionFilterProp="children"
          value={IndustryMapping(dataMerge.industry)}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={dropDownIndustryOptions}
        />
      ),
    },
    {
      key: "subindustry",
      name: "subindustry",
      label: "Associated Industry",
      rules: [
        {
          validator: async (rule: any, value: any) => {
            if (
              formLeft.getFieldsValue().subindustry.length > 0 ||
              formRight.getFieldsValue().subindustry.length > 0
            ) {
              if (value.length > 0) {
                return Promise.resolve();
                // if (!value.include(test.getFieldsValue().industry)) {
                //   return Promise.resolve();
                // } else {
                //   return Promise.reject(
                //     "Associated industry cannot be the same as Primary industry."
                //   );
                // }
              } else {
                return Promise.reject();
              }
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      component: (
        <Select
          listHeight={160}
          getPopupContainer={(trigger) => trigger.parentElement}
          mode="multiple"
          showSearch
          style={{
            width: "100%",
            textAlign: "left",
            marginBottom: "auto",
          }}
          placeholder="Search to Select"
          // onChange={(e: any) => {
          //   setDataMerge({
          //     ...dataMerge,
          //     subindustry: e,
          //   });
          //   setChange(true);
          // }}
          value={dataMerge.subindustry}
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={dropDownIndustryOptions}
        />
        // </div>
      ),
    },
    {
      key: "total_document",
      name: "total_doc",
      label: "Total Documents Published",
      component: <Input disabled />,
    },
    {
      key: "sedol",
      name: ["ref", "sedol"],
      label: "Sedol",
      rules: [
        {
          validator: async (rule: any, value: any) => {
            if (
              (formLeft.getFieldsValue().ref.sedol &&
                formLeft.getFieldsValue().ref.sedol !== null) ||
              (formRight.getFieldsValue().ref.sedol &&
                formRight.getFieldsValue().ref.sedol !== null)
            ) {
              if (value && value !== null && value !== "") {
                return Promise.resolve();
              } else {
                return Promise.reject("");
              }
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      component: <Input />,
    },
    {
      key: "bb",
      name: ["ref", "bb"],
      label: "BB",
      rules: [
        {
          validator: async (rule: any, value: any) => {
            // console.log("value", value);
            if (
              (formLeft.getFieldsValue().ref.bb &&
                formLeft.getFieldsValue().ref.bb !== null) ||
              (formRight.getFieldsValue().ref.bb &&
                formRight.getFieldsValue().ref.bb !== null)
            ) {
              if (value && value !== null && value !== "") {
                return Promise.resolve();
              } else {
                return Promise.reject("");
              }
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      component: <Input />,
    },
    {
      key: "isin",
      name: ["ref", "isin"],
      label: "ISIN",
      rules: [
        {
          validator: async (rule: any, value: any) => {
            // console.log("value", value);
            if (
              (formLeft.getFieldsValue().ref.isin &&
                formLeft.getFieldsValue().ref.isin !== null) ||
              (formRight.getFieldsValue().ref.isin &&
                formRight.getFieldsValue().ref.isin !== null)
            ) {
              if (value && value !== null && value !== "") {
                return Promise.resolve();
              } else {
                return Promise.reject("");
              }
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      component: <Input />,
    },
    {
      key: "exchange",
      name: ["ref", "exchange"],
      label: "Exchange",
      rules: [
        {
          validator: async (rule: any, value: any) => {
            // console.log("value", value);
            if (
              (formLeft.getFieldsValue().ref.exchange &&
                formLeft.getFieldsValue().ref.exchange !== null) ||
              (formRight.getFieldsValue().ref.exchange &&
                formRight.getFieldsValue().ref.exchange !== null)
            ) {
              if (value && value !== null && value !== "") {
                return Promise.resolve();
              } else {
                return Promise.reject("");
              }
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      component: <Input />,
    },
    {
      key: "ciq",
      name: ["ref", "ciq"],
      label: "CIQ",
      rules: [
        {
          validator: async (rule: any, value: any) => {
            // console.log("value", value);
            if (
              (formLeft.getFieldsValue().ref.ciq &&
                formLeft.getFieldsValue().ref.ciq !== null) ||
              (formRight.getFieldsValue().ref.ciq &&
                formRight.getFieldsValue().ref.ciq !== null)
            ) {
              if (value && value !== null && value !== "") {
                return Promise.resolve();
              } else {
                return Promise.reject("");
              }
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      component: <Input />,
    },
    {
      key: "ticker",
      name: ["ref", "ticker"],
      label: "Ticker",
      rules: [
        {
          validator: async (rule: any, value: any) => {
            // console.log("value", value);
            if (
              (formLeft.getFieldsValue().ref.ticker &&
                formLeft.getFieldsValue().ref.ticker !== null) ||
              (formRight.getFieldsValue().ref.ticker &&
                formRight.getFieldsValue().ref.ticker !== null)
            ) {
              if (value && value !== null && value !== "") {
                return Promise.resolve();
              } else {
                return Promise.reject("");
              }
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      component: <Input />,
    },
    {
      key: "perm_id",
      name: ["ref", "perm_id"],
      label: "Perm ID",
      rules: [
        {
          validator: async (rule: any, value: any) => {
            // console.log("value", value);
            if (
              (formLeft.getFieldsValue().ref.perm_id &&
                formLeft.getFieldsValue().ref.perm_id !== null) ||
              (formRight.getFieldsValue().ref.perm_id &&
                formRight.getFieldsValue().ref.perm_id !== null)
            ) {
              if (value && value !== null && value !== "") {
                return Promise.resolve();
              } else {
                return Promise.reject("");
              }
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      component: <Input />,
    },
    {
      key: "url",
      name: ["ref", "url"],
      label: "URL",
      rules: [
        {
          validator: async (rule: any, value: any) => {
            // console.log("value", value);
            if (
              (formLeft.getFieldsValue().ref.url &&
                formLeft.getFieldsValue().ref.url !== null) ||
              (formRight.getFieldsValue().ref.url &&
                formRight.getFieldsValue().ref.url !== null)
            ) {
              if (value && value !== null && value !== "") {
                return Promise.resolve();
              } else {
                return Promise.reject("");
              }
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      component: <Input />,
    },
    {
      key: "openfigi",
      name: ["ref", "openfigi"],
      label: "Openfigi",
      rules: [
        {
          validator: async (rule: any, value: any) => {
            // console.log("value", value);
            if (
              (formLeft.getFieldsValue().ref.openfigi &&
                formLeft.getFieldsValue().ref.openfigi !== null) ||
              (formRight.getFieldsValue().ref.openfigi &&
                formRight.getFieldsValue().ref.openfigi !== null)
            ) {
              if (value && value !== null && value !== "") {
                return Promise.resolve();
              } else {
                return Promise.reject("");
              }
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
      component: <Input />,
    },
  ];

  return (
    <div>
      <div
        style={{
          margin: "1% 1% 0%",
          // border: "1px solid grey",
        }}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Card bordered={false}>
              <Select
                listHeight={128}
                loading={selectloading}
                showSearch
                allowClear
                value={selectedValueLeft}
                defaultActiveFirstOption={false}
                style={{
                  width: "100%",
                }}
                placeholder="Search and select a company"
                onSearch={handleSearchLeft}
                onClear={handleClearLeft}
                onChange={handleChangeLeft}
                optionFilterProp="children"
                filterOption={false}
                options={(filteredOptionsLeft || []).map((d: any) => ({
                  value: d.label + "@:!" + d.value,
                  label: `${d.label} (${d.value})`,
                }))}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={false}>
              <div
                style={{
                  padding: "3px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Merge
              </div>
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={false}>
              <Select
                listHeight={128}
                loading={selectloading}
                showSearch
                allowClear
                value={selectedValueRight}
                defaultActiveFirstOption={false}
                style={{ width: "100%" }}
                placeholder="Search and select a company"
                onSearch={handleSearchRight}
                onClear={handleClearRight}
                onChange={handleChangeRight}
                optionFilterProp="children"
                filterOption={false}
                options={(filteredOptionsRight || []).map((d: any) => ({
                  value: d.label + "@:!" + d.value,
                  label: `${d.label} (${d.value})`,
                }))}
              />
            </Card>
          </Col>
        </Row>
      </div>
      <div
        style={{
          margin: "0% 2.5% 0%",
          minHeight: "40vh",
          maxHeight: "60vh",
          border: "1px solid #DCDCDC",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Card bordered={false}>
              <div
                style={{
                  position: "relative",
                  top: -scrollY,
                }}
              >
                {selectedValueLeft && (
                  <Form
                    style={{
                      width: "100%",
                      margin: "auto",
                      textAlign: "start",
                    }}
                    form={formLeft}
                    layout="vertical"
                    labelCol={{ span: 16 }}
                    wrapperCol={{ flex: 24 }}
                  >
                    <Row gutter={16}>
                      {formDataLeft.map((item: any, index: any) => (
                        <React.Fragment key={index}>
                          {index < 7 ? (
                            <Col span={24}>
                              <Form.Item {...item}>{item.component}</Form.Item>
                            </Col>
                          ) : (
                            <Col span={12}>
                              <Form.Item {...item}>{item.component}</Form.Item>
                            </Col>
                          )}
                        </React.Fragment>
                      ))}
                    </Row>
                  </Form>
                )}
              </div>
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={false}>
              <div
                ref={scrollContainerRef}
                style={{ position: "relative", top: -scrollY }}
              >
                {selectedValueLeft && selectedValueRight && (
                  <Form
                    // ref={formRef}
                    style={{
                      width: "100%",
                      margin: "auto",
                      textAlign: "start",
                    }}
                    form={test}
                    layout="vertical"
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 24 }}
                    onValuesChange={handleFormChange}
                  >
                    <Row gutter={16}>
                      {formDataMerge.map((item: any, index: any) => {
                        // console.log(item);
                        return (
                          <React.Fragment key={index}>
                            {index < 7 ? (
                              <Col span={24}>
                                <Form.Item {...item}>
                                  {item.component}
                                </Form.Item>
                              </Col>
                            ) : (
                              <Col span={12}>
                                <Form.Item {...item}>
                                  {item.component}
                                </Form.Item>
                              </Col>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Row>
                  </Form>
                )}
              </div>
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={false}>
              <div
                ref={scrollContainerRef}
                style={{ position: "relative", top: -scrollY }}
              >
                {selectedValueRight && (
                  <Form
                    // ref={formRight}
                    form={formRight}
                    style={{
                      width: "100%",
                      margin: "auto",
                      textAlign: "start",
                    }}
                    layout="vertical"
                    labelCol={{ span: 16 }}
                    wrapperCol={{ flex: 24 }}
                  >
                    <Row gutter={16}>
                      {formDataRight.map((item: any, index: any) => (
                        <React.Fragment key={index}>
                          {index < 7 ? (
                            <Col span={24}>
                              <Form.Item {...item}>{item.component}</Form.Item>
                            </Col>
                          ) : (
                            <Col span={12}>
                              <Form.Item {...item}>{item.component}</Form.Item>
                            </Col>
                          )}
                        </React.Fragment>
                      ))}
                    </Row>
                  </Form>
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <div
        style={{
          paddingRight: "2.5%",
          textAlign: "end",
        }}
      >
        <Button
          className="open-modal-reset-button"
          onClick={() => {
            handleReset();
          }}
        >
          Reset
        </Button>

        <Button
          className="open-modal-save-button"
          style={{ paddingLeft: "1%" }}
          htmlType="submit"
          // onSubmit={() => {
          //   test.submit();
          // }}
          onClick={() => {
            confirmMerge();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default MyComponent;
