import React, { useState, useRef, useEffect } from "react";
import { Card, Input, Checkbox, List, Button, Space, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const MyComponent = (props: any) => {
  const [indeterminateANR, setIndeterminateANR] = useState(false);
  const [checkAllANR, setCheckAllANR] = useState(false);
  const [checkedANR, setCheckedANR]: any = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [checkedMap, setCheckedMap]: any = useState({});
  const [selectedCountries, setSelectedCountries]: any = useState([]);
  const myRef: any = useRef();
  const [openState, setOpenState] = useState(false);

  useEffect(() => {
    setOpenState(props.open);
  }, []);

  const onCheckAllANRChange = (e: any) => {
    let allCountries = props.country.map((item: any) => item.name);
    if (e.target.checked) {
      allCountries = allCountries.filter(
        (item: string) => !props.existCountry.includes(item)
      );
    }
    setCheckedANR(e.target.checked ? allCountries : []);
    setCheckAllANR(e.target.checked);
    setIndeterminateANR(false);
  };

  const filteredArray = props.country.filter((item: any) => {
    return item.name.toLowerCase().includes(searchValue.toLowerCase());
  });

  const handleCheckboxChange = (event: any) => {
    const countryName = event.target.value;
    const isSelected = event.target.checked;
    setCheckedMap((prevCheckedMap: any) => ({
      ...prevCheckedMap,
      [countryName]: isSelected,
    }));
    if (isSelected) {
      setSelectedCountries([...selectedCountries, countryName]);
    } else {
      setSelectedCountries(
        selectedCountries.filter((name: any) => name !== countryName)
      );
    }
  };

  const handleOk: any = () => {
    const current = new Date();
    setOpenState(false);
    if (props.handleCountry && typeof props.handleCountry === "function") {
      props.handleCountry(checkedANR);
      if (props.handleCancel && typeof props.handleCancel === "function") {
        props.handleCancel();
      }
    }
  };

  const handleCancel: any = () => {
    setOpenState(false);
    if (props.handleCancel && typeof props.handleCancel === "function") {
      props.handleCancel();
    }
  };

  return (
    <>
      <div style={{ width: "90%" }} ref={myRef}>
        <Modal
          title={<h2 style={{ textAlign: "center" }}>{props.name}</h2>}
          centered
          open={openState}
          onOk={handleOk}
          onCancel={handleCancel}
          width={"55vw"}
          footer={[<Button onClick={handleOk}>Add</Button>]}
        >
          <Card
            style={{
              width: "50vw",
              margin: "auto",
              border: "none",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 3fr",
                paddingBottom: "10px",
              }}
            >
              <span style={{ fontWeight: "700" }}>List of countries</span>
              <Card
                style={{
                  textAlign: "left",
                  border: "1px solid rgb(220,220,220)",
                }}
                headStyle={{
                  color: "#000000",
                  borderBottom: "1px solid rgb(220,220,220)",
                }}
                title={
                  <Input
                    type="text"
                    defaultValue={""}
                    placeholder={`Search country`}
                    prefix={<SearchOutlined />}
                    onChange={(ev: any) => {
                      setSearchValue(ev.target.value);
                    }}
                    allowClear
                  />
                }
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Checkbox
                    indeterminate={indeterminateANR}
                    onChange={onCheckAllANRChange}
                    checked={checkAllANR}
                    style={{ fontWeight: "700" }}
                  >
                    Select all countries
                  </Checkbox>
                </div>
                <Checkbox.Group
                  style={{
                    width: "100%",
                    maxHeight: "350px",
                    overflow: "auto",
                  }}
                  value={checkedANR}
                  onChange={(checkedValues) => {
                    setCheckedANR(checkedValues);
                  }}
                >
                  <List
                    itemLayout="horizontal"
                    dataSource={filteredArray}
                    renderItem={(item: any) => {
                      const countryExists = props.existCountry.includes(
                        item.name
                      );
                      return (
                        <List.Item>
                          <List.Item.Meta
                            avatar={
                              <Checkbox
                                value={item.name}
                                checked={checkedMap[item.name]}
                                onChange={handleCheckboxChange}
                                disabled={countryExists}
                              />
                            }
                            title={item.name}
                          />
                        </List.Item>
                      );
                    }}
                  />
                </Checkbox.Group>
              </Card>
            </div>
          </Card>
        </Modal>
      </div>
    </>
  );
};

export default MyComponent;
