import axios from "axios";
import { AuthHeader, Auth_CTHeader } from "./header";
const url = process.env.REACT_APP_SERVER_URL as string;

const uploadfile: any = url + "/main_industry/upload";
export const uploadFile = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(uploadfile, searchObj, header)
      .then((res: any) => {
        // console.log("res",res)
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        // console.log("error",err)
        console.error(err);
        reject(err);
      });
  });
};

const previewfile: any = url + "/main_industry/preview";
export const previewFile = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(previewfile, searchObj, header)
      .then((res: any) => {
        // console.log("res", res);
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        // console.log("error", err);
        console.error(err);
        reject(err);
      });
  });
};

const getfile: any = url + "/main_industry";
export const getFile = (searchObj: any = undefined) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .get(getfile, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        // console.log("error", err);
        console.error(err);
        reject(err);
      });
  });
};

const deletemulticode: any = url + "/main_industry/deleteMany";
export const deleteMultiCode = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(deletemulticode, searchObj, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const deletemultitemplate: any = url + "/industry_mapping_template/deleteMany";
export const deleteMultiTemplate = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(deletemultitemplate, searchObj, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const deletetemplatecodeindustry: any =
  url + "/industry_mapping_template/delete";
export const deleteTemplateCodeIndustry = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(deletetemplatecodeindustry, searchObj, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const updatecode: any = url + "/main_industry";
export const updateIndustryCodeDictionary = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .put(updatecode, searchObj, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const savetemplate: any = url + "/industry_mapping_template/create";
export const saveTemplate = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .post(savetemplate, searchObj, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const updatericindustry: any = url + "/industry";
export const updateRICindustry = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .put(updatericindustry, searchObj, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const saveedittemplate: any = url + "/industry_mapping_template/";
export const saveEdittemplate = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .put(saveedittemplate, searchObj, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const updateeditricindustry: any = url + "/industry/updateEdit";
export const updateEditRICindustry = (searchObj: any) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .put(updateeditricindustry, searchObj, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const getallricindustry: any = url + "/industry_mapping_template";
export const getAllRICIndustry = (searchObj: any = undefined) => {
  let header = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(`token`)}`,
    },
  };
  return new Promise((resolve: any, reject: any) => {
    axios
      .get(getallricindustry, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const getalltemplate: any = url + "/industry_mapping_template/template";
export const getAlltemplate = (searchObj: any = undefined) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .get(getalltemplate, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};

const getallactivericindustry: any = url + "/industry_mapping_template/active";
export const getAllActiveRICIndustry = (searchObj: any = undefined) => {
  return new Promise((resolve: any, reject: any) => {
    let header = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`token`)}`,
      },
    };
    axios
      .get(getallactivericindustry, header)
      .then((res: any) => {
        resolve(res.data ? res.data : []);
      })
      .catch((err: any) => {
        console.error(err);
        reject(err);
      });
  });
};
