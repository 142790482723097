import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const MyComponent = (props: any) => {
  const navigate = useNavigate();
  let auth = { token: localStorage.getItem("isLogin") };
  let privilage = localStorage.getItem("privilage");

  if (!auth.token) {
    return <Navigate to="/login" />;
  }
  if (props.roles && privilage !== props.roles) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button
            type="primary"
            onClick={() => {
              navigate("/home", { replace: true });
            }}
          >
            Back Home
          </Button>
        }
      />
    );
  }

  return <Outlet />;
};
export default MyComponent;
