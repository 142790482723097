import React, { useEffect, useState, useRef, forwardRef } from "react";
import {
  Breadcrumb,
  Form,
  Input,
  notification,
  Select,
  Button,
  Modal,
  Tabs,
  Badge,
  Tooltip,
} from "antd";
import RT, { PageControl } from "./../component/RajangTable";
import { getAllIndustry } from "../controller/search";
import {
  getFile,
  saveTemplate,
  updateRICindustry,
  saveEdittemplate,
  updateEditRICindustry,
  getAllRICIndustry,
  deleteTemplateCodeIndustry,
} from "../controller/industry_mapping";
import { useNavigate, useLocation } from "react-router-dom";
import "./TemplateConfiguration.css";
import Events from "../lib/events";
import { CloseOutlined } from "@ant-design/icons";
import { addLog } from "../controller/profile";

// const Tabs = forwardRef((props, ref) => {
//   return <Tabs ref={ref} {...props} />;
// });

const MyComponent = (props: any) => {
  const initialPageControl: PageControl = {
    numRec: 0,
    recPerPage: 10,
    pageNum: 1,
  };
  const industryRemapRef = useRef<any>([]);
  const selectedOptionRef = useRef<any>([]);
  const closeRef = useRef<any>(false);
  const tempTemplateNameRef = useRef<any>(null);
  const tempDefaultTemplateNameRef = useRef<any>(null);
  const selectedindustrycodeRef = useRef<any>(null);
  const selectednewindustrycodeRef = useRef<any>(null);
  const [pageC, setPageC]: any = useState(initialPageControl);
  const [form] = Form.useForm();
  const { TabPane } = Tabs;
  const [editmode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData]: any = useState([]);
  const [ric, setRic]: any = useState([]);
  const [mainric, setMainRic]: any = useState([]);
  const [ricchild, setRicChild]: any = useState([]);
  const [dropDownCodeIndustryOptions, setdropDownCodeIndustryOptions]: any =
    useState([]);
  const [ricparent, setRicParent]: any = useState([]);
  const [selectedindustrycode, setSeletedIndustryCode]: any = useState(null);
  const [selectednewindustrycode, setSeletedNewIndustryCode]: any =
    useState(null);
  const [templateName, settemplateName]: any = useState(null);
  const [dropDownCoreCodeOptions, setdropDownCoreCodeOptions]: any = useState(
    []
  );
  const [refreshRIC, setRefreshRIC]: any = useState(false);
  const [selectedOriindustrycode, setSeletedOriIndustryCode]: any =
    useState(null);
  const [templateOriName, settemplateOriName]: any = useState(null);
  // const [selectedOption, setSelectedOption]: any = useState([]);
  // const [industryremap, setIndustryRemap]: any = useState([]);
  const [createmode, setCreateMode]: any = useState(false);
  const [fullindustry, setFullIndustry]: any = useState([]);
  const [selecteddefaultindustrycode, setSeletedDefaultIndustryCode]: any =
    useState(null);
  const [templatedefaultName, settemplateDefaultName]: any = useState(
    props.templatedefaultName ? props.templatedefaultName : null
  );
  const [templateid, settemplateDefaultID]: any = useState(null);
  const [listRIC, setlistRIC] = useState([]);
  const [breadcrumb, setBreadCrumb]: any = useState(props.breadcrumb);
  const [subcode, setSubCode]: any = useState(props.subcode);
  const [createsubcode, setCreateSubCode]: any = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const getbaseUrl = window.location.pathname;
  const [activeKey, setActiveKey]: any = useState(
    location.state?.["code"] ? location.state?.["code"] : props.subcode[0]?.code
  );
  const [items, setItems] = useState(props.subcode);
  const [resetValue, setResetValue] = useState(false);
  const newTabIndex = useRef(0);
  const subnewTabIndex = useRef(0);
  // const [isBlockingNavigation, setIsBlockingNavigation] = useState(false);
  const isBlockingNavigation = useRef<any>(false);
  const changes = useRef<any>(false);
  const validate = useRef<any>(false);
  const [width, setWidth] = useState(window.innerWidth);
  const tabsRef: any = useRef(null);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // console.log("tabsRef.current", tabsRef.current);

  const onTabScroll = (event: any) => {
    // if (event === "right") {
    //   tabsRef.current.scrollLeft += 100;
    // } else {
    //   tabsRef.current.scrollLeft -= 100;
    // }
  };

  // console.log("location", location);

  // console.log("formname", form.getFieldsValue().template_name);
  // console.log("tempname", templatedefaultName);
  // console.log("industryremap", Object.keys(industryRemapRef.current).length);
  // console.log("selectedindustrycode", selectedindustrycode);
  // console.log("selectedOptionRef", selectedOptionRef);
  // console.log("selectednewindustrycode", selectednewindustrycode);

  // console.log("closeref", closeRef.current);

  // useEffect(() => {
  //   console.log("abc");
  //   const handleBeforeUnload = () => {
  //     // e.preventDefault();
  //     Modal.confirm({
  //       title: "Cancel Changes?",
  //       content: "Are you sure you want to cancel?",
  //       okText: "Yes",
  //       cancelText: "No",
  //       onOk() {
  //         form.setFieldsValue({
  //           template_name: templatedefaultName,
  //         });
  //         revertOridata();
  //         setSeletedNewIndustryCode(null);
  //         industryRemapRef.current = [];
  //       },
  //     });
  //   };

  //   // handles when page is unloaded
  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // cleanup function handles when component unmounts
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     handleBeforeUnload();
  //   };
  // }, [location, window.location.pathname !== "industry_mapping"]);

  // useEffect(() => {
  //   const handleBeforeUnload = (e: any) => {
  //     e.preventDefault();
  //     e.returnValue =
  //       "Changes you made may not be saved. Are you sure you want to leave?";
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  // useEffect(() => {
  //   console.log("??");
  //   const unblock = () => {
  //     if (isBlockingNavigation) {
  //       return "You have unsaved changes. Are you sure you want to leave?";
  //     }
  //   };

  //   window.onbeforeunload = unblock;

  //   return () => {
  //     window.onbeforeunload = null;
  //   };
  // }, [isBlockingNavigation]);

  // useEffect(() => {
  //   isBlockingNavigation.current = true;
  //   console.log(`The current route is ${location.pathname}`);
  // }, [location]);

  // useEffect(() => {
  //   console.log("CREATE", createmode);
  //   tempTemplateNameRef.current = null;
  //   tempDefaultTemplateNameRef.current = null;
  //   industryRemapRef.current = [];
  //   selectedOptionRef.current = [];
  //   selectedindustrycodeRef.current = null;
  //   selectednewindustrycodeRef.current = null;
  //   Events.on("triggerClose", () => {
  //     let industry_mapping: any = localStorage.getItem("industry_mapping");
  //     if (industry_mapping) {
  //       let parsedData = JSON.parse(industry_mapping);
  //       if (parsedData.mode === true) {
  //         tempTemplateNameRef.current = parsedData.template_name;
  //         tempDefaultTemplateNameRef.current = parsedData.defaulttemplate_name;
  //         industryRemapRef.current = parsedData.industryRemap;
  //         selectedOptionRef.current = parsedData.selectOption;
  //         selectedindustrycodeRef.current = parsedData.selectedindustrycode;
  //         selectednewindustrycodeRef.current =
  //           parsedData.selectednewindustrycode;
  //         handleRefClose();
  //       } else if (parsedData.mode === false) {
  //         tempTemplateNameRef.current = parsedData.template_name;
  //         tempDefaultTemplateNameRef.current = parsedData.defaulttemplate_name;
  //         industryRemapRef.current = parsedData.industryRemap;
  //         selectedOptionRef.current = parsedData.selectOption;
  //         selectedindustrycodeRef.current = parsedData.selectedindustrycode;
  //         selectednewindustrycodeRef.current =
  //           parsedData.selectednewindustrycode;
  //         handleRefEditClose();
  //       }
  //     } else {
  //       console.log("hilang");
  //     }
  //   });

  //   return () => {
  //     Events.off("triggerClose", () => {});
  //   };
  // }, []);

  // const Tabs = forwardRef((props: any, ref: any) => {
  //   return <Tabs ref={ref} {...props} />;
  // });

  useEffect(() => {
    tempTemplateNameRef.current = null;
    tempDefaultTemplateNameRef.current = null;
    industryRemapRef.current = [];
    selectedOptionRef.current = [];
    selectedindustrycodeRef.current = null;
    selectednewindustrycodeRef.current = null;

    const eventHandler = () => {
      // Events.off("triggerClose", eventHandler); // Remove the event listener

      let industry_mapping: any = localStorage.getItem("industry_mapping");
      let selected: any = localStorage.getItem("template");
      if (selected) {
        if (industry_mapping) {
          let parsedData = JSON.parse(industry_mapping);
          if (parsedData.mode === true) {
            tempTemplateNameRef.current = parsedData.template_name;
            tempDefaultTemplateNameRef.current =
              parsedData.defaulttemplate_name;
            industryRemapRef.current = parsedData.industryRemap;
            selectedOptionRef.current = parsedData.selectOption;
            selectedindustrycodeRef.current = parsedData.selectedindustrycode;
            selectednewindustrycodeRef.current =
              parsedData.selectednewindustrycode;
            handleRefClose();
          } else if (parsedData.mode === false) {
            tempTemplateNameRef.current = parsedData.template_name;
            tempDefaultTemplateNameRef.current =
              parsedData.defaulttemplate_name;
            selectedindustrycodeRef.current = parsedData.selectedindustrycode;
            industryRemapRef.current = parsedData.industryRemap;
            if (selectedOptionRef.current.length === 0) {
              selectedOptionRef.current = parsedData.selectOption;

              selectednewindustrycodeRef.current = null;
            } else {
              selectedOptionRef.current = parsedData.selectOption;

              selectednewindustrycodeRef.current =
                parsedData.selectednewindustrycode;
            }

            handleRefEditClose();
          }
        } else {
          let parsedData = JSON.parse(selected);
          if (parsedData.mode === true) {
            tempTemplateNameRef.current = parsedData.template;
            tempDefaultTemplateNameRef.current = parsedData.template;
            handleRefClose();
          } else if (parsedData.mode === false) {
            tempTemplateNameRef.current = parsedData.template;
            tempDefaultTemplateNameRef.current = parsedData.template;
            handleRefEditClose();
          }
        }
      }
    };

    Events.on("triggerClose", eventHandler);
    return () => {
      Events.off("triggerClose", eventHandler); // Make sure to remove the event listener on cleanup
    };
  }, []);

  useEffect(() => {
    if (createmode === true) {
      if (
        selectedOptionRef.current.length > 0 &&
        form.getFieldsValue().template_name !== null &&
        form.getFieldsValue().template_name !== "" &&
        form.getFieldsValue().template_name !== undefined
      ) {
        // changes.current = true;
        // localStorage.setItem("changes", `true`);
        localStorage.setItem(
          "industry_mapping",
          JSON.stringify({
            template_name: form.getFieldsValue().template_name,
            defaulttemplate_name: templatedefaultName,
            industryRemap: industryRemapRef.current,
            selectOption: selectedOptionRef.current,
            mode: createmode,
            selectedindustrycode: selectedindustrycode,
            selectednewindustrycode: selectednewindustrycode,
          })
        );
        // let industry_mapping: any = localStorage.getItem("industry_mapping");
      } else {
        localStorage.removeItem("industry_mapping");
        localStorage.removeItem("redirect");
      }
    } else if (createmode === false) {
      if (
        (selectedindustrycode !== null &&
          Object.keys(industryRemapRef.current).length > 0 &&
          selectednewindustrycode === null) ||
        (form.getFieldsValue().template_name !== templatedefaultName &&
          selectedindustrycode !== null &&
          selectednewindustrycode === null) ||
        (form.getFieldsValue().template_name !== templatedefaultName &&
          selectedindustrycode === null &&
          selectednewindustrycode !== null &&
          selectedOptionRef.current.length > 0) ||
        (selectedindustrycode === null &&
          selectednewindustrycode !== null &&
          selectedOptionRef.current.length === 0) ||
        (form.getFieldsValue().template_name !== templatedefaultName &&
          selectedindustrycode === null &&
          selectednewindustrycode === null) ||
        (selectedindustrycode === null &&
          selectednewindustrycode !== null &&
          selectedOptionRef.current.length > 0)
      ) {
        // console.log("name", form.getFieldsValue().template_name);
        // console.log("templatedefaultName", templatedefaultName);

        // changes.current = true;
        // localStorage.setItem("changes", `true`);
        localStorage.setItem(
          "industry_mapping",
          JSON.stringify({
            template_name: form.getFieldsValue().template_name,
            defaulttemplate_name: templatedefaultName,
            industryRemap: industryRemapRef.current,
            selectOption: selectedOptionRef.current,
            mode: createmode,
            selectedindustrycode: selectedindustrycode,
            selectednewindustrycode: selectednewindustrycode,
          })
        );
        let industry_mapping: any = localStorage.getItem("industry_mapping");
      } else {
        localStorage.removeItem("industry_mapping");
        localStorage.removeItem("redirect");
      }
    }
  }, [
    selectedindustrycode,
    industryRemapRef.current,
    selectednewindustrycode,
    form.getFieldsValue().template_name,
    selectedOptionRef.current,
  ]);

  // useEffect(() => {
  //   // console.log("TRI");
  //   // console.log("createmode", createmode);
  //   // console.log("templateName", form.getFieldsValue().template_name);
  //   // console.log("templatedefaultName", templatedefaultName);
  //   // console.log("selectedindustrycode", selectedindustrycode);
  //   // console.log("selectednewindustrycode", selectednewindustrycode);
  //   if (createmode === true) {
  //     if (
  //       selectedOptionRef.current.length > 0 &&
  //       form.getFieldsValue().template_name !== null &&
  //       form.getFieldsValue().template_name !== "" &&
  //       form.getFieldsValue().template_name !== undefined
  //     ) {
  //       changes.current = true;
  //     } else {
  //       changes.current = false;
  //     }
  //   } else if (createmode === false) {
  //     if (
  //       (selectedindustrycode !== null &&
  //         Object.keys(industryRemapRef.current).length > 0 &&
  //         selectednewindustrycode === null) ||
  //       (form.getFieldsValue().template_name !== templatedefaultName &&
  //         selectedindustrycode !== null &&
  //         selectednewindustrycode === null) ||
  //       (form.getFieldsValue().template_name !== templatedefaultName &&
  //         selectedindustrycode === null &&
  //         selectednewindustrycode !== null &&
  //         selectedOptionRef.current.length > 0)
  //     ) {
  //       changes.current = true;
  //     } else {
  //       changes.current = false;
  //     }
  //   }
  // }, [
  //   selectedindustrycode,
  //   industryRemapRef.current,
  //   selectednewindustrycode,
  //   form.getFieldsValue().template_name,
  //   selectedOptionRef.current,
  // ]);

  useEffect(() => {
    setMainRic(props.ric);
    setBreadCrumb(props.breadcrumb);
  }, [props.ric, props.breadcrumb]);

  // console.log("location", location.state);
  useEffect(() => {
    setSubCode(props.subcode);
    if (location.state?.["code"]) {
      setActiveKey(location.state?.["code"]);
    } else {
      setActiveKey(props.subcode[subcode.length + 1]?.code);
    }
  }, [props.subcode]);

  useEffect(() => {
    // setSubCode(filteredData);
  }, [subcode]);

  // useEffect(() => {
  //   console.log("remapo", industryremap);
  // }, [industryremap]);

  useEffect(() => {
    setRefreshRIC(true);
    if (props.templatedefaultName) {
      settemplateName(props.templatedefaultName);
      setSeletedIndustryCode(props.selecteddefaultindustrycode);
      setSeletedDefaultIndustryCode(props.selecteddefaultindustrycode);
      settemplateDefaultName(props.templatedefaultName);
      settemplateDefaultID(props.templatedefaultId);
      setSubCode(props.subcode);
      setCreateSubCode([]);
      setCreateMode(false);
      if (location.state?.["code"]) {
        setActiveKey(location.state?.["code"]);
      } else {
        setActiveKey(props.subcode[0]?.code);
      }
      industryRemapRef.current = [];
      form.setFieldsValue({
        template_name: props.templatedefaultName,
      });
      localStorage.setItem(
        "template",
        JSON.stringify({
          template: props.templatedefaultName,
          mode: false,
        })
      );
    } else {
      settemplateDefaultName(null);
      settemplateName(null);
      setSeletedIndustryCode(null);
      setCreateMode(true);
      setCreateSubCode([]);
      setActiveKey([]);
      industryRemapRef.current = [];
      add();
      form.setFieldsValue({
        template_name: null,
      });
      localStorage.setItem(
        "template",
        JSON.stringify({
          template: null,
          mode: true,
        })
      );
    }
    localStorage.removeItem("industry_mapping");
    localStorage.removeItem("redirect");
  }, [props.templatedefaultName, props.selecteddefaultindustrycode]);

  useEffect(() => {
    if (refreshRIC === true) {
      getAllRICIndustry()
        .then((res: any) => {
          setlistRIC(res.data);
        })
        .catch((err: any) => {
          console.error(err);
        });
      setRefreshRIC(false);
    }
  }, [refreshRIC]);

  useEffect(() => {}, [ricchild]);

  const revertOridata = () => {
    // console.log("props.templatedefaultName", props.templatedefaultName);
    industryRemapRef.current = [];
    setLoading(true);
    getAllIndustry().then((res: any) => {
      if (res.data) {
        const dropDownIndustryOption = res.data.map((x: any) => ({
          _id: checkIndustryCode(x.industrycode) + " - " + x.description,
          _code: props.templatedefaultName
            ? listCode(
                x.mapping?.[props.templatedefaultName],
                selectedindustrycode
              )
            : "",
        }));

        const dropDownCoreCodeOption = res.data.map((x: any) => ({
          label: checkIndustryCode(x.industrycode) + " - " + x.description,
          value: checkIndustryCode(x.industrycode) + " - " + x.description,
        }));

        setdropDownCoreCodeOptions(dropDownCoreCodeOption);

        if (props.templatedefaultName) {
          const editdata = res.data.map((obj: any) => ({
            _id: checkIndustryCode(obj.industrycode) + " - " + obj.description,
            _code: listCode(
              obj.mapping?.[props.templatedefaultName],
              selectedindustrycode
            ),
          }));

          let defaultdata: any = [];
          editdata.map((obj: any) => {
            if (obj._code !== "") {
              obj._code.forEach((value: any) => {
                defaultdata.push(value);
              });
            }
          });

          selectedOptionRef.current = defaultdata;
          setData(editdata);
        } else {
          setData(dropDownIndustryOption);
        }
      }

      setLoading(false);
    });
  };

  useEffect(() => {
    // console.log("checking", selectedindustrycode)
    setLoading(true);
    industryRemapRef.current = [];
    getFile().then((res: any) => {
      if (res.data) {
        res.data.map((k: any, i: any) => {
          if (k._id === selectedindustrycode) {
            setRic(k);
            setRicChild(k?.child);
            const ricchilds = k.child.map((x: any) => ({
              label: x.code + " - " + x.description,
              value: x.code + " - " + x.description,
            }));
            setdropDownCodeIndustryOptions(ricchilds);
          }
        });
        const ricparents = res.data.map((x: any) => {
          const found = subcode.some((item: any) => item.code === x._id);
          if (found) {
            return {
              _label: x._id,
              value: x._id,
              disabled: true,
            };
          } else {
            return {
              _label: x._id,
              value: x._id,
            };
          }
        });
        setRicParent(ricparents);
        setLoading(false);
      }
    });
  }, [selectedindustrycode]);

  useEffect(() => {
    setLoading(true);
    industryRemapRef.current = [];
    getFile().then((res: any) => {
      if (res.data) {
        res.data.map((k: any, i: any) => {
          if (k._id === selectednewindustrycode) {
            setRic(k);
            // setRicChild(k?.child);
            const ricchilds = k.child.map((x: any) => ({
              label: x.code + " - " + x.description,
              value: x.code + " - " + x.description,
            }));
            setdropDownCodeIndustryOptions(ricchilds);
          }
          //  else {
          //   setdropDownCodeIndustryOptions([]);
          // }
        });
        const ricparents = res.data.map((x: any) => {
          if (createmode === true) {
            return {
              _label: x._id,
              value: x._id,
            };
          } else {
            const found = subcode.some((item: any) => item.code === x._id);
            if (found) {
              return {
                _label: x._id,
                value: x._id,
                disabled: true,
              };
            } else {
              return {
                _label: x._id,
                value: x._id,
              };
            }
          }
        });
        selectedOptionRef.current = [];
        setRicParent(ricparents);
        setLoading(false);
      }
    });
    // console.log("selectednewindustrycode", selectednewindustrycode);
  }, [selectednewindustrycode]);

  useEffect(() => {}, [dropDownCodeIndustryOptions]);

  // useEffect(() => {}, [selectedOption]);

  useEffect(() => {
    setLoading(true);
    getAllIndustry().then((res: any) => {
      if (res.data) {
        if (props.templatedefaultName) {
          const editdata = res.data.map((obj: any) => ({
            _id: checkIndustryCode(obj.industrycode) + " - " + obj.description,
            // _code: listCode(
            //   obj.mapping?.[props.templatedefaultName],
            //   selectedindustrycode
            // ),
            _code: obj.mapping?.[props.templatedefaultName]
              ? listCode(
                  obj.mapping[props.templatedefaultName],
                  selectedindustrycode
                )
              : "",
          }));
          let defaultdata: any = [];
          editdata.map((obj: any) => {
            if (obj._code !== "") {
              obj._code.forEach((value: any) => {
                defaultdata.push(value);
              });
            }
          });
          selectedOptionRef.current = defaultdata;
          setData(editdata);
        } else {
          const dropDownIndustryOption = res.data.map((x: any) => ({
            _id: checkIndustryCode(x.industrycode) + " - " + x.description,
            // _code: props.templatedefaultName
            //   ? listCode(
            //       x.mapping?.[props.templatedefaultName],
            //       selectedindustrycode
            //     )
            //   : "",
            _code: "",
          }));
          const dropDownCoreCodeOption = res.data.map((x: any) => ({
            label: checkIndustryCode(x.industrycode) + " - " + x.description,
            value: checkIndustryCode(x.industrycode) + " - " + x.description,
          }));
          setdropDownCoreCodeOptions(dropDownCoreCodeOption);

          setData(dropDownIndustryOption);
        }
      }
      industryRemapRef.current = [];
      setLoading(false);
    });
  }, [props.templatedefaultName, ricchild]);

  const refreshNewCode = () => {
    setLoading(true);
    getAllIndustry().then((res: any) => {
      if (res.data) {
        const dropDownIndustryOption = res.data.map((x: any) => ({
          _id: checkIndustryCode(x.industrycode) + " - " + x.description,
          _code: "",
        }));
        setData(dropDownIndustryOption);
        // industryRemapRef.current = [];
        // setSelectedOption([]);
        setLoading(false);
      }
    });
  };

  const listCode = (d: any, key: any) => {
    if (d === undefined || d?.length === 0) {
      return [];
    } else {
      const result = d.filter((item: any) => item.startsWith(key));
      if (result?.length === 0) {
        return [];
      } else {
        // const new_d = d.map((item: any) => Object.values(item)).flat();

        const new_d = result.map((item: any) => item.replace(key + ":", ""));
        const filteredData = ricchild.filter((item: any) =>
          new_d.includes(item.code)
        );
        const transformedData = filteredData.map(
          (item: any) => `${item.code} - ${item.description}`
        );
        return transformedData;
      }
    }
  };

  useEffect(() => {
    const fetchRIC = async () => {
      if (props.templatedefaultName) {
        getFile().then((res: any) => {
          if (res.data) {
            setRic(res.data);
            const ricparents = res.data.map((x: any) => {
              const found = subcode.some((item: any) => item.code === x._id);
              if (found) {
                return {
                  _label: x._id,
                  value: x._id,
                  disabled: true,
                };
              } else {
                return {
                  _label: x._id,
                  value: x._id,
                  disabled: false,
                };
              }
            });

            setRicParent(ricparents);
          }
        });
      } else {
        getFile().then((res: any) => {
          if (res.data) {
            setRic(res.data);
            const ricparents = res.data.map((x: any) => {
              return {
                _label: x._id,
                value: x._id,
                disabled: false,
              };
            });
            setRicParent(ricparents);
          }
        });
      }
    };
    fetchRIC();
  }, [templateName]);

  // useEffect(() => {
  //   if (props.ric) {
  //     ric.map((k: any, i: any) => {
  //       if (k._id === props.ric?._id) {
  //         const ricchilds = k.child.map((x: any) => ({
  //           label: x.code + " - " + x.description,
  //           value: x.code + " - " + x.description,
  //         }));
  //         setRicChild(ricchilds);
  //       }
  //     });
  //   }
  // }, [props.ric]);
  useEffect(() => {}, [ricparent]);
  useEffect(() => {}, [ricchild]);

  const resetFunction = () => {
    setResetValue(true);
  };

  const restoreFilter = () => {
    setResetValue(false);
  };

  const selectRIC = (d: any) => {
    // setSeletedNewIndustryCode(d);

    // refreshNewCode();
    // setSelectedOption([]);
    if (createmode === true) {
      if (d !== selectednewindustrycode && selectednewindustrycode !== null) {
        Modal.confirm({
          title: "Change Industry Code",
          content:
            "Changing Industry Code will cause the loss of all mapping field to be empty",
          okText: "Confirm",
          onOk() {
            // setSeletedIndustryCode(d);
            setSeletedNewIndustryCode(d);
            refreshNewCode();
            industryRemapRef.current = [];
            selectedOptionRef.current = [];
            // ric.map((k: any, i: any) => {
            //   if (d === k._id) {
            //     const ricchilds = k.child.map((x: any) => ({
            //       label: x.code + " - " + x.description,
            //       value: x.code + " - " + x.description,
            //     }));
            //     setdropDownCodeIndustryOptions(ricchilds);
            //   }
            // });

            // getFile().then((res: any) => {
            //   if (res.data) {
            //     res.data.map((k: any, i: any) => {
            //       if (d === k._id) {
            //         const ricchilds = k.child.map((x: any) => ({
            //           label: x.code + " - " + x.description,
            //           value: x.code + " - " + x.description,
            //         }));
            //         setdropDownCodeIndustryOptions(ricchilds);
            //       }
            //     });
            //   }
            // });
          },
          onCancel() {
            if (selectednewindustrycode !== null) {
              form.setFieldsValue({ industry_code: selectednewindustrycode });
            }
          },
        });
      } else {
        // setSeletedIndustryCode(d);
        setSeletedNewIndustryCode(d);
        refreshNewCode();
        selectedOptionRef.current = [];
        industryRemapRef.current = [];
        // ric.map((k: any, i: any) => {
        //   if (d === k._id) {
        //     const ricchilds = k.child.map((x: any) => ({
        //       label: x.code + " - " + x.description,
        //       value: x.code + " - " + x.description,
        //     }));
        //     setdropDownCodeIndustryOptions(ricchilds);
        //   }
        // });
        getFile().then((res: any) => {
          if (res.data) {
            res.data.map((k: any, i: any) => {
              if (d === k._id) {
                const ricchilds = k.child.map((x: any) => ({
                  label: x.code + " - " + x.description,
                  value: x.code + " - " + x.description,
                }));
                setdropDownCodeIndustryOptions(ricchilds);
              }
            });
          }
        });
      }
    } else {
      if (d !== selectednewindustrycode && selectednewindustrycode !== null) {
        Modal.confirm({
          title: "Change Industry Code",
          content:
            "Changing Industry Code will cause the loss of all mapping field to be empty",
          okText: "Confirm",
          onOk() {
            setSeletedNewIndustryCode(d);
            refreshNewCode();
            industryRemapRef.current = [];
            selectedOptionRef.current = [];
            revertOridata();
          },
          onCancel() {
            if (selectednewindustrycode !== null) {
              form.setFieldsValue({
                industry_code: selectednewindustrycode,
              });
            }
          },
        });
      } else {
        setSeletedNewIndustryCode(d);
        getAllIndustry().then((res: any) => {
          if (res.data) {
            const editdata = res.data.map((obj: any) => ({
              _id:
                checkIndustryCode(obj.industrycode) + " - " + obj.description,
              // _code: listCode(
              //   obj.mapping?.[props.templatedefaultName],
              //   selectednewindustrycode)
              _code: "",
            }));
            // console.log("editdata", editdata);
            // let defaultdata: any = [];
            // editdata.map((obj: any) => {
            //   obj._code.forEach((value: any) => {
            //     defaultdata.push(value);
            //   });
            // });
            // console.log("defaultdata", defaultdata);
            // setSelectedOption(defaultdata);
            setData(editdata);
            refreshNewCode();
            industryRemapRef.current = [];
            selectedOptionRef.current = [];
            setLoading(false);
          }
        });
      }
    }
  };

  const handleCancel = () => {
    const existsInArray = listRIC.some(
      (obj: any) => obj.name === form.getFieldsValue().template_name
    );
    if (!existsInArray) {
      Modal.confirm({
        title: "Cancel Changes?",
        content: "Are you sure you want to cancel?",
        okText: "Yes",
        cancelText: "No",
        onOk() {
          form.setFieldsValue({
            template_name: null,
          });
          revertOridata();
          setdropDownCodeIndustryOptions([]);
          setSeletedNewIndustryCode(null);
          selectedOptionRef.current = [];
          industryRemapRef.current = [];
        },
      });
    }
  };

  const handleEditCancel = () => {
    if (selectednewindustrycode !== null) {
      if (selectedOptionRef.current.length > 0) {
        Modal.confirm({
          title: "Cancel Changes?",
          content: "Are you sure you want to cancel?",
          okText: "Yes",
          cancelText: "No",
          onOk() {
            form.setFieldsValue({
              template_name: templatedefaultName,
            });
            revertOridata();
            setdropDownCodeIndustryOptions([]);
            setSeletedNewIndustryCode(null);
            selectedOptionRef.current = [];
            industryRemapRef.current = [];
          },
        });
      }
    } else {
      if (
        form.getFieldsValue().template_name !== templatedefaultName ||
        Object.keys(industryRemapRef.current)?.length > 0
      ) {
        Modal.confirm({
          title: "Cancel Changes?",
          content: "Are you sure you want to cancel?",
          okText: "Yes",
          cancelText: "No",
          onOk() {
            form.setFieldsValue({
              template_name: templatedefaultName,
            });
            revertOridata();
            setSeletedNewIndustryCode(null);
            industryRemapRef.current = [];
          },
        });
      }
    }
  };

  const handleClose = () => {
    if (selectednewindustrycode !== null) {
      if (selectedOptionRef.current.length > 0) {
        Modal.confirm({
          title: (
            <>
              Leaving <strong>{templateName}</strong>
            </>
          ),
          content: (
            <>
              Are you sure you want to leave <strong>{templateName}</strong> ?{" "}
            </>
          ),
          okText: "Save and Close",
          cancelText: "Remain on page",
          onOk() {
            localStorage.removeItem("industry_mapping");
            if (
              form.getFieldsValue().template_name !== templatedefaultName ||
              Object.keys(industryRemapRef.current)?.length > 0
            ) {
              closeRef.current = true;
              handleEditDataUpdate();
              // Events.on("changes", () => {
              //   return Events.off("changes", () => {});
              // });
              // Events.on("triggerClose", () => {
              //   return Events.off("triggerClose", () => {});
              // });
            }
          },
          // onCancel() {
          //   Events.emit("changes");
          // },
        });
      } else {
        Modal.confirm({
          title: (
            <>
              Leaving <strong>{templateName}</strong>
            </>
          ),
          content: (
            <>
              Are you sure you want to leave <strong>{templateName}</strong> ?{" "}
            </>
          ),
          okText: "Yes",
          cancelText: "No",
          onOk() {
            if (props.changeMode && typeof props.changeMode === "function") {
              props.changeMode(null);
            }
          },
        });
      }
    } else {
      Modal.confirm({
        title: (
          <>
            Leaving <strong>{templateName}</strong>
          </>
        ),
        content: (
          <>
            Are you sure you want to leave <strong>{templateName}</strong> ?{" "}
          </>
        ),
        okText: "Yes",
        cancelText: "No",
        onOk() {
          localStorage.removeItem("industry_mapping");
          if (props.changeMode && typeof props.changeMode === "function") {
            props.changeMode(null);
          }
        },
      });
    }
  };

  const handleEditClose = () => {
    // console.log("templateName", templateName);
    // console.log("fname", form.getFieldsValue().template_name);
    if (
      form.getFieldsValue().template_name !== templatedefaultName &&
      selectedindustrycode === null &&
      selectednewindustrycode !== null &&
      selectedOptionRef.current.length > 0
    ) {
      Modal.confirm({
        title: (
          <>
            Leaving <strong>{templateName}</strong>
          </>
        ),
        content: (
          <>
            Are you sure you want to leave <strong>{templateName}</strong> ?{" "}
          </>
        ),
        okText: "Save and Close",
        cancelText: "Remain on page",
        onOk() {
          localStorage.removeItem("industry_mapping");
          if (
            form.getFieldsValue().template_name !== templatedefaultName ||
            Object.keys(industryRemapRef.current)?.length > 0
          ) {
            closeRef.current = true;
            handleEditDataUpdate();
            // Events.on("changes", () => {
            //   return Events.off("changes", () => {});
            // });
            // Events.on("triggerClose", () => {
            //   return Events.off("triggerClose", () => {});
            // });
          }
        },
        onCancel() {
          Events.emit("changes");
        },
      });
    } else if (
      form.getFieldsValue().template_name !== templatedefaultName &&
      selectedindustrycode !== null &&
      selectednewindustrycode === null
    ) {
      Modal.confirm({
        title: (
          <>
            Leaving <strong>{templateName}</strong>
          </>
        ),
        content: (
          <>
            Are you sure you want to leave <strong>{templateName}</strong> ?{" "}
          </>
        ),
        okText: "Save and Close",
        cancelText: "Remain on page",
        onOk() {
          localStorage.removeItem("industry_mapping");
          if (
            form.getFieldsValue().template_name !== templatedefaultName ||
            Object.keys(industryRemapRef.current)?.length > 0
          ) {
            closeRef.current = true;
            handleEditDataUpdate();
            // Events.on("changes", () => {
            //   return Events.off("changes", () => {});
            // });
            // Events.on("triggerClose", () => {
            //   return Events.off("triggerClose", () => {});
            // });
          }
        },
        onCancel() {
          Events.emit("changes");
        },
      });
    } else if (
      selectedindustrycode !== null &&
      Object.keys(industryRemapRef.current).length > 0 &&
      selectednewindustrycode === null
    ) {
      Modal.confirm({
        title: (
          <>
            Leaving <strong>{templateName}</strong>
          </>
        ),
        content: (
          <>
            Are you sure you want to leave <strong>{templateName}</strong> ?{" "}
          </>
        ),
        okText: "Save and Close",
        cancelText: "Remain on page",
        onOk() {
          localStorage.removeItem("industry_mapping");
          if (
            form.getFieldsValue().template_name !== templatedefaultName ||
            Object.keys(industryRemapRef.current)?.length > 0
          ) {
            closeRef.current = true;
            handleEditDataUpdate();
            // Events.on("changes", () => {
            //   return Events.off("changes", () => {});
            // });
            // Events.on("triggerClose", () => {
            //   return Events.off("triggerClose", () => {});
            // });
          }
        },
        onCancel() {
          Events.emit("changes");
        },
      });
    } else {
      Modal.confirm({
        title: (
          <>
            Leaving <strong>{templateName}</strong>
          </>
        ),
        content: (
          <>
            Are you sure you want to leave <strong>{templateName}</strong> ?{" "}
          </>
        ),
        okText: "Yes",
        cancelText: "No",
        onOk() {
          if (props.changeMode && typeof props.changeMode === "function") {
            props.changeMode(null);
          }
          localStorage.removeItem("industry_mapping");
        },
      });
    }
    // } else {
    //   if (
    //     form.getFieldsValue().template_name !== templatedefaultName ||
    //     Object.keys(industryRemapRef.current)?.length > 0 && selectedindustrycode===null
    //   ) {
    //     Modal.confirm({
    //       title: (
    //         <>
    //           Leaving <strong>{templateName}</strong>
    //         </>
    //       ),
    //       content: (
    //         <>
    //           Are you sure you want to leave <strong>{templateName}</strong> ?{" "}
    //         </>
    //       ),
    //       okText: "Save and Close",
    //       cancelText: "Remain on page",
    //       onOk() {
    //         if (
    //           form.getFieldsValue().template_name !== templatedefaultName ||
    //           Object.keys(industryRemapRef.current)?.length > 0
    //         ) {
    //           closeRef.current = true;
    //           handleEditDataUpdate();
    //           Events.on("changes", () => {
    //             return Events.off("changes", () => {});
    //           });
    //           Events.on("triggerClose", () => {
    //             return Events.off("triggerClose", () => {});
    //           });
    //         }
    //       },
    //     });
    //   } else {
    //     Modal.confirm({
    //       title: (
    //         <>
    //           Leaving <strong>{templateName}</strong>
    //         </>
    //       ),
    //       content: (
    //         <>
    //           Are you sure you want to leave <strong>{templateName}</strong> ?{" "}
    //         </>
    //       ),
    //       okText: "Yes",
    //       cancelText: "No",
    //       onOk() {
    //         if (props.changeMode && typeof props.changeMode === "function") {
    //           props.changeMode(null);
    //         }
    //       },
    //     });
    //   }
    // }
  };

  const handleRefClose = () => {
    let redirect: any = localStorage.getItem("redirect");
    let parsedData = JSON.parse(redirect);
    if (selectednewindustrycodeRef.current !== null) {
      if (selectedOptionRef.current.length > 0) {
        Modal.confirm({
          title: (
            <>
              Leaving <strong>{tempTemplateNameRef.current}</strong>
            </>
          ),
          content: (
            <>
              Are you sure you want to leave{" "}
              <strong>{tempTemplateNameRef.current}</strong> ?{" "}
            </>
          ),
          okText: "Save and Close",
          cancelText: "Remain on page",
          onOk() {
            if (
              tempTemplateNameRef.current !==
                tempDefaultTemplateNameRef.current ||
              Object.keys(industryRemapRef.current)?.length > 0
            ) {
              closeRef.current = true;
              handleRefEditDataUpdate();
              localStorage.removeItem("template");
            }
          },
        });
      } else {
        Modal.confirm({
          title: (
            <>
              Leaving <strong>{tempTemplateNameRef.current}</strong>
            </>
          ),
          content: (
            <>
              Are you sure you want to leave{" "}
              <strong>{tempTemplateNameRef.current}</strong> ?{" "}
            </>
          ),
          okText: "Yes",
          cancelText: "No",
          onOk() {
            // if (props.changeMode && typeof props.changeMode === "function") {
            //   props.changeMode(null);
            // }
            localStorage.removeItem("industry_mapping");
            setSeletedNewIndustryCode(null);
            navigate(`/${parsedData.navigate}`, {
              replace: parsedData.replace,

              ...(parsedData.state
                ? {
                    state: parsedData.state,
                  }
                : {}),
            });
            localStorage.removeItem("redirect");
            localStorage.removeItem("template");
          },
        });
      }
    } else {
      Modal.confirm({
        title: (
          <>
            Leaving <strong>{tempTemplateNameRef.current}</strong>
          </>
        ),
        content: (
          <>
            Are you sure you want to leave{" "}
            <strong>{tempTemplateNameRef.current}</strong> ?{" "}
          </>
        ),
        okText: "Yes",
        cancelText: "No",
        onOk() {
          // if (props.changeMode && typeof props.changeMode === "function") {
          //   props.changeMode(null);
          // }
          localStorage.removeItem("industry_mapping");
          setSeletedNewIndustryCode(null);
          navigate(`/${parsedData.navigate}`, {
            replace: parsedData.replace,

            ...(parsedData.state
              ? {
                  state: parsedData.state,
                }
              : {}),
          });
          localStorage.removeItem("redirect");
          localStorage.removeItem("template");
        },
      });
    }
  };

  const handleRefEditClose = () => {
    // console.log("templateName1", tempTemplateNameRef.current);
    // console.log(
    //   "tempDefaultTemplateNameRef",
    //   tempDefaultTemplateNameRef.current
    // );
    // console.log("selectedindustrycodeRef", selectedindustrycodeRef.current);
    // console.log(
    //   "selectednewindustrycodeRef",
    //   selectednewindustrycodeRef.current
    // );
    // console.log("fname", form.getFieldsValue().template_name);
    let redirect: any = localStorage.getItem("redirect");
    let parsedData = JSON.parse(redirect);
    if (
      (tempTemplateNameRef.current !== tempDefaultTemplateNameRef.current &&
        selectedindustrycodeRef.current === null &&
        selectednewindustrycodeRef.current !== null &&
        selectedOptionRef.current.length > 0) ||
      (tempTemplateNameRef.current !== tempDefaultTemplateNameRef.current &&
        selectedindustrycodeRef.current !== null &&
        selectednewindustrycodeRef.current === null) ||
      (selectedindustrycodeRef.current !== null &&
        Object.keys(industryRemapRef.current).length > 0 &&
        selectednewindustrycodeRef.current === null) ||
      (selectedindustrycodeRef.current === null &&
        Object.keys(industryRemapRef.current).length > 0 &&
        selectednewindustrycodeRef.current !== null)
    ) {
      Modal.confirm({
        title: (
          <>
            Leaving <strong>{tempTemplateNameRef.current}</strong>
          </>
        ),
        content: (
          <>
            Are you sure you want to leave{" "}
            <strong>{tempTemplateNameRef.current}</strong> ?{" "}
          </>
        ),
        okText: "Save and Close",
        cancelText: "Remain on page",
        onOk() {
          if (
            tempTemplateNameRef.current !==
              tempDefaultTemplateNameRef.current ||
            Object.keys(industryRemapRef.current)?.length > 0
          ) {
            closeRef.current = true;
            handleRefEditDataUpdate();
            localStorage.removeItem("template");
          }
        },
      });
    } else if (
      (tempTemplateNameRef.current !== tempDefaultTemplateNameRef.current &&
        selectedindustrycodeRef.current === null &&
        selectednewindustrycodeRef.current !== null &&
        selectedOptionRef.current.length === 0) ||
      (selectedindustrycodeRef.current === null &&
        selectednewindustrycodeRef.current === null &&
        selectedOptionRef.current.length === 0) ||
      (tempTemplateNameRef.current !== tempDefaultTemplateNameRef.current &&
        selectedindustrycodeRef.current === null &&
        selectednewindustrycodeRef.current === null)
    ) {
      Modal.confirm({
        title: (
          <>
            Leaving <strong>{tempTemplateNameRef.current}</strong>
          </>
        ),
        content: (
          <>
            Are you sure you want to leave{" "}
            <strong>{tempTemplateNameRef.current}</strong> ?{" "}
          </>
        ),
        okText: "Yes",
        cancelText: "No",
        onOk() {
          console.log("check");
          // if (props.changeMode && typeof props.changeMode === "function") {
          //   props.changeMode(null);
          // }
          localStorage.removeItem("industry_mapping");
          setSeletedNewIndustryCode(null);
          navigate(`/${parsedData.navigate}`, {
            replace: parsedData.replace,

            ...(parsedData.state
              ? {
                  state: parsedData.state,
                }
              : {}),
          });
          localStorage.removeItem("redirect");
          localStorage.removeItem("template");
        },
      });
    } else {
      Modal.confirm({
        title: (
          <>
            Leaving <strong>{tempTemplateNameRef.current}</strong>
          </>
        ),
        content: (
          <>
            Are you sure you want to leave{" "}
            <strong>{tempTemplateNameRef.current}</strong> ?{" "}
          </>
        ),
        okText: "Yes",
        cancelText: "No",
        onOk() {
          console.log("check");
          // if (props.changeMode && typeof props.changeMode === "function") {
          //   props.changeMode(null);
          // }
          localStorage.removeItem("industry_mapping");
          setSeletedNewIndustryCode(null);
          navigate(`/${parsedData.navigate}`, {
            replace: parsedData.replace,

            ...(parsedData.state
              ? {
                  state: parsedData.state,
                }
              : {}),
          });
          localStorage.removeItem("redirect");
          localStorage.removeItem("template");
        },
      });
    }
  };

  const checkIndustryCode = (d: any) => {
    if (d) {
      return d < 10 ? d.toString().padStart(2, "0") : d.toString();
    } else {
      return "00";
    }
  };

  const handleSelectedOption = (d: any) => {
    const combinedArray = Object.values(d).flatMap((values) => values);
    selectedOptionRef.current = combinedArray;
    industryRemapRef.current = d;
    updatenewData(d);
  };

  const handleEditSelectedOptions = (d: any) => {
    const combinedArray = Object.values(d).flatMap((values) => values);
    const combinedAndUniqueArray = Array.from(
      new Set([...selectedOptionRef.current, ...combinedArray])
    );
    // console.log("combinedAndUniqueArray", combinedAndUniqueArray);
    // console.log("d", d);
    selectedOptionRef.current = combinedAndUniqueArray;
    industryRemapRef.current = d;
    updatenewData(d);
  };

  const handleDeSelectedOptions = (d: any) => {
    // console.log("d", d);
    if (d) {
      notification.success({
        message: "Code - Industry removed!",
        description: (
          <>
            <strong>{d}</strong> has been removed successfully.
          </>
        ),
        placement: "bottomRight",
        bottom: 50,
        duration: 3,
      });
    }

    // console.log("selection45345", selectedOption);
    const updatedSelectedOptions = selectedOptionRef.current.filter(
      (option: any) => {
        return option !== d;
      }
    );
    // console.log("updatedSelectedOptions", updatedSelectedOptions);
    selectedOptionRef.current = updatedSelectedOptions;
  };

  const updatenewData = (d: any) => {
    // console.log("data", data);
    const updatedData = data.map((item: any) => {
      if (d.hasOwnProperty(item._id)) {
        item._code = d[item._id];
      }
      return item;
    });
    setData(updatedData);
  };

  const handleUpdate = () => {
    form.submit();
    // console.log("industryremap", industryRemapRef.current);
    const existsInArray = listRIC.some(
      (obj: any) => obj.name === form.getFieldsValue().template_name
    );
    if (
      !existsInArray &&
      selectednewindustrycode !== null &&
      form.getFieldsValue().template_name !== null &&
      selectedOptionRef.current.length > 0
    ) {
      if (Object.keys(industryRemapRef.current)?.length === data?.length) {
        let p = {
          name: templateName,
          code: selectednewindustrycode,
          draft: false,
        };
        saveTemplate(p).then((res: any) => {
          if (res.data) {
            notification.success({
              message: `Industry Template created!`,
              description: (
                <>
                  <strong>{templateName}</strong> has been created successfully.
                </>
              ),
              placement: "bottomRight",
              bottom: 50,
            });
            navigate("/industry_mapping", {
              replace: true,
              state: {
                mode: "edittemplate",
                refresh: false,
                name: templateName,
                _id: templateName,
                data: [res.data],
              },
            });
          } else {
            notification.error({
              message: `Failed to create Industry Template!`,
              description: (
                <>
                  <strong>{templateName}</strong> has not been created.
                </>
              ),
              placement: "bottomRight",
              bottom: 50,
            });
          }
        });
      } else {
        let p = {
          name: templateName,
          code: selectednewindustrycode,
          draft: true,
        };
        saveTemplate(p).then((res: any) => {
          if (res.data) {
            notification.success({
              message: `Industry Template created!`,
              description: (
                <>
                  <strong>{templateName}</strong> has been created successfully.
                </>
              ),
              placement: "bottomRight",
              bottom: 50,
            });
            navigate("/industry_mapping", {
              replace: true,
              state: {
                mode: "edittemplate",
                refresh: false,
                name: templateName,
                _id: templateName,
                data: [res.data],
              },
            });
          } else {
            notification.error({
              message: `Failed to create Industry Template!`,
              description: (
                <>
                  <strong>{templateName}</strong> has not been created.
                </>
              ),
              placement: "bottomRight",
              bottom: 50,
            });
          }
        });
      }

      let q = {
        name: templateName,
        code: selectednewindustrycode,
        remap: industryRemapRef.current,
      };
      updateRICindustry(q).then((res: any) => {
        if (res.data) {
          notification.success({
            message: `Industry Template created!`,
            description: (
              <>
                <strong>{templateName}</strong> has been created successfully.
              </>
            ),
            placement: "bottomRight",
            bottom: 50,
          });
          industryRemapRef.current = [];
        } else {
          notification.error({
            message: `Failed to create Industry Template!`,
            description: (
              <>
                <strong>{templateName}</strong> has not been created.
              </>
            ),
            placement: "bottomRight",
            bottom: 50,
          });
        }
      });
    }
    if (selectedOptionRef.current.length === 0) {
      Modal.warning({
        title: "Unable to add save tab",
        content: "Please make sure at least 1 row of code industry is filled.",
        okText: "Ok",
        onOk() {},
      });
    }
  };

  const handleEditDataUpdate = async () => {
    // setRefreshRIC(true);
    // console.log("form.submit", form.submit());
    if (validate.current === true) {
    } else {
      localStorage.removeItem("industry_mapping");

      if (selectednewindustrycode !== null) {
        if (selectedOptionRef.current.length > 0) {
          form.submit();
          let p: any = {
            name: templatedefaultName,
            code: selectednewindustrycode,
          };
          if (templatedefaultName !== templateName) {
            p.newname = templateName;
          }
          if (Object.keys(industryRemapRef.current)?.length === data?.length) {
            p.draft = false;
            saveTemplate(p).then((res: any) => {
              if (res.data) {
                if (createmode === true) {
                  let user: any = localStorage.getItem("user");
                  let log: any = {
                    page: window.location.pathname,
                    action: "Create",
                    datetime: new Date(),
                    details: `Create Template Configuration ${templateName}`,
                    email: user,
                  };
                  addLog(log);
                } else {
                  let user: any = localStorage.getItem("user");
                  let log: any = {
                    page: window.location.pathname,
                    action: "Update",
                    datetime: new Date(),
                    details: `Update Template Configuration ${templateName}`,
                    email: user,
                  };
                  addLog(log);
                }
                notification.success({
                  message: `Industry Template created!`,
                  description: (
                    <>
                      <strong>{templateName}</strong> has been created
                      successfully.
                    </>
                  ),
                  placement: "bottomRight",
                  bottom: 50,
                });
                getAllRICIndustry().then((res: any) => {
                  // setlistRIC(res.data);
                  const newsub: any = res.data.filter(
                    (item: any) => item.name === templateName
                  );
                  if (closeRef.current === true) {
                    navigate("/industry_mapping", {
                      replace: true,
                      state: {
                        mode: "edittemplate",
                        refresh: true,
                        code: selectednewindustrycode,
                        name: templateName,
                        data: newsub,
                      },
                    });
                  } else {
                    navigate("/industry_mapping", {
                      replace: true,
                      state: {
                        mode: "edittemplate",
                        refresh: true,
                        code: selectednewindustrycode,
                        name: templateName,
                        data: newsub,
                      },
                    });
                  }
                });
              } else {
                notification.error({
                  message: `Failed to create Industry Template!`,
                  description: (
                    <>
                      <strong>{templateName}</strong> has not been created.
                    </>
                  ),
                  placement: "bottomRight",
                  bottom: 50,
                });
              }
            });
          } else {
            p.draft = true;
            saveTemplate(p).then((res: any) => {
              if (res.data) {
                if (createmode === true) {
                  let user: any = localStorage.getItem("user");
                  let log: any = {
                    page: window.location.pathname,
                    action: "Create",
                    datetime: new Date(),
                    details: `Create Template Configuration ${templateName}`,
                    email: user,
                  };
                  addLog(log);
                } else {
                  let user: any = localStorage.getItem("user");
                  let log: any = {
                    page: window.location.pathname,
                    action: "Update",
                    datetime: new Date(),
                    details: `Update Template Configuration ${templateName}`,
                    email: user,
                  };
                  addLog(log);
                }
                notification.success({
                  message: `Industry Template created!`,
                  description: (
                    <>
                      <strong>{templateName}</strong> has been created
                      successfully.
                    </>
                  ),
                  placement: "bottomRight",
                  bottom: 50,
                });
                getAllRICIndustry().then((res: any) => {
                  // setlistRIC(res.data);
                  const newsub: any = res.data.filter(
                    (item: any) => item.name === templateName
                  );
                  if (closeRef.current === true) {
                    navigate("/industry_mapping", {
                      replace: true,
                      state: {
                        mode: "edittemplate",
                        refresh: true,
                        code: selectednewindustrycode,
                        name: templateName,
                        data: newsub,
                      },
                    });
                  } else {
                    navigate("/industry_mapping", {
                      replace: true,
                      state: {
                        mode: "edittemplate",
                        refresh: true,
                        code: selectednewindustrycode,
                        name: templateName,
                        data: newsub,
                      },
                    });
                  }
                });
              } else {
                notification.error({
                  message: `Failed to create Industry Template!`,
                  description: (
                    <>
                      <strong>{templateName}</strong> has not been created.
                    </>
                  ),
                  placement: "bottomRight",
                  bottom: 50,
                });
              }
            });
          }

          let q: any = {
            name: templatedefaultName,
            newcode: selectednewindustrycode,
            remap: industryRemapRef.current,
          };
          if (templatedefaultName !== templateName) {
            q.newname = templateName;
          }
          updateEditRICindustry(q).then((res: any) => {
            // if (Object.keys(industryRemapRef.current).length > 0) {
            if (res.success === true) {
              notification.success({
                message: `Industry Template saved!`,
                description: (
                  <>
                    <strong>{templateName}</strong> industry mapping has been
                    saved successfully.
                  </>
                ),
                placement: "bottomRight",
                bottom: 50,
              });
            } else {
              notification.error({
                message: `Failed to save ${templateName} industry mapping!`,
                description: (
                  <>
                    <strong>{templateName}</strong> industry mapping has not
                    been saved.
                  </>
                ),
                placement: "bottomRight",
                bottom: 50,
              });
            }
            // }
          });
          industryRemapRef.current = [];
          setSeletedNewIndustryCode(null);
        }
      } else if (selectedOptionRef.current.length === 0) {
        Modal.warning({
          title: "Unable to add save tab",
          content:
            "Please make sure at least 1 row of code industry is filled.",
          okText: "Ok",
          onOk() {},
        });
      } else {
        if (
          form.getFieldsValue().template_name !== templatedefaultName ||
          Object.keys(industryRemapRef.current)?.length > 0
        ) {
          form.submit();
          let p: any = {
            name: templatedefaultName,
            code: selectedindustrycode,
          };
          if (templatedefaultName !== templateName) {
            p.newname = templateName;
          }
          if (p.newname) {
            saveEdittemplate(p).then((res: any) => {
              if (res.data) {
                if (createmode === true) {
                  let user: any = localStorage.getItem("user");
                  let log: any = {
                    page: window.location.pathname,
                    action: "Create",
                    datetime: new Date(),
                    details: `Create Template Configuration ${templateName}`,
                    email: user,
                  };
                  addLog(log);
                } else {
                  let user: any = localStorage.getItem("user");
                  let log: any = {
                    page: window.location.pathname,
                    action: "Update",
                    datetime: new Date(),
                    details: `Update Template Configuration ${templateName}`,
                    email: user,
                  };
                  addLog(log);
                }
                notification.success({
                  message: `Industry Template saved!`,
                  description: (
                    <>
                      <strong>{templateName}</strong> has been saved
                      successfully.
                    </>
                  ),
                  placement: "bottomRight",
                  bottom: 50,
                });

                getAllRICIndustry().then((res: any) => {
                  // setlistRIC(res.data);
                  const newsub: any = res.data.filter(
                    (item: any) => item.name === templateName
                  );
                  if (closeRef.current === true) {
                    navigate("/industry_mapping", {
                      replace: true,
                      state: {
                        mode: "edittemplate",
                        refresh: false,
                        code: selectedindustrycode,
                        name: templateName,
                        data: newsub,
                      },
                    });
                  } else {
                    navigate("/industry_mapping", {
                      replace: true,
                      state: {
                        mode: "edittemplate",
                        refresh: false,
                        code: selectedindustrycode,
                        name: templateName,
                        data: newsub,
                      },
                    });
                  }
                });
              } else {
                notification.error({
                  message: `Failed to saved Industry Template!`,
                  description: (
                    <>
                      <strong>{templateName}</strong> has not been saved.
                    </>
                  ),
                  placement: "bottomRight",
                  bottom: 50,
                });
              }
            });
          }
          let q: any = {
            name: templatedefaultName,
            code: selectedindustrycode,
            remap: industryRemapRef.current,
          };
          if (templatedefaultName !== templateName) {
            q.newname = templateName;
          }
          updateEditRICindustry(q).then((res: any) => {
            // console.log("hh", industryRemapRef.current);
            if (res.success === true) {
              notification.success({
                message: `Industry Template saved!`,
                description: (
                  <>
                    <strong>{templateName}</strong> industry mapping has been
                    saved successfully.
                  </>
                ),
                placement: "bottomRight",
                bottom: 50,
              });
              getAllRICIndustry().then((res: any) => {
                // setlistRIC(res.data);
                const newsub: any = res.data.filter(
                  (item: any) => item.name === templateName
                );
                if (closeRef.current === true) {
                  navigate("/industry_mapping", {
                    replace: true,
                    state: {
                      mode: "edittemplate",
                      refresh: false,
                      code: selectedindustrycode,
                      name: templateName,
                      data: newsub,
                    },
                  });
                } else {
                  navigate("/industry_mapping", {
                    replace: true,
                    state: {
                      mode: "edittemplate",
                      refresh: false,
                      code: selectedindustrycode,
                      name: templateName,
                      data: newsub,
                    },
                  });
                }
              });
            } else {
              notification.error({
                message: `Failed to save ${templateName} industry mapping!`,
                description: (
                  <>
                    <strong>{templateName}</strong> industry mapping has not
                    been saved.
                  </>
                ),
                placement: "bottomRight",
                bottom: 50,
              });
            }
          });
          industryRemapRef.current = [];
        }
      }
    }
    localStorage.removeItem("redirect");
    // form.submit();
    // const existsInArray = listRIC.some(
    //   (obj: any) => obj.name === form.getFieldsValue().template_name
    // );
    // if (!existsInArray) {

    // }
    // else {
    //   Modal.warning({
    //     title: "Unable to save",
    //     content: "Template Configuration name already exists.",
    //     okText: "Ok",
    //     onOk() {},
    //   });
    // }
  };

  const handleRefEditDataUpdate = async () => {
    setRefreshRIC(true);
    // form.submit();
    let redirect: any = localStorage.getItem("redirect");
    let parsedData = JSON.parse(redirect);
    if (validate.current === true) {
    } else {
      if (selectednewindustrycodeRef.current !== null) {
        if (selectedOptionRef.current.length > 0) {
          form.submit();
          let p: any = {
            name: tempDefaultTemplateNameRef.current,
            code: selectednewindustrycodeRef.current,
          };
          if (
            tempDefaultTemplateNameRef.current !== tempTemplateNameRef.current
          ) {
            p.newname = tempTemplateNameRef.current;
          }
          if (Object.keys(industryRemapRef.current)?.length === data?.length) {
            p.draft = false;
            saveTemplate(p).then((res: any) => {
              if (res.data) {
                notification.success({
                  message: `Industry Template created!`,
                  description: (
                    <>
                      <strong>{tempTemplateNameRef.current}</strong> has been
                      created successfully.
                    </>
                  ),
                  placement: "bottomRight",
                  bottom: 50,
                });
                getAllRICIndustry().then((res: any) => {
                  // setlistRIC(res.data);
                  const newsub: any = res.data.filter(
                    (item: any) => item.name === tempTemplateNameRef.current
                  );
                  if (closeRef.current === true) {
                    localStorage.removeItem("industry_mapping");

                    navigate(`/${parsedData.navigate}`, {
                      replace: parsedData.replace,

                      ...(parsedData.state
                        ? {
                            state: parsedData.state,
                          }
                        : {}),
                    });
                  } else {
                    navigate("/industry_mapping", {
                      replace: true,
                      state: {
                        mode: "edittemplate",
                        refresh: true,
                        code: selectednewindustrycodeRef.current,
                        name: tempTemplateNameRef.current,
                        data: newsub,
                      },
                    });
                  }
                });
              } else {
                notification.error({
                  message: `Failed to create Industry Template!`,
                  description: (
                    <>
                      <strong>{tempTemplateNameRef.current}</strong> has not
                      been created.
                    </>
                  ),
                  placement: "bottomRight",
                  bottom: 50,
                });
              }
            });
          } else {
            p.draft = true;
            saveTemplate(p).then((res: any) => {
              if (res.data) {
                notification.success({
                  message: `Industry Template created!`,
                  description: (
                    <>
                      <strong>{tempTemplateNameRef.current}</strong> has been
                      created successfully.
                    </>
                  ),
                  placement: "bottomRight",
                  bottom: 50,
                });
                getAllRICIndustry().then((res: any) => {
                  // setlistRIC(res.data);
                  const newsub: any = res.data.filter(
                    (item: any) => item.name === tempTemplateNameRef.current
                  );
                  if (closeRef.current === true) {
                    // localStorage.removeItem("industry_mapping");
                    // localStorage.removeItem("redirect");
                    // navigate("/industry_mapping", {
                    //   replace: true,
                    //   state: {
                    //     mode: null,
                    //     refresh: true,
                    //   },
                    // });
                    localStorage.removeItem("industry_mapping");
                    // let redirect: any = localStorage.getItem("redirect");
                    // let parsedData = JSON.parse(redirect);
                    // navigate(`/${parsedData.navigate}`, {
                    //   replace: parsedData.replace,

                    //   ...(parsedData.state
                    //     ? {
                    //         state: {
                    //           ...(parsedData.mode
                    //             ? { mode: parsedData.mode }
                    //             : {}),
                    //           ...(parsedData.name
                    //             ? { name: parsedData.name }
                    //             : {}),
                    //           ...(parsedData.data
                    //             ? { data: parsedData.data }
                    //             : {}),
                    //           ...(parsedData._id
                    //             ? { _id: parsedData._id }
                    //             : {}),
                    //           ...(parsedData.key
                    //             ? { key: parsedData.key }
                    //             : {}),
                    //         },
                    //       }
                    //     : {}),
                    // });
                    navigate(`/${parsedData.navigate}`, {
                      replace: parsedData.replace,

                      ...(parsedData.state
                        ? {
                            state: parsedData.state,
                          }
                        : {}),
                    });
                  } else {
                    navigate("/industry_mapping", {
                      replace: true,
                      state: {
                        mode: "edittemplate",
                        refresh: true,
                        code: selectednewindustrycodeRef.current,
                        name: tempTemplateNameRef.current,
                        data: newsub,
                      },
                    });
                  }
                });
              } else {
                notification.error({
                  message: `Failed to create Industry Template!`,
                  description: (
                    <>
                      <strong>{tempTemplateNameRef.current}</strong> has not
                      been created.
                    </>
                  ),
                  placement: "bottomRight",
                  bottom: 50,
                });
              }
            });
          }

          let q: any = {
            name: tempDefaultTemplateNameRef.current,
            newcode: selectednewindustrycodeRef.current,
            remap: industryRemapRef.current,
          };
          if (
            tempDefaultTemplateNameRef.current !== tempTemplateNameRef.current
          ) {
            q.newname = tempTemplateNameRef.current;
          }
          updateEditRICindustry(q).then((res: any) => {
            // if (Object.keys(industryRemapRef.current).length > 0) {
            if (res.success === true) {
              notification.success({
                message: `Industry Template saved!`,
                description: (
                  <>
                    <strong>{tempTemplateNameRef.current}</strong> industry
                    mapping has been saved successfully.
                  </>
                ),
                placement: "bottomRight",
                bottom: 50,
              });
            } else {
              notification.error({
                message: `Failed to save ${tempTemplateNameRef.current} industry mapping!`,
                description: (
                  <>
                    <strong>{tempTemplateNameRef.current}</strong> industry
                    mapping has not been saved.
                  </>
                ),
                placement: "bottomRight",
                bottom: 50,
              });
            }
            // }
          });
          industryRemapRef.current = [];
          setSeletedNewIndustryCode(null);
        } else {
          localStorage.removeItem("industry_mapping");

          navigate(`/${parsedData.navigate}`, {
            replace: parsedData.replace,

            ...(parsedData.state
              ? {
                  state: parsedData.state,
                }
              : {}),
          });
        }
      } else if (selectedOptionRef.current.length === 0) {
        Modal.warning({
          title: "Unable to add save tab",
          content:
            "Please make sure at least 1 row of code industry is filled.",
          okText: "Ok",
          onOk() {},
        });
      } else {
        if (
          tempTemplateNameRef.current !== tempDefaultTemplateNameRef.current ||
          Object.keys(industryRemapRef.current)?.length > 0
        ) {
          form.submit();
          let p: any = {
            name: tempDefaultTemplateNameRef.current,
            code: selectedindustrycodeRef.current,
          };
          if (
            tempDefaultTemplateNameRef.current !== tempTemplateNameRef.current
          ) {
            p.newname = tempTemplateNameRef.current;
          }
          if (p.newname) {
            saveEdittemplate(p).then((res: any) => {
              if (res.data) {
                notification.success({
                  message: `Industry Template saved!`,
                  description: (
                    <>
                      <strong>{tempTemplateNameRef.current}</strong> has been
                      saved successfully.
                    </>
                  ),
                  placement: "bottomRight",
                  bottom: 50,
                });

                getAllRICIndustry().then((res: any) => {
                  // setlistRIC(res.data);
                  const newsub: any = res.data.filter(
                    (item: any) => item.name === tempTemplateNameRef.current
                  );
                  if (closeRef.current === true) {
                    // console.log("weird12");
                    // localStorage.removeItem("industry_mapping");
                    // localStorage.removeItem("redirect");
                    // navigate("/industry_mapping", {
                    //   replace: true,
                    //   state: {
                    //     mode: null,
                    //     refresh: true,
                    //   },
                    // });

                    localStorage.removeItem("industry_mapping");
                    // let redirect: any = localStorage.getItem("redirect");
                    // let parsedData = JSON.parse(redirect);
                    // navigate(`/${parsedData.navigate}`, {
                    //   replace: parsedData.replace,

                    //   ...(parsedData.state
                    //     ? {
                    //         state: {
                    //           ...(parsedData.mode
                    //             ? { mode: parsedData.mode }
                    //             : {}),
                    //           ...(parsedData.name
                    //             ? { name: parsedData.name }
                    //             : {}),
                    //           ...(parsedData.data
                    //             ? { data: parsedData.data }
                    //             : {}),
                    //           ...(parsedData._id
                    //             ? { _id: parsedData._id }
                    //             : {}),
                    //           ...(parsedData.key
                    //             ? { key: parsedData.key }
                    //             : {}),
                    //         },
                    //       }
                    //     : {}),
                    // });
                    navigate(`/${parsedData.navigate}`, {
                      replace: parsedData.replace,

                      ...(parsedData.state
                        ? {
                            state: parsedData.state,
                          }
                        : {}),
                    });
                  } else {
                    navigate("/industry_mapping", {
                      replace: true,
                      state: {
                        mode: "edittemplate",
                        refresh: false,
                        code: selectedindustrycodeRef.current,
                        name: tempTemplateNameRef.current,
                        data: newsub,
                      },
                    });
                  }
                });
              } else {
                notification.error({
                  message: `Failed to save Industry Template!`,
                  description: (
                    <>
                      <strong>{tempTemplateNameRef.current}</strong> has not
                      been saved.
                    </>
                  ),
                  placement: "bottomRight",
                  bottom: 50,
                });
              }
            });
          }
          let q: any = {
            name: tempDefaultTemplateNameRef.current,
            code: selectedindustrycodeRef.current,
            remap: industryRemapRef.current,
          };
          if (
            tempDefaultTemplateNameRef.current !== tempTemplateNameRef.current
          ) {
            q.newname = tempTemplateNameRef.current;
          }
          updateEditRICindustry(q).then((res: any) => {
            // console.log("hh", industryRemapRef.current);
            if (res.success === true) {
              notification.success({
                message: `Industry Template saved!`,
                description: (
                  <>
                    <strong>{tempTemplateNameRef.current}</strong> industry
                    mapping has been saved successfully.
                  </>
                ),
                placement: "bottomRight",
                bottom: 50,
              });
              getAllRICIndustry().then((res: any) => {
                // setlistRIC(res.data);
                const newsub: any = res.data.filter(
                  (item: any) => item.name === tempTemplateNameRef.current
                );
                if (closeRef.current === true) {
                  // localStorage.removeItem("industry_mapping");
                  // localStorage.removeItem("redirect");
                  // navigate("/industry_mapping", {
                  //   replace: true,
                  //   state: {
                  //     mode: null,
                  //     refresh: true,
                  //   },
                  // });
                  localStorage.removeItem("industry_mapping");
                  // let redirect: any = localStorage.getItem("redirect");
                  // let parsedData = JSON.parse(redirect);
                  // navigate(`/${parsedData.navigate}`, {
                  //   replace: parsedData.replace,
                  //   ...(parsedData.state
                  //     ? {
                  //         state: {
                  //           ...(parsedData.mode
                  //             ? { mode: parsedData.mode }
                  //             : null),
                  //           ...(parsedData.name
                  //             ? { name: parsedData.name }
                  //             : null),
                  //           ...(parsedData.data
                  //             ? { data: parsedData.data }
                  //             : null),
                  //           ...(parsedData._id
                  //             ? { _id: parsedData._id }
                  //             : null),
                  //           // ...(parsedData.key ? { key: parsedData.key } : {}),
                  //         },
                  //       }
                  //     : null),
                  // });
                  navigate(`/${parsedData.navigate}`, {
                    replace: parsedData.replace,

                    ...(parsedData.state
                      ? {
                          state: parsedData.state,
                        }
                      : {}),
                  });
                } else {
                  navigate("/industry_mapping", {
                    replace: true,
                    state: {
                      mode: "edittemplate",
                      refresh: false,
                      code: selectedindustrycodeRef.current,
                      name: tempTemplateNameRef.current,
                      data: newsub,
                    },
                  });
                }
              });
            } else {
              notification.error({
                message: `Failed to save ${tempTemplateNameRef.current} industry mapping!`,
                description: (
                  <>
                    <strong>{tempTemplateNameRef.current}</strong> industry
                    mapping has not been saved.
                  </>
                ),
                placement: "bottomRight",
                bottom: 50,
              });
            }
          });
          industryRemapRef.current = [];
        }
      }
    }
    // const existsInArray = listRIC.some(
    //   (obj: any) => obj.name === form.getFieldsValue().template_name
    // );
    // if (!existsInArray) {

    // }
    // else {
    //   Modal.warning({
    //     title: "Unable to save",
    //     content: "Template Configuration name already exists.",
    //     okText: "Ok",
    //     onOk() {},
    //   });
    // }
    localStorage.removeItem("industry_mapping");
    localStorage.removeItem("redirect");
  };

  const handleRefreshSelect = (d: any) => {
    setLoading(true);
    const updateData = data.map((item: any) => {
      if (item._id === d._id) {
        return {
          ...item,
          _code: d._code,
        };
      } else {
        return item;
      }
    });
    setData(updateData);
    setLoading(false);
  };

  const breadcrumbCOM = (el: any) => {
    if (typeof el === "string") {
      return <React.Fragment key={el}>{ComRenderRow(el, el)}</React.Fragment>;
    } else {
      if (el) {
        return el.map((d: any, index: any) => {
          return (
            <React.Fragment key={d._id}>
              {ComRenderRow(d._id, index)}
            </React.Fragment>
          );
        });
      }
    }
  };

  const ComRenderRow = (d: any, index: any) => {
    if (d) {
      if (index === 0) {
        return (
          <>
            <Breadcrumb.Item>
              <a>{d}</a>
            </Breadcrumb.Item>
          </>
        );
      }
      return (
        <>
          <Breadcrumb.Item>
            <a>{d}</a>
          </Breadcrumb.Item>
        </>
      );
    }
  };

  const formData = [
    {
      key: "template_name",
      name: "template_name",
      label: "Template Configuration",
      labelCol: { style: { paddingLeft: "5%", fontWeight: "600" } },
      rules: [
        {
          required: true,
          message: "Template Configuration Name cannot be empty",
        },
        {
          validator: (rule: any, value: any) => {
            // const existsInArray = listRIC.some(
            //   (obj: any) => obj.name === value
            // );
            const existsInArray = listRIC.some(
              (obj: any) => obj.name === value
            );
            if (existsInArray) {
              validate.current = true;
              return Promise.reject(`${value} already exists`);
            } else {
              validate.current = false;
              return Promise.resolve();
            }
          },
        },
      ],
      component: (
        // <div className="input-form">
        //   <label className="label-form">Template Configuration</label>
        <Input
          placeholder="Enter Template Configuration Name"
          value={templateName}
          style={{ marginRight: "50px", width: "99%" }}
          onChange={(e: any) => {
            settemplateName(e.target.value);
          }}
        />
        // </div>
      ),
    },
    // {
    //   key: "industry_code",
    //   name: "industry_code",
    //   label: "Industry Code",
    //   initailValue: selectedindustrycode,
    //   labelCol: {
    //     style: { paddingLeft: "5%", paddingRight: "60px", fontWeight: "600" },
    //   },
    //   rules: [
    //     {
    //       required: true,
    //       message: "Industry Code cannot be empty",
    //     },
    //     {
    //       validator: (rule: any, value: any) => {
    //         const templateName = form.getFieldValue("template_name");
    //         if (templateName && !value) {
    //           return Promise.reject(
    //             "Industry Code is required after Template Configuration Name"
    //           );
    //         }
    //         return Promise.resolve();
    //       },
    //     },
    //   ],
    //   component: (
    //     <Select
    //       listHeight={160}
    //       getPopupContainer={(trigger) => trigger.parentElement}
    //       showSearch
    //       style={{
    //         width: "93.7%",
    //         height: "100%",
    //         textAlign: "left",
    //         border: "0px solid",
    //         marginRight: "50px",
    //       }}
    //       placeholder="Select Industry Code"
    //       optionFilterProp="children"
    //       onChange={selectRIC}
    //       value={selectedindustrycode}
    //       filterOption={(input: any, option: any) =>
    //         (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
    //       }
    //       options={ricparent}
    //     />
    //   ),
    // },
  ];

  const editformData = [
    {
      key: "template_name",
      name: "template_name",
      // initialValue: templateName,
      label: "Template Configuration",
      labelCol: { style: { paddingLeft: "5%", fontWeight: "600" } },
      rules: [
        {
          required: true,
          message: "Template Configuration Name cannot be empty",
        },
        {
          validator: (rule: any, value: any) => {
            if (value === props.templatedefaultName) {
              validate.current = false;
              return Promise.resolve();
            } else {
              const existsInArray = listRIC.some(
                (obj: any) => obj.name === value
              );
              if (existsInArray) {
                validate.current = true;
                return Promise.reject(`${value} already exists`);
              } else {
                validate.current = false;
                return Promise.resolve();
              }
            }
          },
        },
      ],
      component: (
        <Input
          placeholder="Enter Template Configuration Name"
          // value={templateName}
          style={{ marginRight: "50px", width: "99%" }}
          onChange={(e: any) => {
            settemplateName(e.target.value);
          }}
        />
      ),
    },
    // {
    //   key: "industry_code",
    //   name: "industry_code",
    //   label: "Industry Code",
    //   // initialValue: selectedindustrycode,
    //   labelCol: {
    //     style: { paddingLeft: "5%", paddingRight: "60px", fontWeight: "600" },
    //   },
    //   rules: [
    //     {
    //       required: true,
    //       message: "Industry Code cannot be empty",
    //     },
    //     {
    //       validator: (rule: any, value: any) => {
    //         const templateName = form.getFieldValue("template_name");
    //         if (templateName && !value) {
    //           return Promise.reject(
    //             "Industry Code is required after Template Configuration Name"
    //           );
    //         }
    //         return Promise.resolve();
    //       },
    //     },
    //   ],
    //   component: (
    //     <Select
    //       listHeight={160}
    //       getPopupContainer={(trigger) => trigger.parentElement}
    //       showSearch
    //       style={{
    //         width: "93.7%",
    //         height: "100%",
    //         textAlign: "left",
    //         border: "0px solid",
    //         marginRight: "50px",
    //       }}
    //       placeholder="Select Industry Code"
    //       optionFilterProp="children"
    //       onChange={selectRIC}
    //       value={selectedindustrycode}
    //       filterOption={(input: any, option: any) =>
    //         (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
    //       }
    //       options={ricparent}
    //     />
    //   ),
    // },
  ];

  const onEdit: any = (targetKey: string, action: "add" | "remove") => {
    if (action === "add") {
      if (props.templatedefaultName) {
        const hasNewTab = subcode.some((item: any) =>
          item.code.includes("New Tab")
        );
        if (hasNewTab) {
          Modal.warning({
            title: "Leave without saving?",
            content: "You have unsaved changes. Please save it before leaving",
            okText: "Ok",
            onOk() {
              // navigate("/industry_mapping", {
              //   replace: true,
              //   state: {
              //     mode: null,
              //   },
              // });
            },
          });
        } else {
          add();
        }
      } else {
        const hasNewTab = createsubcode.some((item: any) =>
          item.code.includes("New Tab")
        );
        if (hasNewTab) {
          Modal.warning({
            title: "Leave without saving?",
            content: "You have unsaved changes. Please save it before leaving",
            okText: "Ok",
            onOk() {},
          });
        } else {
          add();
        }
      }
    } else {
      remove(targetKey);
    }
  };

  const add = () => {
    if (props.templatedefaultName) {
      // setSeletedIndustryCode(null);
      if (Object.keys(industryRemapRef.current)?.length !== 0) {
        Modal.warning({
          title: "Unable to add new tab",
          content: "Please save your changes before switching tab.",
          okText: "Ok",
          onOk() {},
        });
      } else {
        setSeletedNewIndustryCode(null);
        setSeletedIndustryCode(null);
        const newActiveKey = "New Tab";
        const updatedData = subcode.map((item: any) => ({
          ...item,
          disabled: true,
        }));
        const newPanes = [...updatedData];
        newPanes.push({
          code: "New Tab",
          key: newActiveKey,
        });
        setSubCode(newPanes);
        setActiveKey(newActiveKey);
        setdropDownCodeIndustryOptions([]);
        refreshNewCode();
        selectedOptionRef.current = [];
        setRicChild([]);
      }
    } else {
      // setSeletedIndustryCode(null);
      // setSeletedNewIndustryCode(null);
      setSeletedNewIndustryCode(null);
      setdropDownCodeIndustryOptions([]);
      const newActiveKey = "New Tab";
      const newPanes = [...createsubcode];
      newPanes.push({
        code: "New Tab",
        key: newActiveKey,
      });
      setCreateSubCode(newPanes);
      setActiveKey(newActiveKey);
      refreshNewCode();
      selectedOptionRef.current = [];
      setRicChild([]);
    }
  };

  const remove = async (targetKey: string) => {
    // console.log("target", targetKey);
    if (props.templatedefaultName) {
      let newActiveKey = activeKey;
      let lastIndex = -1;
      subcode.forEach((item: any, i: any) => {
        if (item.code === targetKey) {
          lastIndex = i - 1;
        }
      });
      if (subcode.length <= 1) {
        Modal.warning({
          title: "Unable to delete",
          content: "At least 1 industry code needed inside the template",
          okText: "Ok",
          onOk() {},
        });
      } else {
        Modal.confirm({
          title: "Delete Industry Code",
          content: (
            <>
              Are you sure you want to delete <strong>{targetKey}</strong> ?{" "}
              <br></br>
              <br></br>
              Important note: Deleted Industry Code cannot be recovered.
            </>
          ),
          okText: "Ok",
          cancelText: "Cancel",
          onOk() {
            industryRemapRef.current = []; // Clear the array
            confirmDelete(targetKey);
          },
        });
      }
    } else {
      let newActiveKey = activeKey;
      let lastIndex = -1;
      createsubcode.forEach((item: any, i: any) => {
        if (item.code === targetKey) {
          lastIndex = i - 1;
        }
      });
      if (createsubcode.length <= 1) {
        Modal.warning({
          title: "Unable to delete",
          content: "At least 1 industry code needed inside the template",
          okText: "Ok",
          onOk() {},
        });
      } else {
        const newPanes = createsubcode.filter(
          (item: any) => item.code !== targetKey
        );
        if (newPanes.length && newActiveKey === targetKey) {
          if (lastIndex >= 0) {
            newActiveKey = newPanes[lastIndex].key;
          } else {
            newActiveKey = newPanes[0].key;
          }
        }
        setCreateSubCode(newPanes);
        setActiveKey(newActiveKey);
      }
    }
  };

  const confirmDelete = (targetKey: string) => {
    let newActiveKey = activeKey;
    const newPanes = subcode.filter((item: any) => item.code !== targetKey);
    if (targetKey === "New Tab") {
      newPanes.map((item: any) => {
        if (item.key !== "New Tab") {
          delete item.disabled;
        }
      });
      setSeletedNewIndustryCode(null);
      setSubCode(newPanes);
      industryRemapRef.current = [];
    } else {
      setSubCode(newPanes);
      industryRemapRef.current = [];
    }
    if (newPanes.length && newActiveKey === targetKey) {
      newActiveKey = newPanes[0].code;
      handleTabChange(newPanes[0].code);
    } else {
      newActiveKey = newPanes[0].code;
      handleTabChange(newPanes[0].code);
    }
    setSeletedIndustryCode(newActiveKey);

    setActiveKey(newActiveKey);
    if (targetKey === "New Tab") {
      notification.success({
        message: `Industry Code deleted!`,
        description: (
          <>
            <strong>{targetKey}</strong> has been deleted successfully.
          </>
        ),
        placement: "bottomRight",
        bottom: 50,
      });
      navigate("/industry_mapping", {
        replace: true,
        state: {
          mode: "edittemplate",
          refresh: true,
          code: newPanes[0].code,
          name: templateName,
          data: newPanes,
        },
      });
    } else {
      deleteTemplateCodeIndustry({ name: templateName, code: targetKey }).then(
        (res: any) => {
          if (res.data) {
            notification.success({
              message: `Industry Code deleted!`,
              description: (
                <>
                  <strong>{targetKey}</strong> has been deleted successfully.
                </>
              ),
              placement: "bottomRight",
              bottom: 50,
            });
            navigate("/industry_mapping", {
              replace: true,
              state: {
                mode: "edittemplate",
                refresh: true,
                code: newPanes[0].code,
                name: templateName,
                data: newPanes,
              },
            });
          } else {
            notification.error({
              message: `Failed to delete Industry Code!`,
              description: (
                <>
                  <strong>{targetKey}</strong> has not been deleted.
                </>
              ),
              placement: "bottomRight",
              bottom: 50,
            });
          }
        }
      );
    }
  };

  const handleTabChange = (key: string) => {
    // console.log("INDUSTRYREMAP", industryRemapRef.current);
    if (industryRemapRef.current.length !== 0) {
      Modal.warning({
        title: "Unable to switch",
        content: "Please save your changes before switching tab.",
        okText: "Ok",
        onOk() {},
      });
    } else {
      if (!key.includes("New Tab")) {
        setActiveKey(key);
        setSeletedIndustryCode(key);
        if (key !== null) {
          setLoading(true);
          // getFile().then((res: any) => {
          //   if (res.data) {
          //     res.data.map((k: any, i: any) => {
          //       if (key === k._id) {
          //         // setRic(k);
          //         // setRicChild(k?.child);
          //         const ricchilds = k.child.map((x: any) => ({
          //           label: x.code + " - " + x.description,
          //           value: x.code + " - " + x.description,
          //         }));
          //         setRicChild(ricchilds);
          //       }
          //     });
          //   }
          // });
          // ric.map((k: any, i: any) => {
          //   if (key === k._id) {
          //     const ricchilds = k.child.map((x: any) => ({
          //       label: x.code + " - " + x.description,
          //       value: x.code + " - " + x.description,
          //     }));
          //     setRicChild(ricchilds);
          //   }
          // });
          industryRemapRef.current = [];

          getAllIndustry().then((res: any) => {
            if (res.data) {
              const editdata = res.data.map((obj: any) => ({
                _id:
                  checkIndustryCode(obj.industrycode) + " - " + obj.description,
                _code: obj.mapping?.[templateName]
                  ? listCode(obj.mapping[templateName], key)
                  : "",
              }));
              let defaultdata: any = [];
              editdata.map((obj: any) => {
                if (obj._code !== "") {
                  obj._code.forEach((value: any) => {
                    defaultdata.push(value);
                  });
                }
              });
              selectedOptionRef.current = defaultdata;
              setData(editdata);
              setLoading(false);
            }
          });
        } else {
          refreshNewCode();
          selectedOptionRef.current = [];
        }
      } else {
        setActiveKey(key);
        if (key !== null) {
          getFile().then((res: any) => {
            if (res.data) {
              res.data.map((k: any, i: any) => {
                if (key === k._id) {
                  setRic(k);
                  setRicChild(k?.child);
                  const ricchilds = k.child.map((x: any) => ({
                    label: x.code + " - " + x.description,
                    value: x.code + " - " + x.description,
                  }));
                  setRicChild(ricchilds);
                }
              });
            }
          });
          industryRemapRef.current = [];

          // getAllIndustry().then((res: any) => {
          //   if (res.data) {
          //     const editdata = res.data.map((obj: any) => ({
          //       _id:
          //         checkIndustryCode(obj.industrycode) + " - " + obj.description,
          //       _code: listCode(obj.mapping[templateName], key),
          //     }));
          //     let defaultdata: any = [];
          //     editdata.map((obj: any) => {
          //       obj._code.forEach((value: any) => {
          //         defaultdata.push(value);
          //       });
          //     });
          //     setSelectedOption(defaultdata);
          //     setData(editdata);
          //     setLoading(false);
          //   }
          // });
        } else {
          refreshNewCode();
          selectedOptionRef.current = [];
        }
      }
    }
  };

  const checkCodeLength = (e: any) => {
    if (subcode.length > 4 && width < 2400) {
      const b = 12 - (subcode.length - 4);
      if (b <= 1) {
        return e.slice(0, 10) + "\u00A0\u00A0";
      } else {
        if (e.length > b) {
          return e.slice(0, 3) + "\u00A0\u00A0";
        } else {
          return e.slice(0, 3) + "...";
        }
      }
    } else {
      return e.slice(0, 3);
    }
  };

  return (
    <>
      {createmode === true && (
        <>
          {/* <div
            style={{
              height: "50px",
              margin: "auto",
              paddingLeft: "4%",
              paddingTop: "20px",
            }}
          >
            <Breadcrumb separator=">">{breadcrumbCOM(breadcrumb)}</Breadcrumb>
          </div> */}
          <div>
            <Form
              form={form}
              style={{
                width: "90%",
                paddingBottom: "150px",
                paddingTop: "15px",
              }}
              // labelAlign="left"
              // wrapperCol={{ span: 17 }}
              // layout="horizontal"
            >
              {formData.map((item: any) => (
                <Form.Item {...item}>{item.component}</Form.Item>
              ))}
              {/* <div style={{ width: "100%", marginTop: "20px" }}>
                <RT
                  tablewidth="90%"
                  hasSelectAll={true}
                  data={data}
                  cols={[
                    {
                      fieldname: "_id",
                      width: "50%",
                      label: "Core Code - Industry",
                      align: "left",
                      sortcolumn: "String",
                      filter: "true",
                      lookup: dropDownCoreCodeOptions,
                    },
                    {
                      fieldname: "_code",
                      width: "50%",
                      label: selectedindustrycode
                        ? `${selectedindustrycode} Code - Industry`
                        : "Code - Industry",
                      align: "left",
                      mode: "create",
                      selectMultiple: ricchild,
                      // sortcolumn: "String",
                      lookup: ricchild,
                      filter: "true",
                    },
                  ]}
                  pageControl={pageC}
                  loading={loading}
                  handleSelectedOption={handleSelectedOption}
                  selectedOption={selectedOption}
                  type={"small"}
                ></RT>
              </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  // border: "1px solid red",
                }}
              >
                <Button
                  type="link"
                  size="large"
                  style={{
                    background: "transparent",
                    borderRadius: "6px",
                    border: "0px",
                    cursor: "pointer",
                    color: "#DF4662",
                    textDecoration: "underline",
                    textDecorationColor: "#DF4662",
                    // marginRight: "-1%",
                  }}
                  onClick={resetFunction}
                >
                  Reset
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Tabs
                  tabPosition="left"
                  addIcon={
                    <>
                      + <b> Add</b>
                    </>
                  }
                  onEdit={onEdit}
                  hideAdd={true}
                  type="editable-card"
                  style={{
                    width: "86%",
                    paddingLeft: "4.8%",
                    paddingBottom: "0%",
                    marginBottom: "0px",
                  }}
                  items={createsubcode.map((tab: any) => ({
                    label: tab.code.includes("New Tab") ? (
                      <Select
                        listHeight={128}
                        getPopupContainer={(trigger) =>
                          trigger.parentElement.parentElement.parentElement
                            .parentElement.parentElement.parentElement
                        }
                        showSearch
                        style={{
                          minWidth: "130px",
                          height: "100%",
                          textAlign: "left",
                          padding: "2px 10px",
                          border: "0px solid",
                          marginRight: "20px",
                        }}
                        placeholder="Select Industry Code"
                        optionFilterProp="children"
                        onChange={selectRIC}
                        value={selectednewindustrycode}
                        filterOption={(input: any, option: any) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={ricparent}
                      />
                    ) : (
                      <>
                        <Badge
                          status={tab.draft === "true" ? "warning" : "success"}
                          style={{ marginRight: "5px" }}
                        />

                        {tab.code}
                      </>
                    ),
                    closable: false,
                    key: tab.code,
                    children: (
                      <>
                        <div style={{ width: "120%" }}>
                          <RT
                            tablewidth="100%"
                            hasSelectAll={true}
                            data={data}
                            cols={[
                              {
                                fieldname: "_id",
                                width: "30%",
                                label: "Core Code - Industry",
                                align: "left",
                                sortcolumn: "String",
                                filter: "true",
                                lookup: dropDownCoreCodeOptions,
                              },
                              {
                                fieldname: "_code",
                                width: "70%",
                                label: selectednewindustrycode
                                  ? `${selectednewindustrycode} Code - Industry`
                                  : "Code - Industry",
                                align: "left",
                                selectMultiple: dropDownCodeIndustryOptions,
                                lookup: dropDownCodeIndustryOptions,
                                filter: "true",
                              },
                            ]}
                            pageControl={pageC}
                            loading={loading}
                            handleSelectedOption={handleSelectedOption}
                            handleRefreshSelect={handleRefreshSelect}
                            handleEditSelectedOptions={
                              handleEditSelectedOptions
                            }
                            handleDeSelectedOptions={handleDeSelectedOptions}
                            selectedOption={selectedOptionRef.current}
                            resetValue={resetValue}
                            restoreFilter={restoreFilter}
                            selectednewindustrycode={selectednewindustrycode}
                            // action={["reset"]}
                            type={"small"}
                          ></RT>
                        </div>
                      </>
                    ),
                  }))}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // marginRight: "4.5%",
                  // marginLeft: "4.5%",
                  marginTop: "-30px",
                }}
              >
                <div>
                  {/* <Button
                    // className="button-green"
                    className="open-modal-reset-button"
                    htmlType="reset"
                    // style={{
                    //   margin: "5px",
                    //   color: "#AAAAAA",
                    //   border: "1px solid #AAAAAA",
                    // }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button> */}
                </div>
                {form.getFieldsValue().template_name !== null &&
                  form.getFieldsValue().template_name !== undefined &&
                  form.getFieldsValue().template_name !== "" &&
                  selectednewindustrycode !== null &&
                  selectedOptionRef.current.length > 0 && (
                    <div>
                      <Button
                        htmlType="submit"
                        className="open-modal-reset-button"
                        onClick={() => {
                          handleCancel();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        htmlType="submit"
                        className="open-modal-save-button"
                        onClick={() => {
                          handleEditDataUpdate();
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  )}
              </div>
            </Form>
          </div>
        </>
      )}
      {createmode === false && (
        <>
          {/* <div
            style={{
              height: "10%",
              margin: "auto",
              paddingLeft: "4%",
              paddingTop: "20px",
            }}
          >
            <Breadcrumb separator=">">{breadcrumbCOM(breadcrumb)}</Breadcrumb>
          </div> */}
          <div>
            <Form
              form={form}
              style={{
                width: "90%",
                paddingBottom: "150px",
                paddingTop: "15px",
              }}
              initialValues={{
                template_name: templateName,
              }}
            >
              {editformData.map((item: any) => (
                <Form.Item {...item}>{item.component}</Form.Item>
              ))}
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  // border: "1px solid red",
                }}
              >
                <Button
                  type="link"
                  size="large"
                  style={{
                    background: "transparent",
                    borderRadius: "6px",
                    border: "0px",
                    cursor: "pointer",
                    color: "#DF4662",
                    textDecoration: "underline",
                    textDecorationColor: "#DF4662",
                    marginLeft: "4%",
                  }}
                  onClick={resetFunction}
                >
                  Reset
                </Button>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <Tabs
                  tabPosition="left"
                  className="custom"
                  addIcon={
                    <>
                      + <b> Add</b>
                    </>
                  }
                  onEdit={onEdit}
                  hideAdd={selectedindustrycode === null ? true : false}
                  type="editable-card"
                  activeKey={activeKey}
                  style={{
                    width: "87%",
                    paddingLeft: "4.8%",
                    paddingBottom: "2px",
                    paddingTop: "2px",
                    marginBottom: "0px",
                  }}
                  onTabScroll={onTabScroll}
                  // tabBarStyle={{ width: "22%" }}
                  onChange={handleTabChange}
                  items={subcode.map((tab: any) => ({
                    label: tab.code.includes("New Tab") ? (
                      <Select
                        listHeight={128}
                        getPopupContainer={(trigger) =>
                          trigger.parentElement.parentElement.parentElement
                            .parentElement.parentElement.parentElement
                        }
                        showSearch
                        style={{
                          minWidth: "130px",
                          height: "100%",
                          textAlign: "left",
                          border: "0px solid",
                          padding: "2px 10px",
                          marginRight: "10px",
                        }}
                        placeholder="Select Industry Code"
                        optionFilterProp="children"
                        onChange={selectRIC}
                        value={selectednewindustrycode}
                        filterOption={(input: any, option: any) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={ricparent}
                      />
                    ) : (
                      <>
                        <Tooltip placement="top" title={<>{tab.code}</>}>
                          <div style={{ width: "200px", textAlign: "left" }}>
                            <Badge
                              status={
                                tab.draft === "true" ? "warning" : "success"
                              }
                              style={{
                                marginRight: "5px",
                                paddingBottom: "7px",
                                paddingTop: "7px",
                                height: "100%",
                              }}
                            />
                            {/* {checkCodeLength(tab.code)} */}
                            {tab.code}
                          </div>
                        </Tooltip>
                      </>
                    ),
                    key: tab.code,
                    disabled: tab.disabled ? true : false,
                    closeIcon: (
                      <>{activeKey === tab.code ? <CloseOutlined /> : ""}</>
                    ),
                    children: (
                      <>
                        {tab.code.includes("New Tab") ? (
                          <div style={{ width: "120%" }}>
                            <RT
                              tablewidth="100%"
                              hasSelectAll={true}
                              data={data}
                              cols={[
                                {
                                  fieldname: "_id",
                                  width: "30%",
                                  label: "Core Code - Industry",
                                  align: "left",
                                  sortcolumn: "String",
                                  filter: "true",
                                  lookup: dropDownCoreCodeOptions,
                                },
                                {
                                  fieldname: "_code",
                                  width: "70%",
                                  label: selectednewindustrycode
                                    ? `${selectednewindustrycode} Code - Industry`
                                    : "Code - Industry",
                                  align: "left",
                                  selectMultiple: dropDownCodeIndustryOptions,
                                  lookup: dropDownCodeIndustryOptions,
                                  filter: "true",
                                },
                              ]}
                              pageControl={pageC}
                              loading={loading}
                              handleSelectedOption={handleSelectedOption}
                              handleRefreshSelect={handleRefreshSelect}
                              handleEditSelectedOptions={
                                handleEditSelectedOptions
                              }
                              handleDeSelectedOptions={handleDeSelectedOptions}
                              selectedOption={selectedOptionRef.current}
                              resetValue={resetValue}
                              restoreFilter={restoreFilter}
                              selectednewindustrycode={selectednewindustrycode}
                              type={"small"}
                            ></RT>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                // marginRight: "4.5%",
                                // marginLeft: "4.5%",
                                marginTop: "-30px",
                              }}
                            >
                              <div>
                                {/* <Button
                    // className="button-green"
                    className="open-modal-reset-button"
                    htmlType="reset"
                    // style={{
                    //   margin: "5px",
                    //   color: "#AAAAAA",
                    //   border: "1px solid #AAAAAA",
                    // }}
                    onClick={() => {
                      handleEditClose();
                    }}
                  >
                    Close
                  </Button> */}
                              </div>
                              {/* {(selectedindustrycode !== null &&
                  Object.keys(industryRemapRef.current).length > 0) ||
                  (form.getFieldsValue().template_name !==
                    templatedefaultName && (
                    <div>
                      <Button
                        htmlType="submit"
                        className="open-modal-reset-button"
                        onClick={() => {
                          handleEditCancel();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        htmlType="submit"
                        className="open-modal-save-button"
                        onClick={() => {
                          handleEditDataUpdate();
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  ))} */}
                              {((selectedindustrycode !== null &&
                                Object.keys(industryRemapRef.current).length >
                                  0 &&
                                selectednewindustrycode === null) ||
                                (form.getFieldsValue().template_name !==
                                  templatedefaultName &&
                                  selectedindustrycode !== null &&
                                  selectednewindustrycode === null) ||
                                (form.getFieldsValue().template_name !==
                                  templatedefaultName &&
                                  selectedindustrycode === null &&
                                  selectednewindustrycode !== null &&
                                  selectedOptionRef.current.length > 0) ||
                                (selectedindustrycode === null &&
                                  selectednewindustrycode !== null &&
                                  selectedOptionRef.current.length > 0)) && (
                                <div>
                                  <Button
                                    htmlType="submit"
                                    className="open-modal-reset-button"
                                    onClick={handleEditCancel}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    htmlType="submit"
                                    className="open-modal-save-button"
                                    onClick={handleEditDataUpdate}
                                  >
                                    Save
                                  </Button>
                                </div>
                              )}

                              {/* {() && (
                  <div>
                    <Button
                      // className="button-green"
                      htmlType="submit"
                      className="open-modal-reset-button"
                      // style={{
                      //   margin: "5px",
                      //   color: "#AAAAAA",
                      //   border: "1px solid #AAAAAA",
                      // }}
                      onClick={() => {
                        handleEditCancel();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      // className="button-green"
                      htmlType="submit"
                      className="open-modal-save-button"
                      // style={{
                      //   margin: "5px",
                      //   color: "#FFFFFF",
                      //   backgroundColor: "#898989",
                      // }}
                      onClick={() => {
                        handleEditDataUpdate();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                )} */}
                            </div>
                          </div>
                        ) : (
                          <div style={{ width: "120%" }}>
                            <RT
                              tablewidth="100%"
                              hasSelectAll={true}
                              data={data}
                              cols={[
                                {
                                  fieldname: "_id",
                                  width: "30%",
                                  label: "Core Code - Industry",
                                  align: "left",
                                  sortcolumn: "String",
                                  filter: "true",
                                  lookup: dropDownCoreCodeOptions,
                                },
                                {
                                  fieldname: "_code",
                                  width: "70%",
                                  label: selectedindustrycode
                                    ? `${selectedindustrycode} Code - Industry`
                                    : "Code - Industry",
                                  align: "left",
                                  selectMultiple: dropDownCodeIndustryOptions,
                                  lookup: dropDownCodeIndustryOptions,
                                  filter: "true",
                                },
                              ]}
                              pageControl={pageC}
                              loading={loading}
                              handleSelectedOption={handleSelectedOption}
                              handleRefreshSelect={handleRefreshSelect}
                              etSelectedOption
                              handleEditSelectedOptions={
                                handleEditSelectedOptions
                              }
                              handleDeSelectedOptions={handleDeSelectedOptions}
                              selectedOption={selectedOptionRef.current}
                              resetValue={resetValue}
                              restoreFilter={restoreFilter}
                              selectednewindustrycode={selectednewindustrycode}
                              type={"small"}
                            ></RT>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                // marginRight: "4.5%",
                                // marginLeft: "4.5%",
                                marginTop: "-30px",
                              }}
                            >
                              <div>
                                {/* <Button
                    // className="button-green"
                    className="open-modal-reset-button"
                    htmlType="reset"
                    // style={{
                    //   margin: "5px",
                    //   color: "#AAAAAA",
                    //   border: "1px solid #AAAAAA",
                    // }}
                    onClick={() => {
                      handleEditClose();
                    }}
                  >
                    Close
                  </Button> */}
                              </div>
                              {/* {(selectedindustrycode !== null &&
                  Object.keys(industryRemapRef.current).length > 0) ||
                  (form.getFieldsValue().template_name !==
                    templatedefaultName && (
                    <div>
                      <Button
                        htmlType="submit"
                        className="open-modal-reset-button"
                        onClick={() => {
                          handleEditCancel();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        htmlType="submit"
                        className="open-modal-save-button"
                        onClick={() => {
                          handleEditDataUpdate();
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  ))} */}
                              {((selectedindustrycode !== null &&
                                Object.keys(industryRemapRef.current).length >
                                  0 &&
                                selectednewindustrycode === null) ||
                                (form.getFieldsValue().template_name !==
                                  templatedefaultName &&
                                  selectedindustrycode !== null &&
                                  selectednewindustrycode === null) ||
                                (form.getFieldsValue().template_name !==
                                  templatedefaultName &&
                                  selectedindustrycode === null &&
                                  selectednewindustrycode !== null &&
                                  selectedOptionRef.current.length > 0) ||
                                (selectedindustrycode === null &&
                                  selectednewindustrycode !== null &&
                                  selectedOptionRef.current.length > 0)) && (
                                <div>
                                  <Button
                                    htmlType="submit"
                                    className="open-modal-reset-button"
                                    onClick={handleEditCancel}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    htmlType="submit"
                                    className="open-modal-save-button"
                                    onClick={handleEditDataUpdate}
                                  >
                                    Save
                                  </Button>
                                </div>
                              )}

                              {/* {() && (
                  <div>
                    <Button
                      // className="button-green"
                      htmlType="submit"
                      className="open-modal-reset-button"
                      // style={{
                      //   margin: "5px",
                      //   color: "#AAAAAA",
                      //   border: "1px solid #AAAAAA",
                      // }}
                      onClick={() => {
                        handleEditCancel();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      // className="button-green"
                      htmlType="submit"
                      className="open-modal-save-button"
                      // style={{
                      //   margin: "5px",
                      //   color: "#FFFFFF",
                      //   backgroundColor: "#898989",
                      // }}
                      onClick={() => {
                        handleEditDataUpdate();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                )} */}
                            </div>
                          </div>
                        )}
                      </>
                    ),
                  }))}
                />
              </div>
            </Form>
          </div>
        </>
      )}
    </>
  );
};

export default MyComponent;
